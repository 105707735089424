import Vue from 'vue'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import VueDraggableResizable from 'vue-draggable-resizable'
Vue.component('vue-draggable-resizable', VueDraggableResizable)
import Contextmenu from 'vue-contextmenujs'
Vue.use(Contextmenu)
import Cookies from 'js-cookie'
import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import Element from 'element-ui'
import './styles/element-variables.scss'
import '@/styles/index.scss' // global css
import AlloyFinger from 'alloyfinger'
import AlloyFingerPlugin from 'alloyfinger/vue/alloy_finger_vue'
Vue.use(AlloyFingerPlugin, { AlloyFinger })
import vueCity from 'vue-city-select'
Vue.use(vueCity)
import App from './App'
import router from './router'
import i18n from './lang'
import Rem from './utils/rem'
// import { param2Obj } from '@/utils'
// import Vconsole from 'vconsole'
// eslint-disable-next-line
// if (env === 'dev' || env === 'test') {
//   const vConsole = new Vconsole()
//   Vue.prototype.vConsole = vConsole
// }
// const params = param2Obj(window.location.hash)
// if (params.lang && params.lang.toLowerCase() === 'en') {
//   Cookies.set('language', 'en')
// } else {
//   Cookies.set('language', 'zh')
// }
Vue.use(Element, {
  size: Cookies.get('size') || 'medium',
  i18n: (key, value) => i18n.t(key, value)
})
Vue.config.productionTip = false
new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App)
})
Rem.setFontSize()
