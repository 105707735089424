import conf from '@/js/conf/conf'
import PullTo from 'vue-pull-to'
import emptyImage from '@/assets/empty.png'
import utils from '@/js/utils'
import service from '@/js/service'
export const mixins = {
  components: {
    PullTo
  },
  props: {
    view: {
      type: Object,
      default() {
        return {}
      }
    },
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    db: {
      type: Object,
      default() {
        return {}
      }
    },
    file: {
      type: Object,
      default() {
        return {}
      }
    },
    mod: {
      type: String,
      default() {
        return 'all'
      }
    },
    params: {
      type: Object,
      default() {
        return {}
      }
    },
    thumbSize: {
      type: Number,
      default: 380
    },
    pageSize: {
      type: Number,
      default: 15
    },
    loginStatus: {
      type: Boolean,
      default: false
    },
    loginUserId: {
      type: Number,
      default: 0
    },
    subtractHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    const schema = {
      scrollTop: 0,
      // 瀑布流各列数据
      columns: [],
      items: [],
      publishes: {},
      works: {},
      initMessageTypeItem: false,
      currentPage: 0,
      cursorValue: 0,
      cursorValueUp: 0,
      cursorValueDown: 0,
      cursorSkipUp: 0,
      cursorSkipDown: 0,
      skip: 0,
      noUpPage: false,
      noDownPage: false,
      deleteMod: false,
      deleteIds: {}
    }
    let container_width, container_height
    if (this.state.platform.type === 'desktop') {
      container_width = 632
      container_height = 632
    } else {
      container_width = utils.width()
      container_height = utils.height()
    }
    const data = {
      worksHost: conf.hosts().worksHost,
      orderTypes: conf.orderTypes(),
      container_width: container_width,
      container_height: container_height,
      width: container_width - 3,
      height: container_height - 95,
      renew: false,
      fixHeight: 100,
      // 达到保留页数时，重新渲染列表
      remainPages: 100,
      userid: 0,
      dialogId: 0,
      showUp: false,
      emptyImage: emptyImage,
      schema: schema,
      dt: {
        'all': utils.deepClone(schema),
        'paying': utils.deepClone(schema),
        'paid': utils.deepClone(schema),
        'cancel': utils.deepClone(schema)
      },
      loading: {
        'all': false,
        'paying': false,
        'paid': false,
        'cancel': false
      }
    }
    return data
  },
  computed: {
    isMobile() {
      return this.state.platform.type === 'mobile'
    },
    isDesktop() {
      return this.state.platform.type === 'desktop'
    },
    reqId() {
      return this.state.reqId
    },
    maxColumns() {
      return Math.max(Math.floor(this.width / (this.thumbSize + 10)), 1)
    },
    columnWidth() {
      return Math.round(this.width / this.maxColumns)
    },
    cardWidth() {
      return this.columnWidth - 10
    },
    // 瀑布流高度
    flowHeight() {
      let height = 0
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const colHeight = its[its.length - 1]._height + its[its.length - 1]._top
        if (colHeight > height) height = colHeight
      }
      return height
    },
    headHeight() {
      return (!this.isDesktop && this.config.showHead) ? 40 : 0
    },
    listStyle() {
      const fixHeight = 0
      if (this.subtractHeight) return { 'height': 'calc(100% - ' + this.subtractHeight + 'px)', 'margin-top': '5px' }
      return { 'height': 'calc(100% - ' + (5 + this.headHeight + fixHeight) + 'px)', 'margin-top': (5 + this.headHeight) + 'px' }
    },
    config() {
      return this.view.nftorder
    },
    users() {
      return this.state.users
    },
    data() {
      return this.dt[this.mod]
    },
    items() {
      return this.data.items
    },
    itemNums() {
      return this.data.items.length
    },
    columns() {
      return this.data ? this.data.columns : []
    },
    limit() {
      const limit = this.pageSize
      if (!this.isMobile) return limit * 2
      return limit
    }
  },
  watch: {
    // 'view.nftorder.show': {
    //   handler(show) {
    //     this.data.canDelete = false
    //     this.data.deleteMod = false
    //     this.data.deleteIds = {}
    //     this.update()
    //     if (this.config.type === this.mod && show) {
    //       if (!this.itemNums) this.getData('down')
    //       this.deleteItems()
    //     }
    //   }
    // },
    'view.nftorder.type': {
      handler() {
        this.data.canDelete = false
        this.data.deleteMod = false
        this.data.deleteIds = {}
        this.update()
        if (this.config.type === this.mod && this.config.show) {
          if (!this.config.pop) this.emit('setLocationHash')
          this.deleteItems()
          if (!this.itemNums) this.getData('down')
        }
      }
    },
    'view.nftorder.renew': {
      handler() {
        if (this.config.type === this.mod && this.config.show) this.renewView()
      }
    }
    // ,
    // 'loginUserId': {
    //   handler() {
    //     this.init()
    //   }
    // }
  },
  mounted() {
    this.calcSize()
    const container = this.scrollContainer()
    if (container) container.onscroll = () => { this.data.scrollTop = container.scrollTop }
    window.addEventListener('resize', this.onResize)
    if (this.config.type === this.mod && this.config.show && !this.itemNums) this.getData('down')
  },
  methods: {
    // 订单支付
    payIt(order) {
      const pay_cb = (status) => {
        if (status) {
          this.setItem(order.nft_orderid, 'pay_status', 1)
          this.update()
        }
      }
      if ((order.type === 1 && order.preorder_status === 1) || order.type === 2 || order.type === 3) {
        this.showPayment(order, pay_cb)
      }
    },
    showPayment(order, cb) {
      this.view.pop.title = this.$t('i.pay')
      this.view.pop.tip = ''
      this.view.pop.type = 'payment_nft'
      this.view.pop.data = order || {}
      this.view.pop.show = true
      this.view.pop.fn = cb
    },
    longTap(id) {
      if (this.data.deleteMod) {
        this.emit('cancelDelete')
      } else {
        this.data.deleteMod = true
        this.data.deleteIds[id] = true
        this.emit('setDeleteData', [this.data.deleteMod, this.data.deleteIds])
        this.update()
      }
    },
    select(id) {
      if (this.data.deleteIds[id]) {
        delete this.data.deleteIds[id]
      } else {
        this.data.deleteIds[id] = true
      }
      this.emit('setDeleteData', [this.data.deleteMod, this.data.deleteIds])
      this.update()
    },
    cancelDelete() {
      this.data.deleteMod = false
      this.data.deleteIds = {}
      this.update()
    },
    // 转场
    goto(mod, dt, type) {
      if (type) {
        this.config.type = type
        return
      }
      this.emit('goto', [mod, dt])
    },
    // 逻辑删除
    deleteItems() {
      let deleteIds = {}
      if (this.mod === 'all') deleteIds = this.state.deleteAllNFTorder
      if (this.mod === 'paying') deleteIds = this.state.deletePayingNFTorder
      if (this.mod === 'paid') deleteIds = this.state.deletePaidNFTorder
      if (this.mod === 'cancel') deleteIds = this.state.deleteCancelNFTorder
      const ids = []
      for (var id in deleteIds) {
        ids.push(parseInt(id))
      }
      if (ids.length) {
        const items = []
        for (var i in this.data.items) {
          if (ids.indexOf(this.data.items[i].nft_orderid) < 0) items.push(this.data.items[i])
        }
        this.data.items = items
        if (this.mod === 'all') this.state.deleteAllNFTorder = {}
        if (this.mod === 'paying') this.state.deletePayingNFTorder = {}
        if (this.mod === 'paid') this.state.deletePaidNFTorder = {}
        if (this.mod === 'cancel') this.state.deleteCancelNFTorder = {}
        this.renderItems(this.data.items, true)
      }
      this.data.canDelete = false
      this.data.deleteMod = false
      this.data.deleteIds = {}
      this.update()
    },
    // 移除
    removeItem(id) {
      for (var i in this.data.items) {
        if (this.data.items[i].nft_orderid === id) {
          this.data.items.splice(i, 1)
        }
      }
      this.renderItems(this.data.items, true)
    },
    // 设置属性
    setItem(id, key, value) {
      for (var i in this.data.items) {
        if (this.data.items[i].nft_orderid === id) {
          this.data.items[i][key] = value
          return this.data.items[i]
        }
      }
    },
    scrollContainer() {
      const main = document.getElementById('nftorder-' + this.mod + '-container')
      if (main) {
        const scroll = main.getElementsByClassName('scroll-container')
        if (scroll.length) return scroll[0]
      }
    },
    onResize() {
      if (!this.config.show) return
      this.calcSize()
      // this.backTop()
      // this.data.scrollTop = 0
      this.renderItems(this.data.items, true)
    },
    backTop() {
      const container = this.scrollContainer()
      if (container) {
        setTimeout(() => {
          container.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        }, 100)
      }
    },
    renewView() {
      this.backTop()
      this.loadData(() => {}, 'renew')
    },
    showItem(item) {
      if (item._top <= this.data.scrollTop - this.height && item._top + item._height >= this.data.scrollTop - this.height) return true
      if (item._top >= this.data.scrollTop - this.height && item._top <= this.data.scrollTop + this.height + this.height) return true
      return false
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    formateMoney: utils.formateMoney,
    // 调用父组件方法
    emit(fn, params) {
      if (!fn) return
      this.$emit('receive', fn, params || [])
    },
    // 提示信息
    message(msg, type) {
      this.emit('message', [msg, type])
    },
    alert(msg, fn) {
      this.emit('alert', [msg, fn])
    },
    // 确认操作
    confirm(msg, fn, title) {
      this.emit('confirm', [msg, fn, title])
    },
    getMinHeight(arr) {
      const a = []
      for (let i = 0; i < arr.length; i++) {
        a.push(parseInt(arr[i]._height) + parseInt(arr[i]._top))
      }
      return Math.min.apply(null, a)
    },
    getMinIndex(val) {
      const arrIndex = []
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const height = its[its.length - 1]._height
        const top = its[its.length - 1]._top
        if (parseInt(height) + parseInt(top) === val) {
          arrIndex.push(i)
        }
      }
      return arrIndex
    },
    renderItems(items, refresh = false) {
      if (refresh) this.data.columns = []
      if (!items.length) return
      // 初始化列、列的第一个元素
      let idx = 0
      if (this.columns.length < this.maxColumns || refresh) {
        for (let i = 0; i < this.maxColumns; i++) {
          if (!this.columns[i] && items[idx]) {
            items[idx]._top = 0
            items[idx]._raw_width = this.thumbSize
            items[idx]._raw_height = 0
            items[idx]._height = items[idx]._raw_height + this.fixHeight
            this.columns.push({ items: [items[idx]] })
            idx++
          }
        }
      }
      // 对剩余元素的判断，应该放到哪一列
      for (var index = idx; index < items.length; index++) {
        // 找到高度最小的一列，可能有多个
        const tmp = []
        for (let i = 0; i < this.columns.length; i++) {
          const its = this.columns[i].items
          tmp.push({
            _height: its[its.length - 1]._height,
            _top: its[its.length - 1]._top
          })
        }
        // 获取最矮的列高度
        const minHeight = this.getMinHeight(tmp)
        // 获取最矮列的索引
        let minHeightIdx = 0
        const arrIndex = this.getMinIndex(minHeight)
        // 出现高度一样的，取索引最小的
        if (arrIndex.length > 0) {
          minHeightIdx = Math.min.apply(null, arrIndex)
        }
        // 设置属性
        items[index]._top = minHeight
        items[index]._raw_width = this.thumbSize
        items[index]._raw_height = 0
        items[index]._height = items[index]._raw_height + this.fixHeight
        this.columns[minHeightIdx].items.push(items[index])
      }
    },
    calcWorkSize(w, h) {
      if (w < 80 && h < 80) return { width: w, height: h }
      let width, height
      if (w > h) {
        width = 80
        height = Math.round(width * h / w)
      } else {
        height = 80
        width = Math.round(height * w / h)
      }
      return { width: width, height: height }
    },
    imgStyle(work) {
      if (!work) return {}
      const size = this.calcWorkSize(work.width, work.height)
      const left = (80 - size.width) / 2
      const top = (80 - size.height) / 2
      return { 'padding-left': left + 'px', 'padding-top': top + 'px' }
    },
    calcSize() {
      if (this.state.platform.type === 'desktop') {
        this.container_width = 632
        this.container_height = 632
      } else {
        this.container_width = utils.width()
        this.container_height = utils.height()
      }
      this.width = this.container_width - 3
      this.height = this.container_height - 95 - this.headHeight
    },
    openDetail(orderid) {
      if (!orderid) return
      this.emit('openPopPage', [{ 'type': 'nftorder', 'orderid': orderid }])
    },
    init(backTop) {
      if (backTop) this.backTop()
      this.dt[this.mod] = utils.deepClone(this.schema)
      this.emit('setDeleteData', [false, []])
    },
    update() {
      this.$forceUpdate()
    },
    updateData(updateHash) {
      if (updateHash && !this.config.pop) this.emit('setLocationHash')
      if (!this.itemNums) {
        this.getData('down')
      } else {
        this.update()
      }
    },
    getAvatar(item) {
      if (!item || !item.avatar) return ''
      const ver = item.avatar_at ? Math.round(new Date(item.avatar_at) / 1000) : ''
      return service.getCosUrl(item.avatar, 'avatar', ver) || ''
    },
    getContent(item, split) {
      if (!item || !item.summary) return ''
      return utils.fomateContent(item.summary, split)
    },
    loadData(loaded, direction) {
      if (this.config.show) {
        this.renew = direction === 'renew'
        if (direction === 'renew') {
          direction = 'down'
          this.data.currentPage = 0
          this.data.cursorValue = 0
          this.data.cursorValueUp = 0
          this.data.cursorValueDown = 0
          this.data.cursorSkipUp = 0
          this.data.cursorSkipDown = 0
          this.data.skip = 0
          this.data.noUpPage = false
          this.data.noDownPage = false
        }
        this.getData(direction, () => {
          this.renew = false
          loaded('done')
        })
      }
    },
    // 分页读取数据
    async getData(direction, cb) {
      direction = direction || 'down'
      if (!this.config.show) return cb && cb()
      if (direction === 'up' && this.data.noUpPage) return cb && cb()
      if (direction === 'down' && this.data.noDownPage) return cb && cb()
      if (this.loading[this.mod]) return cb && cb()
      this.loading[this.mod] = true
      const params = {
        'sort_field': 'nft_orderid',
        'sort': 'desc',
        'limit': this.limit,
        'skip': direction === 'up' ? this.data.cursorSkipUp : this.data.cursorSkipDown,
        'cursor_value': direction === 'up' ? this.data.cursorValueUp : this.data.cursorValueDown,
        'direction': direction
      }
      if (this.mod === 'paying') {
        params.pay_status = 0
      } else if (this.mod === 'paid') {
        params.pay_status = 1
      } else if (this.mod === 'cancel') {
        params.pay_status = -2
      }
      // console.error('params', this.mod, params, direction, this.data.currentPage, this.data)
      service.listGet('nft_order', params, (res, type) => {
        if (type === 'success') {
          let refresh = false
          if (direction === 'up') {
            this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorSkipUp = res.data.cursor_skip_up
            if (!this.data.cursorValueDown && !utils.isEmpty(res.data.cursor_value_down)) this.data.cursorValueDown = res.data.cursor_value_down
            this.data.noUpPage = utils.isEmpty(this.data.cursorValueUp)
            this.data.items = res.data.items.concat(this.data.items)
          } else if (direction === 'down') {
            if ((res.data.count === this.limit && this.data.currentPage >= this.remainPages) || this.renew) {
              if (res.data.count === this.limit && this.data.currentPage >= this.remainPages) {
                this.init(true)
                this.showUp = true
              } else {
                this.init()
              }
              refresh = true
            }
            this.data.currentPage++
            if (!this.data.cursorValueUp && !utils.isEmpty(res.data.cursor_value_up)) this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorValueDown = res.data.cursor_value_down
            this.data.cursorSkipDown = res.data.cursor_skip_down
            this.data.noDownPage = utils.isEmpty(this.data.cursorValueDown)
            this.data.items = this.data.items.concat(res.data.items)
          }
          this.data.skip = utils.getInt(res.data.skip)
          if (res.data.users) {
            for (var userid in res.data.users) {
              this.users[userid] = res.data.users[userid]
            }
          }
          if (res.data.works) {
            for (var workid in res.data.works) {
              this.data.works[workid] = res.data.works[workid]
            }
          }
          if (res.data.publishes) {
            for (var publishid in res.data.publishes) {
              this.data.publishes[publishid] = res.data.publishes[publishid]
            }
          }
          if (direction === 'up') {
            this.renderItems(this.data.items, true)
          } else {
            this.renderItems(res.data.items, refresh)
          }
        } else {
          this.message(res, type)
        }
        cb && cb()
        this.loading[this.mod] = false
      }, false, false)
    }
  }
}
