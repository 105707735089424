<template>
  <el-dialog
    ref="threadDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    :custom-class="isDesktop ? 'pop_page noscroll' : 'pop_page noscroll headless'"
  >
    <div v-if="isDesktop" slot="title" class="header">
      <div class="middle">
        <div class="user margin-left4">
          <div class="l" @click="emit('goto', ['user', { userid: user.userid } ])">
            <span v-if="!user.avatar" class="el-avatar el-avatar--circle noavatar small">
              <i class="iconfont my-man" />
            </span>
            <el-avatar v-if="user.avatar" :size="24" :src="getAvatar()" />
          </div>
          <div class="m">
            {{ user.nickname || view.anonymity }}
          </div>
          <div class="r">
            <el-button v-if="(followState[user.userid] === 0)" class="tap-btn on" @click="unfollow(user.userid)">{{ $t('i.hadFollow') }}</el-button>
            <el-button v-if="(followState[user.userid] === 1)" class="tap-btn on" @click="unfollow(user.userid)">{{ $t('i.followEach') }}</el-button>
            <el-button v-if="(followState[user.userid] !== 0 && followState[user.userid] !== 1)" class="tap-btn" @click="follow(user.userid)">{{ $t('i.follow') }}</el-button>
          </div>
        </div>
      </div>
      <div class="float-right align-right">
        <el-button class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <div v-if="isDesktop" ref="header-card">
        <div class="my-row album-info round8">
          <el-row class="title">
            <span v-if="!album.check" class="tag bg-red white">{{ $t('i.lockUp') }}</span>
            <!-- <span v-if="album.original" class="tag">{{ $t('i.origin') }}</span> <span v-if="album.best" class="tag">{{ $t('i.recomman') }}</span> -->
            {{ album.name || '' }}
          </el-row>
          <el-row class="total">{{ total ? total + ' ' + $t('i.works') : '' }} </el-row>
        </div>
      </div>
      <div v-if="!isDesktop" ref="header-card" class="header-card large" :style="albumStyle">
        <div class="header">
          <div class="left">
            <el-button class="tap-btn" @click="closePop(config)">
              <i class="iconfont my-back" />
            </el-button>
          </div>
          <div class="middle">
            <span v-if="largeMod">{{ $t('i.albumDes') }}</span>
            <div v-if="!largeMod" class="user">
              <div class="l" @click="emit('goto', ['user', { userid: user.userid } ])">
                <span v-if="!user.avatar" class="el-avatar el-avatar--circle noavatar small">
                  <i class="iconfont my-man" />
                </span>
                <el-avatar v-if="user.avatar" :size="24" :src="getAvatar()" />
              </div>
              <div class="m">
                {{ user.nickname || view.anonymity }}
              </div>
              <div class="r">
                <el-button v-if="(followState[user.userid] === 0)" class="tap-btn on" @click="unfollow(user.userid)">{{ $t('i.hadFollow') }}</el-button>
                <el-button v-if="(followState[user.userid] === 1)" class="tap-btn on" @click="unfollow(user.userid)">{{ $t('i.followEach') }}</el-button>
                <el-button v-if="(followState[user.userid] !== 0 && followState[user.userid] !== 1)" class="tap-btn" @click="follow(user.userid)">{{ $t('i.follow') }}</el-button>
              </div>
            </div>
          </div>
          <div class="right">
            <el-button class="tap-btn" @click="showActionSheet(album)">
              <i class="iconfont my-dot-more" />
            </el-button>
          </div>
        </div>
        <div v-if="largeMod" class="my-row album-info">
          <el-row class="title">
            <span v-if="!album.check" class="tag bg-red white">{{ $t('i.lockUp') }}</span>
            <!-- <span v-if="album.original" class="tag">{{ $t('i.origin') }}</span> <span v-if="album.best" class="tag">{{ $t('i.recomman') }}</span> -->
            {{ album.name || '' }}
          </el-row>
          <el-row class="total">{{ total ? total + $t('i.works') : '' }} </el-row>
          <el-row>
            <div @click="emit('goto', ['user', { userid: user.userid } ])">
              <span v-if="!user.avatar" class="el-avatar el-avatar--circle noavatar xlarge">
                <i class="iconfont my-man" />
              </span>
              <el-avatar v-if="user.avatar" :size="64" :src="getAvatar()" />
            </div>
          </el-row>
          <el-row>
            <el-col :span="4">&nbsp;</el-col>
            <el-col :span="16">
              {{ user.nickname || view.anonymity }}
              <span v-if="user.userid !== loginUserId" class="btns">
                <el-button v-if="(followState[user.userid] === 0)" class="opacity-bg" @click="unfollow(user.userid)">{{ $t('i.hadFollow') }}</el-button>
                <el-button v-if="(followState[user.userid] === 1)" class="opacity-bg" @click="unfollow(user.userid)">{{ $t('i.followEach') }}</el-button>
                <el-button v-if="(followState[user.userid] !== 0 && followState[user.userid] !== 1)" class="opacity-bg" @click="follow(user.userid)">{{ $t('i.follow') }}</el-button>
              </span>
            </el-col>
            <el-col :span="4">
              &nbsp;
            <!-- <el-button class="tap-btn" @click="toggleHead">
            <i v-if="!largeMod" class="iconfont my-open-down color-99" />
            <i v-if="largeMod" class="iconfont my-close-up color-99" />
            </el-button> -->
            </el-col>
          </el-row>
        </div>
      </div>
      <works-list
        v-show="config.show"
        ref="works-album"
        mod="album"
        :view="view"
        :state="state"
        :subtract-height="subtractHeight"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :params="album"
        :is-pop="true"
        @receive="handle"
      />
    </div>
    <div solt="footer" class="footer album" :style="{'position': isDesktop ? 'absolute' : ''}">
      <ul>
        <li>
          <el-badge :value="formateNums(albumCount.praise_count)" :hidden="!albumCount.praise_count">
            <el-button class="tap-btn" @click="() => praiseIt(album.albumid)">
              <i class="iconfont" :class="{ 'my-praise-fill': albumCount.praise_state, 'my-praise-blank': !albumCount.praise_state }" />
            </el-button>
          </el-badge>
        </li>
        <li>
          <el-badge :value="formateNums(albumCount.favor_count)" :hidden="!albumCount.favor_count">
            <el-button class="tap-btn" @click="() => favorIt(album.albumid)">
              <i class="iconfont" :class="{ 'my-star': albumCount.favor_state, 'my-blank-star': !albumCount.favor_state }" />
            </el-button>
          </el-badge>
        </li>
        <li>
          <el-badge :value="formateNums(albumCount.share_count)" :hidden="!albumCount.share_count">
            <el-button class="tap-btn" @click="() => shareIt(album.albumid)">
              <i class="iconfont clipboard" :class="{ 'my-share': !albumCount.share_state, 'my-share-fill': albumCount.share_state }" :data-clipboard-text="' ' + $t('i.gridyAblum') + ': ' + album.name + ' ' + mainHost + '#/?mod=albumWorks&albumid=' + album.albumid" />
            </el-button>
          </el-badge>
        </li>
      </ul>
    </div>
  </el-dialog>
</template>
<script>
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
import service from '@/js/service'
import worksList from '@/components/web/worksList'
import { mixins } from '@/mixins/common'
export default {
  components: {
    worksList
  },
  mixins: [mixins],
  data() {
    const data = {
      worksHost: conf.hosts().worksHost,
      hideHead: false,
      scrollTop: 0,
      subtractHeight: 180,
      total: 0,
      album: {},
      user: {}
    }
    data.albumCount = {}
    data.mainHost = conf.hosts().mainHost
    return data
  },
  computed: {
    largeMod() {
      return this.scrollTop < 180
      // return !this.hideHead
    },
    albumStyle() {
      const style = {}
      style['background-color'] = 'var(--color-28)'
      let bg = this.getBackground()
      // if (!bg) bg = this.worksHost + utils.getDefaultBg(this.config.albumid || 0) + '/background'
      if (!bg) bg = utils.getDefaultBg(this.config.albumid || 0)
      if (bg && this.largeMod && !this.view.albumWorks.loading) {
        style['background-image'] = 'url(' + bg + ')'
        style['background-position'] = 'center center'
        style['background-size'] = '100% auto'
        style['background-repeat'] = 'no-repeat'
      } else {
        style['padding-top'] = '30px'
      }
      return style
    },
    config() {
      return this.view.albumWorks
    }
  },
  watch: {
    'view.albumWorks.show': {
      handler() {
        if (this.view.albumWorks.show) {
          this.addFootprint()
          this.update()
          this.view.albumWorks.loading = false
          if (this.config.albumid && this.config.albumid !== this.album.albumid) {
            this.hideHead = false
            this.scrollTop = 0
            this.total = 0
            this.album = {}
            this.user = {}
            this.getAlbum(true)
          }
        }
      }
    },
    'view.album.renew': {
      handler() {
        this.updateItems()
      }
    }
  },
  methods: {
    updateData() {
      this.callSub('works-album', 'updateData')
    },
    toggleHead() {
      this.hideHead = !this.hideHead
    },
    handle(act, data) {
      if (act === 'setScrollTop') {
        this.setScrollTop(...data)
      } else if (act === 'setTotal') {
        this.setTotal(...data)
      } else if (act === 'decTotal') {
        this.total--
      } else {
        this.emit(act, data)
      }
    },
    setTotal(total) {
      this.total = total || 0
    },
    setScrollTop(scrollTop) {
      this.scrollTop = scrollTop
      this.update()
    },
    // 更新数据
    updateItems() {
      const data = this.state.updateAlbum[this.album.albumid]
      if (data) {
        for (const k in data) {
          this.album[k] = data[k]
        }
      }
      this.update()
    },
    renewView() {
      const el = this.$refs['works-album']
      if (el) el.renewView()
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    getElementHeight(refid) {
      return this.$refs[refid] ? parseInt(window.getComputedStyle(this.$refs[refid]).height) : 0
    },
    getAvatar() {
      const ver = Math.round(new Date(this.user.avatar_at) / 1000)
      if (this.user.avatar) return service.getCosUrl(this.user.avatar, 'avatar', ver) || ''
    },
    getBackground() {
      const ver = Math.round(new Date(this.album.update_at) / 1000)
      if (this.album.background) return service.getCosUrl(this.album.background, 'album_background', ver) || ''
    },
    update() {
      this.$forceUpdate()
      setTimeout(() => {
        const cardHeight = this.getElementHeight('header-card')
        this.subtractHeight = cardHeight + 50
      }, 10)
    },
    showActionSheet(item) {
      this.emit('showActionSheet', [item.userid, 'album', item, (id, data, dt, type) => {
        if (type === 'success') {
          for (const k in data) {
            item[k] = data[k]
          }
          // 刷新数据
          if (item.public && item.check && item.flag) {
            delete this.state.deleteHotAlbum[id]
            delete this.state.deleteBestAlbum[id]
            delete this.state.deleteOriginalAlbum[id]
            delete this.state.deleteAlbum[id]
            delete this.state.updateAlbum[id]
          }
          if (!item.hot) {
            this.state.deleteHotAlbum[id] = true
          }
          if (!item.best) {
            this.state.deleteBestAlbum[id] = true
          }
          if (!item.original) {
            this.state.deleteOriginalAlbum[id] = true
          }
          if (!item.public || !item.check || !item.flag) {
            this.state.deleteHotAlbum[id] = true
            this.state.deleteBestAlbum[id] = true
            this.state.deleteOriginalAlbum[id] = true
            this.state.deleteAlbum[id] = true
          }
          this.state.updateAlbum[id] = data
          this.view.album.renew = utils.time('time')
        }
      }])
    },
    // 关注
    follow(userid) {
      this.emit('follow', [userid, this.update])
      this.update()
    },
    // 取消关注
    unfollow(userid) {
      this.emit('unfollow', [userid, this.update])
      this.update()
    },
    // 喜欢
    praiseIt(albumid) {
      if (!albumid) return
      const praise = () => {
        if (this.albumCount.praise_state) {
          this.albumCount.praise_state = 0
          this.albumCount.praise_count--
          service.decCount(this.loginUserId, 'album_praise')
        } else {
          this.albumCount.praise_state = 1
          this.albumCount.praise_count++
          service.incCount(this.loginUserId, 'album_praise')
        }
        this.albumCount.praise_count = Math.max(0, this.albumCount.praise_count)
        this.update()
      }
      praise()
      service.get('album_praise', albumid || 0, (dt, type) => {
        if (type !== 'success') {
          // 失败回滚
          praise()
          this.message(dt, type)
        }
      }, true)
    },
    // 收藏
    favorIt(albumid) {
      if (!albumid) return
      const favor = () => {
        if (this.albumCount.favor_state) {
          this.albumCount.favor_state = 0
          this.albumCount.favor_count--
          service.decCount(this.loginUserId, 'album_favor')
        } else {
          this.albumCount.favor_state = 1
          this.albumCount.favor_count++
          service.incCount(this.loginUserId, 'album_favor')
        }
        this.albumCount.favor_count = Math.max(0, this.albumCount.favor_count)
        this.update()
      }
      favor()
      service.get('album_favor', albumid, (dt, type) => {
        if (type !== 'success') {
          // 失败回滚
          favor()
          this.message(dt, type)
        }
      }, true)
    },
    // 分享
    shareIt(albumid) {
      this.albumCount.share_state = 1
      this.albumCount.share_count++
      this.update()
      // eslint-disable-next-line
      var clipboard = new ClipboardJS('.clipboard')
      clipboard.on('success', (e) => {
        e.clearSelection()
        this.message(this.$t('i.copyAndShare'), 'success')
        clipboard.destroy()
      })
      service.get('album_share', albumid)
      service.incCount(this.loginUserId, 'album_share')
    },
    getAlbum(renew) {
      if (this.view.albumWorks.loading) return
      this.view.albumWorks.loading = true
      service.get('album', this.view.albumWorks.albumid, (dt, type) => {
        if (type === 'success' && dt.data && dt.data.items && dt.data.items.length) {
          this.album = dt.data.items[0]
          this.user = dt.data.users[this.album.userid] || {}
          this.getAlbumCount(this.album.albumid)
          this.updateData()
        } else {
          this.view.albumWorks.loading = false
          this.update()
        }
        if (this.view.albumWorks.typeid) {
          this.album.isBricky = true
        }
      }, false)
      if (renew) this.renewView()
    },
    // 获取专辑计数器
    getAlbumCount(albumid) {
      service.get('album_count', albumid, (ret, type) => {
        if (type === 'success') {
          this.albumCount = ret.data || {}
          this.followState[this.user.userid] = typeof ret.data.follow_state === 'undefined' ? -1 : ret.data.follow_state
        }
      }, false, { userid: this.user.userid })
    },
    addFootprint() {
      service.get('album_view', this.config.albumid, (dt, type) => {
        if (type === 'success') this.guests = dt.data.guests
      })
    }
  }
}
</script>
