<template>
  <el-dialog
    :title="$t('i.login')"
    :visible.sync="view.login.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :custom-class="platform.type !== 'desktop' ? 'pop_page fullscreen header_40' : ''"
    :fullscreen="platform.type !== 'desktop'"
    class="my-dialog"
    width="400px"
  >
    <span slot="title">
      <span class="el-dialog__title">{{ title }}</span>
      <i v-if="login.mod !== 'active'" class="iconfont my-close title-btn" :title="$t('i.close')" @click="view.login.show = false" />
    </span>
    <el-card shadow="never">
      <div class="login">
        <el-card v-if="login.mod !== 'active' && login.mod !== 'bind'" v-loading="login.loging" shadow="never" class="login-card" :style="{ 'height': platform.type !== 'desktop' ? '100vh': '' }" :element-loading-text="$t('i.loging')" element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-form v-if="login.mod !== 'wechat'" label-width="0px" style="width: 280px;margin: 0 auto;padding-left: 12px;">
            <img src="@/assets/icon.png" :title="$t('siteName')" width="64" style="margin:12px 0 12px 95px;">
            <el-form-item v-if="login.mod === 'username'" label="">
              <el-input v-model="login.username" class="my-from-item" maxlength="18" :placeholder="$t('i.reqUname') + ' ' + (login.data.username || '')" clearable />
            </el-form-item>
            <el-form-item v-if="login.mod === 'username'" label="">
              <el-input v-model="login.password" class="my-from-item" maxlength="20" type="password" :placeholder="$t('i.reqPWD')" clearable />
            </el-form-item>
            <el-form-item v-if="login.mod === 'phone'" label="">
              <el-input v-model="login.phone" class="my-from-item" maxlength="11" :placeholder="$t('i.reqPhone') + ' ' + (login.data.mobile || '')" clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="login.phone = login.phone.replace(/[^\d.]/g,'')" />
            </el-form-item>
            <el-form-item v-if="login.mod === 'phone'" class="sms-item" label="">
              <el-input v-model="login.code" class="my-from-item" maxlength="6" :placeholder="$t('i.reqMcode')" clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="login.code = login.code.replace(/[^\d.]/g,'')" />
              <div class="sms-send" :class="{'is-disabled': !login.phone || state.countDownNum}" @click="sendDzqSMS()">
                {{ state.countDownNum ? state.countDownNum : $t('i.getMcode') }}
              </div>
            </el-form-item>
            <el-form-item v-if="login.mod === 'email'" label="">
              <el-input v-model="login.email" class="my-from-item" maxlength="50" :placeholder="$t('i.reqEmail') + ' ' + (login.data.email || '')" clearable />
            </el-form-item>
            <el-form-item v-if="login.mod === 'email'" class="sms-item" label="">
              <el-input v-model="login.code" class="my-from-item" maxlength="6" :placeholder="$t('i.reqMcode')" clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="login.code = login.code.replace(/[^\d.]/g,'')" />
              <div class="sms-send" :class="{'is-disabled': !login.email || state.countDownNum}" @click="sendDzqEmail()">
                {{ state.countDownNum ? state.countDownNum : $t('i.getMcode') }}
              </div>
            </el-form-item>
            <el-form-item>
              <input type="hidden" name="type" value="mobilebrowser_username_login">
              <el-button size="small" type="primary" class="login-btn" @click="userLogin">{{ $t('i.login') }}{{ (login.mod === 'phone' || login.mod === 'email') && !login.secondLogin ? '/' + $t('i.reg') : '' }}</el-button>
              <!-- <el-button v-if="login.mod !== 'phone'" size="small" type="primary" class="reg-btn" @click="login.mod = 'phone'">注册新用户</el-button> -->
            </el-form-item>
          </el-form>
          <div v-if="login.mod === 'wechat'" class="qrcode">
            <div class="img"><img v-if="qrcode" :src="qrcode"></div>
            <div v-if="expire" class="cover" />
            <div v-if="expire" class="info icon" @click="() => genQrcode()">
              <i class="iconfont my-update" />
            </div>
          </div>
          <div v-if="login.mod === 'wechat'" class="txt top">
            {{ expire ? $t('i.qrExpires') : $t('i.scanLogin') }}
          </div>
          <div v-show="true" class="txt">
            {{ $t('i.otherLogin') }}
          </div>
          <div v-show="true" class="txt icon">
            <!-- <i v-if="login.mod !== 'wechat'" class="iconfont my-wechat" :title="$t('i.wechatLogin')" @click="() => (login.mod = 'wechat')" /> -->
            <i class="iconfont my-email" :class="{'on': login.mod === 'email'}" :title="$t('i.emailLogin')" @click="() => (login.mod = 'email')" />
            <i v-if="state.supportMobile" class="iconfont my-phone" :class="{'on': login.mod === 'phone'}" :title="$t('i.phoneLogin')" @click="() => (login.mod = 'phone')" />
            <i class="iconfont my-username" :class="{'on': login.mod === 'username'}" :title="$t('i.unameLogin')" @click="() => (login.mod = 'username')" />
          </div>
          <div class="txt">
            <el-checkbox v-model="login.checked" /> {{ $t('i.agree') }}
            《<el-button class="txt-btn" @click="openPopPage({ 'type': 'agreement' })">{{ $t('i.agreement') }}</el-button>》《<el-button class="txt-btn" @click="openPopPage({ 'type': 'privacy' })">{{ $t('i.privacy') }}</el-button>》
          </div>
        </el-card>
        <el-card v-if="login.mod === 'active'" v-loading="login.loging" shadow="never" class="login-card" :style="{ 'height': platform.type !== 'desktop' ? '100vh': '' }" :element-loading-text="$t('i.submitting')" element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-form label-width="10px" style="width: 280px;margin: 0 auto;">
            <div class="center set-btns" style="margin: 20px 0;">
              <span v-if="!token.avatar" class="el-avatar el-avatar--circle noavatar xlarge">
                <i class="iconfont my-man" />
              </span>
              <el-avatar v-if="token.avatar" :size="64" :src="getAvatar()" />
              <br><br><el-button @click="openLocalImages('avatar')">{{ $t('i.setHeader') }}</el-button>
              <input ref="avatar-uploader" type="file" accept="image/*" style="display:none;" @change="cropImage('avatar')">
            </div>
            <el-form-item label="">
              <el-input v-model="login.nickname" class="my-from-item" maxlength="18" :placeholder="$t('i.reqNick')" clearable />
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" class="login-btn" @click="setNickname">{{ $t('i.commit') }}</el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card v-if="login.mod === 'bind'" v-loading="login.loging" shadow="never" class="login-card" :style="{ 'height': platform.type !== 'desktop' ? '100vh': '' }" :element-loading-text="$t('i.submitting')" element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-form label-width="10px" style="width: 280px;margin: 0 auto;">
            <div class="center set-btns" style="margin: 30px 0 40px 0;">
              <i class="iconfont my-binding" style="font-size:40px;" /> &nbsp;
              <i v-if="bindType === 'email'" class="iconfont my-email" style="font-size:40px;" />
              <i v-if="bindType === 'phone'" class="iconfont my-phone" style="font-size:40px;" />
            </div>
            <el-form-item v-if="bindType === 'email'" label="">
              <el-input v-model="login.email" class="my-from-item" maxlength="50" :placeholder="$t('i.reqEmail')" clearable />
            </el-form-item>
            <el-form-item v-if="bindType === 'email'" class="sms-item" label="">
              <el-input v-model="login.code" class="my-from-item" maxlength="6" :placeholder="$t('i.reqMcode')" clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="login.code = login.code.replace(/[^\d.]/g,'')" />
              <div class="sms-send" :class="{'is-disabled': !login.email || state.countDownNum}" @click="sendDzqEmail('bind')">
                {{ state.countDownNum ? state.countDownNum : $t('i.getMcode') }}
              </div>
            </el-form-item>
            <el-form-item v-if="bindType === 'phone'" label="">
              <el-input v-model="login.phone" class="my-from-item" maxlength="11" :placeholder="$t('i.reqPhone')" clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="login.phone = login.phone.replace(/[^\d.]/g,'')" />
            </el-form-item>
            <el-form-item v-if="bindType === 'phone'" class="sms-item" label="">
              <el-input v-model="login.code" class="my-from-item" maxlength="6" :placeholder="$t('i.reqMcode')" clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="login.code = login.code.replace(/[^\d.]/g,'')" />
              <div class="sms-send" :class="{'is-disabled': !login.phone || state.countDownNum}" @click="sendDzqSMS('bind')">
                {{ state.countDownNum ? state.countDownNum : $t('i.getMcode') }}
              </div>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" class="login-btn" @click="bind">{{ $t('i.bind') }}</el-button>
            </el-form-item>
            <div v-if="bindType === 'phone'" class="align-center padding-top12 yellow">
              {{ $t('i.bindMobileTips') }}
            </div>
            <div v-if="bindType === 'email'" class="align-center padding-top12 yellow">
              {{ $t('i.bindEmailTips') }}
            </div>
          </el-form>
        </el-card>
      </div>
    </el-card>
    <span slot="footer" class="dialog-footer" />
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
import service from '@/js/service'
import COSSDK from '@/js/sdk/cosSDK'
export default {
  name: 'MyLogin',
  mixins: [mixins],
  data() {
    const data = {}
    data.COSSDK = null
    data.platform = utils.platform()
    data.mainHost = conf.hosts().mainHost
    data.catalogs = conf.catalogs()
    data.sto = null
    data.token = {}
    data.sessionToken = null
    data.qrcode = null
    data.reqTimes = 0
    data.expire = false
    return data
  },
  computed: {
    login() {
      return this.view.login
    },
    bindType() {
      if (this.state.supportMobile) {
        return 'phone'
      } else {
        return 'email'
      }
    },
    title() {
      if (this.login.secondLogin) {
        return this.$t('i.login')
      }
      if (this.login.mod === 'active') {
        return this.$t('i.setNick')
      } else if (this.login.mod === 'bind') {
        if (this.bindType === 'phone') {
          return this.$t('i.bindMobile')
        } else if (this.bindType === 'email') {
          return this.$t('i.bindEmail')
        } else {
          return this.$t('i.bind')
        }
      } else if (this.login.mod === 'wechat') {
        return this.$t('i.wechatLogin')
      } else if (this.login.mod === 'username') {
        return this.$t('i.unameLogin')
      } else if (this.login.mod === 'email') {
        return this.$t('i.emailLogin')
      } else {
        return this.$t('i.phoneLogin')
      }
    }
  },
  watch: {
    'view.login.mod': {
      handler(mod) {
        if (mod === 'wechat') {
          this.genQrcode()
        } else {
          this.initQrCode()
        }
      }
    },
    'view.login.show': {
      handler(show) {
        if (!show) {
          this.initQrCode()
        } else if (this.view.login.mod === 'wechat') {
          this.genQrcode()
        }
      }
    }
  },
  mounted() {
    this.token = service.getToken()
    if (this.token.mobile) this.login.data.mobile = this.token.mobile
    if (this.token.email) this.login.data.email = this.token.email
    if (this.token.username) this.login.data.username = this.token.username
  },
  methods: {
    getAvatar() {
      const ver = Math.round(new Date(this.token.avatar_at) / 1000)
      if (this.token.avatar) return service.getCosUrl(this.token.avatar, 'avatar', ver) || ''
    },
    // 打开本地图片
    openLocalImages(type) {
      setTimeout(() => {
        this.$refs[type + '-uploader'].click()
      }, 100)
    },
    // 裁剪头像
    cropImage(type) {
      const dom = this.$refs[type + '-uploader']
      if (!dom.files.length) {
        return
      }
      const file = dom.files[0]
      const fn = (base64) => {
        this.upload(type, base64)
      }
      this.emit('openCropImage', [{ data: { file: file, diyType: 'common' }, fn: fn }])
    },
    upload(type, base64) {
      if (!base64) return
      this.emit('traceEvent', ['upload_avatar'])
      this.emit('loading', [true, this.$t('i.uploading')])
      this.$refs[type + '-uploader'].src = base64
      const file = utils.blobToFile(utils.base64ToBlob(base64), type, 'image/png')
      const key = 'public/' + type + '/' + utils.caclUserPath(this.token.uid) + '.png'
      // 上传文件
      this.COSSDK = new COSSDK()
      this.COSSDK.putObject({
        Bucket: conf.cosBucket.bucket,
        Region: conf.cosBucket.region,
        Key: key,
        Body: file
      }, (err, data) => {
        if (!err && data && data.statusCode === 200 && data.Location) {
          const url = 'cos://' + key.replace('public/' + type + '/', '')
          const datetime = utils.date('datetime')
          if (type === 'avatar') {
            this.token = service.getToken()
            this.token.avatar_at = datetime
            this.token.avatar = url
            this.token.avatarUrl = url
            window.localStorage.setItem('token', JSON.stringify(this.token))
            this.update()
          }
          this.updateProfile({ avatar: url }, () => {
            this.emit('loading', [false])
            this.update()
          })
        } else {
          this.message(this.$t('i.busy'), 'error')
          this.emit('loading', [false])
        }
      })
    },
    // 更新个人资料
    updateProfile(body, cb) {
      body = body || {}
      service.put('user_profile', this.token.uid, body, (dt, status) => {
        if (status === 'success') {
          this.message(this.$t('i.setOk'), status)
          this.update()
        } else {
          this.message(dt, status)
        }
        cb && cb()
      }, true)
    },
    // 发送DZQ短信验证码
    sendDzqSMS(type = '') {
      if (!utils.checkNow('mobile', this.login.phone, true)) return this.message(this.$t('i.reqCorrectPhone'), 'error')
      this.emit('sendDzqSMS', [type || 'login', this.login.phone])
    },
    // 发送Dzq邮件验证码
    sendDzqEmail(type = '') {
      if (!utils.checkNow('email', this.login.email, true)) return this.message(this.$t('i.reqCorrectEmail'), 'error')
      this.emit('sendDzqEmail', [type || 'login', this.login.email])
    },
    // 用户名登录
    userLogin() {
      setTimeout(() => {
        if (this.login.mod === 'phone') return this.smsLogin()
        if (this.login.mod === 'email') return this.emailLogin()
        // 用户名为6~18位字符，包含字母、数字、下划线
        if (!utils.checkNow('username', this.login.username, true)) return this.message(this.$t('i.usernameErr'), 'error')
        if (!utils.checkNow('strongpassword', this.login.password, true)) return this.message(this.$t('i.PWDErr'), 'error')
        if (!this.login.checked) return this.message(this.$t('i.agreeFirst'), 'error')
        this.login.loging = true
        this.emit('traceEvent', ['login_username'])
        service.login(this.login.username, this.login.password, this.userLoginSuccess)
      }, 200)
    },
    bind() {
      this.login.loging = true
      if (this.bindType === 'email') {
        this.bindEmail()
      } else if (this.bindType === 'phone') {
        this.bindPhone()
      }
    },
    bingCb(dt, type) {
      if (type === 'success') {
        this.emit('renewToken', [this.bindType])
        this.message(this.$t('i.bindOk'), type)
        setTimeout(() => {
          this.login.phone = ''
          this.login.email = ''
          this.login.code = ''
          this.login.data = {}
          this.login.show = false
        }, 200)
      } else {
        this.message(dt, type)
      }
      this.login.loging = false
    },
    bindEmail() {
      service.emailBind(this.login.email, this.login.code, this.bingCb)
    },
    bindPhone() {
      service.smsBind(this.login.phone, this.login.code, this.bingCb)
    },
    setNickname() {
      // 昵称为6~18位字符，包含字母、数字、下划线、汉字
      console.error('this.login.nickname', this.login.nickname, utils.checkNow('nickname', this.login.nickname, true))
      if (!utils.checkNow('nickname', this.login.nickname, true)) return this.message(this.$t('i.nickErr'), 'error')
      service.setNickname(this.login.nickname, (dt, type) => {
        if (type === 'success') {
          this.token = service.getToken()
          delete this.token.isMissNickname
          this.token.nickname = this.login.nickname
          window.localStorage.setItem('token', JSON.stringify(this.token))
          this.userLoginSuccess(this.$t('i.nickOk'), type)
        } else {
          this.message(dt, type)
        }
      })
    },
    // 手机号登录/注册
    smsLogin() {
      if (this.login.mod !== 'phone') return
      if (!utils.checkNow('mobile', this.login.phone, true)) return this.message(this.$t('i.reqPhone'), 'error')
      if (!utils.checkNow('str-6-6', this.login.code, true)) return this.message(this.$t('i.reqMcode'), 'error')
      if (!this.login.checked) return this.message(this.$t('i.agreeFirst'), 'error')
      this.login.loging = true
      const inviteCode = window.localStorage.getItem('inviteid')
      window.localStorage.setItem('inviteid', '')
      this.emit('traceEvent', ['login_sms'])
      service.smsLogin(this.login.phone, this.login.code, inviteCode, this.userLoginSuccess)
    },
    // 邮箱登录/注册
    emailLogin() {
      if (this.login.mod !== 'email') return
      if (!utils.checkNow('email', this.login.email, true)) return this.message(this.$t('i.reqEmail'), 'error')
      if (!utils.checkNow('str-6-6', this.login.code, true)) return this.message(this.$t('i.reqMcode'), 'error')
      if (!this.login.checked) return this.message(this.$t('i.agreeFirst'), 'error')
      this.login.loging = true
      const inviteCode = window.localStorage.getItem('inviteid')
      window.localStorage.setItem('inviteid', '')
      this.emit('traceEvent', ['login_email'])
      service.emailLogin(this.login.email, this.login.code, inviteCode, this.userLoginSuccess)
    },
    genQrcode() {
      this.initQrCode()
      service.qrcode((res) => {
        if (res.status === 200 && res.data && res.data.Code === 0) {
          this.sessionToken = res.data.Data.sessionToken
          this.qrcode = res.data.Data.base64Img
          this.sto = setTimeout(this.loopQrLogin, 9000)
        } else {
          this.initQrCode()
        }
      })
    },
    loopQrLogin() {
      this.reqTimes++
      if (this.reqTimes < 60) {
        this.qrLogin()
        this.sto = setTimeout(this.loopQrLogin, 3000)
      } else {
        this.expire = true
      }
    },
    qrLogin() {
      if (this.sessionToken) {
        service.qrlogin(this.sessionToken, this.userLoginSuccess)
      } else {
        this.initQrCode()
      }
    },
    userLoginSuccess(msg, type) {
      this.initQrCode()
      if (type !== 'active' && msg) this.message(msg, type)
      if (type === 'success' || type === 'active') {
        this.emit('getUnread')
        this.emit('setCookie')
        this.emit('traceEvent', ['login_success'])
        if (type === 'success') {
          setTimeout(() => {
            this.login.data = {}
            this.login.show = false
          }, 200)
        } else {
          this.token = service.getToken()
          if (this.token.isMissEmail && this.token.isMissMobile) {
            this.login.mod = 'bind'
          } else {
            this.login.nickname = this.token.nickname || ''
            this.login.mod = 'active'
          }
        }
      }
      this.login.loging = false
    },
    initQrCode() {
      this.sessionToken = null
      this.qrcode = null
      clearTimeout(this.sto)
      this.expire = false
      this.reqTimes = 0
    }
  }
}
</script>
