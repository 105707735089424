<template>
  <el-dialog
    ref="messageDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll messageDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop && config.type !== 'dialog'" class="tap-btn iconfont my-back size20" @click="config.type = 'dialog'" />
        <el-button v-if="!isDesktop && config.type === 'dialog'" class="tap-btn iconfont my-back size20" @click="close()" />
        &nbsp;
      </div>
      <div class="middle margin-left8">
        {{ config.type === 'system' ? $t('i.notification') : (config.type === 'social' ? $t('i.interaction') : $t('i.message')) }}
      </div>
      <div class="float-right">
        <el-button v-if="deleteMod" :class="{'tap-btn': canDelete, 'txt-btn': !canDelete}" @click="deleteItems()">
          <i v-if="canDelete" class="iconfont my-delete size20 blue" />
          <span v-if="!canDelete" class="blue" :class="{'padding-right8' : !canDelete}">{{ $t('i.refuse') }}</span>
        </el-button>
        <el-button v-if="isDesktop && config.type === 'dialog'" class="tap-btn iconfont my-close size20" @click="close()" />
        <el-button v-if="isDesktop && config.type !== 'dialog'" class="tap-btn iconfont my-close size20" @click="config.type = 'dialog'" />
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <message-list
        v-show="config.show && config.type === 'dialog'"
        ref="message-dialog"
        mod="dialog"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        @receive="handle"
      />
      <message-list
        v-show="config.show && config.type === 'social'"
        ref="message-social"
        mod="social"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        @receive="handle"
      />
      <message-list
        v-show="config.show && config.type === 'system'"
        ref="message-system"
        mod="system"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import messageList from '@/components/web/messageList'
import { mixins } from '@/mixins/common'
import service from '@/js/service'
export default {
  components: {
    messageList
  },
  mixins: [mixins],
  data() {
    return {
      canDelete: false,
      deleteMod: false,
      deleteIds: {}
    }
  },
  computed: {
    config() {
      return this.view.message
    }
  },
  watch: {
    'view.message.show': {
      handler() {
        this.init()
      }
    },
    'view.message.type': {
      handler() {
        this.init()
      }
    }
  },
  methods: {
    handle(act, data) {
      if (act === 'setDeleteData') {
        this.deleteMod = data[0]
        this.deleteIds = data[1]
        this.canDelete = this.deleteMod && Object.keys(this.deleteIds).length > 0
        this.update()
      } else if (act === 'cancelDelete') {
        this.cancelDelete()
      } else {
        this.emit(act, data)
      }
    },
    init() {
      this.canDelete = false
      this.deleteMod = false
      this.deleteIds = {}
    },
    close() {
      if (this.config.pop) {
        this.closePop(this.config)
      } else {
        this.goto()
      }
    },
    cancelDelete() {
      this.$refs['message-' + this.config.type].cancelDelete()
      this.init()
    },
    deleteItems() {
      if (this.canDelete) {
        const ids = []
        for (const id in this.deleteIds) {
          ids.push(id)
        }
        if (this.config.type === 'dialog') this.state.deleteDialogMessage = this.deleteIds
        if (this.config.type === 'social') this.state.deleteSocialMessage = this.deleteIds
        if (this.config.type === 'system') this.state.deleteSystemMessage = this.deleteIds
        this.$refs['message-' + this.config.type].deleteItems()
        service.batchDelete(this.config.type === 'dialog' ? 'dialog' : 'notifications', ids, (dt, type) => {}, true)
        this.init()
      } else {
        this.cancelDelete()
      }
    }
  }
}
</script>
