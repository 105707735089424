<template>
  <div v-show="config.show" v-finger:swipe="onSwipe" class="my-container">
    <div v-show="!isDesktop" class="header">
      <div class="left">
        &nbsp;
      </div>
      <div class="middle">
        <el-button-group>
          <!-- <el-button size="small" :type="config.classid === 1 ? 'primary' : 'plain'" @click="emit('gotoWorks', [''])">{{ $t('i.pixel') }}</el-button>
          <el-button size="small" :type="config.classid === 1 ? 'plain' : 'primary'" @click="emit('gotoWorks', [1])">{{ $t('i.puzzle') }}</el-button> -->
          <el-button size="small" :type="'plain'">{{ $t('i.works') }}</el-button>
          <el-button size="small" :type="'primary'" @click="goto('album')">{{ $t('i.album') }}</el-button>
        </el-button-group>
      </div>
      <div class="right" />
    </div>
    <div class="tabs" :style="isDesktop ? 'top: 4px' : ''">
      <el-col :span="16">
        <el-tabs v-model="config.type">
          <el-tab-pane v-for="(type, idx) in types" :key="idx" :label="type[1]" :name="type[0]" />
        </el-tabs>
      </el-col>
      <el-col :span="8" class="filter">
        <el-button class="tap-btn" @click="emit('openSift', ['works'])">
          {{ config.catalogName }}
          <i class="iconfont my-filter" />
        </el-button>
      </el-col>
    </div>
    <works-list
      v-show="config.show && config.type === 'new'"
      ref="works-new"
      mod="new"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <works-list
      v-show="config.show && config.type === 'hot'"
      ref="works-hot"
      mod="hot"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <works-list
      v-show="config.show && config.type === 'best'"
      ref="works-best"
      mod="best"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
    <works-list
      v-show="config.show && config.type === 'original'"
      ref="works-original"
      mod="original"
      :view="view"
      :state="state"
      :login-status="loginStatus"
      :login-user-id="loginUserId"
      @receive="handle"
    />
  </div>
</template>
<script>
import worksList from '@/components/web/worksList'
import { mixins } from '@/mixins/common'
export default {
  components: {
    worksList
  },
  mixins: [mixins],
  data() {
    const data = {}
    return data
  },
  computed: {
    config() {
      return this.view.works
    },
    types() {
      return this.config.types()
    }
  },
  methods: {
    handle(act, data) {
      this.emit(act, data)
    },
    onSwipe(evt) {
      this.switchTab(this.config, evt.direction)
    },
    renewView() {
      const el = this.$refs['works-' + this.config.type]
      if (el) el.renewView()
    }
  }
}
</script>
