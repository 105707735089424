<template>
  <el-dialog
    ref="nftorderDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll nftorderDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        {{ $t('i.nftOrders') }}
      </div>
      <div class="float-right align-right">
        <el-button v-if="deleteMod" :class="{'tap-btn': canDelete, 'txt-btn': !canDelete}" @click="deleteItems()">
          <i v-if="canDelete" class="iconfont my-delete blue size20" />
          <span v-if="!canDelete" class="blue">{{ $t('i.cancel') }}</span>
        </el-button>
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <div class="tabs my-tabs bg-20" style="padding:2px;">
        <el-tabs v-model="config.type">
          <el-tab-pane v-for="(type, idx) in types" :key="idx" :label="type[1]" :name="type[0]" />
        </el-tabs>
      </div>
      <nftorder-list
        v-show="config.show && config.type === 'all'"
        ref="nftorder-all"
        mod="all"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <nftorder-list
        v-show="config.show && config.type === 'paying'"
        ref="nftorder-paying"
        mod="paying"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <nftorder-list
        v-show="config.show && config.type === 'paid'"
        ref="nftorder-paid"
        mod="paid"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <nftorder-list
        v-show="config.show && config.type === 'cancel'"
        ref="nftorder-cancel"
        mod="cancel"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import nftorderList from '@/components/web/nftorderList'
import { mixins } from '@/mixins/common'
import service from '@/js/service'
export default {
  components: {
    nftorderList
  },
  mixins: [mixins],
  data() {
    return {
      subtractHeight: this.state.platform.type === 'desktop' ? 40 : 80,
      canDelete: false,
      deleteMod: false,
      deleteIds: {}
    }
  },
  computed: {
    config() {
      return this.view.nftorder
    },
    types() {
      return this.config.types()
    }
  },
  watch: {
    // 'view.nftorder.show': {
    //   handler() {
    //     this.init()
    //   }
    // },
    'view.nftorder.type': {
      handler() {
        this.init()
      }
    }
  },
  mounted() {
    if (!this.loginUserId) {
      this.emit('login')
      return
    }
    this.init()
  },
  methods: {
    handle(act, data) {
      if (act === 'setDeleteData') {
        this.deleteMod = data[0]
        this.deleteIds = data[1]
        this.canDelete = this.deleteMod && Object.keys(this.deleteIds).length > 0
        this.update()
      } else if (act === 'cancelDelete') {
        this.cancelDelete()
      } else {
        this.emit(act, data)
      }
    },
    init() {
      this.canDelete = false
      this.deleteMod = false
      this.deleteIds = {}
    },
    cancelDelete() {
      this.$refs['nftorder-' + this.config.type].cancelDelete()
      this.init()
    },
    deleteItems() {
      if (this.canDelete) {
        const ids = []
        for (const id in this.deleteIds) {
          ids.push(id)
        }
        if (this.config.type === 'all' || this.config.type === 'paying') this.state.deletePayingNFTorder = this.deleteIds
        if (this.config.type === 'all' || this.config.type === 'paid') this.state.deletePaidNFTorder = this.deleteIds
        if (this.config.type === 'all' || this.config.type === 'cancel') this.state.deleteCancelNFTorder = this.deleteIds
        this.state.deleteAllNFTorder = this.deleteIds
        this.$refs['nftorder-' + this.config.type].deleteItems()
        service.batchDelete('nft_order', ids, (dt, type) => {}, true)
        this.init()
      } else {
        this.cancelDelete()
      }
    }
  }
}
</script>
