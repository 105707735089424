<template>
  <el-dialog
    ref="threadDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll threadDialog"
  >
    <div slot="title" class="header">
      <div v-if="!isDesktop" class="left">
        <el-button class="tap-btn iconfont my-back size20" @click="close()" />
      </div>
      <div class="middle margin-left8">
        <div v-if="!config.loading" class="user">
          <div class="l" @click="emit('goto', ['user', { userid: thread.user_id } ])">
            <span v-if="!avatar" class="el-avatar el-avatar--circle noavatar" :class="{'medium': isDesktop, 'small': !isDesktop}">
              <i class="iconfont my-man" />
            </span>
            <el-avatar v-if="avatar" :size="isDesktop ? 32 : 24" :src="avatar" />
          </div>
          <div class="m" :class="{'margin-left8 margin-top2': isDesktop}" @click="emit('goto', ['user', { userid: thread.user_id } ])">
            {{ nickname || view.anonymity }}
          </div>
          <div class="r" :class="{'margin-top2': isDesktop}">
            <el-button v-if="(followState[thread.user_id] === 0)" class="tap-btn on" @click="unfollow(thread.user_id)">{{ $t('i.hadFollow') }}</el-button>
            <el-button v-if="(followState[thread.user_id] === 1)" class="tap-btn on" @click="unfollow(thread.user_id)">{{ $t('i.followEach') }}</el-button>
            <el-button v-if="(followState[thread.user_id] !== 0 && followState[thread.user_id] !== 1)" class="tap-btn" @click="follow(thread.user_id)">{{ $t('i.follow') }}</el-button>
          </div>
        </div>
      </div>
      <div class="float-right">
        <el-button class="tap-btn iconfont my-dot-more" @click="showActionSheet(thread.user_id, thread.id, thread.is_sticky, thread.is_essence)" />
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="close()" />
      </div>
    </div>
    <div v-show="view.thread.show" class="thread">
      <pull-to :id="'thread-container'" :style="listStyle" :top-load-method="(loaded) => loadData(loaded, 'renew')" :bottom-load-method="(loaded) => loadData(loaded, 'down')" @infinite-scroll="loadData(() => {}, 'down')">
        <thread-detail
          v-show="config.show && config.type === 'thread'"
          ref="thread-detail"
          mod="thread"
          :view="view"
          :state="state"
          :file="file"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          :page-size="1"
          :is-pop="true"
          @receive="handle"
        />
        <comment-item
          v-show="config.show && config.type === 'thread'"
          ref="comment-item"
          mod="thread"
          :view="view"
          :state="state"
          :file="file"
          :login-status="loginStatus"
          :login-user-id="loginUserId"
          :is-pop="true"
          @receive="handle"
        />
      </pull-to>
      <div v-if="data.scrollTop > 200" :style="{'bottom': data.rows > 1 ? '108px' : '58px'}" class="go-top">
        <el-button class="tap-btn" @click="backTop()">
          <i class="iconfont my-top" />
        </el-button>
      </div>
    </div>
    <div slot="footer">
      <div v-if="data.rows > 1" class="input" :class="{ 'small' : data.rows > 1}" :style="{'position': isDesktop ? 'absolute' : ''}">
        <div class="left">
          <el-input ref="input" v-model="data.content" type="textarea" class="my-from-item" name="name" :rows="data.rows" :placeholder="data.placeholder" clearable @focus="moreRows" @blur="() => reply()" />
        </div>
        <div class="right">
          <el-button class="tap-btn margin-top8" @click="createPosts">
            <i class="iconfont my-fly size20" :class="{ 'blue': data.content }" />
          </el-button>
        </div>
      </div>
      <div v-if="data.rows === 1" class="input thread-input" :class="{ 'small' : data.rows > 1}" :style="{'position': isDesktop ? 'absolute' : ''}">
        <div class="left">
          <el-input ref="input" v-model="data.content" type="textarea" class="my-from-item" name="name" :rows="data.rows" :placeholder="data.placeholder" clearable @focus="moreRows" @blur="() => reply()" />
        </div>
        <div class="right">
          <el-badge :value="formateNums(threadCount[threadid] ? threadCount[threadid].praise_count : 0)" :hidden="!(threadCount[threadid] && threadCount[threadid].praise_count)">
            <el-button class="tap-btn margin-left8" @click="praiseIt(threadid)">
              <i class="iconfont size20" :class="{ 'my-praise-fill': threadCount[threadid] && threadCount[threadid].praise_state, 'my-praise-blank': !(threadCount[threadid] && threadCount[threadid].praise_state) }" />
            </el-button>
          </el-badge>
          <el-badge :value="formateNums(threadCount[threadid] ? threadCount[threadid].collect_count : 0)" :hidden="!(threadCount[threadid] && threadCount[threadid].collect_count)">
            <el-button class="tap-btn margin-left8" @click="collectIt(threadid)">
              <i class="iconfont size20" :class="{ 'my-star' : threadCount[threadid] && threadCount[threadid].collect_state, 'my-blank-star' : !(threadCount[threadid] && threadCount[threadid].collect_state) }" />
            </el-button>
          </el-badge>
          <el-badge :value="formateNums(threadCount[threadid] ? threadCount[threadid].share_count : 0)" :hidden="!(threadCount[threadid] && threadCount[threadid].share_count)">
            <el-button class="tap-btn margin-left8 size20 clipboard-thread iconfont my-share" :data-clipboard-text="shareContent" @click="shareIt(threadid)" />
          </el-badge>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import utils from '@/js/utils'
import PullTo from 'vue-pull-to'
import threadDetail from '@/components/web/threadDetail'
import commentItem from '@/components/web/commentItem'
import { mixins } from '@/mixins/common'
import service from '@/js/service'
export default {
  components: {
    PullTo,
    threadDetail,
    commentItem
  },
  mixins: [mixins],
  data() {
    const data = {
      scrollTop: 0,
      placeholder: this.$t('i.reqReply'),
      content: '',
      rows: 1
    }
    let container_width, container_height
    if (this.state.platform.type === 'desktop') {
      container_width = 632
      container_height = 632
    } else {
      container_width = utils.width()
      container_height = utils.height()
    }
    return {
      // 可用宽度
      width: container_width - 3,
      height: container_height - 85,
      container_height: container_height,
      avatar: '',
      nickname: '',
      thread: {},
      items: {},
      shareContent: '',
      placeholder: data.placeholder,
      data: data,
      total: 0
    }
  },
  computed: {
    isDesktop() {
      return this.state.platform.type === 'desktop'
    },
    listStyle() {
      // return { 'height': 'calc(100% - ' + (this.data.rows > 1 ? 132 : 92) + 'px)', 'margin-top': '0px' }
      return { 'height': (this.height - (this.data.rows > 1 ? 40 : 0)) + 'px' }
    },
    config() {
      return this.view.thread
    },
    threadid() {
      return this.config.threadid
    },
    followState() {
      return this.state.followState
    },
    threadCount() {
      return this.state.threadCount
    },
    users() {
      return this.state.users
    }
  },
  watch: {
    'view.thread.threadid': {
      handler() {
        if (this.view.thread.show) this.addFootprint()
      }
    }
  },
  mounted() {
    const container = this.scrollContainer()
    if (container) container.onscroll = () => { this.data.scrollTop = container.scrollTop }
    window.addEventListener('resize', this.onResize)
  },
  methods: {
    formateNums: utils.formateNums,
    scrollContainer() {
      const main = document.getElementById('thread-container')
      if (main) {
        const scroll = main.getElementsByClassName('scroll-container')
        if (scroll.length) return scroll[0]
      }
    },
    onResize() {
      if (!this.config.show) return
      // this.backTop()
      // this.data.scrollTop = 0
    },
    backTop() {
      const container = this.scrollContainer()
      if (container) {
        setTimeout(() => {
          container.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        }, 100)
      }
    },
    close() {
      if (this.config.pop) {
        this.closePop(this.config)
      } else {
        this.goto()
      }
    },
    handle(act, data) {
      if (act === 'reply') {
        this.reply(...data)
      } else if (act === 'update') {
        this.update()
      } else if (act === 'setTotal') {
        this.setTotal(...data)
      } else if (act === 'updateThread') {
        this.updateThread()
      } else if (act === 'updateComment') {
        this.updateComment()
      } else {
        this.emit(act, data)
      }
    },
    updateThread() {
      this.$refs['thread-detail'].update()
    },
    updateComment() {
      this.$refs['comment-item'].update()
    },
    update() {
      this.config.loading = false
      this.thread = this.$refs['thread-detail'].getItem(this.threadid) || {}
      this.avatar = this.$refs['thread-detail'].getAvatar(this.thread)
      this.nickname = this.users[this.thread.user_id] ? this.users[this.thread.user_id].nickname || '' : ''
      this.$forceUpdate()
    },
    moreRows(yes) {
      this.data.rows = (yes || this.data.content) ? 3 : 1
    },
    reply(postid, nickname, reset = false) {
      if (this.data.content && !reset) return
      if (!postid || !nickname || reset) {
        this.data.placeholder = this.placeholder
        this.data.replyPostid = 0
        this.data.replyNickname = ''
        this.moreRows()
      } else {
        this.data.placeholder = this.$t('i.re') + ' ' + nickname + ': '
        this.data.replyPostid = postid
        this.data.replyNickname = nickname
        this.$refs.input.focus()
      }
    },
    setTotal(total) {
      this.total = total || 0
    },
    follow(userid) {
      this.$refs['thread-detail'].follow(userid)
    },
    unfollow(userid) {
      this.$refs['thread-detail'].unfollow(userid)
    },
    praiseIt(threadid) {
      this.$refs['thread-detail'].praiseIt(threadid)
    },
    collectIt(threadid) {
      this.$refs['thread-detail'].collectIt(threadid)
    },
    shareIt(threadid) {
      this.shareContent = this.$refs['thread-detail'].getShareContent(threadid)
      this.$refs['thread-detail'].shareIt(threadid)
    },
    showActionSheet(userid, threadid, is_sticky, is_essence) {
      this.$refs['thread-detail'].showActionSheet(userid, threadid, is_sticky, is_essence)
    },
    createPosts() {
      this.$refs['comment-item'].setData(this.data)
      this.$refs['comment-item'].createPosts()
      this.data.content = ''
    },
    loadData(loaded, direction) {
      this.$refs['comment-item'].loadData(loaded, direction)
    },
    addFootprint() {
      service.get('thread_view', this.threadid)
    }
  }
}
</script>
