<template>
  <div>
    <el-drawer :title="view.actionSheet.title || ''" :visible.sync="view.actionSheet.show" direction="btt" size="50%" :show-close="false" custom-class="action-sheet">
      <ul v-finger:swipe="onSwipe" @click="view.actionSheet.show = false">
        <li v-for="btn in view.actionSheet.btns" :key="btn.title" @click="btn.cb()">{{ btn.title }} <i v-if="btn.icon" class="iconfont yellow" :class="btn.icon" /></li>
        <li style="background:none;" @click="view.actionSheet.show = false">{{ $t('i.close') }}</li>
      </ul>
    </el-drawer>
  </div>
</template>
<script>
export default {
  props: {
    view: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    onSwipe(evt) {
      if (evt.direction === 'Down') this.view.actionSheet.show = false
    }
  }
}
</script>
