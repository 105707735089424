<template>
  <div v-show="!state.play" class="toolbar" :style="{'right': view.rightToolBar ? '0px' : ''}">
    <div style="height: calc(100vh - 200px);overflow-y: auto;">
      <div style="height:335px;">
        <el-popover v-model="selectPopoverShow" placement="right-start" width="40" :visible-arrow="false" popper-class="popover-toolbar" trigger="click">
          <el-button v-if="selectTool !== 'default'" :type="'plain'" :class="'iconfont my-cursor'" :title="$t('i.cursor')" @click="useTool('selectTool', 'default')" />
          <el-button v-if="selectTool !== 'select'" :type="'plain'" :class="'iconfont my-select-cursor'" :title="$t('i.partSelect')" @click="useTool('selectTool', 'select')" />
          <el-button slot="reference" v-finger:double-tap="() => useTool('selectTool', state.act === 'select' ? 'default' : 'select', true)" :type="(state.act === 'default' || state.act === 'select') ? 'primary' : 'plain'" class="iconfont" :class="{'my-cursor': selectTool !== 'select', 'my-select-cursor': selectTool === 'select'}" :title="selectTool !== 'select' ? $t('i.cursor') : $t('i.partSelect')" @click="useTool('selectTool', selectTool)" />
        </el-popover>
        <el-button :type="state.act === 'fill' ? 'primary' : 'plain'" :class="'iconfont my-pen'" :title="$t('i.fill')" @click="setAct('fill')" />
        <el-button :type="state.act === 'batchFill' ? 'primary' : 'plain'" :class="'iconfont my-fill'" :title="$t('i.batchFill')" @click="setAct('batchFill')" />
        <el-popover v-model="erasePopoverShow" placement="right-start" width="40" :visible-arrow="false" popper-class="popover-toolbar" trigger="click">
          <el-button v-if="eraseTool !== 'erase'" :type="'plain'" :class="'iconfont my-eraser'" :title="$t('i.erase')" @click="useTool('eraseTool', 'erase')" />
          <el-button v-if="eraseTool !== 'batchErase'" :type="'plain'" :class="'iconfont my-eraser-batch'" :title="$t('i.batchErase')" @click="useTool('eraseTool', 'batchErase')" />
          <el-button slot="reference" v-finger:double-tap="() => useTool('eraseTool', state.act === 'batchErase' ? 'erase' : 'batchErase', true)" :type="(state.act === 'erase' || state.act === 'batchErase') ? 'primary' : 'plain'" class="iconfont" :class="{'my-eraser': eraseTool !== 'batchErase', 'my-eraser-batch': eraseTool === 'batchErase'}" :title="eraseTool !== 'batchErase' ? $t('i.erase') : $t('i.batchErase')" @click="useTool('eraseTool', eraseTool)" />
        </el-popover>
        <el-popover v-model="brushPopoverShow" placement="right-start" width="40" :visible-arrow="false" popper-class="popover-toolbar" trigger="click">
          <el-button v-if="brushTool !== 'brush'" :type="'plain'" :class="'iconfont my-brush'" :title="$t('i.brush')" @click="useTool('brushTool','brush')" />
          <el-button v-if="brushTool !== 'spray'" :type="'plain'" :class="'iconfont my-spray'" :title="$t('i.spray')" @click="useTool('brushTool','spray')" />
          <el-button slot="reference" v-finger:double-tap="() => useTool('brushTool', state.act === 'spray' ? 'brush' : 'spray', true)" :type="(state.act === 'brush' || state.act === 'spray') ? 'primary' : 'plain'" class="iconfont" :class="{'my-brush': brushTool !== 'spray', 'my-spray': brushTool === 'spray'}" :title="brushTool !== 'spray' ? $t('i.brush') : $t('i.spray')" @click="useTool('brushTool', brushTool)" />
        </el-popover>
        <el-popover v-model="shapePopoverShow" placement="right-start" :visible-arrow="false" popper-class="popover-toolbar" trigger="click">
          <!-- <el-button v-if="shapeTool !== 'line'" :type="'plain'" :class="'iconfont my-line'" :title="$t('i.line')" @click="useTool('shapeTool','line')" /> -->
          <el-button v-if="shapeTool !== 'ellipse'" :type="'plain'" :class="'iconfont my-blank-circle'" :title="$t('i.ellipse')" @click="useTool('shapeTool','ellipse')" />
          <el-button v-if="shapeTool !== 'rectangle'" :type="'plain'" :class="'iconfont my-blank-rectangle'" :title="$t('i.rectangle')" @click="useTool('shapeTool','rectangle')" />
          <el-button v-if="shapeTool !== 'triangle'" :type="'plain'" :class="'iconfont my-blank-triangle'" :title="$t('i.triangle')" @click="useTool('shapeTool','triangle')" />
          <el-button v-if="shapeTool !== 'star'" :type="'plain'" :class="'iconfont my-blank-star'" :title="$t('i.star')" @click="useTool('shapeTool','star')" />
          <el-button slot="reference" v-finger:double-tap="autoChangeShapeTool" :type="shapeBtns[state.act] ? 'primary' : 'plain'" class="iconfont" :class="shapeBtnClass" :title="shapeBtnTitle" @click="useTool('shapeTool',shapeTool)" />
        </el-popover>
        <el-button v-for="(tool, idx) in tools" :key="idx" :type="state.act === tool[1] ? 'primary' : 'plain'" :class="'iconfont ' + tool[2] + ''" :title="tool[3]" @click="emit('setAct', tool[1])" />
        <el-button :type="state.act === 'pick' ? 'primary' : 'plain'" :class="'iconfont my-pick'" :title="$t('i.pick')" @click="emit('setAct', 'pick')" />
        <el-button style="width: 24px;height:24px;border-radius: 100%;margin-top: 8px;" :title="$t('i.curColor')" :style="{ background: (state.color[0] || '#000000') + '!important' }" @click="(event) => {emit('toggleColorPicker', [event, 'first-color'])}" />
      </div>
    </div>
    <div class="bottom">
      <!-- <el-button class="iconfont my-link-add" :title="$t('i.openWebImage')" @click="emit('openWebImage', ['import'])" />
      <el-button class="iconfont my-image-add" :title="$t('i.openLocalImages')" @click="emit('openLocalImages', ['import'])" /> -->
      <el-button :class="'iconfont my-zoomin'" @click="emit('zoom', '+')" />
      <el-button :class="'iconfont my-zoomout'" @click="emit('zoom', '-')" />
      <el-button :type="state.act === 'hand' ? 'primary' : 'plain'" :class="'iconfont my-hand'" :title="$t('i.hand')" @click="emit('toggleAct', 'hand')" />
      <el-button class="iconfont my-scenes" :class="{'blue': state.showScenes}" @click="state.showScenes = !state.showScenes" />
      <!-- <el-button class="iconfont my-palette" :class="{'blue': state.showPalette}" @click="state.showPalette = !state.showPalette" /> -->
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/common'
export default {
  mixins: [mixins],
  data() {
    const data = {
      shapeToolIdx: 0,
      selectTool: 'default',
      selectPopoverShow: false,
      eraseTool: 'erase',
      erasePopoverShow: false,
      brushTool: 'brush',
      brushPopoverShow: false,
      shapeTool: 'ellipse',
      shapePopoverShow: false,
      shapeBtns: {
        'line': ['my-line', this.$t('i.line')],
        'ellipse': ['my-blank-circle', this.$t('i.ellipse')],
        'rectangle': ['my-blank-rectangle', this.$t('i.rectangle')],
        'triangle': ['my-blank-triangle', this.$t('i.triangle')],
        'star': ['my-blank-star', this.$t('i.star')]
      }
    }
    data.tools = {
      'k_84': ['T', 'txt', 'my-txt', this.$t('i.txt')],
      'k_67': ['C', 'freeSelect', 'my-select', this.$t('i.select')],
      'k_81': ['Q', 'resize', 'my-resize', this.$t('i.resize')]
    }
    return data
  },
  computed: {
    shapeBtnClass() {
      const cls = {}
      cls[this.shapeBtns[this.shapeTool] ? this.shapeBtns[this.shapeTool][0] : this.shapeBtns['ellipse'][0]] = true
      return cls
    },
    shapeBtnTitle() {
      return this.shapeBtns[this.shapeTool] ? this.shapeBtns[this.shapeTool][1] : this.shapeBtns['ellipse'][1]
    }
  },
  methods: {
    hidePopover() {
      this.selectPopoverShow = false
      this.erasePopoverShow = false
      this.brushPopoverShow = false
      this.shapePopoverShow = false
    },
    setAct(act) {
      this.emit('setAct', act)
    },
    useTool(type, act, autoHide) {
      this[type] = act
      this.setAct(act)
      if (type === 'shapeTool') {
        this.shapeToolIdx = Object.keys(this.shapeBtns).indexOf(act)
      }
      if (autoHide) setTimeout(this.hidePopover, 10)
    },
    autoChangeShapeTool() {
      const btns = Object.keys(this.shapeBtns)
      this.shapeToolIdx++
      if (!btns[this.shapeToolIdx]) this.shapeToolIdx = 0
      this.useTool('shapeTool', btns[this.shapeToolIdx], true)
    }
  }
}
</script>
