<template>
  <el-dialog :visible.sync="config.show" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" class="my-dialog my-dialog-small megbox-fade-enter-active my-smallform" :top="'20vh'">
    <span slot="title">
      <span class="el-dialog__title">{{ (config.type === 'donate' || config.type === 'donateNFT') ? $t('i.donate') : config.type === 'view' ? $t('i.browse') : '' }}</span>
      <i class="iconfont my-close title-btn" :title="$t('i.close')" @click="() => (closePop(config))" />
    </span>
    <el-card v-loading="config.loading" shadow="never" class="login-card" element-loading-background="rgba(0, 0, 0, 0.6)">
      <el-form ref="formWidget" v-loading="config.loading" label-width="80px" :inline="false" :model="form" :rules="rules" element-loading-background="rgba(0, 0, 0, 0.6)">
        <el-form-item :label="(config.type === 'donateNFT' ? $t('i.nfts') : '') + $t('i.title')">
          <span class="line-height16">{{ itemName() }}</span>
        </el-form-item>
        <el-form-item v-if="config.type === 'view'" :label="(config.type === 'donateNFT' ? $t('i.nfts') : '') + $t('i.introduction')">
          <span class="line-height16">{{ itemInfo() }}</span>
        </el-form-item>
        <div v-if="config.type === 'donate' || config.type === 'donateNFT'">
          <el-form-item v-if="false" :label="$t('i.donateNums')">
            <el-input-number v-model="form.nums" :step="1" :min="1" size="small" />
          </el-form-item>
          <el-form-item v-if="receiver.nickname" :label="$t('i.receiverNick')">
            {{ receiver.nickname }}
          </el-form-item>
          <el-form-item :label="$t('i.receiverAddress')" prop="receiver">
            <el-input v-model.trim="form.receiver" maxlength="255" :placeholder="$t('i.reqReceiverAddress')" clearable />
          </el-form-item>
          <el-form-item :label="$t('i.tradePWD')" prop="password">
            <el-input v-model.trim="form.password" type="password" maxlength="6" clearable />
          </el-form-item>
          <el-form-item label="">
            <el-button v-if="config.type === 'donate' || config.type === 'donateNFT'" class="submit" style="width: 125px;" @click="submitForm('formWidget')">
              {{ $t('i.sure') }}
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-card>
    <span v-if="config.type !== 'donate' && config.type !== 'donateNFT'" slot="footer" class="dialog-footer">
      <el-button size="small" @click="closePop(config)">{{ $t('i.cancel') }}</el-button>
      <el-button v-if="config.type === 'view'" size="small" type="primary" @click="() => submit()">{{ canOpen() ? $t('i.openin') : $t('i.ok') }}</el-button>
      <el-button v-if="config.type !== 'view'" size="small" type="primary" @click="() => submit()">{{ $t('i.ok') }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import service from '@/js/service'
import utils from '@/js/utils'
export default {
  components: {},
  mixins: [mixins],
  props: {},
  data() {
    const data = {}
    data.receiver = {}
    data.form = {
      nums: 1,
      receiver: '',
      password: ''
    }
    data.rules = {
      receiver: [{ trigger: 'blur', validator: this.validPhone }],
      password: [{ trigger: 'blur', validator: this.validPassword }]
    }
    return data
  },
  computed: {
    config() {
      return this.view.itemOperate
    },
    data() {
      return this.view.itemOperate.data
    }
  },
  watch: {
    'view.itemOperate.show': {
      handler() {
        if (this.$refs['formWidget']) this.$refs['formWidget'].resetFields()
        this.config.loading = false
      }
    }
  },
  methods: {
    validPhone(rule, value, cb) {
      if (!value || (value && value.length === 11 && !utils.checkNow('mobile', value, true))) {
        cb(new Error(this.$t('i.reqChainAdd')))
      } else {
        this.getTradeState()
        cb()
      }
    },
    validPassword(rule, value, cb) {
      if (!utils.checkNow('paypassword', value, true)) {
        cb(new Error(this.$t('i.tradePwdError')))
      } else {
        cb()
      }
    },
    itemInfo() {
      if (!this.data.item || !this.data.item_type) return ''
      return this.data.item_type.info || ''
    },
    canOpen() {
      return (this.data.item && this.data.item_type && this.data.item_type.break)
    },
    itemName() {
      if (!this.data.item || !this.data.item_type) return ''
      return this.data.item.name || this.data.item_type.name || ''
    },
    getTradeState() {
      this.emit('getTradeState', ['', this.form.receiver, (ret) => {
        ret = ret || {}
        this.receiver = {}
        if (ret.status < -9) {
          this.emit('alert', [this.$t('i.chainAddressErr')])
        } else if (ret.status < -1) {
          this.emit('alert', [this.$t('i.receiverErr')])
        } else if (ret.status < 4) {
          this.emit('alert', [this.$t('i.receiverError')])
        } else if (ret.status === 4) {
          if (ret.userid === this.loginUserId) {
            this.emit('alert', [this.$t('i.donateErr')])
          } else {
            this.receiver = ret
          }
        }
      }])
    },
    resetForm() {
      if (this.$refs['formWidget']) this.$refs['formWidget'].resetFields()
      this.form.nums = 1
      this.form.receiver = ''
      this.form.password = ''
      this.receiver = {}
      this.config.data = {}
    },
    submit() {
      // this.config.loading = true
      this.closePop(this.config)
      if (this.config.fn && this.canOpen()) this.config.fn()
    },
    submitForm(formName) {
      const cb = (dt, type) => {
        if (type === 'success') {
          this.message(this.$t('i.donateSuccess'), type)
          this.resetForm()
          this.closePop(this.config)
        } else {
          this.message(dt, type)
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.config.type === 'donate') {
            this.state.decItems[this.data.item.itemid] = 1
            this.view.items.renew = utils.time('time')
            const params = {}
            params.type = 'donate'
            params.id = this.data.item.itemid
            params.pay_password = this.form.password
            params.to_userid = this.receiver.userid
            params.nums = this.form.nums
            service.actionPost('item', params, cb, true)
          } else if (this.config.type === 'donateNFT') {
            const params = {}
            params.to_userid = this.receiver.userid
            params.typeid = 0
            params.nftid = this.data.nftid
            params.pay_password = this.form.password
            service.post('donate', params, cb, true)
          }
        }
      })
    }
  }
}
</script>
