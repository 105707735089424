export default {
  'name': '拼图色',
  'hide': true,
  'data': [
    {
      'name': '白色',
      'hex': '#FEFBF5',
      'id': '1'
    },
    {
      'name': '灰白',
      'hex': '#E6E6E4',
      'id': '2'
    },
    {
      'name': '浅蓝灰',
      'hex': '#C0C4D2',
      'id': '7'
    },
    {
      'name': '沙蓝',
      'hex': '#828EA4',
      'id': '43'
    },
    {
      'name': '浅灰',
      'hex': '#ADADB0',
      'id': '3'
    },
    {
      'name': '中灰',
      'hex': '#838588',
      'id': '4'
    },
    {
      'name': '深灰',
      'hex': '#616160',
      'id': '5'
    },
    {
      'name': '深蓝灰',
      'hex': '#49484E',
      'id': '8'
    },
    {
      'name': '黑色',
      'hex': '#111111',
      // 'hex': '#212121',
      'id': '6'
    },
    {
      'name': '薰衣草',
      'hex': '#B0A1CD',
      'id': '33'
    },
    {
      'name': '蛋壳色',
      'hex': '#FDEDE4',
      'id': '9'
    },
    {
      'name': '淡肉色',
      'hex': '#FCE3CD',
      'id': '10'
    },
    {
      'name': '肌色',
      'hex': '#FACE9D',
      'id': '12'
    },
    {
      'name': '浅肉色',
      'hex': '#F8C5A3',
      'id': '11'
    },
    {
      'name': '藕色',
      'hex': '#EDAD87',
      'id': '13'
    },
    {
      'name': '粉葵彩',
      'hex': '#DA8755',
      'id': '14'
    },
    {
      'name': '法布兰棕',
      // 'hex': '#C45C1B',
      'hex': '#B15218',
      'id': '16'
    },
    {
      'name': '棕色',
      'hex': '#743620',
      'id': '17'
    },
    {
      'name': '深棕色',
      'hex': '#482413',
      'id': '18'
    },
    {
      'name': '浅紫',
      'hex': '#9B68A9',
      'id': '34'
    },
    {
      'name': '沙色',
      'hex': '#E3C17F',
      'id': '19'
    },
    {
      'name': '浅黄',
      // 'hex': '#FFEE7D',
      'hex': '#FFE155',
      'id': '21'
    },
    {
      'name': '黄色',
      // 'hex': '#FDD100',
      'hex': '#FFCC00',
      'id': '22'
    },
    {
      'name': '深黄色',
      // 'hex': '#D98700',
      'hex': '#FFAA00',
      'id': '23'
    },
    {
      'name': '中橙',
      'hex': '#F39800',
      'id': '24'
    },
    {
      'name': '橙色',
      'hex': '#FF7E14',
      'id': '25'
    },
    {
      'name': '淡鲑鱼',
      'hex': '#FADBDA',
      'id': '26'
    },
    {
      'name': '亮粉',
      'hex': '#F3A9C9',
      'id': '27'
    },
    {
      'name': '深粉',
      'hex': '#DC6AA0',
      'id': '28'
    },
    {
      'name': '玫红',
      'hex': '#9C0A67',
      'id': '29'
    },
    {
      'name': '黄绿',
      'hex': '#D8E698',
      'id': '44'
    },
    {
      'name': '果绿',
      'hex': '#B7C500',
      'id': '45'
    },
    {
      'name': '草绿',
      'hex': '#00A73C',
      'id': '49'
    },
    {
      'name': '绿色',
      'hex': '#00833B',
      'id': '50'
    },
    {
      'name': '深绿',
      'hex': '#004719',
      'id': '51'
    },
    {
      'name': '军绿',
      'hex': '#15340E',
      'id': '52'
    },
    {
      'name': '肉色',
      'hex': '#C46652',
      'id': '15'
    },
    {
      'name': '红色',
      'hex': '#D92817',
      'id': '30'
    },
    {
      'name': '深红',
      'hex': '#9F0014',
      'id': '31'
    },
    {
      'name': '紫红色',
      'hex': '#663651',
      'id': '83'
    },
    {
      'name': '糖果蓝',
      'hex': '#91CDF1',
      'id': '36'
    },
    {
      'name': '天蓝',
      'hex': '#579ED7',
      'id': '37'
    },
    {
      'name': '深空蓝',
      'hex': '#2980C4',
      'id': '39'
    },
    {
      'name': '浅蓝',
      'hex': '#0086C1',
      'id': '40'
    },
    {
      'name': '蓝色',
      'hex': '#005FAD',
      'id': '41'
    },
    {
      'name': '藏蓝',
      // 'hex': '#003760',
      'hex': '#174664',
      'id': '42'
    },
    // 定制颜色
    {
      'name': '淡古灰',
      'hex': '#DACBBE',
      'id': '80'
    },
    // 定制颜色
    {
      'name': '浅古灰',
      'hex': '#CAB5A2',
      'id': '81'
    },
    // 定制颜色
    {
      'name': '复古灰',
      'hex': '#BA9E85',
      'id': '82'
    },
    {
      'name': '沙红',
      'hex': '#A87570',
      'id': '32'
    },
    {
      'name': '淡蓝',
      'hex': '#1BB8CE',
      'id': '38'
    },
    {
      'name': '青色',
      'hex': '#009E9F',
      'id': '48'
    },
    {
      'name': '沙绿',
      'hex': '#739F80',
      'id': '47'
    },
    {
      'name': '橄榄绿',
      'hex': '#878444',
      'id': '46'
    },
    {
      'name': '褐色',
      'hex': '#907445',
      'id': '20'
    },
    {
      'name': '深紫',
      'hex': '#584C9D',
      'id': '35'
    }
  ]
}
