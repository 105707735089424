import PullTo from 'vue-pull-to'
import emptyImage from '@/assets/lou.png'
import sofaImage from '@/assets/sofa.png'
import utils from '@/js/utils'
import service from '@/js/service'
export const mixins = {
  components: {
    PullTo
  },
  props: {
    view: {
      type: Object,
      default() {
        return {}
      }
    },
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    db: {
      type: Object,
      default() {
        return {}
      }
    },
    file: {
      type: Object,
      default() {
        return {}
      }
    },
    mod: {
      type: String,
      default() {
        return 'comment'
      }
    },
    pageSize: {
      type: Number,
      default: 10
    },
    loginStatus: {
      type: Boolean,
      default: false
    },
    loginUserId: {
      type: Number,
      default: 0
    },
    isPop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let container_height
    if (this.state.platform.type === 'desktop' && this.isPop) {
      container_height = 632
    } else {
      container_height = utils.height() - 20
    }
    const schema = {
      scrollTop: 0,
      items: [],
      total: 0,
      currentPage: 0,
      cursorValue: 0,
      cursorValueUp: 0,
      cursorValueDown: 0,
      noUpPage: false,
      noDownPage: false,
      threadid: 0,
      thread: {},
      postid: 0,
      replyPostid: 0,
      replyNickname: '',
      placeholder: this.$t('i.reqReply'),
      rows: 1,
      content: ''
    }
    const data = {
      // 可用宽度
      container_height: container_height,
      emptyImage: emptyImage,
      sofaImage: sofaImage,
      // 达到保留页数时，重新渲染列表
      remainPages: 100,
      showUp: false,
      renew: false,
      schema: schema,
      dt: {
        'thread': utils.deepClone(schema),
        'comment': utils.deepClone(schema),
        'reply': utils.deepClone(schema)
      },
      loading: {
        'thread': false,
        'comment': false,
        'reply': false
      }
    }
    return data
  },
  computed: {
    isDesktop() {
      return this.state.platform.type === 'desktop'
    },
    isMobile() {
      return this.state.platform.type === 'mobile'
    },
    listStyle() {
      return { 'height': (this.container_height - (this.data.rows > 1 ? 120 : 80)) + 'px' }
    },
    reqId() {
      return this.state.reqId
    },
    viewMod() {
      return this.view.mod
    },
    users() {
      return this.state.users
    },
    data() {
      return this.dt[this.mod]
    },
    items() {
      if (this.data.thread && this.data.thread.content) {
        return [this.data.thread].concat(this.data.items)
      } else {
        return this.data.items
      }
    },
    thread() {
      this.data && this.data.thread ? this.data.thread : {}
    },
    config() {
      return this.view[this.mod]
    },
    threadCount() {
      return this.state.threadCount
    },
    followState() {
      return this.state.followState
    },
    limit() {
      let limit = this.pageSize
      if (!this.isMobile) limit = limit * 2
      return limit
    }
  },
  watch: {
    'view.thread.show': {
      handler() {
        if (this.mod === 'thread') {
          this.view.reply.postid = 0
          this.data.replyPostid = 0
          this.data.replyNickname = ''
          if (this.view.thread.show) this.update()
          if (this.view.thread.show && (!this.data.items.length || this.view.thread.threadid !== this.data.threadid)) {
            if (this.view.thread.threadid !== this.data.threadid) this.init()
            this.getData('down')
          }
        }
      }
    },
    'state.deletePosts': {
      handler() {
        if (this.state.deletePosts.length && ((this.mod === 'thread' && this.view.thread.show) || (this.mod === 'comment' && this.view.comment.show))) {
          this.deleteItems()
        }
      }
    },
    'state.addPosts': {
      handler() {
        if (this.state.addPosts.length && ((this.mod === 'thread' && this.view.thread.show) || (this.mod === 'comment' && this.view.comment.show))) {
          for (var i in this.state.addPosts) {
            this.attachReply(this.state.addPosts[i])
          }
          this.state.addPosts = []
        }
      }
    },
    'view.comment.show': {
      handler() {
        if (this.mod === 'comment') {
          this.view.reply.postid = 0
          if (this.view.comment.show) this.update()
          if (this.view.comment.show && (!this.data.items.length || this.file.threadid !== this.data.threadid)) {
            if (this.file.threadid !== this.data.threadid) this.init()
            this.getData('down')
          }
        }
      }
    },
    'view.reply.show': {
      handler() {
        if (this.mod === 'reply') {
          if (this.view.reply.show) this.update()
          if (this.view.reply.show && (!this.data.items.length || this.view.reply.postid !== this.data.postid)) {
            if (this.view.reply.postid !== this.data.postid) this.init()
            this.getData('down')
          }
        }
      }
    },
    'view.reply.postid': {
      handler() {
        if (this.mod === 'reply') {
          this.init()
        }
      }
    },
    'file.threadid': {
      handler() {
        this.init()
      }
    },
    'file.postid': {
      handler() {
        this.init()
      }
    }
  },
  mounted() {
    const container = this.scrollContainer()
    if (container) container.onscroll = () => { this.data.scrollTop = container.scrollTop }
    window.addEventListener('resize', this.onResize)
    if (this.mod === 'comment') {
      this.view.reply.postid = 0
      if (this.view.comment.show && !this.data.items.length) return this.getData('down')
    } else if (this.mod === 'thread') {
      if (this.view.thread.show && this.view.thread.threadid && !this.data.items.length) return this.getData('down')
    } else {
      if (this.view.reply.show && this.view.reply.postid && !this.data.items.length) return this.getData('down')
    }
  },
  methods: {
    threadid() {
      // mod: comment作品评论模式 thread帖子模式 reply回复模式
      if (this.mod === 'comment' || (this.mod === 'reply' && this.view.reply.from === 'comment')) {
        return this.file.threadid
      } else {
        return this.view.thread.threadid
      }
    },
    scrollContainer() {
      const main = document.getElementById('comment-' + this.mod + '-container')
      if (main) {
        const scroll = main.getElementsByClassName('scroll-container')
        if (scroll.length) return scroll[0]
      }
    },
    onResize() {
      if (!this.config.show) return
      // this.backTop()
      // this.data.scrollTop = 0
    },
    backTop() {
      const container = this.scrollContainer()
      if (container) {
        setTimeout(() => {
          container.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        }, 100)
      }
    },
    renewView() {
      this.backTop()
      this.loadData(() => {}, 'renew')
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    // 调用父组件方法
    emit(fn, params) {
      if (!fn) return
      this.$emit('receive', fn, params || [])
    },
    // 提示信息
    message(msg, type) {
      this.emit('message', [msg, type])
    },
    // 确认操作
    confirm(msg, fn, title) {
      this.emit('confirm', [msg, fn, title])
    },
    update() {
      this.$forceUpdate()
    },
    init(backTop) {
      if (backTop) this.backTop()
      this.renew = false
      this.loading[this.mod] = false
      this.dt[this.mod] = utils.deepClone(this.schema)
    },
    getAvatar(userid) {
      const ver = Math.round(new Date(this.users[userid].avatar_at) / 1000)
      if (this.users[userid] && this.users[userid].avatar) return service.getCosUrl(this.users[userid].avatar, 'avatar', ver) || ''
    },
    getContent(item, split) {
      if (!item || !item.content) return ''
      let content
      if (item.comment_user_id && this.users[item.comment_user_id] && this.users[item.comment_user_id].nickname) {
        content = '@' + this.users[item.comment_user_id].nickname + ' ' + item.content
      } else {
        content = item.content
      }
      return utils.fomateContent(content, split)
    },
    loadData(loaded, direction) {
      if (this.config.show) {
        this.renew = direction === 'renew'
        if (direction === 'renew') {
          direction = 'down'
          this.data.currentPage = 0
          this.data.cursorValue = 0
          this.data.cursorValueUp = 0
          this.data.cursorValueDown = 0
          this.data.noUpPage = false
          this.data.noDownPage = false
        }
        this.getData(direction, () => {
          this.renew = false
          loaded('done')
        })
      }
    },
    moreRows(yes) {
      this.data.rows = (yes || this.data.content) ? 3 : 1
    },
    reply(postid, nickname, reset = false) {
      if (this.mod === 'thread') return this.emit('reply', [postid, nickname])
      if (this.data.content && !reset) return
      if (!postid || !nickname || reset) {
        this.data.placeholder = this.schema.placeholder
        this.data.replyPostid = 0
        this.data.replyNickname = ''
        this.moreRows()
      } else {
        this.data.placeholder = this.$t('i.re') + ' ' + nickname + '：'
        this.data.replyPostid = this.file.postid === postid ? 0 : postid
        this.data.replyNickname = nickname
        this.$refs.input.focus()
      }
    },
    showReply(postid, nickname, reply_count) {
      if (!postid || !reply_count) return
      if (this.mod === 'reply') return this.reply(postid, nickname)
      this.view.reply.from = this.mod
      this.view.reply.work = this.file
      this.view.reply.threadid = this.threadid()
      this.view.reply.postid = postid
      this.view.reply.show = true
      // const dt = { from: this.mod, threadid: this.threadid(), postid: postid }
      // this.emit('goto', ['reply', dt])
    },
    // 赞
    praisePost(post_id, thread_id) {
      let cmd
      if (this.state.likeState[post_id]) {
        cmd = 'unpraise'
        delete this.state.likeState[post_id]
        this.state.likeCount[post_id]--
      } else {
        cmd = 'praise'
        this.state.likeState[post_id] = 1
        this.state.likeCount[post_id]++
      }
      this.update()
      thread_id = thread_id || post_id
      service[cmd](thread_id, post_id || 0, (dt, type) => {
        if (type === 'success') {
          this.state.likeState[post_id] = dt.isLiked ? 1 : 0
          this.state.likeCount[post_id] = dt.likeCount
        } else {
          // 失败回滚
          if (this.state.likeState[post_id]) {
            delete this.state.likeState[post_id]
            this.state.likeCount[post_id]--
          } else {
            this.state.likeState[post_id] = 1
            this.state.likeCount[post_id]++
          }
          this.message(dt, type)
        }
        this.update()
      }, true)
    },
    // 分页读取数据
    async getData(direction, cb) {
      direction = direction || 'down'
      if (this.mod === 'thread' && !this.view.thread.show) return cb && cb()
      if (this.mod === 'comment' && !this.view.comment.show) return cb && cb()
      if (this.mod === 'reply' && !this.view.reply.show) return cb && cb()
      if (direction === 'up' && this.data.noUpPage) return cb && cb()
      if (direction === 'down' && this.data.noDownPage) return cb && cb()
      if (this.loading[this.mod]) return cb && cb()
      this.loading[this.mod] = true
      const params = {
        'sort_field': 'id',
        'sort': 'desc',
        'limit': this.limit,
        'cursor_value': direction === 'up' ? this.data.cursorValueUp : this.data.cursorValueDown,
        'direction': direction,
        'count_total': 1,
        'mod': this.mod
      }
      if (this.mod === 'reply') {
        params.threadid = this.view.reply.threadid
        this.data.threadid = this.view.reply.threadid
        if (this.view.reply.show && this.view.reply.postid) {
          params.postid = this.view.reply.postid
          this.data.postid = this.view.reply.postid
        }
      } else {
        params.threadid = this.threadid()
        this.data.threadid = params.threadid
      }
      if (this.userid > 0) {
        params.userid = this.userid
      }
      // console.error(this.mod, this.loading[this.mod], this.threadid(), this.file.threadid, this.view.thread.threadid, params)
      service.listGet('posts', params, (res, type) => {
        if (type === 'success') {
          if (direction === 'up') {
            this.data.cursorValueUp = res.data.cursor_value_up
            if (!this.data.cursorValueDown && res.data.cursor_value_down) this.data.cursorValueDown = res.data.cursor_value_down
            this.data.noUpPage = !this.data.cursorValueUp
            this.data.items = res.data.items.concat(this.data.items)
          } else if (direction === 'down') {
            if ((res.data.count === this.limit && this.data.currentPage >= this.remainPages) || this.renew) {
              if (res.data.count === this.limit && this.data.currentPage >= this.remainPages) {
                this.init(true)
                this.showUp = true
              } else {
                this.init()
              }
            }
            this.data.currentPage++
            this.data.cursorValueDown = res.data.cursor_value_down
            if (!this.data.cursorValueUp && res.data.cursor_value_up) this.data.cursorValueUp = res.data.cursor_value_up
            this.data.noDownPage = !this.data.cursorValueDown
            this.data.items = this.data.items.concat(res.data.items)
          } else {
            if (res.data.items.length) {
              this.data.noUpPage = false
              this.data.noDownPage = false
              this.data.items = res.data.items
            }
            if (this.mod === 'reply' && (!this.data.thread.id || !res.data.items.length)) {
              this.init()
              this.view.reply.show = false
            }
            this.data.placeholder = this.schema.placeholder
            this.data.replyPostid = 0
            this.data.replyNickname = ''
            this.data.rows = 1
          }
          if (res.data.users) {
            for (var userid in res.data.users) {
              this.users[userid] = res.data.users[userid]
            }
          }
          if (res.data.thread) {
            this.data.thread = res.data.thread
            this.data.thread._istop = 1
          } else {
            this.data.thread = {}
          }
          this.data.total = utils.getInt(res.data.total)
          this.emit('setTotal', [this.data.total])
          this.getLikeState(res.data.items.concat([this.data.thread]))
        } else {
          this.message(res, type)
        }
        cb && cb()
        this.loading[this.mod] = false
        if (this.view.thread.show) this.view.thread.loading = false
        if (this.view.comment.show) this.view.comment.loading = false
        if (this.view.praise.show) this.view.praise.loading = false
      }, false, false)
    },
    // 获取赞的状态
    getLikeState(items) {
      if (Array.isArray(items)) {
        const ids = []
        for (var i in items) {
          if (items[i].id) {
            this.state.likeCount[items[i].id] = items[i].like_count
            this.state.replyCount[items[i].id] = items[i].reply_count
            if (items[i].like_count && typeof this.state.likeState[items[i].id] === 'undefined') ids.push(items[i].id)
          }
        }
        if (ids.length) {
          service.batchGet('praise', ids, (res, type) => {
            if (type === 'success' && res.praise_state) {
              // TODO: 未赞过的也可以加入state
              for (const k in res.praise_state) {
                this.state.likeState[k] = 1
              }
              this.update()
            }
          }, true)
        }
      }
    },
    // 处理评论统计
    dealPostCount(add) {
      if (this.mod === 'reply') return
      const threadid = this.threadid()
      if (this.threadCount[threadid] && this.threadCount[threadid].post_count) {
        if (add) {
          this.threadCount[threadid].post_count++
        } else {
          this.threadCount[threadid].post_count--
        }
        if (this.mod === 'thread') this.emit('updateThread')
      }
    },
    // 处理回复统计
    dealReplyCount(add) {
      if (this.mod === 'reply' && this.data.thread && this.data.thread.reply_count) {
        if (add) {
          this.data.thread.reply_count++
          this.data.total++
        } else {
          this.data.thread.reply_count--
          this.data.total--
        }
        this.emit('setTotal', [this.data.total])
      }
    },
    // 附加到评论的回复里
    attachReply(post) {
      let items
      if (this.mod === 'thread') {
        items = this.dt.thread.items
      } else {
        items = this.dt.comment.items
      }
      for (const i in items) {
        if (items[i].id === post.reply_post_id) {
          if (Array.isArray(items[i].replies)) {
            items[i].replies.unshift(post)
          } else {
            items[i].replies = [post]
          }
          items[i].reply_count++
          if (this.state.replyCount[items[i].id]) this.state.replyCount[items[i].id] = items[i].reply_count
        }
      }
      this.update()
      if (this.mod === 'thread') this.emit('updateThread')
    },
    // 删除回复/评论
    deleteItems() {
      if (this.state.deletePosts.length) {
        const threadid = this.threadid()
        for (var i in this.data.items) {
          const item = this.data.items[i]
          if (this.state.deletePosts.indexOf(item.id) >= 0) {
            this.data.items.splice(i, 1)
            if (this.threadCount[threadid] && this.threadCount[threadid].post_count) this.threadCount[threadid].post_count--
            // 评论数-1
            if (this.mod === 'comment') this.state.workCount.post_count--
          } else {
            for (var idx in item.replies) {
              const id = item.replies[idx].id
              if (this.state.deletePosts.indexOf(id) >= 0) item.replies.splice(idx, 1)
            }
            item.reply_count = item.reply_count - this.state.deletePosts.length
            if (this.state.replyCount[item.id]) this.state.replyCount[item.id] = item.reply_count
          }
        }
        this.state.deletePosts = []
        this.update()
        if (this.mod === 'thread') this.emit('updateThread')
        if (this.mod === 'comment') this.emit('setTotal', [this.data.total])
        this.reply()
      }
    },
    // 移除回复/评论
    removeItem(id) {
      for (var i in this.data.items) {
        if (this.data.items[i].id === id) {
          this.data.items.splice(i, 1)
          this.dealPostCount(false)
          this.dealReplyCount(false)
          if (this.mod === 'comment') this.state.workCount.post_count--
        }
      }
    },
    // 评论/回复
    createPosts() {
      if ((this.mod === 'comment' || (this.mod === 'reply' && this.view.reply.from === 'comment')) && !this.file.threadid) return this.createThread()
      const postDt = {
        content: this.data.content
      }
      if (this.mod === 'reply') {
        if (this.data.replyPostid) {
          postDt.replyid = this.data.replyPostid
          postDt.commentPostId = this.data.replyPostid
        } else {
          postDt.replyid = this.view.reply.postid
        }
        postDt.iscomment = true
      } else {
        if (this.data.replyPostid) {
          postDt.replyid = this.data.replyPostid
          postDt.iscomment = true
        }
      }
      if (this.mod === 'comment' || (this.mod === 'reply' && this.view.reply.from === 'comment')) {
        postDt.id = this.file.threadid
        postDt.realm = 1
        postDt.refid = this.file.workid
      } else {
        postDt.id = this.view.thread.threadid
      }
      // console.error(postDt, this.mod, this.config, this.data.replyPostid)
      service.createPosts(postDt, (res, type) => {
        if (type === 'success') {
          const post = {
            'id': res.id,
            'user_id': res.userId,
            'thread_id': res.threadId,
            'reply_post_id': res.replyPostId,
            'reply_user_id': res.replyUserId,
            'comment_post_id': res.commentPostId,
            'comment_user_id': res.commentUserId,
            'content': res.content,
            'reply_count': res.replyCount,
            'like_count': res.likeCount,
            'created_at': res.createdAt
          }
          // 新回复传给评论页
          if (this.mod === 'reply') this.state.addPosts.push(post)
          if (!this.data.replyPostid || this.mod === 'reply') {
            this.users[res.userId] = res.user
            this.data.items.unshift(post)
            this.backTop()
            this.dealReplyCount(true)
            this.dealPostCount(true)
          } else {
            this.attachReply(post)
            // 重置打开回复页数据
            if (postDt.replyid === this.view.reply.postid) {
              this.view.reply.threadid = 0
              this.view.reply.postid = 0
            }
          }
          this.message(this.$t('i.sendOk'), 'success')
          // 作品评论数+1
          if (!this.data.replyPostid && this.mod === 'comment') this.state.workCount.post_count++
          if (this.mod === 'comment' || (this.mod === 'reply' && this.view.reply.from === 'comment')) {
            service.incCount(this.loginUserId, 'work_comment')
          } else {
            service.incCount(this.loginUserId, 'thread_comment')
          }
          this.data.content = ''
          this.data.rows = 1
          this.data.replyPostid = 0
          this.data.replyNickname = ''
          this.reply('', '', true)
        } else {
          this.message(res, type)
        }
      })
    },
    // 首次评论
    createThread() {
      const threadDt = {
        'categoryId': 2,
        'content': { text: this.data.content || '' }
      }
      if (this.mod === 'comment' || (this.mod === 'reply' && this.view.reply.from === 'comment')) {
        threadDt.realm = 1
        threadDt.refid = this.file.workid
      }
      service.createThread(threadDt, (res, type) => {
        if (type === 'success') {
          // 首次评论直接显示
          const post = {
            'id': res.postId,
            'user_id': res.userId,
            'thread_id': res.threadId,
            'reply_post_id': 0,
            'reply_user_id': 0,
            'comment_post_id': 0,
            'comment_user_id': 0,
            'content': res.content.text || '',
            'reply_count': 0,
            'like_count': 0,
            'created_at': res.createdAt
          }
          if (this.mod === 'comment' || (this.mod === 'reply' && this.view.reply.from === 'comment')) {
            post.workid = this.file.workid
            this.file.threadid = res.threadId
            this.file.postid = res.postId
            this.state.workCount.thread_userid = res.userId
            this.state.workCount.post_count = 1
            // 绑定主题 todo
            service.actionPut('work', { workid: this.file.workid, threadid: this.file.threadid, postid: this.file.postid })
            service.incCount(this.loginUserId, 'work_comment')
          }
          this.data.threadid = res.threadId
          this.users[res.userId] = res.user
          if (this.data.items.length) {
            this.data.items.unshift(post)
            this.update()
          } else {
            this.renewView()
          }
          this.message(this.$t('i.sendOk'), 'success')
          this.data.content = ''
          this.data.rows = 1
          this.data.replyPostid = 0
          this.data.replyNickname = ''
          this.reply('', '', true)
        } else {
          this.message(res, type)
        }
      })
    },
    // 设置数据
    setData(data) {
      this.data.content = data.content || ''
      this.data.placeholder = data.placeholder || ''
      this.data.replyPostid = data.replyPostid || 0
      this.data.replyNickname = data.replyNickname || ''
    },
    showActionSheet(userid, postid, threadid, deleteThread) {
      const actions = {
        show: true,
        title: this.$t('i.pleaseSel'),
        btns: []
      }
      // if ((userid && userid === this.loginUserId) || (this.loginUserId && this.loginUserId < 10000)) {
      //   actions.btns.push({ title: this.$t('i.edit'), cb: () => { console.log(this.$t('i.edit')) } })
      // }
      if ((userid && userid === this.loginUserId) || (this.loginUserId && this.loginUserId < 10000)) {
        actions.btns.push({ title: this.$t('i.delete'), cb: () => { this.delPost(postid, threadid, deleteThread) } })
      }
      actions.btns = actions.btns.concat([
        { title: this.$t('i.advertisingGarbage'), cb: () => { this.emit('reports', [threadid, 2, this.$t('i.advertisingGarbage'), this.loginUserId]) } },
        { title: this.$t('i.violationContent'), cb: () => { this.emit('reports', [threadid, 2, this.$t('i.violationContent'), this.loginUserId]) } },
        { title: this.$t('i.maliciousWatering'), cb: () => { this.emit('reports', [threadid, 2, this.$t('i.maliciousWatering'), this.loginUserId]) } },
        { title: this.$t('i.repeatPosting'), cb: () => { this.emit('reports', [threadid, 2, this.$t('i.repeatPosting'), this.loginUserId]) } },
        { title: this.$t('i.otherReasons'), cb: () => { this.emit('setReportReason', [threadid, 2, this.loginUserId]) } }
      ])
      this.view.actionSheet = actions
    },
    // 删除帖子
    delPost(postid, threadid, deleteThread) {
      if (!this.loginStatus) return this.message('', 'login')
      this.confirm(this.$t('i.ifDelete'), (e) => {
        if (e === 'cancel') return
        this.reply()
        if (deleteThread) {
          service.deleteThread(threadid, (res, type) => {
            if (type === 'success') {
              if (this.mod === 'comment' || (this.mod === 'reply' && this.view.reply.from === 'comment')) {
                this.file.threadid = 0
                this.file.postid = 0
                this.state.workCount.post_count = 0
              }
              this.closePop(this.config)
              this.view.reply.show = false
              this.init()
              this.message(this.$t('i.delSuccess'), 'success')
            } else {
              this.message(res, type)
            }
          })
        } else {
          service.deletePosts(postid, (res, type) => {
            if (type === 'success') {
              if (this.view.reply.postid === postid) {
                // 删除的是层主的评论
                this.view.reply.threadid = 0
                this.view.reply.postid = 0
                this.view.reply.show = false
              } else {
                this.removeItem(postid)
              }
              this.state.deletePosts.push(postid)
              this.data.replyPostid = 0
              this.data.replyNickname = ''
              this.data.rows = 1
              this.message(this.$t('i.delSuccess'), 'success')
            } else {
              this.message(res, type)
            }
          })
        }
      })
    }
  }
}
