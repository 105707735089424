/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2023-2-20
 * @copyright Gridy.Art
 */
export default {
  state: {
    language: 'zh',
    supportMobile: true,
    reqId: '',
    unread: {
      system: 0,
      social: 0,
      dialog: 0,
      total: 0
    },
    paint: {},
    source: {},
    userGroupId: 0,
    wallet: {},
    donateCountDownTo: null,
    donateCountDownStr: '',
    countDownNum: 0,
    countDownIt: null,
    // 主题计数器
    threadCount: {},
    // 作品计数器
    workCount: {},
    // 发行信息
    publishes: {},
    // 系列信息
    series: {},
    // 权益
    powers: {},
    // 用户基本信息
    users: {},
    // 关注状态
    followState: {},
    // 帖子的赞
    likeCount: {},
    likeState: {},
    // 帖子回复
    replyCount: {},
    // 作品的喜欢
    praiseCount: {},
    praiseState: {},
    // 作品的收藏
    favorCount: {},
    favorState: {},
    // 专辑的收藏
    albumFavorCount: {},
    albumFavorState: {},
    previewCols: 64,
    previewRows: 64,
    deleteThreads: [],
    deletePosts: [],
    addPosts: [],
    deleteHotWork: {},
    deleteBestWork: {},
    deleteOriginalWork: {},
    deleteWork: {},
    updateWork: {},
    deleteHotAlbum: {},
    deleteBestAlbum: {},
    deleteOriginalAlbum: {},
    deleteAlbum: {},
    updateAlbum: {},
    deleteCreators: {},
    deleteActiveUsers: {},
    deleteUsers: {},
    deleteAllBrickorder: {},
    deletePayingBrickorder: {},
    deletePaidBrickorder: {},
    deleteReceiveBrickorder: {},
    deleteCommentBrickorder: {},
    deleteAllNFTorder: {},
    deletePayingNFTorder: {},
    deletePaidNFTorder: {},
    deleteCancelNFTorder: {},
    deleteAllresource: {},
    deleteDialogMessage: {},
    deleteSocialMessage: {},
    deleteSystemMessage: {},
    decItems: {},
    showScenes: false,
    showLayers: false,
    showPalette: true,
    curPaletteId: 'bricky',
    drawStartTime: 0,
    newVersion: '',
    pos: [0, 0],
    preAct: 'default',
    act: 'default',
    canvasIniting: false,
    resizeingObj: false,
    draggingObj: false,
    previewBgColor: '',
    algorithm: 'differenceCiede2000',
    colorFilter: {
      // 色调
      hue: 0,
      // 饱和度
      saturation: 0,
      // 色明度
      value: 0,
      // 亮度
      brightness: 0,
      // 对比度
      contrast: 0
    },
    denoiseFactor: 0,
    mergerFactor: 0,
    quantizeFactor: 0,
    lockAspectRatio: false,
    brickfy: false,
    brickSizeId: 1,
    fillShape: '',
    resizing: false,
    hoverObj: [-1, -1],
    colorOrder: 'asc',
    objColors: { count: 0, colors: [] },
    activeObj: {},
    selectObjs: {},
    selectNums: 0,
    objAct: '',
    freeSelecting: false,
    freeSelector: { start: false, show: false, active: false, partSelect: false, idx: -1, parentIdx: -1, startPos: [0, 0], x: -8, y: -8, w: 8, h: 8 },
    multiSelectBox: { start: false, dragging: false, resizing: false, x: -8, y: -8, width: 8, height: 8, startSize: [8, 8], stopSize: [8, 8], offsetCols: 0, offsetRows: 0, startPos: [0, 0], stopPos: [0, 0], offsetX: 0, offsetY: 0 },
    drawShapeBox: { start: false, x: 0, y: 0, w: 0, h: 0 },
    historyAct: false,
    historyId: {},
    canSave: false,
    canUngroup: false,
    canMerge: false,
    canLock: false,
    canUnlock: false,
    canBack: false,
    backLock: false,
    canRedo: false,
    canClean: false,
    editTxtLock: true,
    editTxt: false,
    openWebImages: { mod: 'open', multi: false, callback: null, hook: null },
    openLocalImages: { mod: 'open', multi: false, callback: null, hook: null, params: {}},
    openLocalFiles: { mod: 'open', multi: false, callback: null, hook: null },
    openFiles: [],
    play: false,
    palette: {},
    workAreaBox: {},
    freeColorPicker: { open: false, color: '#FFFFFF', x: 0, y: 0 },
    colorPicker: { open: false, pickId: '', params: [], fileType: 0, color: '', x: 0, y: 0 },
    defaultColor: ['#000000', '#FFFFFF'],
    color: ['#000000', '#FFFFFF'],
    colorIdx: 0,
    objColor: ['#000000', '#FFFFFF'],
    gridSize: 8,
    objGridSize: 0,
    pinchScale: 1,
    pinch: false,
    scale: 1,
    fileId: 'NULL',
    sceneIdx: 0,
    sceneId: '',
    parentObjIdx: -1,
    objIdx: -1,
    canvasAreaX: 0,
    canvasAreaY: 0,
    maxGrids: 48,
    maxObjs: 32,
    maxLayers: 80,
    limitCols: 96,
    limitRows: 96,
    maxCols: 256,
    maxRows: 256,
    dragX: -8,
    dragY: -8
  }
}
