export default {
  'name': '卡卡',
  'data': [
    {
      'hex': '#FBFBFB',
      'id': 'A02',
      'order': 1
    },
    {
      'hex': '#FAF5CD',
      'id': 'E2',
      'order': 1
    },
    {
      'hex': '#DFF139',
      'id': 'F5',
      'order': 1
    },
    {
      'hex': '#F0FEE4',
      'id': 'G01',
      'order': 1
    },
    {
      'hex': '#DAABB3',
      'id': 'GB1',
      'order': 1
    },
    {
      'hex': '#ACB7EF',
      'id': 'J07',
      'order': 1
    },
    {
      'hex': '#F6D4CB',
      'id': 'K03',
      'order': 1
    },
    {
      'hex': '#FF9280',
      'id': 'K08',
      'order': 1
    },
    {
      'hex': '#D40E1F',
      'id': 'L1',
      'order': 1
    },
    {
      'hex': '#F9F9F9',
      'id': 'M01',
      'order': 1
    },
    {
      'hex': '#FF6FB7',
      'id': 'N1',
      'order': 1
    },
    {
      'hex': '#BBC6B6',
      'id': 'Y01',
      'order': 1
    },
    {
      'hex': '#FFE4D3',
      'id': 'Z02',
      'order': 1
    },
    {
      'hex': '#000000',
      'id': 'A01',
      'order': 2
    },
    {
      'hex': '#F73D4B',
      'id': 'C02',
      'order': 2
    },
    {
      'hex': '#FCFED6',
      'id': 'E1',
      'order': 2
    },
    {
      'hex': '#64F343',
      'id': 'F8',
      'order': 2
    },
    {
      'hex': '#D6AA87',
      'id': 'GB2',
      'order': 2
    },
    {
      'hex': '#ABF8FE',
      'id': 'H03',
      'order': 2
    },
    {
      'hex': '#868DD3',
      'id': 'J08',
      'order': 2
    },
    {
      'hex': '#FCC1DD',
      'id': 'K15',
      'order': 2
    },
    {
      'hex': '#F13484',
      'id': 'L2',
      'order': 2
    },
    {
      'hex': '#ABABAB',
      'id': 'M02',
      'order': 2
    },
    {
      'hex': '#FDB583',
      'id': 'N2',
      'order': 2
    },
    {
      'hex': '#909994',
      'id': 'Y02',
      'order': 2
    },
    {
      'hex': '#FCC6AC',
      'id': 'Z05',
      'order': 2
    },
    {
      'hex': '#B4B4B4',
      'id': 'B03',
      'order': 3
    },
    {
      'hex': '#EF4D3E',
      'id': 'C03',
      'order': 3
    },
    {
      'hex': '#FCFF92',
      'id': 'E5',
      'order': 3
    },
    {
      'hex': '#9FF685',
      'id': 'F4',
      'order': 3
    },
    {
      'hex': '#C1BD8D',
      'id': 'GB3',
      'order': 3
    },
    {
      'hex': '#9EE0F8',
      'id': 'H04',
      'order': 3
    },
    {
      'hex': '#3653AF',
      'id': 'H15',
      'order': 3
    },
    {
      'hex': '#F6BDE8',
      'id': 'K17',
      'order': 3
    },
    {
      'hex': '#FB852B',
      'id': 'L3',
      'order': 3
    },
    {
      'hex': '#B6DBAF',
      'id': 'M03',
      'order': 3
    },
    {
      'hex': '#D8FCA4',
      'id': 'N3',
      'order': 3
    },
    {
      'hex': '#697E80',
      'id': 'Y03',
      'order': 3
    },
    {
      'hex': '#F1C4A5',
      'id': 'Z06',
      'order': 3
    },
    {
      'hex': '#878787',
      'id': 'B05',
      'order': 4
    },
    {
      'hex': '#F92B40',
      'id': 'C06',
      'order': 4
    },
    {
      'hex': '#F7EC5C',
      'id': 'E7',
      'order': 4
    },
    {
      'hex': '#5FDF34',
      'id': 'F9',
      'order': 4
    },
    {
      'hex': '#96B69F',
      'id': 'GB4',
      'order': 4
    },
    {
      'hex': '#44CDFB',
      'id': 'H05',
      'order': 4
    },
    {
      'hex': '#162C7E',
      'id': 'H20',
      'order': 4
    },
    {
      'hex': '#E9639E',
      'id': 'K21',
      'order': 4
    },
    {
      'hex': '#F8ED33',
      'id': 'L4',
      'order': 4
    },
    {
      'hex': '#FEA2A3',
      'id': 'M04',
      'order': 4
    },
    {
      'hex': '#91DAFB',
      'id': 'N4',
      'order': 4
    },
    {
      'hex': '#E0D4BC',
      'id': 'Y04',
      'order': 4
    },
    {
      'hex': '#DCB387',
      'id': 'Z08',
      'order': 4
    },
    {
      'hex': '#464648',
      'id': 'B06',
      'order': 5
    },
    {
      'hex': '#E30328',
      'id': 'C07',
      'order': 5
    },
    {
      'hex': '#F0D83A',
      'id': 'D3',
      'order': 5
    },
    {
      'hex': '#39E158',
      'id': 'F10',
      'order': 5
    },
    {
      'hex': '#849DC6',
      'id': 'GB5',
      'order': 5
    },
    {
      'hex': '#06ABE3',
      'id': 'H07',
      'order': 5
    },
    {
      'hex': '#B34EC6',
      'id': 'J12',
      'order': 5
    },
    {
      'hex': '#F1559F',
      'id': 'K19',
      'order': 5
    },
    {
      'hex': '#32C958',
      'id': 'L5',
      'order': 5
    },
    {
      'hex': '#EB903F',
      'id': 'M05',
      'order': 5
    },
    {
      'hex': '#E987EA',
      'id': 'N5',
      'order': 5
    },
    {
      'hex': '#D0CBAE',
      'id': 'Y05',
      'order': 5
    },
    {
      'hex': '#E7B34E',
      'id': 'Z10',
      'order': 5
    },
    {
      'hex': '#2C2C2C',
      'id': 'B07',
      'order': 6
    },
    {
      'hex': '#FDA951',
      'id': 'D5',
      'order': 6
    },
    {
      'hex': '#64E0A4',
      'id': 'G4',
      'order': 6
    },
    {
      'hex': '#94BFE2',
      'id': 'GB6',
      'order': 6
    },
    {
      'hex': '#54A7E9',
      'id': 'H08',
      'order': 6
    },
    {
      'hex': '#B37BDC',
      'id': 'J11',
      'order': 6
    },
    {
      'hex': '#EC4072',
      'id': 'K22',
      'order': 6
    },
    {
      'hex': '#1EBA93',
      'id': 'L6',
      'order': 6
    },
    {
      'hex': '#63CEA2',
      'id': 'M06',
      'order': 6
    },
    {
      'hex': '#B0AA86',
      'id': 'Y06',
      'order': 6
    },
    {
      'hex': '#E3A014',
      'id': 'Z11',
      'order': 6
    },
    {
      'hex': '#913635',
      'id': 'Z21',
      'order': 6
    },
    {
      'hex': '#010101',
      'id': 'B09',
      'order': 7
    },
    {
      'hex': '#911932',
      'id': 'C10',
      'order': 7
    },
    {
      'hex': '#FA8C4F',
      'id': 'D8',
      'order': 7
    },
    {
      'hex': '#3EAE7C',
      'id': 'G5',
      'order': 7
    },
    {
      'hex': '#E2A9D2',
      'id': 'GB7',
      'order': 7
    },
    {
      'hex': '#3977CC',
      'id': 'H13',
      'order': 7
    },
    {
      'hex': '#8758A9',
      'id': 'J15',
      'order': 7
    },
    {
      'hex': '#C63674',
      'id': 'K25',
      'order': 7
    },
    {
      'hex': '#1D779C',
      'id': 'L7',
      'order': 7
    },
    {
      'hex': '#E79273',
      'id': 'M07',
      'order': 7
    },
    {
      'hex': '#B0A796',
      'id': 'Y07',
      'order': 7
    },
    {
      'hex': '#985C3A',
      'id': 'Z18',
      'order': 7
    },
    {
      'hex': '#E7D6DC',
      'id': 'A09',
      'order': 8
    },
    {
      'hex': '#BB0126',
      'id': 'C09',
      'order': 8
    },
    {
      'hex': '#FDD94D',
      'id': 'E8',
      'order': 8
    },
    {
      'hex': '#1D9B54',
      'id': 'F11',
      'order': 8
    },
    {
      'hex': '#AB91C0',
      'id': 'GB8',
      'order': 8
    },
    {
      'hex': '#0F52BD',
      'id': 'H14',
      'order': 8
    },
    {
      'hex': '#E3D2FE',
      'id': 'J03',
      'order': 8
    },
    {
      'hex': '#FDDBE9',
      'id': 'K12',
      'order': 8
    },
    {
      'hex': '#1960C8',
      'id': 'L8',
      'order': 8
    },
    {
      'hex': '#ECDB59',
      'id': 'M08',
      'order': 8
    },
    {
      'hex': '#AE8082',
      'id': 'Y08',
      'order': 8
    },
    {
      'hex': '#713D2F',
      'id': 'Z22',
      'order': 8
    },
    {
      'hex': '#EFEDEE',
      'id': 'A08',
      'order': 9
    },
    {
      'hex': '#F99C5F',
      'id': 'D6',
      'order': 9
    },
    {
      'hex': '#2A5037',
      'id': 'F16',
      'order': 9
    },
    {
      'hex': '#3349C3',
      'id': 'H16',
      'order': 9
    },
    {
      'hex': '#D6BAF5',
      'id': 'J04',
      'order': 9
    },
    {
      'hex': '#E575C7',
      'id': 'K18',
      'order': 9
    },
    {
      'hex': '#E0677A',
      'id': 'K20',
      'order': 9
    },
    {
      'hex': '#945AB1',
      'id': 'L9',
      'order': 9
    },
    {
      'hex': '#DBD9DA',
      'id': 'M09',
      'order': 9
    },
    {
      'hex': '#A88764',
      'id': 'Y09',
      'order': 9
    },
    {
      'hex': '#E4B685',
      'id': 'Z09',
      'order': 9
    },
    {
      'hex': '#ECEAEB',
      'id': 'A10',
      'order': 10
    },
    {
      'hex': '#F47E36',
      'id': 'D7',
      'order': 10
    },
    {
      'hex': '#9AD1BA',
      'id': 'G3',
      'order': 10
    },
    {
      'hex': '#3DBBE3',
      'id': 'H09',
      'order': 10
    },
    {
      'hex': '#301A49',
      'id': 'J19',
      'order': 10
    },
    {
      'hex': '#D33997',
      'id': 'K23',
      'order': 10
    },
    {
      'hex': '#F8DA54',
      'id': 'L10',
      'order': 10
    },
    {
      'hex': '#DBC7EA',
      'id': 'M10',
      'order': 10
    },
    {
      'hex': '#C6B2BB',
      'id': 'Y10',
      'order': 10
    },
    {
      'hex': '#DA8C42',
      'id': 'Z15',
      'order': 10
    },
    {
      'hex': '#874628',
      'id': 'Z20',
      'order': 10
    },
    {
      'hex': '#CDCDCD',
      'id': 'B01',
      'order': 11
    },
    {
      'hex': '#FEDB99',
      'id': 'D1',
      'order': 11
    },
    {
      'hex': '#627032',
      'id': 'F14',
      'order': 11
    },
    {
      'hex': '#2ADED3',
      'id': 'H10',
      'order': 11
    },
    {
      'hex': '#BCBAE2',
      'id': 'J06',
      'order': 11
    },
    {
      'hex': '#F7DAD4',
      'id': 'K02',
      'order': 11
    },
    {
      'hex': '#FCECF7',
      'id': 'L11',
      'order': 11
    },
    {
      'hex': '#F1E9D4',
      'id': 'M11',
      'order': 11
    },
    {
      'hex': '#9D7693',
      'id': 'Y11',
      'order': 11
    },
    {
      'hex': '#DAC898',
      'id': 'Z07',
      'order': 11
    },
    {
      'hex': '#592323',
      'id': 'Z23',
      'order': 11
    },
    {
      'hex': '#FDF6EE',
      'id': 'A04',
      'order': 12
    },
    {
      'hex': '#F8516D',
      'id': 'C01',
      'order': 12
    },
    {
      'hex': '#1A6E3D',
      'id': 'F12',
      'order': 12
    },
    {
      'hex': '#1E334E',
      'id': 'H23',
      'order': 12
    },
    {
      'hex': '#DC99CE',
      'id': 'J10',
      'order': 12
    },
    {
      'hex': '#F893BF',
      'id': 'K16',
      'order': 12
    },
    {
      'hex': '#FDA276',
      'id': 'K9',
      'order': 12
    },
    {
      'hex': '#D8D4D3',
      'id': 'L12',
      'order': 12
    },
    {
      'hex': '#E9EDEE',
      'id': 'M12',
      'order': 12
    },
    {
      'hex': '#644B51',
      'id': 'Y12',
      'order': 12
    },
    {
      'hex': '#FEC993',
      'id': 'Z13',
      'order': 12
    },
    {
      'hex': '#F4EFD1',
      'id': 'A06',
      'order': 13
    },
    {
      'hex': '#F45C45',
      'id': 'C04',
      'order': 13
    },
    {
      'hex': '#FEC667',
      'id': 'D4',
      'order': 13
    },
    {
      'hex': '#C8E87D',
      'id': 'F2',
      'order': 13
    },
    {
      'hex': '#CDE7FE',
      'id': 'H01',
      'order': 13
    },
    {
      'hex': '#B5038F',
      'id': 'J14',
      'order': 13
    },
    {
      'hex': '#B5026A',
      'id': 'K24',
      'order': 13
    },
    {
      'hex': '#56534E',
      'id': 'L13',
      'order': 13
    },
    {
      'hex': '#ADCBF1',
      'id': 'M13',
      'order': 13
    },
    {
      'hex': '#C79266',
      'id': 'Y13',
      'order': 13
    },
    {
      'hex': '#B2714B',
      'id': 'Z14',
      'order': 13
    },
    {
      'hex': '#CED7D4',
      'id': 'B02',
      'order': 14
    },
    {
      'hex': '#F85842',
      'id': 'C5',
      'order': 14
    },
    {
      'hex': '#ACE84C',
      'id': 'F6',
      'order': 14
    },
    {
      'hex': '#D6FDFC',
      'id': 'H02',
      'order': 14
    },
    {
      'hex': '#882893',
      'id': 'J16',
      'order': 14
    },
    {
      'hex': '#FAD4BF',
      'id': 'K05',
      'order': 14
    },
    {
      'hex': '#FCADB2',
      'id': 'K07',
      'order': 14
    },
    {
      'hex': '#337BAD',
      'id': 'M14',
      'order': 14
    },
    {
      'hex': '#A3E7DC',
      'id': 'S1',
      'order': 14
    },
    {
      'hex': '#C37463',
      'id': 'Y14',
      'order': 14
    },
    {
      'hex': '#8B684C',
      'id': 'Z17',
      'order': 14
    },
    {
      'hex': '#98A6A6',
      'id': 'B04',
      'order': 15
    },
    {
      'hex': '#D50527',
      'id': 'C08',
      'order': 15
    },
    {
      'hex': '#FBF65E',
      'id': 'E4',
      'order': 15
    },
    {
      'hex': '#305335',
      'id': 'F15',
      'order': 15
    },
    {
      'hex': '#21C5C4',
      'id': 'H11',
      'order': 15
    },
    {
      'hex': '#2F1E8E',
      'id': 'H22',
      'order': 15
    },
    {
      'hex': '#F5C9CA',
      'id': 'K04',
      'order': 15
    },
    {
      'hex': '#668575',
      'id': 'M15',
      'order': 15
    },
    {
      'hex': '#78CEE7',
      'id': 'S2',
      'order': 15
    },
    {
      'hex': '#747D7A',
      'id': 'Y15',
      'order': 15
    },
    {
      'hex': '#F6F8E3',
      'id': 'Z03',
      'order': 15
    },
    {
      'hex': '#1B1213',
      'id': 'B08',
      'order': 16
    },
    {
      'hex': '#FEFF97',
      'id': 'E3',
      'order': 16
    },
    {
      'hex': '#C0ED9C',
      'id': 'F3',
      'order': 16
    },
    {
      'hex': '#1858A2',
      'id': 'H18',
      'order': 16
    },
    {
      'hex': '#E2E4F0',
      'id': 'J01',
      'order': 16
    },
    {
      'hex': '#FBF4EC',
      'id': 'K01',
      'order': 16
    },
    {
      'hex': '#F8C0A9',
      'id': 'K06',
      'order': 16
    },
    {
      'hex': '#FDC24E',
      'id': 'M16',
      'order': 16
    },
    {
      'hex': '#3FCDCE',
      'id': 'S3',
      'order': 16
    },
    {
      'hex': '#FCFDFF',
      'id': 'T1',
      'order': 16
    },
    {
      'hex': '#F2D8C1',
      'id': 'Z04',
      'order': 16
    },
    {
      'hex': '#F0EEEF',
      'id': 'A07',
      'order': 17
    },
    {
      'hex': '#FDE173',
      'id': 'E6',
      'order': 17
    },
    {
      'hex': '#9EB33E',
      'id': 'F13',
      'order': 17
    },
    {
      'hex': '#02D1F3',
      'id': 'H19',
      'order': 17
    },
    {
      'hex': '#C7D3F9',
      'id': 'J05',
      'order': 17
    },
    {
      'hex': '#F7E3EC',
      'id': 'K11',
      'order': 17
    },
    {
      'hex': '#E89B7D',
      'id': 'K31',
      'order': 17
    },
    {
      'hex': '#FDA42E',
      'id': 'M17',
      'order': 17
    },
    {
      'hex': '#4E8379',
      'id': 'S4',
      'order': 17
    },
    {
      'hex': '#79544E',
      'id': 'Z16',
      'order': 17
    },
    {
      'hex': '#FCFFF8',
      'id': 'A03',
      'order': 18
    },
    {
      'hex': '#FCBF80',
      'id': 'D2',
      'order': 18
    },
    {
      'hex': '#E6ED4F',
      'id': 'F7',
      'order': 18
    },
    {
      'hex': '#213244',
      'id': 'H24',
      'order': 18
    },
    {
      'hex': '#9A64B8',
      'id': 'J13',
      'order': 18
    },
    {
      'hex': '#FBC8DB',
      'id': 'K13',
      'order': 18
    },
    {
      'hex': '#D07E4A',
      'id': 'K32',
      'order': 18
    },
    {
      'hex': '#FEBDA7',
      'id': 'M18',
      'order': 18
    },
    {
      'hex': '#7DCA9C',
      'id': 'S5',
      'order': 18
    },
    {
      'hex': '#FFE4D6',
      'id': 'Z01',
      'order': 18
    },
    {
      'hex': '#F2EEE5',
      'id': 'A05',
      'order': 19
    },
    {
      'hex': '#26B78E',
      'id': 'G6',
      'order': 19
    },
    {
      'hex': '#18869D',
      'id': 'H12',
      'order': 19
    },
    {
      'hex': '#D8C2D9',
      'id': 'J09',
      'order': 19
    },
    {
      'hex': '#FD7E77',
      'id': 'K10',
      'order': 19
    },
    {
      'hex': '#F6BBD1',
      'id': 'K14',
      'order': 19
    },
    {
      'hex': '#BE454A',
      'id': 'K33',
      'order': 19
    },
    {
      'hex': '#FFDEE9',
      'id': 'M19',
      'order': 19
    },
    {
      'hex': '#C8E664',
      'id': 'S6',
      'order': 19
    },
    {
      'hex': '#DD7D41',
      'id': 'Z12',
      'order': 19
    },
    {
      'hex': '#96A09F',
      'id': 'B10',
      'order': 20
    },
    {
      'hex': '#F9D66E',
      'id': 'E9',
      'order': 20
    },
    {
      'hex': '#CAEDCF',
      'id': 'G2',
      'order': 20
    },
    {
      'hex': '#1A70A9',
      'id': 'H17',
      'order': 20
    },
    {
      'hex': '#9C34AD',
      'id': 'J17',
      'order': 20
    },
    {
      'hex': '#D7C6CE',
      'id': 'K26',
      'order': 20
    },
    {
      'hex': '#C69495',
      'id': 'K34',
      'order': 20
    },
    {
      'hex': '#FCBFD1',
      'id': 'M20',
      'order': 20
    },
    {
      'hex': '#E3CCBA',
      'id': 'S7',
      'order': 20
    },
    {
      'hex': '#A5452F',
      'id': 'Z19',
      'order': 20
    },
    {
      'hex': '#F8FBE6',
      'id': 'A11',
      'order': 21
    },
    {
      'hex': '#FAE393',
      'id': 'E10',
      'order': 21
    },
    {
      'hex': '#176268',
      'id': 'G7',
      'order': 21
    },
    {
      'hex': '#BEDDFC',
      'id': 'H06',
      'order': 21
    },
    {
      'hex': '#940595',
      'id': 'J18',
      'order': 21
    },
    {
      'hex': '#C09DA4',
      'id': 'K27',
      'order': 21
    },
    {
      'hex': '#F2B8C6',
      'id': 'K35',
      'order': 21
    },
    {
      'hex': '#E8BEC2',
      'id': 'M21',
      'order': 21
    },
    {
      'hex': '#A17140',
      'id': 'S8',
      'order': 21
    },
    {
      'hex': '#B38561',
      'id': 'Z24',
      'order': 21
    },
    {
      'hex': '#CACAD2',
      'id': 'A12',
      'order': 22
    },
    {
      'hex': '#EDF878',
      'id': 'E11',
      'order': 22
    },
    {
      'hex': '#0A4241',
      'id': 'G8',
      'order': 22
    },
    {
      'hex': '#383995',
      'id': 'H21',
      'order': 22
    },
    {
      'hex': '#6BB1BB',
      'id': 'H25',
      'order': 22
    },
    {
      'hex': '#B58B9F',
      'id': 'K28',
      'order': 22
    },
    {
      'hex': '#F7C3D0',
      'id': 'K36',
      'order': 22
    },
    {
      'hex': '#DFAAA4',
      'id': 'M22',
      'order': 22
    },
    {
      'hex': '#6B372C',
      'id': 'S9',
      'order': 22
    },
    {
      'hex': '#9B9C94',
      'id': 'B11',
      'order': 23
    },
    {
      'hex': '#E1C9BD',
      'id': 'E12',
      'order': 23
    },
    {
      'hex': '#343B1A',
      'id': 'F17',
      'order': 23
    },
    {
      'hex': '#C8E2F9',
      'id': 'H26',
      'order': 23
    },
    {
      'hex': '#EADBF8',
      'id': 'J02',
      'order': 23
    },
    {
      'hex': '#937D8A',
      'id': 'K29',
      'order': 23
    },
    {
      'hex': '#EC806D',
      'id': 'K37',
      'order': 23
    },
    {
      'hex': '#A3656A',
      'id': 'M23',
      'order': 23
    },
    {
      'hex': '#F6BB6F',
      'id': 'S10',
      'order': 23
    },
    {
      'hex': '#F3F6A9',
      'id': 'E13',
      'order': 24
    },
    {
      'hex': '#E8FAA6',
      'id': 'F1',
      'order': 24
    },
    {
      'hex': '#7EC5F9',
      'id': 'H27',
      'order': 24
    },
    {
      'hex': '#768AE1',
      'id': 'J20',
      'order': 24
    },
    {
      'hex': '#DEBEE5',
      'id': 'K30',
      'order': 24
    },
    {
      'hex': '#E09DAF',
      'id': 'K38',
      'order': 24
    },
    {
      'hex': '#F3C6C0',
      'id': 'S11',
      'order': 24
    },
    {
      'hex': '#FFD785',
      'id': 'E14',
      'order': 25
    },
    {
      'hex': '#4E846D',
      'id': 'F18',
      'order': 25
    },
    {
      'hex': '#A9E8E0',
      'id': 'H28',
      'order': 25
    },
    {
      'hex': '#4950C2',
      'id': 'J21',
      'order': 25
    },
    {
      'hex': '#E84854',
      'id': 'K39',
      'order': 25
    },
    {
      'hex': '#C76A62',
      'id': 'S12',
      'order': 25
    },
    {
      'hex': '#FEC832',
      'id': 'E15',
      'order': 26
    },
    {
      'hex': '#907C35',
      'id': 'F19',
      'order': 26
    },
    {
      'hex': '#42ADD1',
      'id': 'H29',
      'order': 26
    },
    {
      'hex': '#D6C6EB',
      'id': 'J22',
      'order': 26
    },
    {
      'hex': '#D093BC',
      'id': 'S13',
      'order': 26
    },
    {
      'hex': '#D0E0AF',
      'id': 'F20',
      'order': 27
    },
    {
      'hex': '#D0DEF9',
      'id': 'H30',
      'order': 27
    },
    {
      'hex': '#E58EAE',
      'id': 'S14',
      'order': 27
    },
    {
      'hex': '#9EE5BB',
      'id': 'F21',
      'order': 28
    },
    {
      'hex': '#BDCEE8',
      'id': 'H31',
      'order': 28
    },
    {
      'hex': '#9F85CF',
      'id': 'S15',
      'order': 28
    },
    {
      'hex': '#C6DF5F',
      'id': 'F22',
      'order': 29
    },
    {
      'hex': '#364A89',
      'id': 'H32',
      'order': 29
    },
    {
      'hex': '#E3FBB1',
      'id': 'F23',
      'order': 30
    },
    {
      'hex': '#B2E694',
      'id': 'F24',
      'order': 31
    },
    {
      'hex': '#92AD60',
      'id': 'F25',
      'order': 32
    }
  ]
}
