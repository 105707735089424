<template>
  <el-dialog
    ref="pixel2Gridy"
    :title="title"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page large headless cropDialog"
  >
    <span v-if="isDesktop" slot="title">
      <span class="el-dialog__title">{{ title || $t('i.pixel2Gridy') }}</span>
      <i class="iconfont my-close title-btn" :title="$t('i.close')" @click="close()" />
    </span>
    <div :style="gridyStyle" @contextmenu.prevent="onContextmenu" @wheel="(e) => opts.d3 ? {} : onWheel(e)">
      <vue-draggable-resizable v-show="!opts.d3" :x="x" :y="y" :w="w" :h="h" :resizable="false" :draggable="true" :parent="false">
        <img v-show="false" ref="image" :src="blankImage">
        <canvas v-show="false" ref="imageCanvas" class="imageCanvas" />
        <canvas v-show="!opts.d3" ref="gridyCanvas" class="gridyCanvas" />
      </vue-draggable-resizable>
      <voxel-viewer
        v-show="opts.d3 && config.show"
        ref="voxelViewer"
        :state="state"
        :width="width"
        :height="height"
        :fixpos="0"
        @receive="(event) => emit('handle', event)"
      />
    </div>
    <!-- <div v-if= '!opts.d3' style="position: absolute;text-align: center;z-index: 97;" :style="{'top': isDesktop ? '12px' : '12px', height: '56px', 'width': '56px', right: '0px'}">
      <el-button size="mini" class="tap-btn opacity-bg" :title="'放大'" plain icon="iconfont my-zoomin" @click.stop="zoom('+')" />
    </div>
    <div v-if= '!opts.d3' style="position: absolute;text-align: center;z-index: 97;" :style="{'top': isDesktop ? '56px' : '56px', height: '56px', 'width': '56px', right: '0px'}">
      <el-button size="mini" class="tap-btn opacity-bg" :title="'缩小'" plain icon="iconfont my-zoomout" @click.stop="zoom('-')" />
    </div> -->
    <div v-if="!opts.d3" style="position: absolute;text-align: center;z-index: 96;" :style="{'bottom': isDesktop ? '153px' : '160px', height: '165px', 'width': '56px', left: '0px'}">
      <el-row style="margin-top: 4px;">
        <el-row style="height: 40px;"><el-button class="tap-btn opacity-bg" size="mini" :title="$t('i.zoomin')" plain icon="iconfont my-zoomin" @click.stop="zoom('+')" /></el-row>
        <el-row style="margin-left: 2px;">
          <el-slider
            v-model="opts.scaleSize"
            vertical
            height="70px"
            :min="4 "
            :max="32 "
            :step="1"
            :show-tooltip="false"
            :disabled="opts.preview"
            style="margin: 0 8px"
          />
        </el-row>
        <el-row style="height: 40px;padding-top: 10px;"><el-button size="mini" class="tap-btn opacity-bg" :title="$t('i.zoomout')" plain icon="iconfont my-zoomout" @click.stop="zoom('-')" /></el-row>
      </el-row>
    </div>
    <div v-if="!opts.d3" style="position: absolute;text-align: center;z-index: 96;" :style="{'bottom': isDesktop ? '153px' : '160px', height: '165px', 'width': '56px', right: '0px'}">
      <el-row style="margin-top: 4px;">
        <el-row style="height: 40px;"><el-button class="tap-btn opacity-bg" size="mini" :disabled="opts.preview" :title="$t('i.moveXUp')" plain icon="iconfont my-move-up" @click.stop="opts.fakeOffsetY++" /></el-row>
        <el-row style="margin-left: 2px;">
          <el-slider
            v-model="opts.fakeOffsetY"
            vertical
            height="70px"
            :min="-maxValue "
            :max="maxValue "
            :step="1"
            :show-tooltip="false"
            :disabled="opts.preview"
            style="margin: 0 8px"
          />
        </el-row>
        <el-row style="height: 40px;padding-top: 10px;"><el-button size="mini" class="tap-btn opacity-bg" :disabled="opts.preview" :title="$t('i.moveXDown')" plain icon="iconfont my-move-down" @click.stop="opts.fakeOffsetY--" /></el-row>
      </el-row>
    </div>
    <div v-if="!opts.d3" style="position: absolute;text-align: center;z-index: 96;" :style="{'bottom': isDesktop ? '120px' : '127px', height: '34px', 'width': '170px', left: '12px'}">
      <el-row style="margin-top: 4px;">
        <el-col :span="4"><el-button size="mini" class="tap-btn opacity-bg" :disabled="opts.preview" :title="$t('i.sizeSmall')" plain icon="iconfont my-size-small" @click.stop="opts.gridSize--" /></el-col>
        <el-col :span="16" class="line-height-20">
          <el-slider
            v-model="opts.gridSize"
            width="70px"
            :min="20 "
            :max="maxGridSize "
            :step="1"
            :show-tooltip="false"
            :disabled="opts.preview"
            style="margin: 0 12px"
          />
        </el-col>
        <el-col :span="4"><el-button size="mini" class="tap-btn opacity-bg" :disabled="opts.preview" :title="$t('i.sizeBig')" plain icon="iconfont my-size-big" @click.stop="opts.gridSize++" /></el-col>
      </el-row>
    </div>
    <div v-if="!opts.d3" style="position: absolute;text-align: center;z-index: 95;" :style="{'bottom': isDesktop ? '120px' : '127px', height: '34px', 'width': '170px', right: '16px'}">
      <el-row style="margin-top: 4px;">
        <el-col :span="4"><el-button size="mini" class="tap-btn opacity-bg" :disabled="opts.preview" :title="$t('i.moveYLeft')" plain icon="iconfont my-move-left" @click.stop="opts.offsetX--" /></el-col>
        <el-col :span="16" class="line-height-20">
          <el-slider
            v-model="opts.offsetX"
            width="70px"
            :min="-maxValue "
            :max="maxValue "
            :step="1"
            :show-tooltip="false"
            :disabled="opts.preview"
            style="margin: 0 12px"
          />
        </el-col>
        <el-col :span="4"><el-button size="mini" class="tap-btn opacity-bg" :disabled="opts.preview" :title="$t('i.moveYRight')" plain icon="iconfont my-move-right" @click.stop="opts.offsetX++" /></el-col>
      </el-row>
    </div>
    <div v-if="opts.preview" v-show="false" style="position: absolute;text-align: center;z-index: 97;" class="mid-button-2gridy" :style="{'bottom': isDesktop ? '125px' : '125px', height: '34px', 'width': '156px', left: 'calc((100% - 156px) / 2)'}">
      <el-button class="opacity-bg" style="padding: 8px 16px;margin-bottom: 0;" @click="submit(true)">
        <i class="iconfont my-cart margin-right8 green" /> <span class="green">{{ $t('i.createPuzzle') }}</span>
      </el-button>
    </div>
    <div slot="footer" class="footer foot_2girdy bg-22" style="position: absolute;" :class="{'padding-left12 padding-right12': isDesktop}">
      <el-row style="width: 400px;margin: 8px auto 15px auto;">
        <el-row style="width: 400px;height: 30px;margin: 0 auto;">
          <el-col :span="4">{{ $t('i.optimizeColors') }}</el-col>
          <el-col :span="4" class="align-center"><el-switch v-model="opts.optimizeColors" /></el-col>
          <el-col :span="4" :class="{'color-66': opts.preview}">{{ $t('i.showAllLines') }}</el-col>
          <el-col :span="4" class="align-center"><el-switch v-model="opts.showAllLines" :disabled="opts.preview" /></el-col>
          <el-col :span="4">{{ $t('i.previewEffect') }}</el-col>
          <el-col :span="4" class="align-center"><el-switch v-model="opts.preview" /></el-col>
        </el-row>
      </el-row>
      <el-row>
        <el-col :span="6" class="align-left" :class="{'padding-left8': !isDesktop}">
          <el-button class="tap-btn" @click="close()">
            <i class="iconfont my-close" />
          </el-button>
        </el-col>
        <el-col :span="12" class="size16">
          {{ $t('i.toGridy') }}
        </el-col>
        <el-col :span="6" class="align-right" :class="{'padding-right8': !isDesktop}">
          <el-button class="tap-btn" @click="submit()">
            <i class="iconfont my-check" />
          </el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import GRIDY from '@/js/sdk/GridySDK'
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
import voxelViewer from '@/components/viewer/voxelViewer'
import blankImage from '@/assets/blank.png'
export default {
  components: {
    voxelViewer
  },
  mixins: [mixins],
  props: {},
  data() {
    let width, height
    if (this.state.platform.type === 'desktop') {
      width = 632
      height = 540
    } else {
      width = utils.width()
      height = utils.height() - 107
    }
    return {
      GRIDY: null,
      touchEndLock: false,
      width: width,
      height: height,
      params: utils.getHashParams(),
      blankImage: blankImage,
      inited: false,
      imgEl: null,
      voxelViewerEl: null,
      maxValue: 320,
      maxGridSize: 320,
      lineColor: `rgba(0, 255, 0, 0.5)`,
      centerLineColor: `rgba(255, 0, 0, 1)`,
      lineWidth: 20,
      x: 0,
      y: 0,
      obj: {
        cols: 0,
        rows: 0,
        data: []
      },
      work: null,
      opts: {
        d3: false,
        imageWidth: width,
        imageHeight: height,
        canvasWidth: width,
        canvasHeight: height,
        width: width,
        height: height,
        scale: 1,
        scaleSize: 8,
        gridSize: 80,
        offsetX: 0,
        offsetY: 0,
        fakeOffsetY: 0,
        startX: 0,
        startY: 0,
        optimizeColors: false,
        mergerFactor: 10,
        denoiseFactor: 2,
        preview: false,
        showAllLines: false
      }
    }
  },
  computed: {
    w() {
      return this.opts.imageWidth * this.opts.scale
    },
    h() {
      return this.opts.imageHeight * this.opts.scale
    },
    config() {
      return this.view.pixel2Gridy
    },
    data() {
      return this.config.data
    },
    skuId() {
      return (this.config.data.skuId || '') + ''
    },
    squareCanvasRatio() {
      // 扫码来的限制一个尺寸
      if (this.params.cols && this.params.rows && this.params.cols === this.params.rows && this.params.referrer === 'sn') {
        return [{ id: '1-1-' + this.params.cols, label: '1:1', tip: this.params.cols + 'x' + this.params.cols, icon: 'my-ratio-1-1 size12', cols: this.params.cols, rows: this.params.cols }]
      }
      return this.squareCanvasRatios[this.skuId || '10001']
    },
    title() {
      return this.config.title
    },
    gridyStyle() {
      return { 'width': this.width + 'px', 'height': this.height + 'px', 'overflow': 'hidden', 'background': 'var(--color-00)', 'text-align': 'center' }
    }
  },
  watch: {
    'opts.optimizeColors': {
      handler() {
        this.drawing()
      }
    },
    'opts.gridSize': {
      handler() {
        this.drawing()
      }
    },
    'opts.offsetX': {
      handler() {
        this.drawing()
      }
    },
    'opts.fakeOffsetY': {
      handler() {
        this.opts.offsetY = this.opts.fakeOffsetY * -1
        this.drawing()
      }
    },
    'opts.showAllLines': {
      handler() {
        this.drawing()
      }
    },
    'opts.preview': {
      handler() {
        this.opts.d3 = this.opts.preview
        this.drawing()
      }
    },
    'opts.scaleSize': {
      handler() {
        this.zoom(this.opts.scaleSize)
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.config.show) {
        this.loading(true)
        setTimeout(() => {
          this.init(() => {
            this.GRIDY = new GRIDY()
            this.initImage()
          })
        }, 50)
      } else {
        if (this.imgEl) {
          this.imgEl.onload = null
          this.imgEl.onerror = null
        }
        this.inited = false
      }
    }, 50)
  },
  methods: {
    // 滚轴缩放
    onWheel(event) {
      if (event.deltaY > 0) {
        this.zoom('-')
      } else {
        this.zoom('+')
      }
    },
    // 缩放网格画
    zoom(act) {
      if (act === '+') {
        if (this.opts.scaleSize === 1) {
          this.opts.scaleSize = 2
        } else if (this.opts.scaleSize < 8) {
          this.opts.scaleSize = this.opts.scaleSize + 2
        } else {
          this.opts.scaleSize = this.opts.scaleSize + 4
        }
        this.opts.scaleSize = Math.min(this.opts.scaleSize, 32)
      } else if (act === '-') {
        if (this.opts.scaleSize > 8) {
          this.opts.scaleSize = this.opts.scaleSize - 4
        } else {
          this.opts.scaleSize = this.opts.scaleSize - 2
        }
        this.opts.scaleSize = Math.max(this.opts.scaleSize, 2)
      } else {
        this.opts.scaleSize = act
      }
      this.opts.scale = this.opts.scaleSize / 8
      this.opts.canvasWidth = this.w
      this.opts.canvasHeight = this.h
      this.drawing()
    },
    calcImageSize(w, h, maxWidth, maxHeight) {
      let width, height
      if (w < maxWidth && h < maxHeight) {
        return { width: w, height: h }
      } else if (w / h > maxWidth / maxHeight) {
        width = maxWidth
        height = Math.round(width * h / w)
      } else {
        height = maxHeight
        width = Math.round(height * w / h)
      }
      return { width: width, height: height }
    },
    init(cb) {
      if (this.inited || !this.opts.d3) {
        cb && cb()
        return
      }
      this.imgEl = this.$refs.image
      this.voxelViewerEl = this.$refs.voxelViewer
      if (!this.imgEl || !this.voxelViewerEl) {
        setTimeout(() => {
          this.init(cb)
        }, 50)
        return
      }
      const load = () => {
        if (this.opts.d3) {
          this.voxelViewerEl.setDiyMod('originImage')
          this.voxelViewerEl.launch(() => {
            this.voxelViewerEl.draw(this.imgEl, () => {
              this.loading(false)
            })
          })
        } else {
          this.loading(false)
        }
      }
      this.imgEl.onload = load
      this.imgEl.onerror = load
      this.inited = true
      cb && cb()
    },
    initImage(cb) {
      if (this.data.file.size > 1024 * 1024) this.loading(true, this.$t('i.loading'))
      setTimeout(() => {
        const image = this.$refs.image
        image.src = this.data.img || window.URL.createObjectURL(this.data.file)
        if (image) {
          image.onload = () => {
            const size = this.calcImageSize(image.width, image.height, this.width, this.height)
            this.setParams(size.width, size.height)
            this.drawing(false, cb)
            setTimeout(() => {
              this.loading(false)
            }, 100)
          }
        }
      }, 100)
    },
    loading(show, tip) {
      this.config.loading = show
      this.emit('loading', [!!show, tip])
    },
    close() {
      this.closePop(this.config)
    },
    setParams(width, height) {
      this.opts.imageWidth = width
      this.opts.imageHeight = height
      this.opts.canvasWidth = width
      this.opts.canvasHeight = height
      this.opts.scale = 1
      this.opts.scaleSize = 8
      this.opts.gridSize = 80
      this.opts.offsetX = 0
      this.opts.offsetY = 0
      this.opts.fakeOffsetY = 0
      this.opts.startX = 0
      this.opts.startY = 0
    },
    drawing(createPixle = false, cb) {
      if (this.opts.preview) {
        this.loading(true, this.$t('i.rending'))
        setTimeout(() => {
          this.init(() => {
            this.GRIDY = new GRIDY()
            this.draw(createPixle, cb)
          })
        }, 50)
      } else {
        this.draw(createPixle, cb)
      }
    },
    draw(createPixle = false, cb) {
      const w = this.opts.imageWidth * 10
      const h = this.opts.imageHeight * 10
      const canvas = this.$refs.imageCanvas
      canvas.width = w
      canvas.height = h
      const ctx = canvas.getContext('2d')
      ctx.drawImage(this.$refs.image, 0, 0, this.$refs.image.width, this.$refs.image.height, 0, 0, w, h)
      const offsetX = this.opts.offsetX
      const offsetY = this.opts.offsetY
      let x = w / 2
      let y = 0
      let cols = Math.ceil(w / this.opts.gridSize)
      let rows = Math.ceil(h / this.opts.gridSize)
      const size = this.opts.gridSize
      const n = (this.opts.showAllLines || this.opts.preview || createPixle) ? cols : 2
      this.drawLine(ctx, x + offsetX, y, x + offsetX, h, this.lineColor, this.lineWidth)
      this.drawLine(ctx, x + offsetX, h / 2 + offsetY - size / 2, x + offsetX, h / 2 + offsetY + size / 2, this.centerLineColor, this.lineWidth)
      let startX = 0
      let startY = 0
      for (let i = 1; i < n; i++) {
        this.drawLine(ctx, x + i * size + offsetX, y, x + i * size + offsetX, h, this.lineColor, this.lineWidth)
        this.drawLine(ctx, x - i * size + offsetX, y, x - i * size + offsetX, h, this.lineColor, this.lineWidth)
        if (x - i * size + offsetX <= 0 && startX >= 0) {
          startX = x - i * size + offsetX
        }
      }
      x = 0
      y = h / 2
      const m = (this.opts.showAllLines || this.opts.preview || createPixle) ? rows : 2
      this.drawLine(ctx, x, y + offsetY, w, y + offsetY, this.lineColor, this.lineWidth)
      this.drawLine(ctx, w / 2 + offsetX - size / 2, y + offsetY, w / 2 + offsetX + size / 2, y + offsetY, this.centerLineColor, this.lineWidth)
      for (let i = 1; i < m; i++) {
        this.drawLine(ctx, x, y + i * size + offsetY, w, y + i * size + offsetY, this.lineColor, this.lineWidth)
        this.drawLine(ctx, x, y - i * size + offsetY, w, y - i * size + offsetY, this.lineColor, this.lineWidth)
        if (y - i * size + offsetY <= 0 && startY >= 0) {
          startY = y - i * size + offsetY
        }
      }
      // 修正 startX
      if (startX === -size) {
        startX = 0
      } else {
        if (startX + size / 2 < 0) {
          startX = startX + size
          cols--
        }
        // 修正 cols rows
        if (cols * size + startX - size / 2 > w) {
          cols--
        }
      }
      // 修正 startY
      if (startY === -size) {
        startY = 0
      } else {
        if (startY + size / 2 < 0) {
          startY = startY + size
          rows--
        }
        // 修正 cols rows
        if (rows * size + startY - size / 2 > h) {
          rows--
        }
      }
      this.obj = {
        cols: cols,
        rows: rows,
        data: []
      }
      this.opts.startX = startX
      this.opts.startY = startY
      if (this.opts.preview || createPixle) {
        // 获取对象数据
        for (let y = -1; y <= rows; y++) {
          for (let x = -1; x <= cols; x++) {
            const color = this.getPixelColorHex(canvas, x * size + startX + size / 2, y * size + startY + size / 2)
            // 绘制预览图
            this.drawGrid(ctx, x * size + startX, y * size + startY, size, size, color)
            if (x >= 0 && y >= 0 && x < cols && y < rows) this.obj.data.push(color)
          }
        }
        this.createWork(cb)
        if (this.opts.optimizeColors) {
          // 优化预览图
          this.redrawPixelPreview(ctx, startX, startY, cols, rows, size)
        } else {
          this.drawGridyImage()
        }
      } else {
        this.drawGridyImage()
      }
    },
    // 重绘像素预览
    redrawPixelPreview(ctx, startX, startY, cols, rows, size, cb) {
      const obj = this.GRIDY.getObj(0, 0, -1)
      for (let y = 0; y < rows; y++) {
        for (let x = 0; x < cols; x++) {
          const color = obj.data[y * cols + x]
          this.drawGrid(ctx, x * size + startX, y * size + startY, size, size, color)
        }
      }
      this.drawGridyImage(cb)
    },
    drawGridyImage(cb) {
      const canvas = this.$refs.gridyCanvas
      canvas.width = this.opts.canvasWidth
      canvas.height = this.opts.canvasHeight
      const ctx = canvas.getContext('2d')
      ctx.drawImage(this.$refs.imageCanvas, 0, 0, this.$refs.imageCanvas.width, this.$refs.imageCanvas.height, 0, 0, canvas.width, canvas.height)
      this.x = (this.width - this.opts.imageWidth * this.opts.scale) / 2
      this.y = (this.height - this.opts.imageHeight * this.opts.scale) / 2
      if (this.opts.d3) {
        this.voxelViewerEl.setWork(this.GRIDY.getFile())
        this.voxelViewerEl.launch(() => {
          this.voxelViewerEl.draw(canvas, () => {
            this.loading(false)
          })
        })
      } else {
        this.loading(false)
      }
      cb && cb()
    },
    // 创建作品
    createWork(cb) {
      // name = utils.getFileName(name)
      const name = ''
      let work = this.GRIDY.createFile({ userid: this.loginUserId, name: name, cols: this.obj.cols, rows: this.obj.rows })
      const scene = this.GRIDY.createScene({ userid: this.loginUserId, name: name })
      const obj = this.GRIDY.createObj({ userid: this.loginUserId, name: name, cols: this.obj.cols, rows: this.obj.rows, data: this.obj.data, fillShape: '' })
      scene.objs = [obj]
      work.canvas.scenes.push(scene)
      work.type = 0
      work.origin = 2
      this.GRIDY.setFile(work)
      if (this.opts.optimizeColors && (this.opts.mergerFactor || this.opts.denoiseFactor)) {
        this.GRIDY.calcObjColors(0, 0, -1)
        this.GRIDY.mergeObjColor(0, 0, -1, this.opts.mergerFactor)
        this.GRIDY.objDenoise(0, 0, -1, this.opts.denoiseFactor)
        work = this.GRIDY.getFile()
      }
      this.work = work
      cb && cb(work)
    },
    // 绘制直线
    drawLine(ctx, x1, y1, x2, y2, color, lineWidth) {
      color = color || '#000000'
      ctx.beginPath()
      ctx.moveTo(x1, y1)
      ctx.lineTo(x2, y2)
      ctx.lineWidth = lineWidth || 1
      ctx.strokeStyle = color
      ctx.closePath()
      ctx.stroke()
    },
    // 绘制网格
    drawGrid(ctx, x, y, width, height, color, type) {
      color = color || '#000000'
      type = type || 'fill'
      ctx[type + 'Style'] = color
      ctx[type + 'Rect'](x, y, width, height)
    },
    getPixelColorHex(canvas, x, y) {
      // 获取 Canvas 2D 上下文
      const ctx = canvas.getContext('2d')
      // 获取指定坐标的像素数据（RGBA 数组，长度4）
      const pixel = ctx.getImageData(x, y, 1, 1).data
      // 提取 RGB 值
      const r = pixel[0]
      const g = pixel[1]
      const b = pixel[2]
      // 将 RGB 转换为十六进制字符串
      const toHex = (value) => value.toString(16).padStart(2, '0')
      return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase()
    },
    calcRatioId(cols, rows) {
      for (const i in conf.canvasRatio) {
        if (conf.canvasRatio[i].cols / conf.canvasRatio[i].rows === cols / rows) {
          return conf.canvasRatio[i].id
        }
      }
      return ''
    },
    submit(openBrickyViewer = false) {
      if (!this.opts.preview) {
        this.alert(this.$t('i.previewFirst'))
        return
      }
      this.drawing(true, (work) => {
        const file = null
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const suiteMod = true
        // let suiteMod = false
        const cols = this.obj.cols - 1
        const rows = this.obj.rows - 1
        const width = cols * this.opts.gridSize
        const height = rows * this.opts.gridSize
        canvas.width = width
        canvas.height = height
        ctx.drawImage(this.$refs.imageCanvas, this.opts.startX + this.opts.gridSize, this.opts.startY + this.opts.gridSize, width, height, 0, 0, width, height)
        const img = canvas.toDataURL('image/png')
        let brickSizeId, ratioid
        if (cols > conf.state.maxCols || rows > conf.state.maxRows) {
          this.alert(this.$t('i.sizeExceeds', { size: conf.state.maxCols + 'x' + conf.state.maxRows }))
          this.drawing()
          return
        }
        if (this.data.diyType === 'common' || this.data.diyType === 'banner') {
          this.config.fn(img, this.data.diyType)
          setTimeout(this.close, 500)
        } else if (openBrickyViewer || this.data.diyType === 'brick') {
          const colorfyId = this.data.colorfyId || ''
          this.emit('openBrickyViewer', [{ data: { type: 'workViewer', isAi: this.data.isAi, skuId: this.skuId, suiteMod: suiteMod, colorfyId: colorfyId, ratioid: ratioid, brickSizeId: brickSizeId, width: width, height: height, img: img, file: file, work: work, diyType: this.data.diyType, imageUrl: this.data.imageUrl || '', name: this.data.file.name }, fn: this.config.fn }])
        } else {
          this.emit('openGridyViewer', [{ data: { type: 'workViewer', skuId: this.skuId, ratioid: ratioid, brickSizeId: brickSizeId, width: width, height: height, img: img, file: file, work: work, diyType: this.data.diyType, imageUrl: this.data.imageUrl || '', name: this.data.file.name }, fn: this.config.fn }])
        }
        // setTimeout(this.close, 1000)
      })
    }
  }
}
</script>
