<template>
  <el-dialog :title="title" :visible.sync="config.show" :fullscreen="platform.type !== 'desktop'" :top="marginTop" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" custom-class="pop_page header_40 selecter resourceSelecterDialog">
    <div slot="title" class="header">
      <div class="left">
        <div v-if="!simple && !batchDelete" class="title-btn back size16 padding-top4 padding-left4" @click="closePop(config)"><span class="color-99">{{ $t('i.refuse') }}</span></div>
        <el-button v-if="!simple && batchDelete" v-show="(platform.type === 'desktop' && canSelect) || platform.type !== 'desktop'" class="tap-btn iconfont size20" :class="platform.type !== 'desktop' ? 'my-back' : 'my-close'" @click="closePop(config)" />
        <el-button v-if="simple && leftBack && platform.type !== 'desktop'" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        <span v-if="!config.tabs || !config.tabs.length">{{ title }}</span>
        <el-button-group v-if="config.tabs && config.tabs.length">
          <el-button v-for="(tab, i) in config.tabs" :key="tab[0] + i" size="small" :type="config.tab === tab[0] ? 'plain' : 'primary'" @click="config.tab = tab[0]">{{ tab[1] }}</el-button>
        </el-button-group>
      </div>
      <div class="float-right align-right">
        <div v-if="!simple" v-show="(batchDelete && canSelect) || !batchDelete" class="title-btn size16" @click="selectItems()"><span :class="{'color-99': !canSelect, 'blue': canSelect}">
          {{ batchDelete ? $t('i.delete') : $t('i.sure') }}
        </span></div>
        <el-button v-if="simple && platform.type === 'desktop'" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        <el-button v-if="platform.type === 'desktop' && !simple && batchDelete && !canSelect" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
      </div>
    </div>
    <div :class="{'my-container': platform.type !== 'desktop'}">
      <div v-if="config.sifts && config.sifts.length" class="tabs">
        <el-tabs v-model="config.sift">
          <el-tab-pane v-for="(sift, idx) in config.sifts" :key="idx" :label="sift[1]" :name="sift[0]" />
        </el-tabs>
      </div>
      <resource-list
        v-show="config.show && config.type === 'selecter'"
        ref="resource-selecter"
        mod="selecter"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import resourceList from '@/components/web/resourceList'
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
export default {
  components: {
    resourceList
  },
  mixins: [mixins],
  data() {
    return {
      platform: utils.platform(),
      canSelect: false,
      selectMod: false,
      selectIds: {}
    }
  },
  computed: {
    config() {
      return this.view.resourceSelecter
    },
    batchDelete() {
      return !!this.config.batchDelete
    },
    showActions() {
      return !!this.config.showActions
    },
    simple() {
      return !!this.config.simple
    },
    leftBack() {
      return !!this.config.leftBack
    },
    table() {
      return this.config.table
    },
    act() {
      return this.config.act
    },
    keyfield() {
      return this.config.keyfield || this.table + 'id'
    },
    resourceNames() {
      return this.view.resourceNames()
    },
    title() {
      return this.config.title || (this.resourceNames[this.table] ? (this.$t('i.choose') + ' ' + this.resourceNames[this.table]) : this.$t('i.pleaseSel'))
    }
  },
  watch: {
    // 'view.resourceSelecter.show': {
    //   handler() {
    //     setTimeout(() => {
    //       this.init()
    //       this.initView()
    //     }, 10)
    //   }
    // },
    'view.resourceSelecter.type': {
      handler() {
        this.init()
      }
    },
    'view.resourceSelecter.renew': {
      handler() {
        this.renewView()
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    handle(act, data) {
      if (act === 'setSelectData') {
        this.selectMod = data[0]
        this.selectIds = data[1]
        this.canSelect = this.selectMod && Object.keys(this.selectIds).length > 0
        this.update()
        if (data[2] === 'use') this.selectItems(data[3] || '')
      } else if (act === 'closePop') {
        this.closePop(this.config)
      } else if (act === 'buyNow') {
        this.buyNow(data)
      } else if (act === 'cancelSelect') {
        this.cancelSelect()
      } else {
        this.emit(act, data)
      }
    },
    init() {
      this.canSelect = false
      this.selectMod = false
      this.selectIds = {}
    },
    renewView() {
      this.$refs['resource-' + this.config.type].renewView()
    },
    initView() {
      this.$refs['resource-' + this.config.type].init()
    },
    cancelSelect() {
      this.$refs['resource-' + this.config.type].cancelSelect()
      this.init()
    },
    getSelectItems() {
      return this.$refs['resource-' + this.config.type].getSelectItems()
    },
    selectItems(act = '') {
      if (this.canSelect) {
        const items = this.getSelectItems()
        this.config.fn && this.config.fn(items, act)
        if (!act) {
          if (this.batchDelete) {
            setTimeout(() => {
              this.renewView()
            }, 500)
          } else {
            this.closePop(this.config)
          }
        }
      }
      this.cancelSelect()
    },
    // 购买商品
    buyNow(goods) {
      this.config.fn && this.config.fn(goods)
      this.closePop(this.config)
      this.cancelSelect()
    },
    addResource() {
      this.openResourceMng({ 'table': this.table, 'act': 'add' })
    }
  }
}
</script>
