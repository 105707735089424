<template>
  <el-dialog
    ref="taskDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll taskDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        {{ $t('i.taskCenter') }}
      </div>
      <div class="float-right align-right">
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <div class="container-header">
        <div ref="header-card" class="padding8">
          <div class="my-row round8" style="height: 80px;">
            <div class="float-left padding-top4">
              <span v-if="!(user.avatar && !config.loading)" class="el-avatar el-avatar--circle noavatar large">
                <i class="iconfont my-man" />
              </span>
              <el-avatar v-if="user.avatar && !config.loading" :size="48" :src="getAvatar()" />
            </div>
            <div class="info padding-top4">
              <span class="name margin-left4">{{ user.nickname || view.anonymity }}</span>
              <span v-if="false" class="addition opacity-bg">Lv.3</span>
              <br>
              <el-button class="txt-btn white">{{ (source[10008] && source[10008].quantity) ? source[10008].quantity : 0 }} <span class="white">{{ $t('i.growth') }}</span></el-button>
              <el-button class="txt-btn white">{{ (source[10006] && source[10006].quantity) ? source[10006].quantity : 0 }} <span class="white">{{ $t('i.crystal') }}</span></el-button>
              <el-button class="txt-btn white">{{ (source[10007] && source[10007].quantity) ? source[10007].quantity : 0 }} <span class="white">{{ $t('i.diamond') }}</span></el-button>
            </div>
          </div>
        </div>
        <div class="tabs user-tabs margin-top4" :class="{'headless': !config.showHead}">
          <el-tabs v-model="config.type">
            <el-tab-pane v-for="(type, idx) in types" :key="idx" :label="type[1]" :name="type[0]" />
          </el-tabs>
        </div>
      </div>
      <task-list
        v-show="config.show && config.type === 'mine'"
        ref="task-mine"
        mod="mine"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <task-list
        v-show="config.show && config.type === 'new'"
        ref="task-new"
        mod="new"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <task-list
        v-show="config.show && config.type === 'finish'"
        ref="task-finish"
        mod="finish"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import taskList from '@/components/web/taskList'
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import service from '@/js/service'
export default {
  components: {
    taskList
  },
  mixins: [mixins],
  data() {
    return {
      swipeLock: false,
      scrollTop: 0,
      subtractHeight: 125,
      user: {}
    }
  },
  computed: {
    config() {
      return this.view.task
    },
    types() {
      return this.config.types()
    },
    source() {
      return this.state.source
    }
  },
  watch: {
    // 'view.task.show': {
    //   handler() {
    //     if (this.view.task.show) {
    //       this.view.task.loading = false
    //       if (this.loginUserId !== this.user.userid) {
    //         this.getUser(true)
    //       }
    //       this.update()
    //     }
    //   }
    // },
    'view.task.renew': {
      handler() {
        this.getSource()
      }
    },
    'view.task.init': {
      handler(init) {
        if (init && init.mine) {
          this.callSub('task-mine', 'init', [true])
        } else if (init && init.new) {
          this.callSub('task-new', 'init', [true])
        } else if (init && init.finish) {
          this.callSub('task-finish', 'init', [true])
        } else {
          this.initView()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.view.task.loading = false
    this.getUser()
    this.autoAcceptTask()
  },
  methods: {
    // 自动接受任务
    autoAcceptTask() {
      this.emit('autoAcceptTask', [(dt, type) => {
        if (type === 'success' && dt && dt.data && dt.data.count) this.renewView()
        // TODO: 显示未完成的任务数
        this.update()
      }])
    },
    handle(act, data) {
      if (act === 'getSource') {
        this.getSource()
      } else {
        this.emit(act, data)
      }
    },
    update() {
      this.$forceUpdate()
      setTimeout(() => {
        this.subtractHeight = this.getElementHeight('header-card') + 40
      }, 10)
    },
    getAvatar() {
      const ver = Math.round(new Date(this.user.avatar_at) / 1000)
      if (this.user.avatar) return service.getCosUrl(this.user.avatar, 'avatar', ver) || ''
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    getElementHeight(refid) {
      return this.$refs[refid] ? parseInt(window.getComputedStyle(this.$refs[refid]).height) : 0
    },
    renewView() {
      this.callSub('task-mine', 'renewView')
    },
    initView() {
      this.callSub('task-mine', 'init', [true])
      this.callSub('task-new', 'init', [true])
    },
    callSub(ref, fn, params = []) {
      const el = this.$refs[ref]
      if (el && el[fn]) {
        el[fn](...params)
      } else {
        setTimeout(() => {
          this.callSub(ref, fn, params)
        }, 100)
      }
    },
    getUser(renew) {
      if (this.view.task.loading) return
      this.view.task.loading = true
      this.emit('getUser', [this.loginUserId, (user) => {
        if (user) this.user = user
        this.view.task.loading = false
        this.update()
        this.getSource()
      }])
      if (renew) this.renewView()
    },
    // 获取成长值、晶石、晶钻
    getSource() {
      this.emit('getSource', [(dt, type) => {
        this.update()
      }])
    }
  }
}
</script>
