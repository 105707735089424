<template>
  <el-dialog
    ref="walletDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll walletDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        {{ $t('i.myMoney') }}
      </div>
      <div class="float-right align-right">
        <el-button class="tap-btn iconfont my-bankcard" @click="emit('openResource', ['bankcard'])" />
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <div class="container-header">
        <div ref="header-card" class="padding4">
          <div class="my-row padding-left24 round8">
            <el-row>
              <el-col :span="12">
                {{ $t('i.money') }}<br>
                <div class="padding-top8 size16">{{ formateMoney(wallet.available_amount ? (wallet.available_amount + wallet.freeze_amount) * 100 : 0) }}</div>
              </el-col>
              <el-col :span="12">
                {{ $t('i.freeze') }}<br>
                <div class="padding-top8 size16">{{ formateMoney(wallet.freeze_amount ? wallet.freeze_amount * 100 : 0) }}</div>
              </el-col>
            </el-row>
            <el-row class="padding-top12">
              <el-col :span="12">
                {{ $t('i.canWithDrawal') }}<br>
                <div class="padding-top8 size16">{{ formateMoney((wallet.available_amount ? wallet.available_amount * 100 : 0)) }}</div>
              </el-col>
              <el-col :span="12">
                <br>
                <div class="padding-top8" @click="openPopPage({ 'type': 'setPayPassword', 'title': $t('i.setTradePWD'), 'data': {} })">
                  <i class="iconfont my-pay_pwd size14" /> {{ $t('i.setTradePWD') }}
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="tabs user-tabs margin-top8" :class="{'headless': !config.showHead}">
          <el-tabs v-model="config.type">
            <el-tab-pane v-for="(type, idx) in types" :key="idx" :label="type[1]" :name="type[0]" />
          </el-tabs>
        </div>
      </div>
      <flow-list
        v-show="config.show && config.type === 'flow'"
        ref="flow-list"
        mod="flow"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <flow-list
        v-show="config.show && config.type === 'cash'"
        ref="cash-list"
        mod="cash"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
    </div>
    <div slot="footer" class="footer padding-top8" style="position:absolute;">
      <el-button type="primary" class="btn-cancel" style="max-width:200px;" @click="showWithdraw">
        {{ $t('i.withDrawal') }}
      </el-button>
      <el-button type="light" class="btn-ok" style="max-width:200px;" @click="showActionSheet('charge')">
        {{ $t('i.recharg') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import flowList from '@/components/web/flowList'
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import service from '@/js/service'
export default {
  components: {
    flowList
  },
  mixins: [mixins],
  data() {
    return {
      swipeLock: false,
      scrollTop: 0,
      subtractHeight: 240,
      user: {}
    }
  },
  computed: {
    config() {
      return this.view.wallet
    },
    types() {
      return this.config.types()
    },
    wallet() {
      return this.state.wallet
    }
  },
  // watch: {
  //   'view.wallet.show': {
  //     handler() {
  //       if (this.view.wallet.show) {
  //         this.view.wallet.loading = false
  //         if (this.loginUserId !== this.user.userid) {
  //           this.getWallet(true)
  //         }
  //         this.update()
  //       }
  //     }
  //   },
  //   'view.wallet.renew': {
  //     handler() {
  //       this.getWallet()
  //     }
  //   },
  //   'view.wallet.init': {
  //     handler(init) {
  //       if (init && init.flow) {
  //         this.callSub('flow-list', 'init', [true])
  //       } else if (init && init.cash) {
  //         this.callSub('cash-list', 'init', [true])
  //       } else {
  //         this.initView()
  //       }
  //     },
  //     deep: true
  //   }
  // },
  mounted() {
    this.view.wallet.loading = false
    if (!this.loginUserId) {
      this.emit('login')
      return
    }
    this.getWallet()
  },
  methods: {
    handle(act, data) {
      this.emit(act, data)
    },
    update() {
      this.$forceUpdate()
      setTimeout(() => {
        this.subtractHeight = this.getElementHeight('header-card') + 90
      }, 10)
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    formateMoney: utils.formateMoney,
    getElementHeight(refid) {
      return this.$refs[refid] ? parseInt(window.getComputedStyle(this.$refs[refid]).height) : 0
    },
    renewView() {
      this.callSub(this.config.type + '-list', 'renewView')
      if (this.config.type === 'cash') {
        this.callSub('flow-list', 'init', [true])
      } else {
        this.callSub('cash-list', 'init', [true])
      }
    },
    initView() {
      this.callSub('flow-list', 'init', [true])
      this.callSub('cash-list', 'init', [true])
    },
    callSub(ref, fn, params = []) {
      const el = this.$refs[ref]
      if (el && el[fn]) {
        el[fn](...params)
      } else {
        setTimeout(() => {
          this.callSub(ref, fn, params)
        }, 100)
      }
    },
    // 获取钱包晶币
    getWallet(renew) {
      this.emit('getWallet', [(dt, type) => {
        if (renew) this.renewView()
        this.update()
      }])
    },
    showWithdraw() {
      const cb = () => {
        this.getWallet(true)
        this.alert(this.$t('i.withDrawalTips'))
        this.emit('traceEvent', ['requestWithdraw'])
      }
      this.view.pop.title = this.$t('i.withDrawal')
      this.view.pop.tip = ''
      this.view.pop.type = 'withdraw'
      this.view.pop.data = this.publish
      this.view.pop.show = true
      this.view.pop.fn = cb
      this.emit('traceEvent', ['showWithdraw'])
    },
    showPayment(order, cb) {
      this.view.pop.title = this.$t('i.recharg')
      this.view.pop.tip = ''
      this.view.pop.type = 'payment_charge'
      this.view.pop.data = order || {}
      this.view.pop.show = true
      this.view.pop.fn = cb
    },
    charge(amount) {
      const pay_cb = (success) => {
        if (success) {
          this.getWallet(true)
          this.alert(this.$t('i.rechargeOk'))
          this.emit('traceEvent', ['finishCharge', '', { amount: amount }])
        }
      }
      this.emit('traceEvent', ['createChargeOrder', '', { amount: amount }])
      const data = { amount: amount, pay_type: 2 }
      service.post('charge_order', data, (dt, type) => {
        if (type === 'success') {
          if (dt && dt.data && dt.data.id) this.showPayment({ charge_orderid: dt.data.id }, pay_cb)
        } else {
          this.message(dt, type)
        }
      }, true)
    },
    showActionSheet(type, mustLogin) {
      this.emit('traceEvent', ['clickChargeButton'])
      if (mustLogin && !this.loginStatus) return this.message('', 'login')
      const actions = {
        show: true,
        title: '',
        btns: []
      }
      if (type === 'charge') {
        actions.title = this.$t('i.recharg')
        actions.btns = [
          {
            title: '50' + this.$t('i.money'), cb: () => {
              this.charge(5000)
            }
          },
          {
            title: '100' + this.$t('i.money'), cb: () => {
              this.charge(10000)
            }
          },
          {
            title: '300' + this.$t('i.money'), cb: () => {
              this.charge(30000)
            }
          },
          {
            title: '500' + this.$t('i.money'), cb: () => {
              this.charge(50000)
            }
          },
          {
            title: '1000' + this.$t('i.money'), cb: () => {
              this.charge(100000)
            }
          },
          {
            title: '3000' + this.$t('i.money'), cb: () => {
              this.charge(300000)
            }
          },
          {
            title: '5000' + this.$t('i.money'), cb: () => {
              this.charge(500000)
            }
          }
        ]
        if (this.loginUserId && this.loginUserId < 10000) {
          actions.btns.unshift({
            title: '0.1' + this.$t('i.money'), cb: () => {
              this.charge(1)
            }
          })
        }
      }
      this.view.actionSheet = actions
    }
  }
}
</script>
