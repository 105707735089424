<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'users-' + mod + '-container'" :style="listStyle" class="my-list" :top-load-method="(loaded) => topPull ? loadData(loaded, showUp ? 'up' : 'renew') : {}" :bottom-load-method="(loaded) => bottomPull ? loadData(loaded, 'down') : {}" @infinite-scroll="getData('down')">
      <div class="flow-container">
        <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
          <div v-for="(item, i) in column.items" :key="i" class="item">
            <div class="card" :style="{ 'width': cardWidth + 'px', 'height': (item._height - 5) + 'px' }">
              <div class="user">
                <div class="left" @click="emit('goto', ['user', { userid: item.userid } ])">
                  <span v-if="!item.avatar" class="el-avatar el-avatar--circle noavatar large">
                    <i class="iconfont my-man" />
                  </span>
                  <el-avatar v-if="item.avatar" :size="42" :src="getAvatar(item)" />
                </div>
                <div class="center" :style="{'line-height': mod ==='guest' ? '20px' : '', 'padding-top': mod ==='guest' ? '4px' : ''}" @click="emit('goto', ['user', { userid: item.userid } ])">
                  {{ item.nickname || view.anonymity }} <span v-if="mod === 'invite' && item.verify_status === 2" class="tag">{{ $t('i.verifyed') }}</span>
                  <span v-if="mod ==='guest' && data.guest[item.userid] && data.guest[item.userid].count" class="color-66"><br>{{ (data.guest[item.userid].update_at ? formateTime(data.guest[item.userid].update_at) + ', ' : '') + $t('i.visitCount', { count: formateNums(data.guest[item.userid].count) }) }}</span>
                </div>
                <div v-if="(item.userid !== loginUserId)" class="float-right">
                  <el-button v-if="(followState[item.userid] === 0)" class="tap-btn on" @click="unfollow(item.userid)">{{ $t('i.hadFollow') }}</el-button>
                  <el-button v-if="(followState[item.userid] === 1)" class="tap-btn on" @click="unfollow(item.userid)">{{ $t('i.followEach') }}</el-button>
                  <el-button v-if="(followState[item.userid] !== 0 && followState[item.userid] !== 1)" class="tap-btn" @click="follow(item.userid)">{{ $t('i.follow') }}</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div v-if="flowHeight > height && data.noDownPage" class="tip">{{ $t('i.showAll') }}</div>
      <el-empty v-if="(!itemNums && !loading[mod])" :image="emptyImage" :description="mod === 'invite' ? $t('i.inviteTips') : $t('i.none')" />
    </pull-to>
    <div v-if="data.scrollTop > 200" class="go-top">
      <el-button class="tap-btn" @click="backTop()">
        <i class="iconfont my-top" />
      </el-button>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/users'
export default {
  mixins: [mixins]
}
</script>
