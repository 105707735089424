<template>
  <el-dialog
    ref="chatDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll chatDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle margin-left8">
        {{ config.nickname || $t('i.chat') }}
      </div>
      <div class="float-right">
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <chat-list
        v-show="config.show"
        ref="chat"
        mod="chat"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import chatList from '@/components/web/chatList'
import { mixins } from '@/mixins/common'
export default {
  components: {
    chatList
  },
  mixins: [mixins],
  data() {
    return {
      renewSto: null
    }
  },
  computed: {
    config() {
      return this.view.chat
    }
  },
  mounted() {
    if (this.view.chat.show) this.renew()
  },
  methods: {
    handle(act, data) {
      if (act !== 'setDeleteData') {
        this.emit(act, data)
      }
    },
    renew() {
      if (this.renewSto) {
        clearTimeout(this.renewSto)
        this.renewSto = null
      }
      if (this.$refs['chat']) this.$refs['chat'].renewView()
      this.renewSto = setTimeout(this.renew, 1000 * 30)
    }
  }
}
</script>
