<template>
  <el-dialog
    ref="gridyViewerDialog"
    :title="title"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page large headless gridyviewer"
  >
    <div class="header" style="background:none;" :class="{'padding-top12 padding-left4 padding-right8': isDesktop, 'padding-top8': !isDesktop}">
      <div class="left">
        <el-button v-if="!isViewer" class="tap-btn" @click="close()">
          <i class="iconfont my-back size24" />
        </el-button>
        &nbsp;
      </div>
      <div class="right">
        <el-button v-if="!isViewer" class="tap-btn" @click="submit()">
          <i class="iconfont my-check size24" />
        </el-button>
        <el-button v-if="isViewer" class="tap-btn" @click="close()">
          <i class="iconfont my-close size24" />
        </el-button>
      </div>
    </div>
    <div
      v-loading="false"
      :element-loading-text="$t('i.rending')"
      shadow="never"
      element-loading-background="rgba(0, 0, 0, 0.6)"
      class="previewArea"
      :style="previewAreaStyle"
      @wheel="(e) => opts.d3 ? {} : onWheel(e)"
      @dblclick="(e) => (isDesktop && !opts.d3) ? doubleTap(e) : {}"
    >
      <vue-draggable-resizable
        v-show="!opts.d3"
        :x="opts.x"
        :y="opts.y"
        :z="0"
        :w="1"
        :h="1"
        :parent="false"
        :draggable="isDesktop"
        :resizable="false"
        :disable-user-select="true"
        @dragstop="onDragStop"
      >
        <img
          id="image"
          ref="image"
          v-finger:multipoint-start="multipointStart"
          v-finger:multipoint-end="multipointEnd"
          v-finger:pinch="pinch"
          v-finger:rotate="rotate"
          v-finger:press-move="pressMove"
          v-finger:double-tap="doubleTap"
          :src="blankImage"
        >
      </vue-draggable-resizable>
      <voxel-viewer
        v-if="opts.d3 && config.show"
        ref="voxelViewer"
        :state="state"
        :width="width"
        :height="height"
        :fixpos="0"
        @receive="(event) => emit('handle', event)"
      />
    </div>
    <div v-if="!pixleOnly" style="position: absolute;text-align: center;z-index: 99;" :style="{'top': isDesktop ? '15px' : '4px', height: '50px', 'left': isDesktop ? '90px' : '60px', width: 'calc(100% - ' + (isDesktop ? 180 : 120) + 'px)'}">
      <el-row class="margin-bottom4 margin-top4" style="overflow-x: auto;">
        <el-button-group>
          <el-button v-if="data.type !== 'workViewer'" size="small" class="opacity-bg" style="height:32px;" :type="opts.workTypeid === 'originImage' ? 'light' : ''" @click="setWorkType('originImage')">{{ $t('i.originImage') }}</el-button>
          <el-button v-for="workType in workTypes" :key="workType.id" size="small" class="opacity-bg" style="height:32px;" :type="opts.workTypeid === workType.id ? 'light' : ''" @click="setWorkType(workType.id)">{{ workType.label }}</el-button>
        </el-button-group>
      </el-row>
    </div>
    <div style="position: absolute;text-align: center;" :style="{'top': '60px', height: '50px', 'padding-left': '4px', width: ' calc(100% - ' + (isDesktop ? 18 : 8) + 'px)'}">
      <!-- <el-button v-if="canPlay && !playing && !opts.diy" class="box32 padding4 opacity-bg margin-left4" @click="() => start()">
        <i class="iconfont my-play size20" :title="$t('i.play')" />
      </el-button>
      <el-button v-if="canPlay && playing && !opts.diy" class="box32 padding4 opacity-bg margin-left4" type="light" @click="() => stop()">
        <i class="iconfont my-stop size20" :title="$t('i.stop')" />
      </el-button> -->
      <el-button v-if="canPlay && !playing" :disabled="playing" class="box32 opacity-bg padding4 margin-left4" @click="() => pre()">
        <i class="iconfont my-pre size20" :title="$t('i.pre')" />
      </el-button>
      <el-button v-if="canPlay && !playing" :disabled="playing" class="box32 opacity-bg padding4 margin-left4" @click="() => next()">
        <i class="iconfont my-next size20" :title="$t('i.next')" />
      </el-button>
      <!-- <div v-if="opts.diy || opts.paletteId === 'brickfy'" class="box48 padding4 float-right" :class="{'margin-right12': isDesktop}" @click="buyIt">
        <i class="iconfont my-cart yellow size32" :title="$t('i.buyNow')" />
      </div> -->
      <!-- <el-popover v-if="opts.diy && opts.diyType === 'tshirt'" v-model="diyColorPopover" :visible-arrow="false" width="32" placement="top" popper-class="popover-toolbar padding-left2" trigger="click">
        <el-button v-for="(color, i) in diyColors" v-show="opts.diyColor !== color" :key="i" class="box32 padding4 opacity-bg" @click="changeDiyColor(color)"><i class="iconfont my-square-full size20" :style="{'color': color }" /></el-button>
        <el-button slot="reference" class="box32 padding4 float-right margin-top8 opacity-bg">
          <div class="iconfont my-square-full size20" title="选择颜色" :style="{'color': opts.diyColor }" />
        </el-button>
      </el-popover>
      <el-popover v-if="opts.diy && opts.diyType === 'tshirt'" v-model="diySizePopover" :visible-arrow="false" width="32" placement="top" popper-class="popover-toolbar padding-left2" trigger="click">
        <el-button v-for="(size, i) in diySizes" v-show="opts.diySize !== size" :key="i" class="box32 padding4 opacity-bg" @click="changeDiySize(size)"><div class="size12" :title="size">{{ size }}</div></el-button>
        <el-button slot="reference" class="box32 padding4 float-right margin-right4 margin-top8 opacity-bg">
          <div class="size12" title="选择尺寸">{{ opts.diySize }}</div>
        </el-button>
      </el-popover>
      <el-popover v-if="opts.diy && opts.diyType === 'tshirt'" v-model="diySidePopover" :visible-arrow="false" width="70" placement="top" popper-class="popover-toolbar" trigger="click">
        <el-button v-for="(side, i) in diySides" v-show="opts.diySide !== i" :key="i" class="padding4 opacity-bg" style="width: 70px;height:32px; margin-left: 0;" @click="() => changeDiySide(i)"><div class="size12" :title="side">{{ side }}</div></el-button>
        <el-button slot="reference" class="padding4 float-right margin-right4 margin-top8 opacity-bg" style="width: 70px;height:32px;">
          <div class="size12" title="选择印刷方式">{{ diySides[opts.diySide] }}</div>
        </el-button>
      </el-popover> -->
    </div>
    <div style="position: absolute;text-align: center;z-index: 96;" class="mid-button" :style="{'bottom': isDesktop ? '75px' : '63px', height: '34px', 'width': '156px', left: 'calc((100% - 156px) / 2)'}">
      <el-button class="opacity-bg" style="padding: 8px 16px;margin-bottom: 0;" @click="openBrickyViewer">
        <i class="iconfont my-cart margin-right8" :class="{'green': opts.workTypeid !== 2, 'blue': opts.workTypeid === 2}" /> <span :class="{'green': opts.workTypeid !== 2, 'blue': opts.workTypeid === 2}">{{ $t('i.createPuzzle') }}</span>
      </el-button>
    </div>
    <div slot="footer" :style="{'width': (isDesktop ? width : width - 15) + 'px'}">
      <el-row v-if="((pixleOnly && opts.paletteId !== 'originImage') || !pixleOnly)" class="margin-bottom4 btns-bar" :class="(data.work && data.work.type === 0) ? 'col-9' : ''">
        <el-col :span="span">
          <el-dropdown trigger="click" placement="top-start" @command="setBricksize">
            <el-button class="iconfont my-size size20 padding8 opacity-bg" :disabled="opts.paletteId === 'originImage' || showOriginImage" :title="$t('i.size')" />
            <el-dropdown-menu slot="dropdown" class="my-dropdown">
              <el-dropdown-item v-for="(sizeOpt, i) in brickSizeOpts" v-show="showSizeOpt(sizeOpt.cols, sizeOpt.rows)" :key="i" :title="sizeOpt.cols + 'x' + sizeOpt.rows" :command="sizeOpt.cols + '-' + sizeOpt.rows"> {{ sizeOpt.cols + 'x' + sizeOpt.rows }} <span class="float-right align-right" :class="opts.brickSizeId === sizeOpt.cols + '-' + sizeOpt.rows ? 'iconfont my-check' : ''" /></el-dropdown-item>
              <el-dropdown-item v-for="(sizeOpt, i) in additionalBrickSizeOpts" :key="'a_' + i" :title="sizeOpt.cols + 'x' + sizeOpt.rows" :command="sizeOpt.cols + '-' + sizeOpt.rows"> {{ sizeOpt.cols + 'x' + sizeOpt.rows }} <span class="float-right align-right" :class="opts.brickSizeId === sizeOpt.cols + '-' + sizeOpt.rows ? 'iconfont my-check' : ''" /></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="span">
          <el-dropdown trigger="click" placement="top-start" @command="setBrickShape">
            <el-button class="iconfont tap-btn opacity-bg margin-bottom12" :class="opts.roundTile ? 'my-circle-shape' : 'my-square-shape'" :title="$t('i.shape')" />
            <el-dropdown-menu slot="dropdown" class="my-dropdown">
              <el-dropdown-item :title="$t('i.round')" :command="true">{{ $t('i.round') }} <span class="float-right align-right" :class="opts.roundTile ? 'iconfont my-check' : ''" /></el-dropdown-item>
              <el-dropdown-item :title="$t('i.square')" :command="false">{{ $t('i.square') }} <span class="float-right align-right" :class="!opts.roundTile ? 'iconfont my-check' : ''" /></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col v-if="data.work && data.work.type === 0" :span="span">
          <el-dropdown trigger="click" placement="top-start" @command="(e) => setPalette(e)">
            <el-button class="iconfont my-palette size20 padding8 opacity-bg" :disabled="opts.paletteId === 'originImage' || showOriginImage" :title="$t('i.toning')" />
            <el-dropdown-menu slot="dropdown" class="my-dropdown">
              <el-dropdown-item command=""> {{ $t('i.primaryColors') }} <span class="float-right align-right" :class="opts.paletteId === '' ? 'iconfont my-check' : ''" /></el-dropdown-item>
              <el-dropdown-item v-if="data.type !== 'workViewer'" command="64"> {{ $t('i.simplifiedColors') }}(64) <span class="float-right align-right" :class="opts.paletteId === '64' ? 'iconfont my-check' : ''" /></el-dropdown-item>
              <el-dropdown-item v-if="data.type !== 'workViewer'" command="32"> {{ $t('i.simplifiedColors') }}(32) <span class="float-right align-right" :class="opts.paletteId === '32' ? 'iconfont my-check' : ''" /></el-dropdown-item>
              <el-dropdown-item v-if="data.type !== 'workViewer'" command="16"> {{ $t('i.simplifiedColors') }}(16) <span class="float-right align-right" :class="opts.paletteId === '16' ? 'iconfont my-check' : ''" /></el-dropdown-item>
              <el-dropdown-item v-for="item in palette.opts" :key="item.value" :command="item.value">
                {{ item.label }}
                <span class="float-right align-right" :class="opts.paletteId === item.value ? 'iconfont my-check' : ''" />
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="span">
          <el-button class="iconfont my-colorfilter size20 padding8 opacity-bg" :disabled="opts.paletteId === 'originImage' || showOriginImage" :title="$t('i.hue')" @click="openColorFilter()" />
        </el-col>
        <el-col :span="span">
          <el-button class="iconfont my-denoise size20 padding8 opacity-bg" :disabled="opts.paletteId === 'originImage' || showOriginImage" :title="$t('i.noiseReduction')" @click="openDenoise()" />
        </el-col>
        <el-col :span="span">
          <el-popover v-model="mngColorShow" placement="top-start" offset="4" popper-class="padding4" width="260" trigger="click">
            <div class="my-title-bar padding-left8 padding-bottom4" style="border-bottom: 1px solid var(--color-22);">
              {{ $t('i.colors') }} <span v-if="colorsCount">({{ colorsCount > 128 ? '128+' : colorsCount }})</span>
              <el-button :type="opts.colorOrder === 'desc' ? 'light' : ''" class="iconfont my-sort-desc padding4 float-right" :title="$t('i.reverse')" @click="toggleColorOrder" />
              <el-button class="iconfont my-denoise padding4 margin-right4 float-right" :disabled="opts.paletteId === 'originImage' || showOriginImage" :title="$t('i.noiseReduction')" @click="openDenoise()" />
            </div>
            <div class="my-colors">
              <div :style="{ width: '100%', height: '206px', 'overflow-x': 'hidden', 'overflow-y': 'auto'}">
                <el-row v-for="(color, idx) in colors" :key="'in_' + idx">
                  <el-col :span="3">
                    <div v-if="data.work && data.work.type !== 0" class="box24 border-33 round align-center" :title="'#' + color[2]" :style="{ background: color[0], 'line-height': '24px' }" />
                    <div v-if="data.work && data.work.type === 0" class="preview" :title="color[0].toUpperCase()" :style="{ background: color[0] }" />
                  </el-col>
                  <!-- <el-col v-if="data.work && data.work.type === 0" :span="7" :title="color[0].toUpperCase()">
                    <span v-if="editColor.idx !== idx" @click="toggleColorEditer(idx, true)">{{ color[0].toUpperCase() }}</span>
                    <el-input v-if="editColor.idx === idx" v-model.trim="editColor.newColor" type="input" maxlength="7" onkeyup="this.value = this.value.replace(/[^#0-9a-fA-F.]/g,'')" @blur="changeColor(idx)" />
                  </el-col> -->
                  <el-col v-if="data.work && data.work.type === 0" :span="7" :title="'#' + cids[color[0].toUpperCase()] || color[0].replace('#', '')">
                    #{{ cids[color[0].toUpperCase()] || color[0].replace('#', '') }}
                  </el-col>
                  <el-col v-if="data.work && data.work.type !== 0" :span="7" class="padding-top8" :title="'#' + color[2]">#{{ color[2] }}</el-col>
                  <el-col :span="6" :title="$t('i.colorCount')">x{{ color[1] }}</el-col>
                  <el-col :span="4" :title="$t('i.changeColor')" class="align-right">
                    <el-button class="iconfont my-change padding4 margin0" @click="(event) => toggleColorPicker(event, 'viewer-change-color', [color[0].toUpperCase()])" />
                  </el-col>
                  <el-col :span="4" :title="$t('i.deleteColor')" class="align-right">
                    <el-button :disabled="colorsCount <= 2" class="iconfont my-delete padding4 margin0" :title="$t('i.delete')" @click="deleteObjColor(color[0])" />
                  </el-col>
                </el-row>
              </div>
            </div>
            <el-button slot="reference" class="iconfont my-color-drop size20 padding8 opacity-bg" :disabled="opts.paletteId === 'originImage' || showOriginImage" :title="$t('i.manageColors')" />
          </el-popover>
        </el-col>
        <el-col :span="span">
          <el-popconfirm :title="$t('i.restoreAll')" cancel-button-type="Primary" placement="top" @confirm="restore()">
            <el-button slot="reference" class="iconfont my-restore size20 padding8 opacity-bg" :disabled="opts.paletteId === 'originImage' || showOriginImage" :title="$t('i.restore')" />
          </el-popconfirm>
        </el-col>
        <el-col :span="span">
          <el-popconfirm :title="(data.preview ? ($t('i.sure') + $t('i.toEditor')) : '') + $t('i.ifAdvancedMode')" cancel-button-type="Primary" placement="top" @confirm="submit()">
            <el-button slot="reference" class="iconfont my-design size20 padding8 opacity-bg" :disabled="playing" :title="$t('i.create')" />
          </el-popconfirm>
        </el-col>
        <el-col :span="span">
          <el-dropdown trigger="click" placement="top-start" @command="handle">
            <el-button class="iconfont my-out size20 padding8 opacity-bg" :title="$t('i.more')" />
            <el-dropdown-menu slot="dropdown" class="my-dropdown">
              <el-dropdown-item v-if="data.type !== 'workViewer'" command="exportOriginImage"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />{{ $t('i.exportOrigin') }}</div></el-dropdown-item>
              <el-dropdown-item command="exportPixel"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />{{ $t('i.exportPixel') }}</div></el-dropdown-item>
              <el-dropdown-item command="exportGridy"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />{{ $t('i.exportGridy') }}</div></el-dropdown-item>
              <el-dropdown-item command="exportBrick"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />{{ $t('i.exportPuzzle') }}</div></el-dropdown-item>
              <el-dropdown-item command="exportAdvance"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />{{ $t('i.advancedExport') }}</div></el-dropdown-item>
              <el-dropdown-item command="publishWork" divided> <i class="iconfont my-zone" /> {{ $t('i.publish') }}...</el-dropdown-item>
              <el-dropdown-item command="share"><i class="iconfont my-share-out" /> {{ $t('i.copyLink') }}</el-dropdown-item>
              <el-dropdown-item command="pop"> <i class="iconfont my-pop" /> {{ $t('i.createShareImage') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div v-show="false" ref="share-url" class="clipboard" :data-clipboard-text="' ' + getInfo(data.work) + ' ' + mainHost + '#/?mod=workViewer&workid=' + (data.work ? data.work.workid : 0)" @click="shareIt(data.work ? data.work.workid : 0)" />
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import service from '@/js/service'
import conf from '@/js/conf/conf'
import GRIDY from '@/js/sdk/GridySDK'
import voxelViewer from '@/components/viewer/voxelViewer'
import blankImage from '@/assets/blank.png'
export default {
  components: {
    voxelViewer
  },
  mixins: [mixins],
  props: {},
  data() {
    let width, height
    if (this.state.platform.type === 'desktop') {
      width = 632
      height = 632
    } else {
      width = utils.width()
      height = utils.height()
    }
    const palette = conf.palette()
    const defaultOpts = {
      x: 0,
      y: 0,
      initScale: 1,
      defaultGridSize: 8,
      gridSize: 8,
      filter: {
        // 色调
        hue: 0,
        // 饱和度
        saturation: 0,
        // 色明度
        value: 0,
        // 亮度
        brightness: 0,
        // 对比度
        contrast: 0
      },
      paletteId: 'originPalette',
      workTypeid: 0,
      shapeIdx: 0,
      brickSizeId: '',
      ratioid: '',
      preFillShape: '',
      fillShape: '',
      colorOrder: 'asc',
      brickfy: false,
      roundTile: false,
      d3: true,
      canvasBgColor: '',
      gridColor: '',
      showGrid: true,
      diy: false,
      diyType: 'brick',
      diyColor: '#FFFFFF',
      diySize: 'L',
      diySide: 0
    }
    const diySides = [this.$t('i.singlePrinting'), this.$t('i.backPrinting'), this.$t('i.twoPrinting')]
    const diySizes = ['SM', 'M', 'L', 'XL', 'XXL']
    const diyColors = ['#FFFFFF', '#888888', '#111111', '#E1AC0D']
    return {
      mainHost: conf.hosts().mainHost,
      sizeOpts: conf.sizeOpts,
      diySides: diySides,
      diySizes: diySizes,
      diyColors: diyColors,
      inited: false,
      showOriginImage: false,
      sceneIdx: 0,
      playing: false,
      count: 0,
      sto: null,
      imgEl: null,
      voxelViewerEl: null,
      GRIDYXLS: null,
      GRIDYLXF: null,
      GRIDYPAPER: null,
      GRIDYBOM: null,
      GRIDY: null,
      touchEndLock: false,
      mngColorShow: false,
      diyColorPopover: false,
      diySizePopover: false,
      diySidePopover: false,
      defaultOpts: defaultOpts,
      opts: utils.deepClone(defaultOpts),
      additionalBrickSizeOpts: [],
      width: width,
      height: height,
      blankImage: blankImage,
      palette: palette,
      cids: {},
      colors: [],
      colorsCount: 0,
      editColor: {
        color: '',
        newColor: '',
        idx: -1
      },
      sceneObj: '',
      pixleOnly: true,
      workTypes: conf.workTypes()
    }
  },
  computed: {
    brickSizeOpts() {
      return this.opts.ratioid ? (this.sizeOpts[this.opts.ratioid] || []) : []
    },
    config() {
      return this.view.gridyViewer
    },
    data() {
      return this.config.data
    },
    title() {
      let tit
      if (this.data.preview) {
        tit = this.$t('i.exportPreview')
      } else {
        if (this.opts.diy || this.opts.paletteId === 'brickfy') {
          tit = this.$t('i.createPuzzle')
        }
      }
      return tit
    },
    previewAreaStyle() {
      return { 'width': this.width + 'px', 'height': this.height + 'px', 'background': '#000000', 'overflow': 'hidden', 'text-align': 'center' }
    },
    scenes() {
      return (this.data.work && this.data.work.canvas) ? this.data.work.canvas.scenes : []
    },
    isViewer() {
      return this.data.type === 'workViewer' || this.data.type === 'imagesViewer'
    },
    canPlay() {
      return this.isViewer && (this.scenes.length > 1 || (this.data.images && this.data.images.length > 1))
    },
    span() {
      return this.data.preview ? 3 : 3
    }
  },
  watch: {
    'mngColorShow': {
      handler() {
        if (this.mngColorShow) {
          this.emit('traceEvent', ['mngColorShow'])
          this.getObjColors()
        }
      }
    },
    'opts.paletteId': {
      handler() {
        const colorIdsObj = conf.getColorIds(this.opts.paletteId)
        this.cids = colorIdsObj.colorIds
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.view.myPublish.fn = null
      if (this.config.show) {
        this.loading(true)
        setTimeout(() => {
          this.init(() => {
            // eslint-disable-next-line
            if (!this.imgEl.scaleX) Transform(this.imgEl)
            this.opts = utils.deepClone(this.defaultOpts)
            if (typeof this.data.fillShape !== 'undefined') this.opts.fillShape = this.data.fillShape
            delete this.data.originWork
            this.GRIDY = new GRIDY()
            // console.error('data0', utils.deepClone(this.data), this.data.diyType, this.opts)
            if (this.data.type === 'workViewer') {
              this.opts.paletteId = this.data.work.paletteId || 'originPalette'
              this.opts.fillShape = this.data.work.fillShape
              this.data.ratioid = this.data.work.canvas.aspectFactor
              this.opts.ratioid = this.data.ratioid
              this.opts.brickSizeId = this.data.work.canvas.cols + '-' + this.data.work.canvas.rows
              this.data.originWork = utils.deepClone(this.data.work)
              this.opts.workTypeid = this.data.workTypeid || (this.data.work.type === 1 ? 2 : 0)
              this.calcAdditionalBrickSizeOpts(this.data.originWork.canvas)
              this.GRIDY.setFile(this.data.work)
              this.setWorkType(this.opts.workTypeid)
            } else if (this.data.type === 'imagesViewer') {
              this.opts.brickSizeId = ''
              this.opts.workTypeid === 'originImage'
              this.opts.paletteId = 'originImage'
              this.sceneIdx = this.data.idx || 0
              const names = this.data.names || []
              for (const idx in this.data.images) {
                this.importImages(idx, this.data.name || names[idx] || '')
              }
              this.data.work = this.GRIDY.getFile()
              this.opts.workTypeid = this.data.workTypeid || (this.data.work.type === 1 ? 2 : 0)
              this.data.originWork = utils.deepClone(this.data.work)
              if (names[0] || this.data.name) this.data.work.name = names[0] || this.data.name
              this.data.work.loop = 0
              this.data.work.rate = 0.5
            } else {
              this.data.work = this.GRIDY.getFile()
              this.opts.paletteId = '32'
              this.opts.ratioid = this.data.ratioid
              this.opts.brickSizeId = this.data.brickSizeId || this.calcBrickSizeId(this.opts.ratioid)
              const name = utils.getFileName(this.data.name)
              this.GRIDY.setFileName(name)
              this.GRIDY.importImage(this.sceneIdx, this.data.img || this.data.file, name, (status) => {
                this.GRIDY.setScenesAttrs(this.sceneIdx, { name: name })
                if (status) {
                  const obj = this.GRIDY.getObj(this.sceneIdx, 0)
                  this.calcAdditionalBrickSizeOpts(obj)
                  this.GRIDY.autoCanvasSize()
                  this.setDefaultGridSize(obj.cols, obj.rows)
                  this.setWorkType(this.data.workTypeid || 0)
                }
                this.data.work = this.GRIDY.getFile()
              }, { userid: this.loginUserId, brickSizeId: this.opts.brickSizeId, ratioid: this.opts.ratioid, compress: true })
            }
            const colorIdsObj = conf.getColorIds(this.opts.paletteId)
            this.cids = colorIdsObj.colorIds
          })
        }, 50)
      } else {
        if (this.imgEl) {
          this.imgEl.onload = null
          this.imgEl.onerror = null
        }
        this.inited = false
      }
    }, 50)
  },
  methods: {
    calcAdditionalBrickSizeOpts(obj) {
      const opts = []
      const cols = obj.cols
      const rows = obj.rows
      const ratioid = this.calcRatioId(cols, rows)
      if (ratioid) {
        this.opts.ratioid = ratioid
      } else {
        if (cols / 8 >= 16 && rows / 8 >= 16) opts.push({ cols: Math.round(cols / 8), rows: Math.round(rows / 8) })
        if (cols / 4 >= 16 && rows / 4 >= 16) opts.push({ cols: Math.round(cols / 4), rows: Math.round(rows / 4) })
        if (cols / 2 >= 16 && rows / 2 >= 16) opts.push({ cols: Math.round(cols / 2), rows: Math.round(rows / 2) })
        opts.push({ cols: cols, rows: rows })
        if (cols * 1.5 <= 256 && rows * 1.5 <= 256) opts.push({ cols: Math.round(cols * 1.5), rows: Math.round(rows * 1.5) })
        if (cols * 2 <= 256 && rows * 2 <= 256) opts.push({ cols: cols * 2, rows: rows * 2 })
        if (cols * 3 <= 256 && rows * 3 <= 256) opts.push({ cols: cols * 3, rows: rows * 3 })
        if (cols * 4 <= 256 && rows * 4 <= 256) opts.push({ cols: cols * 4, rows: rows * 4 })
        this.opts.brickSizeId = cols + '-' + rows
      }
      this.additionalBrickSizeOpts = opts
    },
    calcRatioId(cols, rows) {
      for (const i in conf.canvasRatio) {
        if (conf.canvasRatio[i].cols / conf.canvasRatio[i].rows === cols / rows) {
          return conf.canvasRatio[i].id
        }
      }
      return ''
    },
    showSizeOpt(cols, rows) {
      if (this.additionalBrickSizeOpts && this.additionalBrickSizeOpts.length) {
        for (const i in this.additionalBrickSizeOpts) {
          if (this.additionalBrickSizeOpts[i].cols === cols && this.additionalBrickSizeOpts[i].rows === rows) {
            return false
          }
        }
      }
      if (this.data.type === 'workViewer') {
        return cols <= this.data.originWork.canvas.cols && rows <= this.data.originWork.canvas.rows
      }
      return true
    },
    calcBrickSizeId(ratioid) {
      for (const i in conf.canvasRatio) {
        if (conf.canvasRatio[i].id === ratioid) {
          return conf.canvasRatio[i].cols + '-' + conf.canvasRatio[i].rows
        }
      }
      return ''
    },
    importImages(idx, name) {
      name = utils.getFileName(name || this.data.name)
      idx = parseInt(idx)
      const imagesCount = this.data.images.length
      if (idx > 0) this.GRIDY.addScene()
      this.GRIDY.importImage(idx, this.data.images[idx], name, (status) => {
        this.GRIDY.setScenesAttrs(idx, { name: name })
        if (imagesCount - idx === 1) {
          const obj = this.GRIDY.getObj(idx, 0)
          this.opts.brickSizeId = obj.cols + '-' + obj.rows
          this.calcAdditionalBrickSizeOpts(obj)
          this.setWorkType('originImage')
        }
      }, { userid: this.loginUserId, brickSizeId: this.opts.brickSizeId, compress: true })
    },
    init(cb) {
      if (this.inited) {
        cb && cb()
        return
      }
      this.imgEl = this.$refs.image
      this.voxelViewerEl = this.$refs.voxelViewer
      if (!this.imgEl || !this.voxelViewerEl) {
        setTimeout(() => {
          this.init(cb)
        }, 50)
        return
      }
      const load = () => {
        this.setImageStyle()
        if (this.opts.d3) {
          this.loadD3(this.imgEl)
        } else {
          this.loading(false)
        }
      }
      this.imgEl.onload = load
      this.imgEl.onerror = load
      this.inited = true
      cb && cb()
    },
    loadD3(canvas) {
      this.voxelViewerEl.setWork(this.GRIDY.getFile())
      if (this.opts.paletteId === 'originImage' || this.showOriginImage) {
        this.voxelViewerEl.setDiyMod('originImage')
      } else {
        this.voxelViewerEl.setDiyMod(this.opts.workTypeid === 2 ? 'brick' : '', { color: this.opts.diyColor, size: this.opts.diySize, side: this.opts.diySide })
      }
      this.voxelViewerEl.launch(() => {
        this.voxelViewerEl.draw(canvas, () => {
          this.loading(false)
        })
      })
    },
    close() {
      if (this.playing) this.stop()
      this.closePop(this.config)
    },
    loading(show, tip) {
      this.config.loading = show
      this.emit('loading', [!!show, tip || this.$t('i.rending')])
    },
    toggleColorOrder() {
      if (this.opts.colorOrder === 'asc') {
        this.opts.colorOrder = 'desc'
      } else {
        this.opts.colorOrder = 'asc'
      }
      this.getObjColors(this.opts.colorOrder)
    },
    // 获取对象颜色表
    getObjColors(orderby = 'asc') {
      const colorsObj = this.GRIDY.calcObjColors(this.sceneIdx, 0)
      let colors = []
      if (colorsObj) {
        for (let color in colorsObj) {
          // colors.push([color, colorsObj[color]])
          if (color) {
            color = color.toUpperCase()
            colors.push([color, colorsObj[color], this.cids[color]])
          }
        }
      }
      colors = utils.sortArr(colors, 1)
      if (orderby === 'asc') {
        colors = colors.reverse()
      }
      this.colorsCount = colors.length
      this.colors = colors.slice(0, 128)
    },
    // 打开降噪面板
    openDenoise() {
      const fn = () => {
        this.loading(true)
        setTimeout(() => {
          this.GRIDY.objDenoise(this.sceneIdx, 0, -1, this.state.denoiseFactor)
          this.GRIDY.mergeObjColor(this.sceneIdx, 0, -1, this.state.mergerFactor)
          this.GRIDY.quantizeObjColor(this.sceneIdx, 0, -1, this.state.quantizeFactor)
          this.state.quantizeFactor = 0
          this.draw()
        }, 50)
      }
      this.emit('openPop', [{ title: this.$t('i.noiseReduction'), type: 'denoise', fn: fn }])
    },
    openColorFilter() {
      const fn = () => {
        this.loading(true)
        setTimeout(() => {
          for (const k in this.state.colorFilter) {
            this.opts.filter[k] = this.state.colorFilter[k]
            this.state.colorFilter[k] = 0
          }
          this.setFilter()
        }, 50)
      }
      this.emit('openPop', [{ title: this.$t('i.hue'), type: 'colorFilter', fn: fn }])
    },
    // 打开调色板取色
    toggleColorPicker(event, pickId, params) {
      this.emit('toggleColorPicker', [event, pickId, params, this.data.work.type])
    },
    // 编辑颜色代码
    toggleColorEditer(i, edit) {
      this.editColor['color'] = (this.colors[i] && this.colors[i][0]) ? this.colors[i][0] : ''
      this.editColor['newColor'] = this.editColor['color']
      this.editColor['idx'] = i
      if (!edit) {
        this.editColor.color = ''
        this.editColor.newColor = ''
        this.editColor.idx = -1
      }
      this.update()
    },
    // 更换颜色
    changeColor(i) {
      this.loading(true)
      const color = this.editColor['color'] || ''
      const newColor = this.editColor['newColor'] || ''
      if (/^#([0-9a-f]{6})$/i.test(newColor)) {
        this.changeObjColor(color, newColor)
      } else {
        if (this.colors[i] && this.colors[i][0]) this.colors[i][0] = color
      }
      setTimeout(() => {
        this.toggleColorEditer(i, false)
      }, 50)
    },
    // 更换颜色
    changeObjColor(color, newColor) {
      if (color || newColor) {
        this.GRIDY.changeObjColor(this.sceneIdx, 0, -1, color, newColor)
        this.getObjColors()
        this.draw()
      }
    },
    // 删除颜色
    deleteObjColor(color) {
      this.loading(true)
      setTimeout(() => {
        this.GRIDY.deleteObjColor(this.sceneIdx, 0, -1, color)
        this.getObjColors()
        this.draw()
      }, 50)
    },
    // 恢复作品
    restoreWork() {
      this.opts.paletteId = 'originPalette'
      this.opts.brickSizeId = ''
      this.data.work = utils.deepClone(this.data.originWork)
      this.GRIDY.setFile(this.data.work)
      this.draw()
    },
    // 还原
    restore() {
      if (this.data.type === 'workViewer' && this.data.originWork) {
        this.restoreWork()
        return
      }
      this.loading(true)
      setTimeout(() => {
        this.GRIDY.reloadImage(this.sceneIdx, 0, -1, 'origin', () => {
          const d3 = this.opts.d3
          this.opts = utils.deepClone(this.defaultOpts)
          this.opts.d3 = d3
          const obj = this.GRIDY.getObj(this.sceneIdx, 0)
          this.GRIDY.autoCanvasSize()
          this.setDefaultGridSize(obj.cols, obj.rows)
          this.setPalette('64')
          this.imgEl.scaleX = 1
          this.imgEl.scaleY = 1
          this.imgEl.translateX = 0
          this.imgEl.translateY = 0
        }, { userid: this.loginUserId, brickSizeId: this.opts.brickSizeId })
      }, 50)
    },
    // AI 绘画
    paint(style) {
      this.emit('loading', [true, this.$t('i.rending')])
      this.GRIDY.exportObj(this.sceneIdx, 0, -1, { formate: 'originImage' }, (ret) => {
        if (!ret || !ret[0]) {
          this.emit('loading', [false])
          return
        }
        this.GRIDY.compressImage(ret[0][1], ret[0][0], (objData) => {
          const data = {
            name: this.$t('i.AImages'),
            info: this.$t('i.AImages'),
            resolution: '768:768',
            input_image: objData.base64,
            style: style
          }
          service.post('paint', data, (dt, type) => {
            if (type === 'success') {
              setTimeout(() => {
                this.emit('openResource', ['paint'])
              }, 50)
            } else {
              if (type === 'login') {
                this.message(dt, type)
              } else {
                this.alert(dt)
              }
            }
            this.emit('loading', [false])
          }, true)
        }, 768, 768)
      })
    },
    // 定制商品
    diyGift(type) {
      this.emit('traceEvent', ['diyGift', '', { type: type }])
      this.loading(true)
      setTimeout(() => {
        this.opts.diyType = type
        if (!type) {
          this.opts.brickfy = false
          this.opts.diy = false
          this.draw()
          return
        }
        this.opts.diy = true
        if (type === 'brick') {
          this.opts.paletteId = 'brickfy'
          this.opts.shapeIdx = 1
          this.opts.fillShape = 'circle'
          this.GRIDY.setObjPalette(this.sceneIdx, 0, -1, this.opts.paletteId)
        } else if (type === 'tshirt') {
          this.opts.shapeIdx = 2
          this.opts.fillShape = 'circle'
          this.GRIDY.setObjPalette(this.sceneIdx, 0, -1, this.opts.paletteId)
        }
        const file = this.GRIDY.getFile()
        if (file.workid && type) this.emit('workCount', [file.workid, 'diy'])
        this.draw()
      }, 50)
    },
    openBrickyViewer() {
      const work = this.GRIDY.getFile()
      if (work.workid) this.emit('workCount', [work.workid, 'diy'])
      const diyType = 'brick'
      if (this.data.type === 'imagesViewer') {
        const obj = this.GRIDY.getObj(this.sceneIdx, 0, -1)
        const base64 = obj.originImage || obj.thumbImage || ''
        const file = utils.blobToFile(utils.base64ToBlob(base64), obj.name, 'image/png')
        this.emit('openCropImage', [{ data: { file: file, diyType: 'brick' }}])
      } else {
        this.emit('openBrickyViewer', [{ data: { brickSizeId: this.opts.brickSizeId, colorfyId: '', paletteId: this.opts.paletteId, work: work, type: 'workViewer', diyType: diyType, fillShape: '', canback: false }}])
      }
      setTimeout(this.close, 1000)
    },
    // 购买定制商品
    buyIt() {
      this.emit('traceEvent', ['buyIt', '', { diyType: this.opts.diyType }])
      this.alert(this.$t('i.cantBuy'))
    },
    // 更换尺寸
    changeDiySize(size) {
      this.opts.diySize = size
      this.diySizePopover = false
      this.emit('traceEvent', ['changeDiySize', '', { size: size }])
    },
    // 更换印刷方式
    changeDiySide(idx) {
      this.loading(true)
      setTimeout(() => {
        this.opts.diySide = idx
        this.diySidePopover = false
        this.draw()
      }, 50)
      this.emit('traceEvent', ['changeDiySide', '', { idx: idx }])
    },
    // 更换颜色
    changeDiyColor(color) {
      this.loading(true)
      setTimeout(() => {
        this.opts.diyColor = color
        this.diyColorPopover = false
        this.draw()
      }, 50)
      this.emit('traceEvent', ['changeDiyColor', '', { color: color }])
    },
    setDefaultGridSize(cols, rows) {
      if (!cols || !rows) return
      let gridSize = 4
      if (cols / rows > this.width / this.height) {
        gridSize = Math.floor(Math.floor(this.width / cols) / 2) * 2
      } else {
        gridSize = Math.floor(Math.floor(this.height / rows) / 2) * 2
      }
      this.opts.gridSize = Math.max(gridSize, 4)
      this.opts.defaultGridSize = this.opts.gridSize
    },
    setImageStyle() {
      if (!this.imgEl || !this.imgEl.width || !this.imgEl.height) return
      const left = (this.width - this.imgEl.width) / 2
      const top = (this.height - this.imgEl.height) / 2
      this.imgEl.style.left = left + 'px'
      this.imgEl.style.top = top + 'px'
      this.imgEl.style.position = 'relative'
    },
    // 设置作品类型
    setWorkType(workTypeid) {
      if (workTypeid === 'originImage') {
        this.opts.fillShape = ''
        this.opts.workTypeid = 'originImage'
        this.setPalette('originImage')
        return
      } else {
        if (this.opts.paletteId === 'originImage') this.opts.paletteId = ''
      }
      let shapeid = ''
      for (const i in this.workTypes) {
        if (workTypeid === this.workTypes[i].id) {
          shapeid = this.workTypes[i].shapeid
          this.opts.workTypeid = workTypeid
          break
        }
      }
      this.emit('traceEvent', ['setWorkType_gridy', '', { shapeid: shapeid, workTypeid: workTypeid }])
      this.setShape(shapeid, false)
    },
    // 设置网格形状
    setShape(shapeid, trace = true) {
      this.loading(true)
      setTimeout(() => {
        this.opts.fillShape = shapeid
        this.draw()
      }, 50)
      if (trace) this.emit('traceEvent', ['setShape', '', { shapeid: shapeid }])
    },
    // 设置拼图颗粒
    setBrickShape(roundTile, trace = true) {
      this.loading(true)
      setTimeout(() => {
        this.opts.roundTile = roundTile
        this.draw()
      }, 50)
      if (trace) this.emit('traceEvent', ['setBrickShape', '', { roundTile: roundTile }])
    },
    // 设置色相饱和度
    setFilter() {
      this.loading(true)
      setTimeout(() => {
        const options = { userid: this.loginUserId, brickSizeId: this.opts.brickSizeId, paletteId: this.opts.paletteId, colorFilter: this.opts.filter }
        this.GRIDY.reloadImage(this.sceneIdx, 0, -1, this.opts.brickSizeId ? 'origin' : 'thumb', () => {
          this.GRIDY.autoCanvasSize()
          this.draw()
        }, options)
      }, 50)
      this.emit('traceEvent', ['setFilter'])
    },
    // 设置网格大小：越小越清晰
    setBricksize(sizeId) {
      this.opts.brickSizeId = sizeId
      if (this.data.type === 'workViewer') {
        this.setWorkPalette(this.opts.paletteId, this.opts.brickSizeId)
        return
      }
      this.loading(true)
      setTimeout(() => {
        const options = { userid: this.loginUserId, brickSizeId: this.opts.brickSizeId, paletteId: this.opts.paletteId, colorFilter: this.opts.filter }
        this.GRIDY.reloadImage(this.sceneIdx, 0, -1, sizeId ? 'origin' : 'thumb', () => {
          this.GRIDY.autoCanvasSize()
          this.draw()
        }, options)
      }, 50)
      this.emit('traceEvent', ['setBricksize', '', { sizeId: sizeId }])
    },
    // 设置作品调色板和网格大小
    setWorkPalette(paletteId, brickSizeId = '', silence = false) {
      this.emit('traceEvent', ['setWorkPalette', '', { paletteId: paletteId, brickSizeId: brickSizeId }])
      if (this.data.type !== 'workViewer' || !this.data.originWork) return
      if (!silence) this.loading(true)
      setTimeout(() => {
        this.opts.paletteId = paletteId
        if (typeof brickSizeId !== 'undefined') this.opts.brickSizeId = brickSizeId
        this.data.work = utils.deepClone(this.data.originWork)
        // if (this.opts.paletteId === 'bricky') {
        //   this.data.work.type = 1
        // } else {
        //   this.data.work.type = 0
        // }
        this.GRIDY.setFile(this.data.work)
        const options = { gridSize: this.opts.gridSize, fillShape: this.opts.fillShape, ratioid: this.opts.ratioid, brickfy: this.opts.brickfy, paletteId: this.opts.paletteId, colorFilter: this.opts.filter }
        if (this.opts.d3) options.gridSize = 16
        const reloadOptions = { userid: this.loginUserId, brickSizeId: this.opts.brickSizeId, paletteId: this.opts.paletteId }
        reloadOptions.calcSize = true
        const canvas = document.createElement('canvas')
        options.fillShape = ''
        const colorIdsObj = conf.getColorIds(this.opts.paletteId)
        options.darkMatch = colorIdsObj.darkMatch
        this.GRIDY.drawScene(canvas, this.sceneIdx, options)
        this.GRIDY.replaceImage(this.sceneIdx, 0, -1, canvas, undefined, (status) => {
          const obj = this.GRIDY.getObj(this.sceneIdx, 0, -1)
          obj.x = 0
          obj.y = 0
          this.GRIDY.setCanvasSize(obj.cols, obj.rows)
          this.draw()
        }, reloadOptions)
      }, 10)
    },
    // 设置调色板
    setPalette(paletteId, silence = false) {
      if (this.data.type === 'workViewer') {
        this.setWorkPalette(paletteId, this.opts.brickSizeId, silence)
        return
      }
      this.emit('traceEvent', ['setPalette', '', { paletteId: paletteId }])
      if (!silence) this.loading(true)
      setTimeout(() => {
        if (paletteId === 'originImage') {
          this.draw(() => {}, paletteId)
        } else {
          this.opts.paletteId = paletteId
          const obj = this.GRIDY.getObj(this.sceneIdx, 0)
          this.opts.brickSizeId = obj.cols + '-' + obj.rows
          this.calcAdditionalBrickSizeOpts(obj)
          const options = { userid: this.loginUserId, brickSizeId: this.opts.brickSizeId, paletteId: paletteId, colorFilter: this.opts.filter }
          const colorfyDt = conf.colorfyDt()
          if (colorfyDt[paletteId]) {
            options.paletteId = paletteId === 'colors' ? 'brickfyColors' : 'brickfyGray'
            options.colorfyId = paletteId
            options.forceColorfy = true
            options.darkMatch = false
          }
          this.GRIDY.reloadImage(this.sceneIdx, 0, -1, this.opts.brickSizeId ? 'origin' : 'thumb', (status) => {
            this.draw()
          }, options)
        }
      }, 50)
    },
    getColorfyWork(colorfyObj, work) {
      work = work || utils.deepClone(this.GRIDY.getFile())
      if (!colorfyObj) return work
      colorfyObj.name = work.name
      colorfyObj.userid = this.loginUserId
      colorfyObj.fillShape = ''
      if (work.canvas.scenes[0].objs.length === 1) {
        // 单对象改写数据
        work.canvas.scenes[0].objs[0].x = 0
        work.canvas.scenes[0].objs[0].y = 0
        work.canvas.scenes[0].objs[0].data = colorfyObj.data
        work.canvas.scenes[0].objs[0].cols = colorfyObj.cols
        work.canvas.scenes[0].objs[0].rows = colorfyObj.rows
      } else {
        // 多对象合并掉
        const newObj = this.GRIDY.createObj(colorfyObj)
        work.canvas.scenes[0].objs = [newObj]
      }
      work.canvas.cols = colorfyObj.cols
      work.canvas.rows = colorfyObj.rows
      return work
    },
    handle(act) {
      let file = utils.deepClone(this.GRIDY.getFile())
      file.exportPaletteId = this.opts.paletteId
      if (act === 'exportPixel' || act === 'exportGridy' || act === 'exportBrick' || act === 'exportAdvance') {
        this.emit('showExportSheet', [file, act, this.opts, this.sceneIdx, this.voxelViewerEl, true])
      } else if (act === 'exportOriginImage') {
        this.emit('exportFile', [file, 'originImage', 'originImage', this.opts, this.sceneIdx])
      } else if (act === 'publishWork') {
        if (this.opts.workTypeid === 2) {
          // 改写对象数据
          if (this.sceneObj) {
            file = this.getColorfyWork(this.sceneObj)
          }
          file.type = 1
        } else {
          file.type = 0
        }
        this.emit('publishWork', [file, false, (dt) => {
          file.catalogid = dt.catalogid
          file.name = dt.name
          file.info = dt.info
          file.tags = dt.tags
          file.public = dt.public
          file.public_at = dt.public_at
          file.share = dt.share
          file.threadid = dt.threadid
          file.postid = dt.postid
          file.workid = dt.workid
          this.GRIDY.setFile(file)
        }])
      } else if (act === 'share') {
        this.$refs['share-url'].click()
      } else if (act === 'pop') {
        this.emit('exportFile', [file, 'pop', 'pop', this.opts, this.sceneIdx])
      }
    },
    // 分享
    shareIt(workid) {
      if (!workid) {
        this.alert(this.$t('i.publishAndShare'), () => {
          this.handle('publishWork')
        })
        return
      }
      // eslint-disable-next-line
      var clipboard = new ClipboardJS('.clipboard')
      clipboard.on('success', (e) => {
        e.clearSelection()
        this.message(this.$t('i.copyAndShare'), 'success')
        clipboard.destroy()
      })
      service.get('work_share', workid)
      service.incCount(this.loginUserId, 'work_share')
      this.emit('traceEvent', ['shareIt'])
    },
    getInfo(file) {
      if (!file) return ''
      return (file.name || '') + ' ' + this.getWorkTags(file) + ' ' + (file.info || '')
    },
    getWorkTags(work) {
      if (!work.tags) return ''
      let tags = ''
      if (work.tags && work.tags.length) {
        tags = '#' + work.tags.join('# #') + '#'
      }
      return tags
    },
    ease(x) {
      return Math.sqrt(1 - Math.pow(x - 1, 2))
    },
    onDragStop(x, y) {
      this.opts.x = x
      this.opts.y = y
    },
    /* eslint-disable */
    multipointStart() {
      To.stopAll()
      this.opts.initScale = this.imgEl.scaleX
    },
    rotate(evt) {
      this.imgEl.rotateZ += evt.angle
    },
    pinch(evt) {
      this.imgEl.scaleX = this.imgEl.scaleY = this.opts.initScale * evt.zoom
    },
    // 滚轴缩放
    onWheel(event) {
      if (event.deltaY > 0) {
        this.zoom('-')
      } else {
        this.zoom('+')
      }
    },
    // 缩放
    zoom(act) {
      if (act === '+') {
        if (this.opts.gridSize === 1) {
          this.opts.gridSize = 2
        } else if (this.opts.gridSize < 8) {
          this.opts.gridSize = this.opts.gridSize + 2
        } else {
          this.opts.gridSize = this.opts.gridSize + 4
        }
        this.opts.gridSize = Math.min(this.opts.gridSize, 32)
      } else if (act === '-') {
        if (this.opts.gridSize > 8) {
          this.opts.gridSize = this.opts.gridSize - 4
        } else {
          this.opts.gridSize = this.opts.gridSize - 2
        }
        this.opts.gridSize = Math.max(this.opts.gridSize, 1)
      } else {
        if (act < 0.125) {
          act = 0.125
        } else if (act > 2) {
          act = 2
        }
        this.opts.gridSize = Math.round(8 * Math.abs(act))
      }
      this.draw()
      this.opts.initScale = this.opts.gridSize / conf.state.gridSize
    },
    /* eslint-disable */
    multipointEnd() {
      if (this.touchEndLock) return
      To.stopAll()
      if (this.imgEl.scaleX < 1) {
        new To(this.imgEl, 'scaleX', 0.6, 500, this.ease)
        new To(this.imgEl, 'scaleY', 0.6, 500, this.ease)
      } else if (this.imgEl.scaleX > 0.8 && this.imgEl.scaleX < 1.2) {
        new To(this.imgEl, 'scaleX', 1, 500, this.ease)
        new To(this.imgEl, 'scaleY', 1, 500, this.ease)
      } else if (this.imgEl.scaleX > 1.2) {
        new To(this.imgEl, 'scaleX', 1.5, 500, this.ease)
        new To(this.imgEl, 'scaleY', 1.5, 500, this.ease)
      } else if (this.imgEl.scaleX > 2) {
        new To(this.imgEl, 'scaleX', 2, 500, this.ease)
        new To(this.imgEl, 'scaleY', 2, 500, this.ease)
      }
      var rotation = this.imgEl.rotateZ % 360
      if (rotation < 0)rotation = 360 + rotation
      this.imgEl.rotateZ=rotation
      if (rotation > 0 && rotation < 45) {
        new To(this.imgEl, 'rotateZ', 0, 500, this.ease)
      } else if (rotation >= 315) {
        new To(this.imgEl, 'rotateZ', 360, 500, this.ease)
      } else if (rotation >= 45 && rotation < 135) {
        new To(this.imgEl, 'rotateZ', 90, 500, this.ease)
      } else if (rotation >= 135 && rotation < 225) {
        new To(this.imgEl, 'rotateZ', 180, 500, this.ease)
      } else if (rotation >= 225 && rotation < 315) {
        new To(this.imgEl, 'rotateZ', 270, 500, this.ease)
      }
      this.touchEndLock = true
      setTimeout(() => {
        this.touchEndLock = false
      }, 300)
    },
    pressMove(evt) {
      this.imgEl.translateX += evt.deltaX
      this.imgEl.translateY += evt.deltaY
      evt.preventDefault()
    },
    doubleTap(evt) {
      To.stopAll()
      if (this.opts.gridSize > this.opts.defaultGridSize ) {
        this.opts.gridSize = this.opts.defaultGridSize
      } else {
        this.opts.gridSize = this.opts.defaultGridSize * 2
      }
      this.draw()
    },
    draw(cb, paletteId) {
      // this.loading(true)
      this.sceneObj = ''
      paletteId = paletteId || this.opts.paletteId
      const options = { gridSize: this.opts.gridSize, fillShape: this.opts.fillShape, brickfy: this.opts.brickfy, paletteId: paletteId, workTypeid: this.opts.workTypeid, darkMatch: false }
      if (this.opts.d3) options.gridSize = 16
      if (this.opts.workTypeid === 2) options.bgId = 'white'
      options.roundTile = this.opts.roundTile
      let imageUrl = ''
      if (this.data.type === 'imagesViewer') {
        // 图片预览模式
        options.transparent = false
        options.colorfy = false
        const obj = this.GRIDY.getObj(this.sceneIdx, 0, -1)
        obj.fillShape = this.opts.fillShape
        this.data.work.canvas.cols = obj.cols
        this.data.work.canvas.rows = obj.rows
        if(paletteId === 'originImage') {
          imageUrl = obj.originImage || obj.thumbImage || ''
        }
        if (!imageUrl) imageUrl = this.GRIDY.getObjImage(this.sceneIdx, 0, -1, options)
      } else if (this.data.type === 'workViewer') {
        if (this.opts.workTypeid === 2) {
          options.fn = (sceneObj) => {
            this.sceneObj = sceneObj
          }
        }
        imageUrl = this.GRIDY.getSceneImage(this.sceneIdx, options)
      } else {
        options.colorfy = false
        const obj = this.GRIDY.getObj(this.sceneIdx, 0, -1)
        obj.fillShape = this.opts.fillShape
        // 导图模式
        if(paletteId === 'originImage') {
          imageUrl = obj.originImage || obj.thumbImage || ''
        }
        const colorfyDt = conf.colorfyDt()
        if (colorfyDt[paletteId]) {
          options.paletteId = paletteId === 'colors' ? 'brickfyColors' : 'brickfyGray'
          options.colorfyId = paletteId
          options.forceColorfy = true
          options.colorfy = true
          options.darkMatch = false
        }
        if (!imageUrl) imageUrl = this.GRIDY.getObjImage(this.sceneIdx, 0, -1, options)
      }
      this.imgEl.src = imageUrl
      this.showOriginImage = paletteId === 'originImage'
      cb && cb(imageUrl)
    },
    submit() {
      if (this.data.preview) {
        return this.close()
      }
      this.emit('traceEvent', ['submitGridyViewer'])
      this.view.popPage.show = false
      if (this.playing) this.stop()
      let file = this.GRIDY.getFile()
      if (this.opts.workTypeid === 2) file.type = 1
      if (this.data.type === 'imagesViewer' || this.data.type === 'workViewer') {
        if (file.workid) {
          service.get('work_ref', file.workid, '')
          file.refWorkid = file.workid
          file.origin = 1
          delete file.workid
        }
        file.public = 0
        file.publish = false
        if (this.data.type === 'imagesViewer') {
          file.origin = 2
          const scene = utils.deepClone(file.canvas.scenes[this.sceneIdx])
          file.canvas.scenes = [scene]
        }
        this.emit('saveIt', [file])
        this.emit('importIt', [file, 'fileJson', '', [], file.type])
      } else {
        const obj = this.GRIDY.getObj(this.sceneIdx, 0, -1)
        obj.fillShape = this.opts.fillShape
        if (this.opts.workTypeid === 'originImage') obj.paletteId = ''
        if (this.data.imageUrl) obj.originUrl = this.data.imageUrl
        // 有作品id的直接打开
        file.origin = 2
        if (file.workid) {
          this.emit('importIt', [file, 'fileJson', '', [], file.type])
        } else {
          this.config.fn && this.config.fn(obj, 'objJson')
        }
      }
    },
    // 开始播放
    start() {
      this.playing = true
      this.count = 0
      this.play()
    },
    // 上一个
    pre() {
      const scenes = this.data.work.canvas.scenes
      if (this.sceneIdx === 0) {
        this.sceneIdx = scenes.length - 1
      } else if (this.sceneIdx) {
        this.sceneIdx = this.sceneIdx - 1
      }
      this.setPalette(this.opts.paletteId)
    },
    // 下一个
    next() {
      const scenes = this.data.work.canvas.scenes
      if (this.sceneIdx === scenes.length - 1) {
        this.sceneIdx = 0
      } else if (this.sceneIdx < scenes.length - 1) {
        this.sceneIdx = this.sceneIdx + 1
      }
      this.setPalette(this.opts.paletteId)
    },
    // 播放场景
    play() {
      const scenes = this.data.work.canvas.scenes
      if (scenes.length < 2 || !this.playing) {
        return
      }
      this.count++
      if (this.sceneIdx === scenes.length - 1) {
        this.sceneIdx = 0
      } else if (this.sceneIdx < scenes.length - 1) {
        this.sceneIdx = this.sceneIdx + 1
      }
      this.setPalette(this.opts.paletteId, true)
      this.data.work.rate = this.data.work.rate || 12
      this.data.work.loop = this.data.work.loop || 0
      if (this.data.work.loop && this.count / scenes.length >= this.data.work.loop) {
        this.count = 0
        this.playing = false
        return
      }
      this.sto = setTimeout(this.play, 1000 / this.data.work.rate)
    },
    // 停止播放
    stop() {
      this.playing = false
      this.count = 0
      this.sceneIdx = 0
      this.draw()
      if (this.sto) clearTimeout(this.sto)
    }
  }
}
</script>
