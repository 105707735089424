export default {
  domain: 'Gridy.Art',
  siteName: 'Gridy.Art 百格画',
  copyRight: '©2025 Gridy.Art',
  siteIntro: '网格跳动成立于2015年；专注于像素画内容生态平台建设，<br>打造像素IP，传播像素文化；我们提供跨平台领先的像素画、<br>积木拼图、钻石拼图、豆豆拼图、3D体素模型设计工具，<br>应用人工智能、区块链技术打造闭环的内容创作、分享、分发、<br>版权保护、商业应用、虚实结合的数字内容生态平台。',
  siteTips: '提供像素画、积木拼图、钻石拼图、豆豆拼图创作工具<br>致力于打造数字内容生态平台及Web3.0社区',
  contactInfo: '公司：福州网格跳动网络科技有限公司<br>地址：福建省福州高新区华建大厦5号楼402<br> 联系人：吴先生<br>微信：dreawu<br>邮箱：389193@qq.com',
  currency: '￥',
  productTypes: {
    '8000': '乐高式积木拼图',
    '9000': '百乐酷积木拼图',
    '10001': '串联式积木拼图',
    '10002': '钻石拼图',
    '11': '钻石熊猫人',
    '12': '涂鸦熊猫人',
    '13': '流体熊猫人',
    '14': '摆件熊猫人',
    '15': '手办熊猫人',
    '0': '积木拼图',
    '1': '钻石拼图',
    '99': '其他产品'
  },
  tabNames: {
    'draft': '我的草稿',
    'works': '我的作品',
    'mynft': '我的藏品',
    'sellnft': '售卖藏品',
    'find': '发现',
    'creator': '创作者',
    'myassets': '我的素材',
    'assets': '公共素材库',
    'setting': '设置',
    'msg': '消息',
    'fans': '粉丝',
    'worth': '资产',
    'orders': '订单',
    'faq': '快速入门',
    'hotkeys': '快捷键列表',
    'update': '检查更新',
    'feedback': '发送反馈',
    'aboutus': '关于我们',
    'login': '登录'
  },
  i: {
    aiPixelPuzzle: 'AI像素拼图',
    zoomin: '放大',
    zoomout: '缩小',
    sizeSmall: '网格小一点',
    sizeBig: '网格大一点',
    moveYLeft: '向左移动Y轴',
    moveYRight: '向右移动Y轴',
    moveXUp: '向上移动X轴',
    moveXDown: '向下移动X轴',
    sizeExceeds: '当前作品行或列超出{size}',
    previewFirst: '请先预览再提交！',
    optimizeColors: '自动降噪',
    showAllLines: '开网格线',
    previewEffect: '预览效果',
    toGridy: '网格化处理',
    gridSize: '网格大小',
    offsetX: '偏移量 X',
    offsetY: '偏移量 Y',
    viewSite: '访问Gridy.Art网站',
    realTips: '提示：<br>1、我们将收集您的姓名和身份证号用于实名认证<br>2、实名认证通过后，可获得完整的服务和体验',
    hours: '小时',
    minute: '分钟',
    aMomentAgo: '刚刚',
    minuteAgo: '1分钟前',
    minutesAgo: '分钟前',
    hourAgo: '1小时前',
    hoursAgo: '小时前',
    yesterday: '昨天',
    beforeYesterday: '前天',
    daysAgo: '天前',
    weekAgo: '周前',
    beanPuzzle: '豆豆拼图',
    blockPuzzle: '积木拼图',
    diamondPuzzle: '钻石拼图',
    my: '我的',
    your: '了你',
    yTopics: '的主题',
    yReplies: '的评论',
    reqConten: '请输入内容',
    discard: '丢弃',
    donateLock: '转赠({donateLockSeconds}后)',
    donateLocking: '({donateLockSeconds}后可转赠',
    setPayPWDFirst: '请先设置交易密码',
    realVerifyFirst: '请先通过实名认证',
    loginAgain: '请重新登录',
    opend: '已打开',
    noItems: '你还没有{name}呢',
    waitCheck: '待审核',
    checkErr: '审核不通过',
    waitingPay: '待打款',
    payOk: '已打款',
    payErr: '打款失败',
    sendOk: '发送成功',
    puzzleBOM: '拼图零件清单',
    virtualPuzzle: '虚拟拼图',
    powerBy: 'Power By Gridy.Art 版权所有，未经许可，严禁商用',
    paperPowerBy: '©百格画 版权所有，未经授权，严禁商用',
    moreGridy: '更多百格画资源请访问：https://www.gridy.art/',
    newTxt: '新文本',
    clickEdit: '请双击编辑...',
    scanForPixel: '请扫码体验海量像素画吧',
    broswerPixel: '访问百格画体验海量像素画吧',
    POP: '海报',
    mergerObj: '合并的对象',
    free: '免费',
    goodsList: '商品列表',
    copyy: '份',
    orderDetail: '订单信息',
    reqOrderAddress: '请选择配送地址',
    goodsDetail: '商品信息',
    customized: '定制',
    total: '合计',
    deliveryFee: '配送费',
    freeShipping: '已免运费',
    notRange: '不在配送范围',
    coupons: '优惠券',
    getCoupons: '领取优惠券',
    clickGet: '（点击领取）',
    maxDiscount: '已享受最大优惠，',
    couponNums: '张优惠券',
    noCoupons: '无可用优惠券',
    vipDiscount: '会员折扣',
    orderNotes: '订单备注',
    optional: '选填',
    payAmount: '实付',
    submitOrder: '提交订单',
    noOpen: '该定制商品暂未开放购买！',
    outRange: '很抱歉，您的收货地址不在配送范围内',
    reqGoods: '请选择要购买的商品',
    orderErr: '无法下单，请返回后重试！',
    simpleWhite: '白底简易',
    simpleBlack: '黑底简易',
    whiteFrame: '白框',
    blackFrame: '黑框',
    originalBg: '原生背景',
    black: '黑色',
    white: '白色',
    lightGray: '浅灰色',
    lightGreen: '浅绿色',
    green: '绿色',
    blue: '蓝色',
    red: '红色',
    yellow: '黄色',
    apricot: '杏色',
    pink: '粉色',
    darkGray: '深灰色',
    base: '底板',
    orderDetails: '实物订单详情',
    NFTOrderDetails: '数字订单详情',
    NFTIntro: '藏品介绍',
    NFTBenefits: '藏品权益',
    NFTCert: '藏品证书',
    NFTIPS: '藏品提示',
    bindNewMobile: '绑定新手机',
    bindNewEmail: '绑定新邮箱',
    verifyOldMobile: '验证旧手机',
    verifyOldEmail: '验证旧邮箱',
    changeLoginPWD: '修改登录密码',
    changeTradePWD: '修改交易密码',
    hall: '展厅',
    warehouse: '仓库',
    backpack: '背包',
    withDrawaList: '提现记录',
    payRecords: '收支记录',
    visiter: '访客',
    acceTask: '可接任务',
    myTask: '我的任务',
    newUser: '最新用户',
    saleCalendar: '发售日历',
    sale: '发售',
    add: '添加',
    creat: '创建',
    user: '用户',
    aiPuzzle: 'AI像素拼图',
    vPuzzle: '百变拼图',
    recommendTopic: '推荐话题',
    newTopic: '最新话题',
    pubTime: '发布时间',
    updateTime: '更新时间',
    new: '最新',
    atMen: '要@的人',
    topics: '话题',
    asset: '素材',
    addres: '地址',
    subscribe: '申购',
    purchase: '正式购',
    airDrop: '空投',
    suggestion: '意见建议',
    bugFeed: 'BUG反馈',
    cooperation: '商务合作',
    festivals: '节日节气',
    education: '教育文化',
    sports: '体育健身',
    healthcare: '医疗健康',
    clothing: '服装饰品',
    household: '家居日用',
    tools: '工具用品',
    toys: '玩具游戏',
    foods: '餐饮美食',
    fruit: '蔬菜水果',
    animals: '动物植物',
    business: '商务办公',
    traffic: '汽车交通',
    building: '建筑空间',
    science: '科学宇宙',
    persons: '人物动漫',
    icon: '图标形状',
    squares: '广场',
    gridyZone: '百格圈',
    puzzleZone: '拼图圈',
    NFTZone: '数藏圈',
    gameZone: '游戏圈',
    headlineZone: '头条圈',
    feedbackZone: '反馈圈',
    quickErr: '请勿频繁操作',
    reqErr: '请求错误',
    intErr: '内部错误',
    netErr: '网络错误',
    reqPubContent: '请输入发布内容',
    pubQuick: '发布太快，请稍后重试',
    reqReplyConetnt: '请输入评论内容',
    replyQuick: '回复太快，请稍后重试',
    accountBen: '当前账号已被禁用',
    checkFail: '当前账号暂未通过审核',
    checkFailed: '当前账号未通过审核',
    loginSucess: '登录成功',
    loginFail: '登录失败，请重试',
    taskCancel: '任务已放弃',
    ifCancelTask: '确定放弃该任务吗？',
    taskAccepted: '任务已接受',
    gain: '获得',
    aiLineUping: 'AI作画排队中，请耐心等待...',
    maxSelectTips: '最多选择{maxSelectCount}项',
    deleteComp: '彻底删除',
    updated: '有更新',
    delSuccess: '删除成功',
    cancelTopUp: '取消置顶',
    fans: '粉丝',
    discounted: '已打{discount}折',
    joinVip: '加入VIP享受更多权益吧',
    vipBenefits: '会员权益对比',
    benefit: '权益',
    withDrawalTips: '提现成功，请等待打款',
    canWithDrawal: '可提现',
    withDrawalOk: '提现成功',
    rechargeOk: '充值成功',
    recharg: '充值',
    origin: '原',
    buySucess: '购买成功',
    regularFee: '原价',
    discoun: '折扣',
    fee: '现价',
    scale: '分销提成',
    haveAd: '广告',
    waterMark: '导出水印',
    creatAlbum: '创建专辑',
    maxCooperate: '多人作画',
    exportAnimation: '导出动画',
    exportHigh: '高清导出',
    diyDiscount: '定制商品折扣',
    nftDiscount: '数字藏品折扣',
    advanceService: '专属客服',
    offlineActivity: '线下活动',
    signPraise: '签到奖励',
    visitCount: '共访问{count}次',
    verifyed: '已实名',
    pubOrg: '发行机构',
    cancelFollow: '取消关注',
    visit: '访问',
    msg: '私信',
    draf: '草稿',
    invit: '邀请',
    newAlbum: '新建专辑',
    mine: '我的',
    homepage: '个人主页',
    watch: '快来围观',
    reqReply: '快留下你的精彩评论吧',
    relays: '转发',
    praiseCount: '共{liked_count}个点赞',
    allComment: '全部评论',
    views: '浏览',
    signRuleTips: '1、连续签到奖励每天递增，第7天后，可获得与第7天相同的奖励；<br>2、若签到中断，可补昨日断签。否则，将从第一天重新开始；<br>3、长期坚持签到，还有额外神秘奖励。',
    signCalendar: '签到日历',
    signTips: '已连续签到{continue_days}天 ',
    signTody: '今日已签到',
    notSignTody: '今日未签到',
    dayTips: '第{continue_days}天',
    signed: '已签到',
    signNow: '立即签到',
    suppleSign: '是否补签？',
    suppleSignObtain: '补签获得',
    signObtain: '签到获得',
    taskCenter: '任务中心',
    goFinish: '去完成',
    getPraise: '领取奖励',
    receiveTask: '接受任务',
    signRule: '签到规则',
    style: '风格',
    from: '来源',
    catalog: '类目',
    sift: '筛选',
    contactUs: '联系我们',
    userFeedback: '用户反馈',
    myMoney: '我的晶币',
    aiCartoon: 'AI动漫',
    aiPixel: 'AI像素画',
    aiBeanPixel: 'AI拼豆图纸',
    allSerivce: '全部服务',
    serivce: '服务',
    newCanvas: '新建画布',
    interact: '互动',
    download: '下载',
    favor: '收藏',
    praise: '点赞',
    footprint: '足迹',
    yes: '是',
    no: '否',
    area: '所在地区',
    depositBank: '开户银行',
    accountName: '开户姓名',
    IDNO: '证件号码',
    note: '备注',
    reqBankName: '请输入银行名称',
    reqBankCard: '请输入银行卡号',
    reqAccountName: '请输入开户姓名',
    reqIDNO: '请输入身份证号码',
    reqNote: '请输入备注信息',
    name: '姓名',
    reqRealName: '请输入姓名',
    address: '详细地址',
    reqAddress: '请输入详细地址',
    accountNameErr: '请输入正确的开户姓名',
    IDNOErr: '请输入正确的身份证号码',
    bankNameErr: '请输入正确的银行名称',
    bankCardErr: '请输入正确的银行卡号',
    reqArea: '请选择所在地区',
    albumIntro: '请输入专辑介绍',
    unlimited: '不限',
    publishAndShare: '请先发布，再分享',
    copyAndShare: '链接复制成功，请粘贴分享',
    usePaperTips: '请慎重使用该图纸，如果颗粒数量不够，请联系客服！',
    rendingFail: '对不起，请尝试调整下截取的脸部大小或者更换一张高清的照片试试！',
    haveTips: '还没拥有万能的AI像素拼图吗？',
    paperTips: '这里可以找到您保存的图纸和用过的系列号。',
    editerTips: '这里有像素画创作神器，快来创作一幅吧。',
    beanPaperTips: '这里可以找到您的像素作品和拼豆图纸。',
    uploadTips: '快上传照片，看看您的AI像素拼图长什么样子吧。',
    cropTips: '小贴士：截取的脸部占比大一点，效果会更好哦',
    startNow: '开始',
    viewDrawings: '查看图纸',
    recomman: '荐',
    secondCreate: '二创',
    hotValue: '热度',
    lockUp: '封',
    upChain: '上链',
    copyrights: '版权',
    published: '已发布',
    upload: '上传',
    discount: '满减券',
    coupon: '折扣券',
    discountValue: '{discount}折',
    overAvailable: '满{min_price}元可用',
    validDay: '领取{expire_day}天内有效',
    collect: '立即领用',
    applicableProducts: '适用产品：',
    SNTitle: '系 列 号 ：',
    savePaperTitle: '保存图纸：',
    useTimes: '使用次数：',
    downPaperTitle: '下载图纸：',
    useSN: '使用该系列号',
    increaseFrequency: '申请增加次数',
    localRestore: '从本地恢复',
    localBackup: '备份到本地',
    cleanRecycle: '清空回收站',
    startCreate: '开始创作',
    settings: '个人设置',
    setAvatar: '设置头像',
    setBg: '设置背景',
    nickname: '昵称',
    sex: '性别',
    girl: '女',
    boy: '男',
    secrecy: '保密',
    birthday: '生日',
    location: '所在地',
    editProfile: '修改个人简介',
    profileTips: '个人简介在120个汉字以内',
    editNick: '修改昵称',
    selectSex: '选择性别',
    attachment: '附件',
    selectAiart: '选择AI作品',
    publishing: '发布中，请稍候...',
    reqTitle: '填写标题会有更多赞哦（选填）',
    sayWhat: '说点什么...',
    selectAssets: '选择素材',
    uploadAttachment: '上传附件',
    selectFile: '选择文件',
    selectWorks: '选择作品',
    selectNFT: '选择数字藏品',
    addTitle: '添加标题',
    cancelTitle: '取消标题',
    publishTo: '发布到',
    noRightAttachment: '对不起，没有附件功能权限',
    commonEmojis: '常用表情',
    faceEmo: '笑脸和情感',
    animalNature: '动物和自然',
    travel: '旅行和地点',
    maxAttachment: '最多上传{count}个附件',
    maxImg: '最多上传{count}张图片',
    canAttachment: '还能上传{count}个附件',
    canImg: '还能上传{count}张图片',
    maxFilesize: '超过{count}，无法上传',
    saySome: '说点什么呗',
    sayMore: '多说一点，会有更多赞哦',
    toHot: '太火爆了，请稍后重新发布',
    pubSuccess: '发布成功',
    realCommited: '实名信息提交成功',
    pwdChanged: '登录密码已修改，请重新登录',
    tradePwdChanged: '交易密码已修改',
    bindOk: '绑定成功',
    confirmReceive: '已确认收货',
    ifCancelOrder: '确定取消订单吗',
    reveiveOk: '领取成功',
    tradePWDTips: '交易密码为6位数字',
    pwdTips: '8~20位字符，必须包含字母、数字和特殊字符',
    reqCheckPWD: '请输入确认密码',
    pwdCheckFail: '两次输入的密码不一致',
    usernameTips: '6~18位英文字符，不能包含特殊字符',
    nicknameTips: '6~18位字符，不能包含特殊字符',
    reqRealname: '请输入真实姓名',
    idcardErr: '请输入正确的身份证号码',
    orderCopyOk: '订单编号复制成功',
    sendSuccess: '发送成功，感谢您的关注',
    snLimit: '当前系列号已超出使用次数！',
    orderType: '订单类型',
    orderAmount: '订单金额',
    purchaseAuantity: '交易数量',
    orderNumber: '订单编号',
    paymentMethod: '支付方式',
    aliPayment: '支付宝支付',
    paymentTime: '付款时间',
    nftStatus: '藏品状态',
    distributionFailed: '发放失败',
    waitingDistribution: '待发放',
    distributionSuccess: '发放成功',
    cancelOrder: '取消订单',
    confirmReceipt: '确认收货',
    obtainDrawings: '获取图纸',
    uploadImg: '上传图片',
    createPixelHeader: '创作像素头像',
    makeCarton: '上传照片 一键生成动漫形象',
    reqSNForPaper: '已经购买了吗？请输入系列号获取图纸吧。',
    haveMagicPuzzle: '还没拥有百变拼图吗？',
    issuInfo: '发行信息',
    issuNums: '发行数量',
    pubSale: '公开发售',
    priPurchase: '优先购',
    openSub: '开放申购',
    openCast: '开放铸造',
    freeDrop: '免费空投',
    blindReser: '盲盒预留',
    activityReser: '活动预留',
    creatorReser: '创作者或创作机构预留',
    protectReser: '版权研究及保护预留',
    commercialReser: '第三方商业授权预留',
    exhibitionReser: '公开展览展示预留',
    secondaryReser: '站内二次创作预留',
    gameReser: '电子游戏应用预留',
    puzzleReser: '拼图玩具应用预留',
    printingReser: '实物印刷应用预留',
    commerceReser: '电商平台应用预留',
    publishingReser: '出版物应用预留',
    auctionReser: '公开拍卖预留',
    purchaseNotes: '购买须知',
    workIntro: '作品简介',
    workStory: '作品故事',
    benefits: '权益列表',
    benefitsInfo: '权益说明',
    longPress: '请长按保存图片',
    commitNow: '提 交',
    realname: '真实姓名',
    idcard: '身份证号',
    state: '状态',
    noVerify: '未认证',
    bind: '绑定',
    chainAccount: '区块链账户地址',
    chainBrowser: '区块链浏览器',
    classify: '类型',
    topic: '主题',
    reqTopic: '请输入反馈主题',
    content: '内容',
    reqContent: '请输入反馈内容',
    linkman: '联系人',
    reqName: '请留下您的名字',
    contact: '联系方式',
    reqContact: '请留下联系方式',
    newVer: '有新版本：',
    downloadNow: '现在下载',
    lastVer: '最新版',
    aiWorks: 'AI作品',
    aiCartoonTips: '快来体验AI动漫形象吧！',
    aiImgPuzzleTips: '快来看看百变拼图吧！',
    aiBeanPixelTips: '快上传像素画，看看转换后的拼豆图纸吧！ ',
    aiPuzzleTips: '快上传照片，看看您的AI像素拼图长什么样子吧！ ',
    checkPWD: '确认密码',
    payPWD: '支付密码',
    reqNewMobile: '请输入新手机号',
    reqNewEmail: '请输入新邮箱',
    newMobile: '新手机号',
    newEmail: '新邮箱',
    verifyCode: '验证码',
    choose: '选择',
    ifClean: '确定清空回收站吗？',
    waitPay: '待付款',
    waitSend: '待发货',
    waitReceive: '待收货',
    hadFinish: '已完成',
    waitReply: '待评价',
    myOrders: '我的订单',
    tradeSuccess: '交易成功',
    payFail: '支付失败',
    hadCancel: '已取消',
    hadExpires: '已过期',
    payNow: '立即支付',
    waitingDrawLots: '等待抽签',
    limitCount: '限量{total}份',
    notification: '服务通知',
    interaction: '互动消息',
    growth: '成长值',
    diamondLess: '晶钻不足',
    diamond: '晶钻',
    crystal: '晶石',
    myItems: '我的物品',
    nfts: '藏品',
    donate: '转赠',
    donateNums: '转赠数量',
    receiverNick: '接收昵称',
    receiverAddress: '接收地址',
    reqReceiverAddress: '请输入接收地址',
    openin: '打 开',
    reqChainAdd: '请输入正确的手机号、电子邮箱或区块链地址',
    chainAddressErr: '区块链地址不存在',
    receiverErr: '接收者不存在或账户异常',
    receiverError: '接收者不符合交易条件',
    donateErr: '不能赠送给自己',
    donateSuccess: '已转赠成功',
    myInvite: '我的邀请',
    inviteCount: '已邀请{total}人',
    inviteLinkTips: '邀请您加入 Gridy.Art ，这里的精彩只有你能懂！',
    inviteLink: '链接复制成功，请粘贴邀请好友吧',
    creator: '创作者',
    nftOrders: '数藏订单',
    inviteTips: '快邀请好友吧',
    withDrawal: '提现',
    recommendedTopics: '推荐主题',
    activeUsers: '活跃用户',
    hotTopic: '热门话题',
    cropImg: '裁剪图片',
    loading: '加载中...',
    topUp: '置顶',
    re: '回复',
    sayTips: '快，快来说点什么吧',
    author: '作者',
    landlord: '楼主',
    floorMaster: '层主',
    allReply: '全部回复',
    replyTips: '快，快来抢沙发吧',
    replyCount: '查看全部{reply_count}条回复',
    replies: '回复',
    comment: '评论',
    chat: '聊天',
    gridyAblum: '百格画专辑',
    hadFollow: '已关注',
    followEach: '互相关注',
    follow: '关注',
    albumDes: '专辑详情',
    selectAlbum: '选择专辑',
    selectAlbumTips: '请选择或创建一个专辑',
    joined: '已加入',
    titleTips: '名称为2~24个字符，不能包含特殊字符哦',
    createAlbum: '请创建专辑',
    addNewAlbum: '创建新专辑',
    addAlbumTips: '请输入专辑名称',
    noAlbum: '空专辑',
    none: '这里空空的',
    showAll: '已显示全部内容',
    creatingSimilarity: '创作相似',
    addOk: '添加成功',
    setup: '设置',
    username: '用户名',
    nobind: '未绑定',
    mobile: '手机号',
    email: '邮箱',
    wechat: '微信',
    passwd: '登录密码',
    tradePWD: '交易密码',
    realVerify: '实名认证',
    verify: '我要认证',
    verifying: '认证中',
    verified: '已认证',
    verifyFail: '认证失败',
    blockChain: '区块链账户',
    creating: '创建中',
    browse: '查看',
    reject: '被拒绝',
    freeze: '被冻结',
    wantCreate: '我要创建',
    myBankcard: '我的银行卡',
    myAddress: '我的地址',
    bindWechat: '绑定微信',
    modifyUname: '修改用户名',
    modifyOk: '修改成功',
    bindMobile: '绑定手机号码',
    bindMobileTips: 'Tips: 为了您的账号安全，请绑定手机号码',
    bindEmail: '绑定邮箱',
    bindEmailTips: 'Tips: 为了您的账号安全，请绑定邮箱',
    bindMobileFirst: '请先绑定手机号码',
    bindEmailFirst: '请先绑定邮箱',
    setPWD: '设置登录密码',
    setTradePWD: '设置交易密码',
    blockChainTips: '实名认证通过后，可创建区块链账户',
    createSent: '创建请求发送成功',
    days: '天',
    reqNewVer: '为了提供更好的服务，请安装最新版本',
    hadOut: '已退出登录',
    closeOther: '关闭其他',
    closeLeft: '关闭到左侧',
    closeRight: '关闭到右侧',
    closeAll: '全部关闭',
    gltf: 'GLTF体素模型',
    glb: 'GLB体素模型',
    highPng: '高清PNG',
    highJpg: '高清JPG',
    highWebp: '高清WEBP',
    highGif: '高清GIF',
    effectImg: '效果图',
    highEffectImg: '高清效果图',
    effectImgRound: '效果图（圆形）',
    highEffectImgRound: '高清效果图（圆形）',
    downPaperErr: '图纸下载失败，当前系列号最多只能下载{down_limit}次哦！',
    exportInPreview: '请在“导出预览”里导出',
    useClient: '请使用客户端下载该文件',
    cantEdit: '无法编辑该作品',
    copies: '副本',
    workSaveAs: '作品已另存为：',
    saveOk: '保存成功',
    nameTips: '名称18个汉字以内，不能包含特殊字符',
    consumeMoney: '本次消费 {consumeNums} 个晶钻，签到和做任务可以获得晶钻',
    noRightSave: '无权保存他人作品',
    noRights: '无权发布他人作品',
    shareImgCreated: '分享图已生成',
    downTips: '下载提示',
    all: '全部',
    cantOpen: '无法打开该图纸！',
    paperDeled: '选中的图纸已删除！',
    useCountAdded: '恭喜！使用次数已增加！',
    userSn: '使用系列号',
    snPass: '系列号验证通过',
    inputSN: '请输入产品系列号',
    snTips: 'Tips：包装正面刮开二维码可获得系列号',
    recycle: '回收站',
    cantMeger: '锁定的对象不能合并',
    defaultObj: '默认对象',
    newObj: '新的对象',
    copyObj: '复制的对象',
    cutObj: '剪切的对象',
    inputTxt: '请输入文本',
    newScene: '新的场景',
    setCanvasSize: '设置画布尺寸',
    crop: '裁剪',
    exportFail: '导出失败',
    backupTips: '请使用客户端进行备份',
    imgCantLoad: '该图片不支持外部加载',
    inputImgUrl: '请输入图片网址',
    fileErr: '当前文件已损坏，无法打开',
    restoreOk: '从本地恢复成功',
    opening: '正在打开文件...',
    reported: '已举报',
    reason: '提交举报理由',
    reasonTips: '举报理由在4~100个汉字以内',
    wait: '请稍候...',
    actFail: '操作失败，请稍后重试',
    hotESC: '选取部分对象时，按ESC可取消选取',
    hotDEL: '选取部分对象时，按DEL键可快速删除',
    hotEnter: '选取部分对象时，按回车键可快速裁剪',
    hotShift: '按Shift键缩放对象时可锁定比例',
    hotSpace: '按空格键可显示原图',
    hotShiftB: '按Shift+B可快速设置画布背景',
    hotShiftG: '按Shift+G可快速设置网格颜色',
    tool: '工具',
    menu: '菜单',
    other: '其他',
    hadSendSMS: '验证码已下发，请注意查收短信',
    hadSendEmail: '验证码已下发，请注意查收邮件',
    reqVIP: '请先激活VIP资格',
    had: '已',
    setAs: '设为',
    private: '私密',
    public: '公开',
    rejectAudit: '取消审核',
    auditPass: '审核通过',
    unhot: '取消热门',
    hot: '热门',
    notRecommend: '取消推荐',
    recommend: '推荐',
    notOriginal: '取消原创',
    original: '原创',
    powerOnOff: '热荐原开关',
    advertisingGarbage: '广告垃圾',
    violationContent: '违规内容',
    maliciousWatering: '恶意灌水',
    repeatPosting: '重复发帖',
    otherReasons: '其他理由',
    actSuccess: '操作成功',
    pleaseSel: '请选择',
    finish: '完成',
    preStep: '上一步',
    nextStep: '下一步',
    empty: '空',
    hotKeysList: '快捷键列表',
    help: '帮助',
    quickStart: '快速入门',
    feedback: '发送反馈',
    aboutUs: '关于我们',
    checkUpdate: '检查更新',
    resetZoom: '重置缩放',
    view: '视图',
    canvasRatio: '画布比例',
    canvas: '画布',
    moveDown: '向下移动',
    moveUp: '向上移动',
    moveRight: '向右移动',
    moveLeft: '向左移动',
    objPos: '对象位置',
    height: '高度',
    width: '宽度',
    objZoom: '对象缩放',
    objSize: '对象大小',
    triangl: '三角形',
    gridShape: '网格形状',
    clearObj: '清除对象',
    modify: '修改',
    revoke: '撤销',
    redo: '重做',
    clearHistory: '清空历史',
    cut: '剪切',
    paste: '粘贴',
    selectAll: '全选',
    deselect: '取消选择',
    print: '打印',
    import: '导入',
    export: '导出',
    file: '文件',
    draft: '我的草稿',
    localFile: '本地文件',
    customize: '自定义',
    quickOpen: '快速打开像素画',
    pixel2Gridy: '导入像素画',
    pixelImport: '本地像素画',
    quickImport: '快速导入',
    excelDrawing: 'EXCEL表格画',
    puzzleEffect: '拼图效果图',
    puzzleDrawing: '拼图图纸',
    pdfPuzzleDrawing: '拼图图纸(PDF)',
    selectPalette: '请选择调色板',
    object: '对象',
    ifDelete: '确定删除吗？',
    editSceneName: '编辑场景名称',
    scene: '场景',
    zoomOut: '缩小',
    zoomIn: '放大',
    rendin: '渲染中...',
    works: '作品',
    album: '专辑',
    zone: '圈子',
    nft: '数藏',
    discover: '发现',
    aiart: 'AI作画',
    editer: '创作像素画',
    addPost: '发布主题',
    message: '消息',
    pleaseLogin: '请登录',
    task: '任务',
    sign: '签到',
    items: '物品',
    money: '晶币',
    order: '订单',
    vip: 'VIP会员',
    invite: '邀请好友',
    localDrafts: '本地草稿',
    myAlbum: '我的专辑',
    myThread: '我的主题',
    myHomepage: '我的主页',
    myNFT: '我的藏品',
    myFollow: '我的关注',
    myFans: '我的粉丝',
    myGuest: '我的访客',
    myDownload: '我的下载',
    myFavor: '我的收藏',
    myPraise: '我的点赞',
    myFootprint: '我的足迹',
    myProfile: '个人设置',
    myAccount: '账号设置',
    logout: '退出登录',
    ifLogout: '确定退出登录吗？',
    firstColor: '主选色（左键）',
    secondColor: '次选色（右键）',
    exchange: '互换',
    retract: '收起',
    open: '打开',
    home: '首页',
    login: '登录',
    reg: '注册',
    setNick: '设置昵称',
    wechatLogin: '微信登录',
    unameLogin: '用户名登录',
    phoneLogin: '手机号登录/注册',
    emailLogin: '邮箱登录/注册',
    loging: '登录中...',
    reqUname: '请输入用户名',
    reqPWD: '请输入密码',
    reqPhone: '请输入手机号',
    reqEmail: '请输入邮箱',
    reqMcode: '请输入验证码',
    getMcode: '获取验证码',
    qrExpires: '二维码已过期，请点击刷新',
    scanLogin: '请使用微信，扫码登录',
    otherLogin: '其他登录方式',
    agree: '同意',
    agreement: '用户协议',
    privacy: '隐私政策',
    setHeader: '设置头像',
    reqNick: '请输入昵称',
    commit: '提交',
    uploading: '上传中...',
    busy: '太火爆了，请稍后重试',
    setOk: '设置成功',
    reqCorrectPhone: '请输入正确的手机号码',
    reqCorrectEmail: '请输入正确的电子邮箱',
    usernameErr: '用户名为6~18位英文字符，不能包含特殊字符哦',
    PWDErr: '密码为8~20位字符，必须包含字母、数字和特殊字符',
    agreeFirst: '请阅读并同意《用户协议》和《隐私政策》',
    nickErr: '昵称为6~18位字符，不能包含特殊字符哦',
    nickOk: '昵称已设置',
    smsCodeErr: '请输入正确的验证码',
    mergeDown: '向下合并',
    clear: '清除',
    layer: '图层对象',
    blankObj: '添加空对象',
    openLocalImages: '添加本地图片',
    openWebImage: '添加网络图片',
    editObjName: '编辑对象名称',
    grid: '网格',
    createTime: '创建时间',
    canvaSizes: '画布尺寸',
    consumeTime: '创作耗时',
    fixCanvas: '符合画布',
    setCanvas: '画布调整',
    leftBar: '左侧界面',
    rightBar: '右侧界面',
    newFile: '新建...',
    openFile: '打开...',
    save: '保存',
    saveAs: '另存为...',
    cloudSync: '同步到云端',
    rename: '重命名',
    cursor: '指针(V)',
    partSelect: '部分选定(A)',
    fill: '铅笔(P)',
    batchFill: '油漆桶(F)',
    erase: '橡皮擦(E)',
    batchErase: '魔术橡皮擦(M)',
    brush: '笔刷(B)',
    spray: '喷涂(S)',
    line: '直线(L)',
    ellipse: '圆(1)',
    rectangle: '长方形(2)',
    triangle: '三角形(3)',
    star: '星形(4)',
    pick: '滴管(D)',
    curColor: '当前颜色',
    hand: '手型工具(H)',
    txt: '文本(T)',
    select: '选取框(C)',
    resize: '缩放(Q)',
    remove: '移除',
    pickColor: '取色',
    pleaseInput: '请输入...',
    newWork: '新建像素画布',
    localImg: '本地图片',
    webImg: '网络图片',
    gridyFile: 'Gridy文件',
    myWorks: '我的作品',
    drafts: '草稿箱',
    randomPattern: '随机图案',
    recentlyOpened: '最近打开',
    pleaseSelect: '请选择...',
    pubWork: '发布作品',
    submitting: '提交中...',
    catalogue: '目录',
    title: '名称',
    inputTitle: '请输入作品名称',
    introduction: '简介',
    inputIntro: '请输入作品介绍',
    tag: '标签',
    showOut: '展示',
    pubShow: '公开展示',
    share: '分享：',
    shareOut: '分享到圈子',
    hotKeys: '快捷键',
    palette: '调色板',
    standardColor: '标准色',
    moreColors: '更多颜色...',
    CDStart: '距离开始',
    CDStop: '距离结束',
    end: '已结束',
    hour: '时',
    minutes: '分',
    seconds: '秒',
    edit: '编辑',
    flip: '翻转',
    flipH: '水平翻转',
    flipV: '垂直翻转',
    sort: '排列',
    layTop: '置于顶层',
    layBottom: '置于底层',
    layUp: '上移一层',
    layDown: '下移一层',
    align: '对齐',
    alignLeft: '左对齐',
    alignCenter: '水平居中',
    alignRight: '右对齐',
    alignTop: '顶对齐',
    alignMiddle: '垂直居中',
    alignBottom: '底对齐',
    alignSpaceH: '水平等间距',
    alignSpaceV: '垂直等间距',
    group: '组合',
    ungroup: '取消组合',
    merge: '合并',
    lock: '锁定',
    unlock: '解锁',
    copy: '复制',
    objColors: '对象颜色',
    setPalette: '对象调色',
    ifRestoreImage: '确定恢复图像？',
    restoreImage: '恢复图像',
    rotate: '旋转',
    rotateRight: '顺时针旋转90°',
    rotateLeft: '逆时针旋转90°',
    loop: '永久',
    playRate: '播放帧率：{rate}帧/秒',
    playTimes: '播放次数',
    animateSet: '动画设置',
    addScene: '添加场景',
    copyparse: '拷贝',
    delScene: '确定删除该场景吗？',
    play: '播放',
    stop: '停止',
    singlePrinting: '单面印刷',
    backPrinting: '背面印刷',
    twoPrinting: '双面印刷',
    AImages: 'AI图生图',
    exportOrigin: '导出原图',
    create: '创作',
    toEditor: '返回编辑器',
    colorCount: '颜色数量',
    changeColor: '更换颜色',
    deleteColor: '去除颜色',
    restoreAll: '确定恢复到初始状态吗？',
    restore: '恢复',
    numLimit: '限量{total}份',
    preemptiveRights: '优先购买',
    payWin: '立即支付(中签)',
    notWin: '未中签',
    subscribed: '已申购(待抽签)',
    wantSubscribed: '我要申购',
    sellOut: '已售罄',
    waitingDraw: '申购成功，请等待抽签',
    castOk: '恭喜，已成功铸造',
    claimed: '恭喜，空投领取成功',
    waiting: '还没到开放时间',
    pay: '支付',
    casting: '铸造',
    favorOk: '恭喜，收藏成功',
    addAlbum: '加入专辑',
    buyIt: '立即购买',
    purchased: '已购买',
    copyLink: '复制分享链接',
    secondCreation: '二次创作',
    wantCast: '我要铸造',
    alreadyCast: '已铸造',
    claimAirdrop: '领取空投',
    received: '已领取',
    cantBuy: '暂时还不能购买！您可以先导出图纸！',
    toning: '调色',
    primaryColors: '原色',
    simplifiedColors: '精简色',
    shape: '形状',
    size: '大小',
    pre: '往后',
    next: '往前',
    originImage: '原图',
    savePaperLimit: '图纸保存失败，当前系列号最多只能保存{save_limit}副哦！',
    savePaperFail: '图纸保存失败，请返回重试！',
    savePaperOk: '图纸已保存！在“我的图纸”里可以找到哦！',
    createPuzzle: '定制拼图',
    downPaperTips: '下载图纸打印出来拼',
    downPaper: '下载图纸',
    nextPage: '下一页',
    prePage: '上一页',
    savePaperTips: '保存图纸下次再拼',
    savePaper: '保存图纸',
    rending: 'AI渲染中，请耐心等待...',
    paySuccess: '支付成功',
    buy: '购买',
    payed: '已支付',
    orderExpires: '订单已过期',
    orderCancel: '订单已取消',
    orderClosed: '订单已关闭',
    prompt: '提示',
    sure: '确定',
    refuse: '取消',
    ok: '确 定',
    cancel: '取 消',
    canvaWidth: '画布宽度',
    canvaHeight: '画布高度',
    canvaSize: '请选择画布尺寸',
    va: '明度',
    sa: '饱和度',
    hu: '色相',
    quantFilter: '量化过滤',
    mergeColors: '合并相似色',
    autoDenoise: '自动去杂色',
    tips: '说明',
    lastDigits: '尾号',
    selectCard: '请选择银行卡',
    bCard: '银行卡',
    bankCard: '银行卡号',
    inputAmount: '请输入提现金额',
    withdrawalAmount: '提现金额为',
    canWithdrawal: '最多可提现',
    amountLess: '余额不足',
    withDrawalFee: '每天提现1次，手续费',
    selectPCD: '请选择省市区',
    selectYMD: '请选择年月日',
    year: '年',
    month: '月',
    day: '日',
    tradePwd: '请输入交易密码',
    recharge: '请充值',
    tradePwdError: '请输入正确的交易密码',
    moneyLess: '晶币不足，请充值',
    moneyPay: '晶币支付',
    scanPay: '请用微信扫码支付',
    wechatPay: '微信支付',
    castingFail: '材料不够，无法铸造',
    materials: '本次铸造需要',
    remaining: '余',
    close: '关闭',
    exportPreview: '导出预览',
    customizedPuzzle: '定制拼图',
    exportMainImage: '导出主图',
    exportPreviewImage: '导出预览图',
    mainImage: '主图',
    previewImage: '预览效果图',
    pixel: '像素画',
    gridy: '百格画',
    puzzle: '拼图',
    exportPixel: '导出像素画',
    exportGridy: '导出百格画',
    exportPuzzle: '导出拼图...',
    advancedExport: '高级导出...',
    publish: '发布',
    createShareImage: '生成分享图',
    shareMoments: '导出效果图发朋友圈',
    onWall: '挂到墙上看看效果',
    quitePreview: '退出预览',
    preview: '预览',
    ifAdvancedMode: '确定进入高级创作模式吗？',
    advancedMode: '高级模式',
    round: '圆形',
    square: '方形',
    brickShape: '颗粒形状',
    manageColors: '颜色管理',
    change: '更换',
    delete: '删除',
    hide: '隐藏',
    show: '显示',
    moreThen: '大于',
    noiseReduction: '降噪',
    positive: '正序',
    reverse: '倒序',
    color: '色',
    particle: '颗粒',
    colors: '颜色',
    hue: '色相/饱和度',
    frame: '相框',
    default: '默认',
    bg: '背景',
    picSize: '画面大小',
    getDrawings: '获取图纸',
    buyNow: '我要购买',
    uploadPhoto: '上传照片',
    lookBack: '往后',
    ahead: '往前',
    back: '返回',
    more: '更多',
    pdfPaper: '图纸(PDF)',
    paper: '图纸',
    sn: '系列号'
  },
  paletteName: {
    base: '主题色',
    brickfy: '百乐酷',
    brickfyColors: '多彩',
    skin: '肤色',
    gray: '灰色',
    metals: '金属'
  },
  style: {
    default: '',
    dance: '跳动款',
    classic: '经典款',
    pink: '粉红款',
    golden: '金黄款',
    colors: '人像'
  },
  colorfy: {
    dance: '跳动',
    classic: '经典',
    pink: '粉红',
    golden: '金黄',
    colors: '人像'
  },
  extendPalettes: {
    BRICK: '百乐酷',
    HDD: '黄豆豆',
    KK: '卡卡',
    MARD: 'MARD',
    MM: '漫漫',
    MXW: '咪小窝',
    PP: '盼盼',
    XW: '小舞'
  },
  shapes: {
    'line': '直线',
    'ellipse': '圆',
    'rectangle': '矩形',
    'triangle': '三角形',
    'star': '星形',
    'heart': '心形'
  },
  errMsg: {
    'BAD_REQUEST': '请求异常，请联系客服',
    'UNAUTHORIZED': '请登录',
    'REQUEST_DENIED': '本次请求被拒绝',
    'NOT_FOUND': '未找到数据',
    'METHOD_NOT_ALLOWED': '不支持的请求方式',
    'NOT_ACCEPTABLE': '不可接受的请求',
    'UNSUPPORTED_MEDIA_TYPE': '不可接受的请求',
    'REQUEST_RATE_LIMITED': '请勿频繁刷新，请稍后重试',
    'INTERNAL_SERVER_ERROR': '请求出错，请联系客服',
    'BAD_GATEWAY': '网关出错，请联系客服',
    'SERVICE_UNAVAILABLE': '请求不可用，请联系客服'
  },
  guideSteps: [
    {
      element: '.my-menu',
      popover: {
        title: '开始',
        description: '在"文件"中选择"新建..."',
        position: 'bottom'
      }
    },
    {
      element: '.my-link-add',
      popover: {
        title: '导入网络图片',
        description: '通过网址导入图片后，将自动生成网格画',
        position: 'left'
      }
    },
    {
      element: '.my-object-add',
      popover: {
        title: '导入本地图片',
        description: '选择导入本地图片后，将自动生成网格画',
        position: 'left'
      }
    },
    {
      element: '.my-blank-object-add',
      popover: {
        title: '添加空对象',
        description: '添加空对象后可以自由创作',
        position: 'left'
      }
    },
    {
      element: '.left-side',
      popover: {
        title: '选择工具',
        description: '请选择作图工具进行艺术创作',
        position: 'right'
      }
    },
    {
      element: '.work-area',
      popover: {
        title: '开始艺术创作',
        description: '单击左键或右键开始作画，也可以按住左键或右键连续作画',
        position: 'right'
      }
    }
  ]
}
