<template>
  <el-dialog :visible.sync="config.show" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" class="my-dialog my-dialog-small megbox-fade-enter-active my-popform" :top="'20vh'">
    <span slot="title">
      <span class="el-dialog__title" :class="config.classname">{{ config.title }}</span>
      <i class="iconfont my-close title-btn" :title="$t('i.close')" @click="() => (config.show = false)" />
    </span>
    <el-card v-loading="config.loading" shadow="never" class="login-card" element-loading-background="rgba(0, 0, 0, 0.6)">
      <span v-if="!config.type">
        <el-input v-if="config.rows > 1" v-model="config.content" type="textarea" :maxlength="config.maxlength || 256" class="my-from-item" :rows="config.rows" :placeholder="config.placeholder" />
        <el-input v-if="config.rows === 1" v-model="config.content" type="input" :maxlength="config.maxlength || 256" class="my-from-item" :rows="config.rows" :placeholder="config.placeholder" clearable />
      </span>
      <div v-if="config.type === 'compound' && compound.count" class="line-height-15">
        &nbsp; &nbsp; {{ $t('i.materials') }}<br>
        <ul>
          <li v-for="(item, idx) in compound.items" v-show="compound.item_types[item.item_typeid] && compound.item_types[item.item_typeid].name" :key="idx">
            {{ compound.item_types[item.item_typeid].name + ' x' + item.nums }}
            <span v-if="!compound.items_quantity[item.item_typeid] || item.nums > compound.items_quantity[item.item_typeid]" class="red">({{ $t('i.remaining') }}{{ compound.items_quantity[item.item_typeid] || 0 }})</span>
            <span v-if="compound.items_quantity[item.item_typeid] && item.nums <= compound.items_quantity[item.item_typeid]" class="green">({{ $t('i.remaining') }}{{ compound.items_quantity[item.item_typeid] || 0 }})</span>
          </li>
        </ul>
      </div>
      <div v-if="config.type === 'payment_nft' || config.type === 'payment_brick' || config.type === 'payment_charge' || config.type === 'payment_orders'" class="line-height-15">
        <div v-if="order.amount" class="my-row align-center size24">{{ $t('currency') }}{{ formateMoney(order.amount) }}</div>
        <div class="my-row payment">
          <div v-if="!isDesktop && !showQrcode" class="padding-bottom10" :class="{'color-ee': pay_type !== 2, 'blue': pay_type === 2 }" @click="selectPayType(2)"><i class="iconfont my-ok" :class="{'color-99': pay_type !== 2, 'blue': pay_type === 2 }" /> {{ $t('i.wechatPay') }}</div>
          <div v-if="(isDesktop || showQrcode) && qrcode" class="align-center">
            <img :src="qrcode" style="width: 160px; height: 160px;">
            <div class="padding-top12">{{ $t('i.scanPay') }}</div>
          </div>
          <div v-if="!showQrcode && config.type !== 'payment_charge'" class="padding-bottom10">
            <span :class="{'color-ee': pay_type !== 1, 'blue': pay_type === 1 }" @click="selectPayType(1)"><i class="iconfont my-ok" :class="{'color-99': pay_type !== 1, 'blue': pay_type === 1 }" /> {{ $t('i.moneyPay') }}</span>
            <span v-if="wallet.user_id" class="float-right padding4" :class="{'red': order.amount > wallet.available_amount * 100, 'green': order.amount <= wallet.available_amount * 100}" @click="gotoWallet">{{ $t('i.remaining') }}{{ formateMoney(wallet.available_amount * 100) }} <span v-if="order.amount > wallet.available_amount * 100">{{ $t('i.recharge') }}</span></span>
          </div>
          <div v-if="pay_type === 1" class="color-99">
            <el-input v-model.trim="paypassword" type="password" maxlength="6" :placeholder="$t('i.tradePwd')" clearable />
          </div>
        </div>
      </div>
      <div v-if="config.type === 'area'" class="line-height-15 padding8">
        <el-form ref="formWidget" v-loading="config.loading" label-width="10px" :inline="false" :model="form" element-loading-background="rgba(0, 0, 0, 0.6)">
          <v-distpicker :province="form.province" :city="form.city" :area="form.district" @change="onChangeArea" />
        </el-form>
      </div>
      <div v-if="config.type === 'date'" class="line-height-15 padding8">
        <el-form ref="formWidget" v-loading="config.loading" label-width="10px" class="" :inline="false" :model="form" element-loading-background="rgba(0, 0, 0, 0.6)">
          <div class="input-row">
            <el-select v-model="form.year" name="year" :placeholder="$t('i.year')">
              <el-option v-for="(v, i) in new Array(51)" :key="1960 + i" :value="1960 + i" />
            </el-select>
            <el-select v-model="form.month" name="month" :placeholder="$t('i.month')">
              <el-option v-for="(v, i) in new Array(12)" :key="1 + i" :value="padding(1 + i, 2)" />
            </el-select>
            <el-select v-model="form.day" name="day" :placeholder="$t('i.day')">
              <el-option v-for="(v, i) in new Array(31)" :key="1 + i" :value="padding(1 + i, 2)" />
            </el-select>
          </div>
        </el-form>
      </div>
      <div v-if="config.type === 'withdraw'" class="line-height-15">
        <el-form ref="formWidget" v-loading="config.loading" class="my-withdraw-form" label-width="80px" :inline="false" :model="form" element-loading-background="rgba(0, 0, 0, 0.6)">
          <el-form-item :label="$t('i.withdrawalAmount')" class="margin-top12">
            <el-input v-model.number="form.amount" maxlength="5" :placeholder="$t('i.inputAmount')" clearable />
          </el-form-item>
          <el-form-item :label="$t('i.bankCard')">
            <el-select v-model="form.bankcardid" :placeholder="$t('i.selectCard')" style="padding: 0px;">
              <el-option v-for="item in bankcards" :key="item.value" :label="item.bank + ' (' + $t('i.lastDigits') + item.account.substr(-4) + ')'" :value="item.bankcardid" />
            </el-select>
          </el-form-item>
          <!-- <el-form-item :label="$t('i.tradePWD')">
            <el-input v-model.number="form.password" type="password" maxlength="6" placeholder="请输入交易密码" clearable />
          </el-form-item> -->
          <el-form-item :label="$t('i.tips')" class="color-99 margin-bottom4">
            <span v-html="getWithdrawTip()" />
          </el-form-item>
        </el-form>
      </div>
      <div v-if="config.type === 'denoise'" class="line-height-15">
        <el-row>
          <el-col :span="12" class="padding-left12 line-height-20">{{ $t('i.autoDenoise') }}</el-col>
          <el-col :span="12" class="align-right padding-right8">
            <el-input-number v-model="state.denoiseFactor" class="my-input-number" :style="{ width: '102px', 'text-align': 'center' }" :min="0" :max="100" size="mini" />
          </el-col>
        </el-row>
        <el-row>
          <el-slider
            v-model="state.denoiseFactor"
            :min="0"
            :max="50"
            :step="1"
            :show-tooltip="false"
            style="margin: 0 12px"
          />
        </el-row>
        <el-row>
          <el-col :span="12" class="padding-left12 line-height-20">{{ $t('i.mergeColors') }}</el-col>
          <el-col :span="12" class="align-right padding-right8">
            <el-input-number v-model="state.mergerFactor" class="my-input-number" :style="{ width: '102px', 'text-align': 'center' }" :min="0" :max="100" size="mini" />
          </el-col>
        </el-row>
        <el-row>
          <el-slider
            v-model="state.mergerFactor"
            :min="0"
            :max="30"
            :step="1"
            :show-tooltip="false"
            style="margin: 0 12px"
          />
        </el-row>
        <!-- <el-row>
          <el-col :span="12" class="padding-left12 line-height-20">{{ $t('i.quantFilter') }}</el-col>
          <el-col :span="12" class="align-right padding-right8">
            <el-input-number v-model="state.quantizeFactor" class="my-input-number" :style="{ width: '102px', 'text-align': 'center' }" :min="0" :max="100" size="mini" />
          </el-col>
        </el-row>
        <el-row>
          <el-slider
            v-model="state.quantizeFactor"
            :min="0"
            :max="100"
            :step="1"
            :show-tooltip="false"
            style="margin: 0 12px"
          />
        </el-row> -->
      </div>
      <div v-if="config.type === 'colorFilter'" class="line-height-15">
        <el-row>
          <el-col :span="12" class="padding-left12 line-height-20">{{ $t('i.hu') }}(H)</el-col>
          <el-col :span="12" class="align-right padding-right8">
            <el-input-number v-model="state.colorFilter.hue" class="my-input-number" :style="{ width: '102px', 'text-align': 'center' }" :min="-180" :max="180" size="mini" />
          </el-col>
        </el-row>
        <el-row>
          <el-slider
            v-model="state.colorFilter.hue"
            :min="-180"
            :max="180"
            :step="1"
            :show-tooltip="false"
            style="margin: 0 12px"
          />
        </el-row>
        <el-row>
          <el-col :span="12" class="padding-left12 line-height-20">{{ $t('i.sa') }}(S)</el-col>
          <el-col :span="12" class="align-right padding-right8">
            <el-input-number v-model="state.colorFilter.saturation" class="my-input-number" :style="{ width: '102px', 'text-align': 'center' }" :min="-100" :max="100" size="mini" />
          </el-col>
        </el-row>
        <el-row>
          <el-slider
            v-model="state.colorFilter.saturation"
            :min="-100"
            :max="100"
            :step="1"
            :show-tooltip="false"
            style="margin: 0 12px"
          />
        </el-row>
        <el-row>
          <el-col :span="12" class="padding-left12 line-height-20">{{ $t('i.va') }}(V)</el-col>
          <el-col :span="12" class="align-right padding-right8">
            <el-input-number v-model="state.colorFilter.value" class="my-input-number" :style="{ width: '102px', 'text-align': 'center' }" :min="-100" :max="100" size="mini" />
          </el-col>
        </el-row>
        <el-row>
          <el-slider
            v-model="state.colorFilter.value"
            :min="-100"
            :max="100"
            :step="1"
            :show-tooltip="false"
            style="margin: 0 12px"
          />
        </el-row>
        <!-- <el-row>
          <el-col :span="12" class="padding-left12 line-height-20">亮度(B)</el-col>
          <el-col :span="12" class="align-right padding-right8">
            <el-input-number v-model="state.colorFilter.brightness" class="my-input-number" :style="{ width: '102px', 'text-align': 'center' }" :min="-128" :max="128" size="mini" />
          </el-col>
        </el-row>
        <el-row>
          <el-slider
            v-model="state.colorFilter.brightness"
            :min="-128"
            :max="128"
            :step="1"
            :show-tooltip="false"
            style="margin: 0 12px"
          />
        </el-row>
        <el-row>
          <el-col :span="12" class="padding-left12 line-height-20">对比度(C)</el-col>
          <el-col :span="12" class="align-right padding-right8">
            <el-input-number v-model="state.colorFilter.contrast" class="my-input-number" :style="{ width: '102px', 'text-align': 'center' }" :min="-128" :max="128" size="mini" />
          </el-col>
        </el-row>
        <el-row>
          <el-slider
            v-model="state.colorFilter.contrast"
            :min="-128"
            :max="128"
            :step="1"
            :show-tooltip="false"
            style="margin: 0 12px"
          />
        </el-row> -->
      </div>
      <div v-if="config.type === 'setCanvasSize'" class="line-height-15">
        <el-row>
          <el-popover v-model="data.showPopover" placement="bottom" :width="260" popper-class="layer-popover" trigger="click">
            <el-row v-for="ratio in data.ratio" :key="ratio.label" :label="ratio.label" class="padding-left8 padding-right8 hover-bg-blue">
              <div @click="setDataAttr('ratioDt', ratio)">
                <el-col :span="12">
                  <i class="iconfont size24 margin-right12 margin-left4" :class="ratio.icon" /><span class="size16">{{ ratio.label }}</span>
                </el-col>
                <el-col :span="12" class="color-cc align-right size12 padding-top12 padding-right8">{{ ratio.tip }}</el-col>
              </div>
            </el-row>
            <div slot="reference" class="border-28 padding8 margin-top8 margin-left8 margin-bottom12 bg-28 round4 hover-bg-33" style="margin-right: 8px;height: 40px;">
              <span v-if="data.ratioDt.id">
                <i class="iconfont size18 margin-right12 margin-left4" :class="data.ratioDt.icon" /><span class="size16">{{ data.ratioDt.label }}</span>
              </span>
              <span v-if="!data.ratioDt.id" class="padding-left4 color-ee">{{ $t('i.canvaSize') }}</span>
              <i class="el-icon-arrow-down el-icon--right float-right margin-right4 margin-top4 color-ee" />
            </div>
          </el-popover>
        </el-row>
        <el-row>
          <el-col :span="12" class="padding-left12 line-height-20">{{ $t('i.canvaWidth') }}</el-col>
          <el-col :span="12" class="align-right padding-right8">
            <el-input-number v-model="data.ratioDt.cols" class="my-input-number" :style="{ width: '102px', 'text-align': 'center' }" :min="4" :max="state.maxCols" size="mini" />
          </el-col>
        </el-row>
        <el-row>
          <el-slider
            v-model="data.ratioDt.cols"
            :min="4"
            :max="state.maxCols"
            :step="1"
            :show-tooltip="false"
            style="margin: 0 12px"
            @change="data.ratioDt.id = ''"
          />
        </el-row>
        <el-row>
          <el-col :span="12" class="padding-left12 line-height-20">{{ $t('i.canvaHeight') }}</el-col>
          <el-col :span="12" class="align-right padding-right8">
            <el-input-number v-model="data.ratioDt.rows" class="my-input-number" :style="{ width: '102px', 'text-align': 'center' }" :min="4" :max="state.maxRows" size="mini" />
          </el-col>
        </el-row>
        <el-row>
          <el-slider
            v-model="data.ratioDt.rows"
            :min="4"
            :max="state.maxRows"
            :step="1"
            :show-tooltip="false"
            style="margin: 0 12px"
            @change="data.ratioDt.id = ''"
          />
        </el-row>
      </div>
      <div v-if="config.tip" class="red padding-left8">{{ config.tip }}</div>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <div v-if="!showQrcode">
        <el-button size="small" @click="config.show = false">{{ $t('i.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="() => submit()">{{ $t('i.ok') }}</el-button>
      </div>
    </span>
  </el-dialog>
</template>
<script>
import conf from '@/js/conf/conf'
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import service from '@/js/service'
import VDistpicker from 'v-distpicker'
export default {
  components: {
    VDistpicker
  },
  mixins: [mixins],
  props: {},
  data() {
    const form = {
      year: '',
      month: '',
      day: '',
      area: {},
      province: '',
      city: '',
      district: '',
      amount: '',
      password: '',
      bankcardid: ''
    }
    const data = {}
    data.apiHost = conf.hosts().apiHost
    data.form = form
    // 支付方式；1=钱包晶币支付; 2=微信支付; 12=支付宝支付;
    data.pay_type = 0
    data.paypassword = ''
    data.bankcards = []
    data.wallet = {}
    data.showQrcode = false
    data.qrcode = ''
    data.order = {}
    data.compound = {}
    data.settings = {}
    data.itv = null
    return data
  },
  computed: {
    config() {
      return this.view.pop
    },
    data() {
      return this.config.data
    }
  },
  mounted() {
    this.view.pop.loading = false
    this.pay_type = 0
    this.paypassword = ''
    this.wallet = {}
    this.showQrcode = false
    if (this.view.pop.show && this.view.pop.type) {
      this.loadData()
    }
    if (this.itv) {
      clearInterval(this.itv)
      this.itv = null
    }
    if (this.view.pop.type === 'date') {
      this.form.year = this.view.pop.data.year || ''
      this.form.month = this.view.pop.data.month || ''
      this.form.day = this.view.pop.data.day || ''
    } else if (this.view.pop.type === 'area') {
      this.form.province = this.view.pop.data.province || ''
      this.form.city = this.view.pop.data.city || ''
      this.form.district = this.view.pop.data.district || ''
    }
  },
  methods: {
    padding: utils.padding,
    formateMoney: utils.formateMoney,
    setDataAttr(k, v) {
      this.data[k] = v
      setTimeout(() => {
        this.data.showPopover = false
      }, 10)
    },
    gotoWallet() {
      this.goto('wallet')
      this.config.show = false
    },
    getQrcode() {
      let type, orderid
      if (this.config.type === 'payment_nft') {
        type = 'nft'
        orderid = this.data.nft_orderid
      } else if (this.config.type === 'payment_brick') {
        type = 'brick'
        orderid = this.data.brick_orderid
      } else if (this.config.type === 'payment_charge') {
        type = 'charge'
        orderid = this.data.charge_orderid
      } else if (this.config.type === 'payment_orders') {
        type = 'orders'
        orderid = this.data.orderid
      } else {
        return
      }
      service.actionGet('qrcode', { type: type, orderid: orderid }, (dt, type) => {
        if (type === 'success' && dt.data && dt.data.url) {
          this.qrcode = dt.data.url
          this.update()
          // 轮询订单状态
          this.itv = setInterval(this.getOrder, 5000)
        }
      })
    },
    getOrder() {
      // console.error('xxxxxx')
      if (!this.view.pop.show) {
        if (this.itv) {
          clearInterval(this.itv)
          this.itv = null
        }
        return
      }
      let table, data
      if (this.config.type === 'payment_nft') {
        table = 'nft_order'
        data = { nft_orderid: this.data.nft_orderid }
      } else if (this.config.type === 'payment_brick') {
        table = 'brick_order'
        data = { brick_orderid: this.data.brick_orderid }
      } else if (this.config.type === 'payment_charge') {
        table = 'charge_order'
        data = { charge_orderid: this.data.charge_orderid }
      } else if (this.config.type === 'payment_orders') {
        table = 'orders'
        data = { orderid: this.data.orderid, simple: 1 }
      } else {
        return
      }
      service.actionGet(table, data, (dt, type) => {
        if (type === 'success') {
          if (dt && dt.data && dt.data.count) {
            this.order = dt.data.items[0]
            if (this.order.pay_status < 0) {
              this.alert(this.order.pay_status === -3 ? this.$t('i.orderExpires') : (this.order.pay_status === -2 ? this.$t('i.orderCancel') : this.$t('i.orderClosed')))
              this.config.show = false
              if (this.config.fn) this.config.fn(false, this.order.pay_status)
            } else if (this.order.pay_status === 1) {
              this.alert(this.$t('i.payed'))
              this.config.show = false
              if (this.config.fn) this.config.fn(true)
            }
          }
        } else {
          this.message(dt, type)
        }
        this.update()
      }, true)
    },
    getWithdrawTip() {
      const tips = []
      const sets = this.settings
      if (sets.cash_min_sum && sets.cash_max_sum) {
        tips.push('· ' + this.$t('i.withdrawalAmount') + ' ' + sets.cash_min_sum + ' ~ ' + sets.cash_max_sum)
      }
      if (sets.cash_rate && sets.cash_interval_time) {
        tips.push('· ' + this.$t('i.withDrawalFee') + ' ' + (sets.cash_rate * 100) + '%')
      }
      return tips.join('<br>')
    },
    // 获取配置信息
    getSettings(tag) {
      this.settings = {}
      service.get('settings', tag, (dt, type) => {
        if (type === 'success' && dt && Array.isArray(dt.data) && dt.data.length) {
          for (var i in dt.data) {
            this.settings[dt.data[i].key] = dt.data[i].value
          }
          this.update()
        }
      }, false, '', true)
    },
    // 获取晶币
    getWallet() {
      this.wallet = {}
      if (!this.loginUserId) return
      service.get('wallet', this.loginUserId, (dt, type) => {
        if (type === 'success') {
          if (dt && dt.data && dt.data.user_id && dt.data.user_id === this.loginUserId) {
            this.wallet = dt.data
          }
        }
        this.update()
      }, true)
    },
    getBankcard() {
      const params = {
        'sort_field': 'isdefault',
        'sort': 'desc',
        'limit': 20,
        'direction': 'down',
        'simple': 1
      }
      this.bankcards = {}
      service.listGet('bankcard', params, (dt, type) => {
        if (type === 'success' && dt && dt.data && dt.data.count) {
          this.bankcards = dt.data.items
        }
      })
    },
    loadData() {
      if (this.config.type === 'compound') {
        this.compound = {}
        service.actionGet('item_type', { type: 'stuff', id: this.data.item_typeid }, (dt, type) => {
          if (type === 'success') {
            if (dt && dt.data) this.compound = dt.data
          } else {
            this.message(dt, type)
          }
          this.update()
        }, true)
      } else if (this.config.type === 'payment_nft' || this.config.type === 'payment_brick' || this.config.type === 'payment_charge' || this.config.type === 'payment_orders') {
        this.qrcode = ''
        this.order = {}
        this.getOrder()
        if (this.isDesktop) this.getQrcode()
        if (!this.isDesktop) this.pay_type = (this.config.type === 'payment_charge' || this.config.type === 'payment_orders') ? 2 : 1
        if (this.config.type === 'payment_nft' || this.config.type === 'payment_brick' || this.config.type === 'payment_orders') this.getWallet()
      } else if (this.config.type === 'withdraw') {
        this.getWallet()
        this.getBankcard()
        this.getSettings('cash')
      }
    },
    selectPayType(pay_type) {
      this.pay_type = pay_type
    },
    onChangeArea(data) {
      this.form.area = data
    },
    submit() {
      if (this.config.type === 'setCanvasSize') {
        this.emit('setCanvasSize', [this.data.ratioDt.cols, this.data.ratioDt.rows])
        this.config.show = false
      } else if (this.config.type === 'denoise' || this.config.type === 'colorFilter') {
        this.config.show = false
        if (this.config.fn) setTimeout(this.config.fn, 100)
      } else if (this.config.type === 'date') {
        if (!this.form.year || !this.form.month || !this.form.day) {
          this.alert(this.$t('i.selectYMD'))
        } else {
          if (this.config.fn) this.config.fn({ year: this.form.year, month: this.form.month, day: this.form.day })
          this.config.show = false
        }
      } else if (this.config.type === 'area') {
        if (!this.form.area.province.code || !this.form.area.city.code || !this.form.area.area.code) {
          this.alert(this.$t('i.selectPCD'))
        } else {
          if (this.config.fn) this.config.fn(this.form.area)
          this.config.show = false
        }
      } else if (this.config.type === 'compound') {
        // 判断是否满足条件
        if (this.compound.count) {
          for (var idx in this.compound.items) {
            const item = this.compound.items[idx]
            if (!this.compound.items_quantity[item.item_typeid] || item.nums > this.compound.items_quantity[item.item_typeid]) {
              this.alert(this.$t('i.castingFail'))
              this.config.show = false
              return
            }
          }
        }
        if (this.config.fn) this.config.fn()
      } else if (this.config.type === 'payment_nft' || this.config.type === 'payment_brick' || this.config.type === 'payment_charge' || this.config.type === 'payment_orders') {
        let type, orderid, desc
        if (this.config.type === 'payment_brick') {
          type = 'brick'
          orderid = this.data.brick_orderid
          desc = ''
        } else if (this.config.type === 'payment_nft') {
          type = 'nft'
          orderid = this.data.nft_orderid
          desc = ''
        } else if (this.config.type === 'payment_orders') {
          type = 'orders'
          orderid = this.data.orderid
          desc = this.$t('i.buy') + this.data.name
        } else if (this.config.type === 'payment_charge') {
          type = 'charge'
          orderid = this.data.charge_orderid
          desc = ''
        }
        service.incCount(this.loginUserId, this.config.type)
        // pay_type 支付方式；-1=无需支付; 0=金币支付; 1=钱包晶币支付; 2=微信支付; 12=支付宝支付;
        if (this.pay_type === 2) {
          if (!this.isDesktop) {
            // 转向H5支付页面
            let url = this.apiHost + 'pay/' + type + '/' + orderid
            const params = utils.getHashParams()
            Object.keys(params).map((k) => {
              if (!params[k]) params[k] = 0
              url = url + '/' + k + '/' + params[k]
            })
            utils.open(url)
          } else {
            // 显示二维码
            this.showQrcode = true
            this.getQrcode()
          }
        } else if (this.pay_type === 1) {
          if (this.order.amount > this.wallet.available_amount * 100) {
            this.alert(this.$t('i.moneyLess'))
            return
          }
          if (!utils.checkNow('paypassword', this.paypassword, true)) {
            this.alert(this.$t('i.tradePwdError'))
          } else {
            service.actionPost('wallet', { type: type + '_pay', id: orderid, paypassword: this.paypassword, desc: desc }, (dt, type) => {
              if (type === 'success') {
                this.alert(this.$t('i.paySuccess'))
                this.config.show = false
                if (this.config.fn) this.config.fn(true)
              } else {
                this.message(dt, type)
              }
            }, true)
          }
        }
      } else {
        if (this.config.fn) this.config.fn()
      }
    },
    withdraw() {
      if (!this.form.amount || !utils.checkNow('int', this.form.amount, true)) {
        this.alert(this.$t('i.inputAmount'))
      } else if (this.form.amount < parseInt(this.settings.cash_min_sum) && this.form.amount > parseInt(this.settings.cash_max_sum)) {
        this.alert(this.$t('i.withdrawalAmount') + ' ' + this.settings.cash_min_sum + ' ~ ' + this.settings.cash_max_sum + '')
      } else if (this.form.amount > this.wallet.available_amount) {
        if (!this.wallet.available_amount) {
          this.alert(this.$t('i.amountLess'))
        } else {
          this.alert(this.$t('i.canWithdrawal') + ' ' + this.wallet.available_amount)
        }
      } else if (!this.form.bankcardid) {
        this.alert(this.$t('i.selectCard'))
      // } else if (!utils.checkNow('paypassword', this.form.password, true)) {
      //   this.alert(this.$t('i.tradePwdError'))
      } else {
        let bankcard = {}
        for (var k in this.bankcards) {
          if (this.bankcards[k].bankcardid === this.form.bankcardid) {
            bankcard = this.bankcards[k]
            break
          }
        }
        const account = this.$t('i.bCard') + ': ' + bankcard.bank + ' ' + bankcard.name + '; ' + this.$t('i.bankCard') + ': ' + bankcard.account
        service.withdraw(this.form.amount, account, this.form.bankcardid, (dt, type) => {
          if (type === 'success') {
            this.config.show = false
            if (this.config.fn) this.config.fn(true)
          } else {
            if (type === 'login') {
              this.message(dt, type)
            } else {
              this.alert(dt)
            }
          }
        })
      }
    }
  }
}
</script>
