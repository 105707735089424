<template>
  <el-dialog
    ref="resourceDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll resourceDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        <span v-if="!config.tabs || !config.tabs.length">{{ title }}</span>
        <el-button-group v-if="config.tabs && config.tabs.length">
          <el-button v-for="(tab, i) in config.tabs" :key="tab[0] + i" size="small" :type="config.tab === tab[0] ? 'plain' : 'primary'" @click="config.tab = tab[0]">{{ tab[1] }}</el-button>
        </el-button-group>
      </div>
      <div class="float-right" :class="{'padding-right4' : selectMod && !canDelete}">
        <el-button v-if="selectMod" :class="{'tap-btn': canDelete, 'txt-btn': !canDelete}" @click="deleteItems()">
          <i v-if="canDelete" class="iconfont my-delete size20 blue" />
          <span v-if="!canDelete" class="blue">{{ $t('i.refuse') }}</span>
        </el-button>
        <el-button v-if="!selectMod && table !== 'paint'" class="tap-btn iconfont my-add size20" @click="addResource()" />
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <resource-list
        v-show="config.show"
        ref="resource-all"
        mod="all"
        :db="db"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        @receive="handle"
      />
    </div>
    <div v-if="table === 'draft' || table === 'paint'" slot="footer" class="footer padding-top8" style="position:absolute;">
      <el-button v-if="config.tab === 'draft'" type="primary" class="btn-cancel" style="max-width:200px;" @click="openLocalFile">
        {{ $t('i.localRestore') }}
      </el-button>
      <el-button v-if="config.tab === 'draft'" type="light" class="btn-ok" style="max-width:200px;" @click="emit('exportDraft')">
        {{ $t('i.localBackup') }}
      </el-button>
      <el-button v-if="config.tab === 'recycle'" type="light" class="btn-ok" style="max-width:200px;" @click="clearRecycle">
        {{ $t('i.cleanRecycle') }}
      </el-button>
      <div v-if="table === 'paint'" class="input padding-top8" :style="{'position': isDesktop ? 'absolute' : ''}">
        <!-- <el-button type="yellow" class="btn-ok" style="max-width:200px;" @click="emit('openPopPage', [{ 'type': 'toCartoon' }])">
          {{ $t('i.aiCartoon') }}
        </el-button> -->
        <el-button type="light" class="btn-ok" style="max-width:200px;" @click="emit('openAiPaint')">
          {{ $t('i.startCreate') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// import utils from '@/js/utils'
import resourceList from '@/components/web/resourceList'
import { mixins } from '@/mixins/common'
import service from '@/js/service'
export default {
  components: {
    resourceList
  },
  mixins: [mixins],
  data() {
    return {
      canDelete: false,
      selectMod: false,
      selectIds: {}
    }
  },
  computed: {
    config() {
      return this.view.resource
    },
    table() {
      return this.config.table
    },
    act() {
      return this.config.act
    },
    keyfield() {
      return this.config.keyfield || this.table + 'id'
    },
    resourceNames() {
      return this.view.resourceNames()
    },
    title() {
      return this.resourceNames[this.table]
    }
  },
  watch: {
    // 'view.resource.show': {
    //   handler() {
    //     this.init()
    //     this.initView()
    //   }
    // },
    'view.resource.type': {
      handler() {
        this.init()
      }
    },
    'view.resource.renew': {
      handler() {
        this.renewView()
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    // 清空回收站
    clearRecycle() {
      this.confirm(this.$t('i.ifClean'), (action) => {
        if (action === 'confirm') {
          this.emit('clearRecycle', () => {
            this.renewView()
          })
        }
      })
    },
    openLocalFile() {
      const fn = () => {
        setTimeout(this.renewView, 100)
        this.init()
        this.initView()
      }
      this.emit('openLocalFile', ['restore', fn])
    },
    handle(act, data) {
      if (act === 'setSelectData') {
        this.selectMod = data[0]
        this.selectIds = data[1]
        this.canDelete = this.selectMod && Object.keys(this.selectIds).length > 0
        this.update()
      } else if (act === 'cancelSelect') {
        this.cancelSelect()
      } else {
        this.emit(act, data)
      }
    },
    init() {
      if (this.paintSto) {
        clearTimeout(this.paintSto)
        this.paintSto = null
      }
      this.canDelete = false
      this.selectMod = false
      this.selectIds = {}
    },
    callSub(fn) {
      const el = this.$refs['resource-' + this.config.type]
      if (el && el[fn]) {
        el[fn]()
      } else {
        setTimeout(() => {
          this.callSub(fn)
        }, 100)
      }
      // if (this.$refs['resource-' + this.config.type]) this.$refs['resource-' + this.config.type][fn]()
    },
    renewView() {
      this.callSub('renewView')
    },
    initView() {
      this.callSub('init')
    },
    cancelSelect() {
      this.callSub('cancelSelect')
      this.init()
    },
    deleteItems() {
      if (this.canDelete) {
        this.state.deleteAllresource = this.selectIds
        this.callSub('deleteItems')
        if (this.table === 'work') {
          for (const id in this.selectIds) {
            service.put(this.table, id, { flag: 0 }, (dt, type) => {}, true)
          }
        } else if (this.table === 'draft') {
          for (const id in this.selectIds) {
            if (this.config.tab === 'recycle') {
              this.db.delete('files', { fileId: id })
            } else {
              this.db.del('files', { fileId: id })
            }
          }
        } else {
          const ids = []
          for (const id in this.selectIds) {
            ids.push(id)
          }
          service.batchDelete(this.table, ids, (dt, type) => {}, true)
        }
        this.init()
      } else {
        this.cancelSelect()
      }
    },
    addResource() {
      if (this.table === 'work' || this.table === 'draft') {
        this.view.createNewfile = false
        setTimeout(() => {
          this.view.createNewfile = true
        }, 10)
      } else {
        this.openResourceMng({ 'table': this.table, 'act': 'add' })
      }
    }
  }
}
</script>
