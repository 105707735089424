<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'nftorder-' + mod + '-container'" :style="listStyle" class="my-list" :top-load-method="(loaded) => loadData(loaded, showUp ? 'up' : 'renew')" :bottom-load-method="(loaded) => loadData(loaded, 'down')" @infinite-scroll="getData('down')">
      <div class="flow-container">
        <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
          <div v-for="(item, i) in column.items" :key="i" class="item">
            <div class="card" :style="{ 'width': cardWidth + 'px', height: '98px' }">
              <div v-finger:long-tap="() => longTap(item.nft_orderid)" class="order">
                <div class="left" @click="() => openDetail(item.nft_orderid)">
                  <img :src="worksHost + 'work/action/download/gridy/size/8/shape/none/workid/' + item.workid + '/' + item.workid + '.png?ver=' + item.update_at" class="round4" :style="imgStyle(data.works[item.workid])">
                </div>
                <div class="summary float-left" style="width: calc(100% - 130px);">
                  <div @click="() => openDetail(item.nft_orderid)">
                    <el-row class="padding-top4 padding-bottom4">
                      {{ data.publishes[item.publishid] ? data.publishes[item.publishid].name : '' }} <span class="color-99 size12">{{ formateTime(item.add_at) }}</span>
                    </el-row>
                    <el-row class="padding-bottom4">
                      <div v-if="data.publishes[item.publishid]" class="color-99">{{ (data.publishes[item.publishid] && data.publishes[item.publishid].userid && users[data.publishes[item.publishid].userid]) ? users[data.publishes[item.publishid].userid].nickname : '' }}</div>
                    </el-row>
                  </div>
                  <div class="padding-top4" @click="() => openDetail(item.nft_orderid)">
                    <el-col :span="12">
                      <span v-if="item.amount" class="yellow">
                        ¥{{ formateMoney(item.amount) }}
                      </span>
                    </el-col>
                    <el-col :span="12">
                      <div v-if="item.pay_type !== -1" class="acts">
                        <span v-if="(item.type !== 1 || (item.type === 1 && item.preorder_status === 1)) && item.pay_status === 1">{{ $t('i.tradeSuccess') }}</span>
                        <span v-if="(item.type !== 1 || (item.type === 1 && item.preorder_status === 1)) && item.pay_status < 0" class="color-99">{{ item.pay_status === -1 ? $t('i.payFail') : (item.pay_status === -2 ? $t('i.hadCancel') : (item.pay_status === -3 ? $t('i.hadExpires') : '')) }}</span>
                        <span v-if="(item.type !== 1 || (item.type === 1 && item.preorder_status === 1)) && item.pay_status === 0" class="blue" @click="payIt(item)">{{ $t('i.payNow') }}</span>
                        <span v-if="item.type === 1 && item.preorder_status === 0" class="yellow">{{ $t('i.waitingDrawLots') }}</span>
                        <span v-if="item.type === 1 && item.preorder_status === -1" class="red">{{ $t('i.notWin') }}</span>
                      </div>
                      <div v-if="item.pay_type === -1" class="acts">{{ $t('i.tradeSuccess') }}</div>
                    </el-col>
                  </div>
                </div>
                <div v-if="data.deleteMod" class="select-btn padding-top4" @click="select(item.nft_orderid)">
                  <i class="iconfont my-ok" :class="{ gray: !data.deleteIds[item.nft_orderid], blue: data.deleteIds[item.nft_orderid]}" />
                </div>
              </div>
              <!-- <span v-if="item.type === 1 && orderTypes[item.type]" class="tag" style="position: relative;top: -25px;left: 5px;">{{ orderTypes[item.type] }}</span> -->
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loading[mod] && itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div v-if="flowHeight > height && data.noDownPage" class="tip">{{ $t('i.showAll') }}</div>
      <el-empty v-if="(!itemNums && !loading[mod])" :image="emptyImage" :description="$t('i.none')" />
    </pull-to>
    <div v-if="data.scrollTop > 200" class="go-top">
      <el-button class="tap-btn" @click="backTop()">
        <i class="iconfont my-top" />
      </el-button>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/nftorder'
export default {
  mixins: [mixins]
}
</script>
