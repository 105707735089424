<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'message-' + mod + '-container'" :style="listStyle" class="my-list" :top-load-method="(loaded) => loadData(loaded, showUp ? 'up' : 'renew')" :bottom-load-method="(loaded) => loadData(loaded, 'down')" @infinite-scroll="getData('down')">
      <div class="flow-container">
        <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
          <div v-for="(item, i) in column.items" :key="i" class="item">
            <div class="card" :style="{ 'width': cardWidth + 'px' }">
              <div class="user" @click="(data.deleteMod && item.user.type !== 'social' && item.user.type !== 'system') ? {} : linkTo(item, item.user.type)">
                <div class="left">
                  <el-badge :value="formateNums(item.unreadCount)" :hidden="!item.unreadCount">
                    <span v-if="mod !=='system' && !item.user.type && !item.user.avatar" class="el-avatar el-avatar--circle noavatar large">
                      <i class="iconfont my-man" />
                    </span>
                    <el-avatar v-if="mod !=='system' && !item.user.type && item.user.avatar" :size="42" :src="getAvatar(item.user)" />
                    <el-avatar v-if="mod ==='system' && !item.user.type" :size="42" :src="icon" />
                    <span v-if="mod ==='dialog' && item.user.type" class="el-avatar el-avatar--circle noavatar large bg-blue">
                      <i class="iconfont white" :class="{'my-msg' : item.user.type === 'social', 'my-alert' : item.user.type === 'system'}" />
                    </span>
                  </el-badge>
                </div>
                <div v-finger:long-tap="() => longTap(item.id)" class="summary" :style="{width: (data.deleteMod && item.user.type !== 'social' && item.user.type !== 'system') ? 'calc(100% - 90px)' : ''}">
                  <el-row>
                    <div :class="{'padding-top10': !item.summary}">
                      <b>{{ item.user.nickname }}</b> <span class="time">{{ item.time }}</span>
                    </div>
                  </el-row>
                  <el-row v-if="item.summary">
                    <div v-html="getContent(item)" />
                  </el-row>
                </div>
                <div v-if="data.deleteMod && item.user.type !== 'social' && item.user.type !== 'system'" class="select-btn" @click="select(item.id)">
                  <i class="iconfont my-ok" :class="{ gray: !data.deleteIds[item.id], blue: data.deleteIds[item.id]}" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loading[mod] && itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div v-if="flowHeight > height && data.noDownPage" class="tip">{{ $t('i.showAll') }}</div>
      <el-empty v-if="(!itemNums && !loading[mod] && config.type !=='dialog')" :image="emptyImage" :description="$t('i.none')" />
    </pull-to>
    <div v-if="data.scrollTop > 200" class="go-top">
      <el-button class="tap-btn" @click="backTop()">
        <i class="iconfont my-top" />
      </el-button>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/message'
export default {
  mixins: [mixins]
}
</script>
