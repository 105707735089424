/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2023-2-20
 * @copyright Gridy.Art
 */
import i18n from '../../lang'
// eslint-disable-next-line
const $t = (key, value) => i18n.t(key, value)
export default {
  view: {
    loading: false,
    // 当前模块，editer: 编辑模式，works: 作品
    mod: 'works',
    mods: ['index', 'nft', 'works', 'aiart', 'album', 'albumWorks', 'zone', 'discover', 'topics', 'topicDetail', 'thread', 'reply', 'user', 'editer', 'setup', 'services', 'message', 'chat', 'wallet', 'task', 'orderMng', 'resource', 'resourceMng', 'resourceSelecter', 'cropImage', 'pixel2Gridy', 'gridyViewer', 'workViewer', 'post', 'order', 'nftorder', 'invite', 'vip', 'sign', 'items', 'praise', 'favor', 'download', 'footprint', 'users', 'relation', 'profile', 'account'],
    resourceNames: () => {
      return { 'order': $t('i.order'), 'paint': $t('i.aiart'), 'album': $t('i.album'), 'bankcard': $t('i.bCard'), 'address': $t('i.addres'), 'attachments_1': $t('i.asset'), 'attachments_0': $t('i.file'), 'user_follow': $t('i.atMen'), 'work': $t('i.myWorks'), 'nft': $t('i.nft'), 'topics': $t('i.topics') }
    },
    resourceActs: () => {
      return { 'create': $t('i.creat'), 'add': $t('i.add'), 'edit': $t('i.edit'), 'view': $t('i.browse') }
    },
    showFootBarMods: ['index', 'works', 'aiart', 'album', 'zone', 'discover'],
    latestMod: '',
    historyMods: [],
    login: {
      from: '',
      show: false,
      loging: false,
      // 登录模式： username 用户名密码登录，phone 手机号登录，email 邮箱登录，wechat 微信登录
      mod: i18n.locale !== 'zh' ? 'email' : 'phone',
      secondLogin: false,
      checked: true,
      data: {},
      username: '',
      password: '',
      email: '',
      phone: '',
      code: ''
    },
    addNew: {
      show: false
    },
    index: {
      show: false,
      showHead: false,
      loading: true,
      renew: ''
    },
    diy: {
      show: false,
      showHead: false,
      loading: true,
      renew: ''
    },
    nft: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      renew: '',
      type: 'publish',
      typeid: 0,
      types: () => {
        return [['publish', $t('i.sale')], ['paradrop', $t('i.airDrop')], ['compound', $t('i.casting')], ['calendar', $t('i.saleCalendar')]]
      }
      // types: [['publish', $t('i.sale')], ['preorder', $t('i.subscribe')], ['paradrop', $t('i.airDrop')], ['compound', $t('i.casting')], ['calendar', $t('i.saleCalendar')]]
    },
    // 未启用
    seriesWorks: {
      from: '',
      show: false,
      showHead: true,
      pop: true,
      loading: true,
      renew: '',
      seriesid: 0
    },
    works: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      renew: '',
      classid: 0,
      type: 'new',
      typeid: 0,
      // types: [['new', $t('i.new')], ['hot', $t('i.hot')], ['best', $t('i.recommend')], ['original', $t('i.original')]],
      types: () => {
        return [['new', $t('i.new')], ['best', $t('i.recommend')], ['original', $t('i.original')]]
      },
      catalogName: $t('i.all'),
      catalogid: 0,
      sort: 'desc',
      sortType: 'update',
      sortTypes: () => {
        return [['update', $t('i.updateTime')], ['create', $t('i.pubTime')]]
      }
    },
    aiart: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      renew: '',
      type: 'new',
      typeid: 0,
      types: () => {
        return [['new', $t('i.new')], ['best', $t('i.recommend')]]
      },
      catalogName: $t('i.all'),
      catalogid: 0,
      styleName: $t('i.all'),
      styleid: '0',
      origin: '',
      originTypes: () => {
        return [['', $t('i.all')], ['0', '文生图'], ['1', '图生图']]
      },
      sort: 'desc',
      sortType: 'update',
      sortTypes: () => {
        return [['update', $t('i.updateTime')], ['create', $t('i.pubTime')]]
      }
    },
    album: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      renew: '',
      type: 'new',
      typeid: 0,
      // types: [['new', $t('i.new')], ['hot', $t('i.hot')], ['best', $t('i.recommend')], ['original', $t('i.original')]],
      types: () => {
        return [['new', $t('i.new')], ['best', $t('i.recommend')], ['original', $t('i.original')]]
      },
      catalogName: $t('i.all'),
      catalogid: 0,
      sort: 'desc',
      sortType: 'update',
      sortTypes: () => {
        return [['update', $t('i.updateTime')], ['create', $t('i.createTime')]]
      }
    },
    albumWorks: {
      from: 'album',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      typeid: 0,
      albumid: 0
    },
    zone: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      renew: '',
      type: 'new',
      typeid: 0,
      types: () => {
        return [['new', $t('i.new')], ['best', $t('i.recommend')]]
      },
      catalogName: $t('i.all'),
      catalogid: 0,
      sort: 'desc',
      sortType: 'update',
      sortTypes: () => {
        return [['update', $t('i.updateTime')], ['create', $t('i.pubTime')]]
      }
    },
    discover: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      renew: ''
    },
    thread: {
      from: 'zone',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: '',
      type: 'thread',
      threadid: 0
    },
    user: {
      from: '',
      show: false,
      showHead: false,
      hideBack: false,
      loading: true,
      pop: true,
      renew: '',
      type: 'thread',
      typeid: 0,
      types: () => {
        return [['thread', $t('i.topic')], ['works', $t('i.works')], ['album', $t('i.album')], ['nft', $t('i.nft')]]
      },
      userid: 0,
      // yes: 管理模式
      mng: 'no',
      resource: ''
    },
    topics: {
      from: 'zone',
      show: false,
      showHead: true,
      hideBack: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'hot',
      typeid: 0,
      types: () => {
        return [['new', $t('i.newTopic')], ['hot', $t('i.hotTopic')], ['best', $t('i.recommendTopic')]]
      }
    },
    topicDetail: {
      from: 'zone',
      show: false,
      showHead: true,
      hideBack: true,
      loading: true,
      pop: true,
      renew: '',
      topicid: 0
    },
    users: {
      from: 'discover',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'new',
      typeid: 0,
      types: () => {
        return [['new', $t('i.newUser')], ['active', $t('i.activeUsers')], ['creator', $t('i.creator')]]
      }
    },
    task: {
      from: 'user',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: '',
      init: '',
      type: 'mine',
      typeid: 0,
      types: () => {
        return [['mine', $t('i.myTask')], ['new', $t('i.acceTask')], ['finish', $t('i.hadFinish')]]
      }
    },
    relation: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'fans',
      typeid: 1,
      types: () => {
        return [['follow', $t('i.follow')], ['fans', $t('i.fans')], ['guest', $t('i.visiter')]]
      },
      userid: 0
    },
    setup: {
      from: 'user',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: ''
    },
    services: {
      from: 'user',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: ''
    },
    message: {
      from: 'user',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: '',
      type: 'dialog',
      types: () => {
        return [['dialog', $t('i.message')], ['social', $t('i.interaction')], ['system', $t('i.notification')]]
      }
    },
    chat: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      dialogId: 0,
      userid: 0,
      nickname: ''
    },
    wallet: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'flow',
      types: () => {
        return [['flow', $t('i.payRecords')], ['cash', $t('i.withDrawaList')]]
      }
    },
    items: {
      from: 'user',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: '',
      type: 'all',
      typeid: 0,
      types: () => {
        return [['all', $t('i.all')], ['bag', $t('i.backpack')], ['store', $t('i.warehouse')]]
      }
      // types: [['all', $t('i.all')], ['bag', $t('i.backpack')], ['store', $t('i.warehouse')], ['hall', $t('i.hall')]]
    },
    order: {
      from: '',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'all',
      typeid: 0,
      types: () => {
        return [['all', $t('i.all')], ['paying', $t('i.waitPay')], ['paid', $t('i.waitSend')], ['receive', $t('i.waitReceive')]]
      }
      // types: [['all', $t('i.all')], ['paying', $t('i.waitPay')], ['paid', $t('i.waitSend')], ['receive', $t('i.waitReceive')], ['comment', $t('i.waitReply')]]
    },
    nftorder: {
      from: 'nft',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'all',
      typeid: 0,
      types: () => {
        return [['all', $t('i.all')], ['paying', $t('i.waitPay')], ['paid', $t('i.payed')], ['cancel', $t('i.hadCancel')]]
      }
    },
    orderMng: {
      from: '',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      table: '',
      keyfield: '',
      id: '',
      act: 'add',
      // skuId 8000=乐高式积木拼图 9000=百乐酷积木拼图 10001=串联式积木拼图 10002=钻石拼图
      skuId: '',
      data: {}
    },
    resource: {
      from: '',
      show: false,
      showHead: false,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      table: '',
      showActions: true,
      simple: false,
      keyfield: '',
      type: 'all',
      tab: '',
      tabid: 0,
      tabs: [],
      data: {}
    },
    resourceMng: {
      from: '',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      id: '',
      act: 'add',
      data: {}
    },
    resourceSelecter: {
      from: '',
      show: false,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      table: '',
      showActions: true,
      batchDelete: false,
      simple: false,
      leftBack: false,
      keyfield: '',
      maxSelectCount: 1,
      type: 'selecter',
      typeid: 0,
      types: [],
      tab: '',
      tabid: 0,
      tabs: [],
      sift: 0,
      sifts: [],
      fn: null
    },
    cropImage: {
      from: '',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      data: {},
      fn: null
    },
    pixel2Gridy: {
      from: '',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      data: {},
      fn: null
    },
    gridyViewer: {
      from: '',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      data: {},
      fn: null
    },
    paperViewer: {
      from: '',
      show: false,
      showHead: true,
      loading: false,
      hideSaveBtn: false,
      pop: true,
      renew: '',
      title: '',
      data: {},
      fn: null
    },
    brickyViewer: {
      from: '',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      data: {},
      fn: null
    },
    workViewer: {
      from: 'works',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      workid: '',
      data: {},
      fn: null
    },
    post: {
      from: 'zone',
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      renew: '',
      title: '',
      id: '',
      act: 'add',
      catalogid: 0,
      catalogName: '',
      data: {}
    },
    invite: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: ''
    },
    vip: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: ''
    },
    sign: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: ''
    },
    praise: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'thread',
      types: () => {
        return [['thread', $t('i.topic')], ['works', $t('i.works')], ['album', $t('i.album')]]
      }
    },
    favor: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'thread',
      types: () => {
        return [['thread', $t('i.topic')], ['works', $t('i.works')], ['album', $t('i.album')]]
      }
    },
    download: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'works'
    },
    footprint: {
      from: 'user',
      show: false,
      showHead: true,
      loading: true,
      pop: true,
      renew: '',
      type: 'thread',
      types: () => {
        return [['thread', $t('i.topic')], ['works', $t('i.works')], ['album', $t('i.album')]]
      }
    },
    profile: {
      from: 'setup',
      show: false,
      showHead: false,
      loading: true,
      pop: true,
      renew: ''
    },
    account: {
      from: 'setup',
      show: false,
      showHead: false,
      pop: true,
      renew: '',
      loading: true
    },
    itemOperate: {
      title: 'items',
      show: false,
      loading: false,
      type: ''
    },
    pop: {
      show: false,
      title: '',
      tip: '',
      placeholder: '',
      classname: '',
      content: '',
      maxlength: 256,
      rows: 1,
      data: {},
      type: '',
      loading: false,
      fn: null
    },
    popPage: {
      from: '',
      // fromUrl: true,
      show: false,
      showHead: true,
      loading: false,
      pop: true,
      title: '',
      mobile: '',
      publishid: 0,
      orderid: 0,
      // skuId 8000=乐高式积木拼图 9000=百乐酷积木拼图 10001=串联式积木拼图 10002=钻石拼图
      skuId: '',
      data: {},
      type: '',
      types: () => {
        return [['toCartoon', $t('i.aiCartoon')], ['toBrickArt', $t('i.vPuzzle')], ['toPixel', $t('i.aiPixel')], ['toBeanPixel', $t('i.aiBeanPixel')], ['toBrickfy', $t('i.aiPuzzle')], ['paint', $t('i.aiart')], ['order', $t('i.orderDetails')], ['nftorder', $t('i.NFTOrderDetails')], ['chainAccount', $t('i.blockChain')], ['publishInfo', $t('i.NFTIntro')], ['publishPower', $t('i.NFTBenefits')], ['publishCert', $t('i.NFTCert')], ['nftnotice', $t('i.NFTIPS')], ['bindPhone', $t('i.bindMobile')], ['rebindPhone', $t('i.bindNewMobile')], ['unbindPhone', $t('i.verifyOldMobile')], ['bindEmail', $t('i.bindEmail')], ['rebindEmail', $t('i.bindNewEmail')], ['unbindEmail', $t('i.verifyOldEmail')], ['setPassword', $t('i.changeLoginPWD')], ['setPayPassword', $t('i.changeTradePWD')], ['realVerify', $t('i.realVerify')], ['realVerifyInfo', $t('i.realVerify')], ['aboutUs', $t('i.aboutUs')], ['contactUs', $t('i.contactUs')], ['feedback', $t('i.userFeedback')], ['checkUpdate', $t('i.checkUpdate')], ['agreement', $t('i.agreement')], ['privacy', $t('i.privacy')]]
      }
    },
    myPop: {
      show: false,
      tab: 'draft',
      group: 'draft',
      mod: 'tab'
    },
    editer: {
      from: '',
      mod: '',
      workid: 0
    },
    albumSelector: {
      show: false,
      workid: 0
    },
    comment: {
      show: false,
      pop: true,
      work: {}
    },
    reply: {
      from: '',
      show: false,
      pop: true,
      work: {},
      threadid: 0,
      postid: 0
    },
    moreWorks: {
      show: false,
      title: ''
    },
    myPublish: {
      show: false,
      fn: null
    },
    actionSheet: {
      show: false,
      title: '',
      btns: []
    },
    myNotify: {
      show: false,
      title: '',
      content: '',
      btn: '',
      fn: null
    },
    sift: {
      show: false,
      type: 'works'
    },
    imagesViewer: {
      show: false,
      pop: true,
      list: []
    },
    anonymity: $t('i.user'),
    // del assets
    assets: false,
    zoomBar: false,
    rightSide: true,
    headerHeight: 50,
    leftSideWidth: 100,
    rightSideWidth: 288,
    rightToggleWidth: 12,
    rightToolBar: false,
    previewMod: '2d',
    createNewfile: false,
    scenes: true,
    objs: true,
    palette: true,
    inColors: true,
    outColors: true
  }
  // view: () => {
  //   return {}
  // }
}
