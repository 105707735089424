export default {
  'name': '盼盼',
  'data': [
    {
      'hex': '#FBFBFB',
      'id': '15',
      'order': 1
    },
    {
      'hex': '#BBC6B6',
      'id': '168',
      'order': 1
    },
    {
      'hex': '#F6D4CB',
      'id': '18',
      'order': 1
    },
    {
      'hex': '#DAABB3',
      'id': '254',
      'order': 1
    },
    {
      'hex': '#FF9280',
      'id': '35',
      'order': 1
    },
    {
      'hex': '#ACB7EF',
      'id': '46',
      'order': 1
    },
    {
      'hex': '#DFF139',
      'id': '48',
      'order': 1
    },
    {
      'hex': '#FF6FB7',
      'id': '59',
      'order': 1
    },
    {
      'hex': '#F0FEE4',
      'id': '64',
      'order': 1
    },
    {
      'hex': '#FAF5CD',
      'id': '65',
      'order': 1
    },
    {
      'hex': '#D40E1F',
      'id': '67',
      'order': 1
    },
    {
      'hex': '#F9F9F9',
      'id': '71',
      'order': 1
    },
    {
      'hex': '#FFE4D3',
      'id': '76',
      'order': 1
    },
    {
      'hex': '#000000',
      'id': '1',
      'order': 2
    },
    {
      'hex': '#909994',
      'id': '172',
      'order': 2
    },
    {
      'hex': '#FCFED6',
      'id': '2',
      'order': 2
    },
    {
      'hex': '#F13484',
      'id': '24',
      'order': 2
    },
    {
      'hex': '#D6AA87',
      'id': '255',
      'order': 2
    },
    {
      'hex': '#ABF8FE',
      'id': '30',
      'order': 2
    },
    {
      'hex': '#F73D4B',
      'id': '31',
      'order': 2
    },
    {
      'hex': '#64F343',
      'id': '33',
      'order': 2
    },
    {
      'hex': '#868DD3',
      'id': '36',
      'order': 2
    },
    {
      'hex': '#FCC1DD',
      'id': '38',
      'order': 2
    },
    {
      'hex': '#FCC6AC',
      'id': '49',
      'order': 2
    },
    {
      'hex': '#ABABAB',
      'id': '55',
      'order': 2
    },
    {
      'hex': '#FDB583',
      'id': '60',
      'order': 2
    },
    {
      'hex': '#B4B4B4',
      'id': '13',
      'order': 3
    },
    {
      'hex': '#697E80',
      'id': '166',
      'order': 3
    },
    {
      'hex': '#FB852B',
      'id': '22',
      'order': 3
    },
    {
      'hex': '#C1BD8D',
      'id': '256',
      'order': 3
    },
    {
      'hex': '#9FF685',
      'id': '26',
      'order': 3
    },
    {
      'hex': '#FCFF92',
      'id': '28',
      'order': 3
    },
    {
      'hex': '#EF4D3E',
      'id': '53',
      'order': 3
    },
    {
      'hex': '#D8FCA4',
      'id': '57',
      'order': 3
    },
    {
      'hex': '#F6BDE8',
      'id': '62',
      'order': 3
    },
    {
      'hex': '#9EE0F8',
      'id': '63',
      'order': 3
    },
    {
      'hex': '#B6DBAF',
      'id': '73',
      'order': 3
    },
    {
      'hex': '#3653AF',
      'id': '8',
      'order': 3
    },
    {
      'hex': '#F1C4A5',
      'id': '80',
      'order': 3
    },
    {
      'hex': '#E0D4BC',
      'id': '167',
      'order': 4
    },
    {
      'hex': '#DCB387',
      'id': '19',
      'order': 4
    },
    {
      'hex': '#F8ED33',
      'id': '21',
      'order': 4
    },
    {
      'hex': '#96B69F',
      'id': '257',
      'order': 4
    },
    {
      'hex': '#F7EC5C',
      'id': '3',
      'order': 4
    },
    {
      'hex': '#F92B40',
      'id': '54',
      'order': 4
    },
    {
      'hex': '#91DAFB',
      'id': '58',
      'order': 4
    },
    {
      'hex': '#E9639E',
      'id': '6',
      'order': 4
    },
    {
      'hex': '#5FDF34',
      'id': '66',
      'order': 4
    },
    {
      'hex': '#FEA2A3',
      'id': '72',
      'order': 4
    },
    {
      'hex': '#162C7E',
      'id': '75',
      'order': 4
    },
    {
      'hex': '#44CDFB',
      'id': '77',
      'order': 4
    },
    {
      'hex': '#878787',
      'id': '78',
      'order': 4
    },
    {
      'hex': '#D0CBAE',
      'id': '174',
      'order': 5
    },
    {
      'hex': '#32C958',
      'id': '23',
      'order': 5
    },
    {
      'hex': '#849DC6',
      'id': '258',
      'order': 5
    },
    {
      'hex': '#B34EC6',
      'id': '32',
      'order': 5
    },
    {
      'hex': '#06ABE3',
      'id': '34',
      'order': 5
    },
    {
      'hex': '#39E158',
      'id': '39',
      'order': 5
    },
    {
      'hex': '#F1559F',
      'id': '40',
      'order': 5
    },
    {
      'hex': '#E7B34E',
      'id': '43',
      'order': 5
    },
    {
      'hex': '#464648',
      'id': '45',
      'order': 5
    },
    {
      'hex': '#E30328',
      'id': '5',
      'order': 5
    },
    {
      'hex': '#EB903F',
      'id': '56',
      'order': 5
    },
    {
      'hex': '#E987EA',
      'id': '61',
      'order': 5
    },
    {
      'hex': '#F0D83A',
      'id': '74',
      'order': 5
    },
    {
      'hex': '#64E0A4',
      'id': '11',
      'order': 6
    },
    {
      'hex': '#63CEA2',
      'id': '157',
      'order': 6
    },
    {
      'hex': '#913635',
      'id': '16',
      'order': 6
    },
    {
      'hex': '#B0AA86',
      'id': '169',
      'order': 6
    },
    {
      'hex': '#EC4072',
      'id': '20',
      'order': 6
    },
    {
      'hex': '#54A7E9',
      'id': '25',
      'order': 6
    },
    {
      'hex': '#94BFE2',
      'id': '259',
      'order': 6
    },
    {
      'hex': '#B37BDC',
      'id': '27',
      'order': 6
    },
    {
      'hex': '#FDA951',
      'id': '29',
      'order': 6
    },
    {
      'hex': '#E3A014',
      'id': '50',
      'order': 6
    },
    {
      'hex': '#2C2C2C',
      'id': '51',
      'order': 6
    },
    {
      'hex': '#1EBA93',
      'id': '69',
      'order': 6
    },
    {
      'hex': '#010101',
      'id': '14',
      'order': 7
    },
    {
      'hex': '#E79273',
      'id': '159',
      'order': 7
    },
    {
      'hex': '#985C3A',
      'id': '17',
      'order': 7
    },
    {
      'hex': '#B0A796',
      'id': '171',
      'order': 7
    },
    {
      'hex': '#E2A9D2',
      'id': '260',
      'order': 7
    },
    {
      'hex': '#1D779C',
      'id': '37',
      'order': 7
    },
    {
      'hex': '#FA8C4F',
      'id': '4',
      'order': 7
    },
    {
      'hex': '#C63674',
      'id': '41',
      'order': 7
    },
    {
      'hex': '#3EAE7C',
      'id': '44',
      'order': 7
    },
    {
      'hex': '#911932',
      'id': '47',
      'order': 7
    },
    {
      'hex': '#8758A9',
      'id': '7',
      'order': 7
    },
    {
      'hex': '#3977CC',
      'id': '9',
      'order': 7
    },
    {
      'hex': '#1D9B54',
      'id': '10',
      'order': 8
    },
    {
      'hex': '#713D2F',
      'id': '12',
      'order': 8
    },
    {
      'hex': '#ECDB59',
      'id': '158',
      'order': 8
    },
    {
      'hex': '#AE8082',
      'id': '177',
      'order': 8
    },
    {
      'hex': '#AB91C0',
      'id': '261',
      'order': 8
    },
    {
      'hex': '#0F52BD',
      'id': '52',
      'order': 8
    },
    {
      'hex': '#1960C8',
      'id': '68',
      'order': 8
    },
    {
      'hex': '#BB0126',
      'id': '81',
      'order': 8
    },
    {
      'hex': '#FDDBE9',
      'id': '84',
      'order': 8
    },
    {
      'hex': '#E7D6DC',
      'id': '85',
      'order': 8
    },
    {
      'hex': '#FDD94D',
      'id': '88',
      'order': 8
    },
    {
      'hex': '#E3D2FE',
      'id': '94',
      'order': 8
    },
    {
      'hex': '#A88764',
      'id': '170',
      'order': 9
    },
    {
      'hex': '#DBD9DA',
      'id': '195',
      'order': 9
    },
    {
      'hex': '#3349C3',
      'id': '42',
      'order': 9
    },
    {
      'hex': '#945AB1',
      'id': '70',
      'order': 9
    },
    {
      'hex': '#2A5037',
      'id': '79',
      'order': 9
    },
    {
      'hex': '#E0677A',
      'id': '82',
      'order': 9
    },
    {
      'hex': '#F99C5F',
      'id': '90',
      'order': 9
    },
    {
      'hex': '#E4B685',
      'id': '91',
      'order': 9
    },
    {
      'hex': '#D6BAF5',
      'id': '93',
      'order': 9
    },
    {
      'hex': '#EFEDEE',
      'id': '95',
      'order': 9
    },
    {
      'hex': '#E575C7',
      'id': '98',
      'order': 9
    },
    {
      'hex': '#874628',
      'id': '116',
      'order': 10
    },
    {
      'hex': '#3DBBE3',
      'id': '121',
      'order': 10
    },
    {
      'hex': '#F8DA54',
      'id': '156',
      'order': 10
    },
    {
      'hex': '#C6B2BB',
      'id': '164',
      'order': 10
    },
    {
      'hex': '#DBC7EA',
      'id': '187',
      'order': 10
    },
    {
      'hex': '#D33997',
      'id': '83',
      'order': 10
    },
    {
      'hex': '#ECEAEB',
      'id': '86',
      'order': 10
    },
    {
      'hex': '#DA8C42',
      'id': '87',
      'order': 10
    },
    {
      'hex': '#F47E36',
      'id': '89',
      'order': 10
    },
    {
      'hex': '#301A49',
      'id': '92',
      'order': 10
    },
    {
      'hex': '#9AD1BA',
      'id': '96',
      'order': 10
    },
    {
      'hex': '#FEDB99',
      'id': '100',
      'order': 11
    },
    {
      'hex': '#BCBAE2',
      'id': '105',
      'order': 11
    },
    {
      'hex': '#DAC898',
      'id': '112',
      'order': 11
    },
    {
      'hex': '#592323',
      'id': '117',
      'order': 11
    },
    {
      'hex': '#2ADED3',
      'id': '122',
      'order': 11
    },
    {
      'hex': '#CDCDCD',
      'id': '123',
      'order': 11
    },
    {
      'hex': '#F7DAD4',
      'id': '125',
      'order': 11
    },
    {
      'hex': '#FCECF7',
      'id': '151',
      'order': 11
    },
    {
      'hex': '#9D7693',
      'id': '176',
      'order': 11
    },
    {
      'hex': '#F1E9D4',
      'id': '185',
      'order': 11
    },
    {
      'hex': '#627032',
      'id': '97',
      'order': 11
    },
    {
      'hex': '#DC99CE',
      'id': '104',
      'order': 12
    },
    {
      'hex': '#1A6E3D',
      'id': '106',
      'order': 12
    },
    {
      'hex': '#FEC993',
      'id': '113',
      'order': 12
    },
    {
      'hex': '#1E334E',
      'id': '120',
      'order': 12
    },
    {
      'hex': '#F893BF',
      'id': '126',
      'order': 12
    },
    {
      'hex': '#FDF6EE',
      'id': '132',
      'order': 12
    },
    {
      'hex': '#F8516D',
      'id': '136',
      'order': 12
    },
    {
      'hex': '#D8D4D3',
      'id': '160',
      'order': 12
    },
    {
      'hex': '#644B51',
      'id': '173',
      'order': 12
    },
    {
      'hex': '#E9EDEE',
      'id': '190',
      'order': 12
    },
    {
      'hex': '#FDA276',
      'id': '99',
      'order': 12
    },
    {
      'hex': '#B5038F',
      'id': '103',
      'order': 13
    },
    {
      'hex': '#B2714B',
      'id': '115',
      'order': 13
    },
    {
      'hex': '#B5026A',
      'id': '127',
      'order': 13
    },
    {
      'hex': '#C8E87D',
      'id': '128',
      'order': 13
    },
    {
      'hex': '#FEC667',
      'id': '131',
      'order': 13
    },
    {
      'hex': '#CDE7FE',
      'id': '140',
      'order': 13
    },
    {
      'hex': '#F4EFD1',
      'id': '145',
      'order': 13
    },
    {
      'hex': '#F45C45',
      'id': '148',
      'order': 13
    },
    {
      'hex': '#56534E',
      'id': '152',
      'order': 13
    },
    {
      'hex': '#C79266',
      'id': '175',
      'order': 13
    },
    {
      'hex': '#ADCBF1',
      'id': '193',
      'order': 13
    },
    {
      'hex': '#882893',
      'id': '102',
      'order': 14
    },
    {
      'hex': '#8B684C',
      'id': '114',
      'order': 14
    },
    {
      'hex': '#ACE84C',
      'id': '129',
      'order': 14
    },
    {
      'hex': '#FAD4BF',
      'id': '137',
      'order': 14
    },
    {
      'hex': '#F85842',
      'id': '138',
      'order': 14
    },
    {
      'hex': '#D6FDFC',
      'id': '139',
      'order': 14
    },
    {
      'hex': '#CED7D4',
      'id': '146',
      'order': 14
    },
    {
      'hex': '#FCADB2',
      'id': '154',
      'order': 14
    },
    {
      'hex': '#C37463',
      'id': '165',
      'order': 14
    },
    {
      'hex': '#337BAD',
      'id': '183',
      'order': 14
    },
    {
      'hex': '#A3E7DC',
      'id': '231',
      'order': 14
    },
    {
      'hex': '#2F1E8E',
      'id': '101',
      'order': 15
    },
    {
      'hex': '#305335',
      'id': '130',
      'order': 15
    },
    {
      'hex': '#F6F8E3',
      'id': '133',
      'order': 15
    },
    {
      'hex': '#F5C9CA',
      'id': '135',
      'order': 15
    },
    {
      'hex': '#21C5C4',
      'id': '143',
      'order': 15
    },
    {
      'hex': '#FBF65E',
      'id': '150',
      'order': 15
    },
    {
      'hex': '#747D7A',
      'id': '178',
      'order': 15
    },
    {
      'hex': '#668575',
      'id': '184',
      'order': 15
    },
    {
      'hex': '#98A6A6',
      'id': '201',
      'order': 15
    },
    {
      'hex': '#D50527',
      'id': '204',
      'order': 15
    },
    {
      'hex': '#78CEE7',
      'id': '237',
      'order': 15
    },
    {
      'hex': '#E2E4F0',
      'id': '118',
      'order': 16
    },
    {
      'hex': '#F2D8C1',
      'id': '134',
      'order': 16
    },
    {
      'hex': '#C0ED9C',
      'id': '141',
      'order': 16
    },
    {
      'hex': '#1858A2',
      'id': '149',
      'order': 16
    },
    {
      'hex': '#FDC24E',
      'id': '182',
      'order': 16
    },
    {
      'hex': '#1B1213',
      'id': '200',
      'order': 16
    },
    {
      'hex': '#F8C0A9',
      'id': '211',
      'order': 16
    },
    {
      'hex': '#FEFF97',
      'id': '216',
      'order': 16
    },
    {
      'hex': '#FBF4EC',
      'id': '221',
      'order': 16
    },
    {
      'hex': '#3FCDCE',
      'id': '238',
      'order': 16
    },
    {
      'hex': '#C7D3F9',
      'id': '119',
      'order': 17
    },
    {
      'hex': '#9EB33E',
      'id': '142',
      'order': 17
    },
    {
      'hex': '#79544E',
      'id': '144',
      'order': 17
    },
    {
      'hex': '#02D1F3',
      'id': '163',
      'order': 17
    },
    {
      'hex': '#FDA42E',
      'id': '179',
      'order': 17
    },
    {
      'hex': '#FDE173',
      'id': '213',
      'order': 17
    },
    {
      'hex': '#F0EEEF',
      'id': '214',
      'order': 17
    },
    {
      'hex': '#F7E3EC',
      'id': '220',
      'order': 17
    },
    {
      'hex': '#4E8379',
      'id': '233',
      'order': 17
    },
    {
      'hex': '#E89B7D',
      'id': '245',
      'order': 17
    },
    {
      'hex': '#9A64B8',
      'id': '124',
      'order': 18
    },
    {
      'hex': '#E6ED4F',
      'id': '147',
      'order': 18
    },
    {
      'hex': '#FEBDA7',
      'id': '194',
      'order': 18
    },
    {
      'hex': '#213244',
      'id': '196',
      'order': 18
    },
    {
      'hex': '#FFE4D6',
      'id': '203',
      'order': 18
    },
    {
      'hex': '#FBC8DB',
      'id': '210',
      'order': 18
    },
    {
      'hex': '#FCFFF8',
      'id': '219',
      'order': 18
    },
    {
      'hex': '#FCBF80',
      'id': '223',
      'order': 18
    },
    {
      'hex': '#7DCA9C',
      'id': '235',
      'order': 18
    },
    {
      'hex': '#D07E4A',
      'id': '246',
      'order': 18
    },
    {
      'hex': '#D8C2D9',
      'id': '153',
      'order': 19
    },
    {
      'hex': '#FFDEE9',
      'id': '186',
      'order': 19
    },
    {
      'hex': '#26B78E',
      'id': '191',
      'order': 19
    },
    {
      'hex': '#18869D',
      'id': '202',
      'order': 19
    },
    {
      'hex': '#DD7D41',
      'id': '208',
      'order': 19
    },
    {
      'hex': '#F2EEE5',
      'id': '209',
      'order': 19
    },
    {
      'hex': '#F6BBD1',
      'id': '215',
      'order': 19
    },
    {
      'hex': '#FD7E77',
      'id': '218',
      'order': 19
    },
    {
      'hex': '#C8E664',
      'id': '227',
      'order': 19
    },
    {
      'hex': '#BE454A',
      'id': '243',
      'order': 19
    },
    {
      'hex': '#9C34AD',
      'id': '161',
      'order': 20
    },
    {
      'hex': '#FCBFD1',
      'id': '188',
      'order': 20
    },
    {
      'hex': '#CAEDCF',
      'id': '192',
      'order': 20
    },
    {
      'hex': '#1A70A9',
      'id': '197',
      'order': 20
    },
    {
      'hex': '#A5452F',
      'id': '199',
      'order': 20
    },
    {
      'hex': '#E3CCBA',
      'id': '230',
      'order': 20
    },
    {
      'hex': '#D7C6CE',
      'id': '241',
      'order': 20
    },
    {
      'hex': '#F9D66E',
      'id': '242',
      'order': 20
    },
    {
      'hex': '#96A09F',
      'id': '251',
      'order': 20
    },
    {
      'hex': '#C69495',
      'id': '275',
      'order': 20
    },
    {
      'hex': '#940595',
      'id': '162',
      'order': 21
    },
    {
      'hex': '#E8BEC2',
      'id': '180',
      'order': 21
    },
    {
      'hex': '#176268',
      'id': '207',
      'order': 21
    },
    {
      'hex': '#BEDDFC',
      'id': '212',
      'order': 21
    },
    {
      'hex': '#A17140',
      'id': '234',
      'order': 21
    },
    {
      'hex': '#B38561',
      'id': '247',
      'order': 21
    },
    {
      'hex': '#C09DA4',
      'id': '253',
      'order': 21
    },
    {
      'hex': '#F2B8C6',
      'id': '266',
      'order': 21
    },
    {
      'hex': '#FAE393',
      'id': '276',
      'order': 21
    },
    {
      'hex': '#F8FBE6',
      'id': '291',
      'order': 21
    },
    {
      'hex': '#DFAAA4',
      'id': '189',
      'order': 22
    },
    {
      'hex': '#383995',
      'id': '198',
      'order': 22
    },
    {
      'hex': '#0A4241',
      'id': '206',
      'order': 22
    },
    {
      'hex': '#6B372C',
      'id': '226',
      'order': 22
    },
    {
      'hex': '#6BB1BB',
      'id': '239',
      'order': 22
    },
    {
      'hex': '#B58B9F',
      'id': '252',
      'order': 22
    },
    {
      'hex': '#EDF878',
      'id': '270',
      'order': 22
    },
    {
      'hex': '#F7C3D0',
      'id': '272',
      'order': 22
    },
    {
      'hex': '#CACAD2',
      'id': '277',
      'order': 22
    },
    {
      'hex': '#A3656A',
      'id': '181',
      'order': 23
    },
    {
      'hex': '#343B1A',
      'id': '205',
      'order': 23
    },
    {
      'hex': '#EADBF8',
      'id': '217',
      'order': 23
    },
    {
      'hex': '#F6BB6F',
      'id': '224',
      'order': 23
    },
    {
      'hex': '#937D8A',
      'id': '250',
      'order': 23
    },
    {
      'hex': '#C8E2F9',
      'id': '263',
      'order': 23
    },
    {
      'hex': '#EC806D',
      'id': '264',
      'order': 23
    },
    {
      'hex': '#E1C9BD',
      'id': '274',
      'order': 23
    },
    {
      'hex': '#9B9C94',
      'id': '278',
      'order': 23
    },
    {
      'hex': '#E8FAA6',
      'id': '222',
      'order': 24
    },
    {
      'hex': '#F3C6C0',
      'id': '228',
      'order': 24
    },
    {
      'hex': '#768AE1',
      'id': '244',
      'order': 24
    },
    {
      'hex': '#7EC5F9',
      'id': '267',
      'order': 24
    },
    {
      'hex': '#DEBEE5',
      'id': '282',
      'order': 24
    },
    {
      'hex': '#E09DAF',
      'id': '283',
      'order': 24
    },
    {
      'hex': '#F3F6A9',
      'id': '288',
      'order': 24
    },
    {
      'hex': '#C76A62',
      'id': '225',
      'order': 25
    },
    {
      'hex': '#4E846D',
      'id': '240',
      'order': 25
    },
    {
      'hex': '#4950C2',
      'id': '249',
      'order': 25
    },
    {
      'hex': '#A9E8E0',
      'id': '271',
      'order': 25
    },
    {
      'hex': '#E84854',
      'id': '284',
      'order': 25
    },
    {
      'hex': '#FFD785',
      'id': '289',
      'order': 25
    },
    {
      'hex': '#D093BC',
      'id': '229',
      'order': 26
    },
    {
      'hex': '#907C35',
      'id': '248',
      'order': 26
    },
    {
      'hex': '#42ADD1',
      'id': '265',
      'order': 26
    },
    {
      'hex': '#D6C6EB',
      'id': '273',
      'order': 26
    },
    {
      'hex': '#FEC832',
      'id': '290',
      'order': 26
    },
    {
      'hex': '#E58EAE',
      'id': '232',
      'order': 27
    },
    {
      'hex': '#D0E0AF',
      'id': '262',
      'order': 27
    },
    {
      'hex': '#D0DEF9',
      'id': '279',
      'order': 27
    },
    {
      'hex': '#9F85CF',
      'id': '236',
      'order': 28
    },
    {
      'hex': '#9EE5BB',
      'id': '269',
      'order': 28
    },
    {
      'hex': '#BDCEE8',
      'id': '280',
      'order': 28
    },
    {
      'hex': '#C6DF5F',
      'id': '268',
      'order': 29
    },
    {
      'hex': '#364A89',
      'id': '281',
      'order': 29
    },
    {
      'hex': '#E3FBB1',
      'id': '285',
      'order': 30
    },
    {
      'hex': '#B2E694',
      'id': '286',
      'order': 31
    },
    {
      'hex': '#92AD60',
      'id': '287',
      'order': 32
    }
  ]
}
