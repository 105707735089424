<template>
  <el-dialog
    ref="relationDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll relationDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        <div class="tabs my-tabs" style="width:160px;">
          <el-tabs v-model="config.type">
            <el-tab-pane v-for="(type, idx) in types" :key="idx" :label="type[1]" :name="type[0]" />
          </el-tabs>
        </div>
      </div>
      <div class="float-right align-right">
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <users-list
        v-show="config.show && config.type === 'follow'"
        ref="users-follow"
        mod="follow"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <users-list
        v-show="config.show && config.type === 'fans'"
        ref="users-fans"
        mod="fans"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
      <users-list
        v-show="config.show && config.type === 'guest'"
        ref="users-guest"
        mod="guest"
        :view="view"
        :state="state"
        :login-status="loginStatus"
        :login-user-id="loginUserId"
        :subtract-height="subtractHeight"
        @receive="handle"
      />
    </div>
  </el-dialog>
</template>
<script>
import usersList from '@/components/web/usersList'
import { mixins } from '@/mixins/common'
export default {
  components: {
    usersList
  },
  mixins: [mixins],
  data() {
    return {
      subtractHeight: this.state.platform.type === 'desktop' ? 5 : 40
    }
  },
  computed: {
    config() {
      return this.view.relation
    },
    types() {
      return this.config.types()
    }
  },
  // watch: {
  //   'view.relation.show': {
  //     handler() {
  //       if (this.view.relation.show) this.updateData()
  //     }
  //   }
  // },
  mounted() {
    this.updateData()
  },
  methods: {
    updateData() {
      this.callSub('users-' + this.config.type, 'updateData')
    },
    handle(act, data) {
      this.emit(act, data)
    }
  }
}
</script>
