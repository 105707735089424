<template>
  <el-dialog
    ref="workViewerDialog"
    :title="title"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page headless workviewer"
  >
    <div class="header" style="background:none;" :class="{'padding-top12 padding-left4 padding-right8': isDesktop, 'padding-top8': !isDesktop}">
      <div class="float-left padding-left4 padding-top4">
        <div v-if="!config.loading">
          <div v-if="!isDesktop" class="float-left">
            <el-button class="tap-btn iconfont my-back size20" @click="close()" />
            &nbsp;
          </div>
          <!-- <div class="float-left padding-left12" @click="emit('goto', ['user', { userid: work.userid } ])">
            <span v-if="!(users[work.userid] && users[work.userid].avatar)" class="el-avatar el-avatar--circle noavatar" :class="{'large': isDesktop, 'medium': !isDesktop}">
              <i class="iconfont my-man" />
            </span>
            <el-avatar v-if="(users[work.userid] && users[work.userid].avatar)" :size="isDesktop ? 42 : 32" :src="getAvatar(work.userid)" />
          </div>
          <div class="float-left white" :class="{'padding12': isDesktop, 'padding8': !isDesktop}" @click="emit('goto', ['user', { userid: work.userid } ])">
            {{ (users[work.userid]) ? users[work.userid].nickname : view.anonymity }}
          </div>
          <div class="float-left" :class="{'padding-top4': isDesktop}">
            <el-button v-if="(followState[work.userid] === 0)" class="size12 opacity-bg" @click="unfollow(work.userid)">{{ $t('i.hadFollow') }}</el-button>
            <el-button v-if="(followState[work.userid] === 1)" class="size12 opacity-bg" @click="unfollow(work.userid)">{{ $t('i.followEach') }}</el-button>
            <el-button v-if="(followState[work.userid] !== 0 && followState[work.userid] !== 1)" class="size12 opacity-bg" @click="follow(work.userid)">{{ $t('i.follow') }}</el-button>
          </div> -->
        </div>
      </div>
      <div class="float-right">
        <el-button class="tap-btn" @click="handle('workActionSheet')">
          <i class="iconfont my-dot-more size24" />
        </el-button>
        <el-button v-if="isDesktop" class="tap-btn" @click="close()">
          <i class="iconfont my-close size24" />
        </el-button>
      </div>
    </div>
    <div
      v-loading="false"
      :element-loading-text="$t('i.rending')"
      shadow="never"
      element-loading-background="rgba(0, 0, 0, 0.6)"
      class="previewArea"
      :style="previewAreaStyle"
    >
      <img
        v-show="false"
        id="image"
        ref="image"
        :src="blankImage"
      >
      <voxel-viewer
        v-if="opts.d3 && config.show"
        ref="voxViewer"
        :state="state"
        :width="width"
        :height="height"
        :sky-styleid="0"
        :fixpos="-1"
        @receive="(event) => emit('handle', event)"
      />
    </div>
    <div style="position: absolute;text-align: center;z-index: 99;" :style="{'top': isDesktop ? '15px' : '4px', height: '50px', 'left': isDesktop ? '90px' : '60px', width: 'calc(100% - ' + (isDesktop ? 180 : 120) + 'px)'}">
      <el-row class="margin-bottom4 margin-top4" style="overflow-x: auto;">
        <el-button-group>
          <!-- <el-button v-if="work.type" :key="2" size="small" class="opacity-bg" style="height:32px;" :type="opts.workTypeid === 2 ? 'light' : ''" @click="setWorkType(2)">{{ $t('i.originImage') }}</el-button> -->
          <el-button v-for="workType in workTypes" :key="workType.id" size="small" class="opacity-bg" style="height:32px;" :type="opts.workTypeid === workType.id ? 'light' : ''" @click="setWorkType(workType.id)">{{ workType.label }}</el-button>
        </el-button-group>
      </el-row>
    </div>
    <div style="position: absolute;text-align: center;" :style="{'top': '60px', height: '50px', 'padding-left': '4px', width: ' calc(100% - ' + (isDesktop ? 28 : 8) + 'px)'}">
      <el-button v-if="canPlay && !playing" class="box32 padding4 opacity-bg margin-left4" @click="() => start()">
        <i class="iconfont my-play size20" :title="$t('i.play')" />
      </el-button>
      <el-button v-if="canPlay && playing" class="box32 padding4 opacity-bg margin-left4" type="light" @click="() => stop()">
        <i class="iconfont my-stop size20" :title="$t('i.stop')" />
      </el-button>
      <!-- <el-button v-if="canPlay && !playing" :disabled="playing" class="box32 opacity-bg padding4 margin-left4" @click="() => pre()">
        <i class="iconfont my-pre size20" :title="$t('i.pre')" />
      </el-button>
      <el-button v-if="canPlay && !playing" :disabled="playing" class="box32 opacity-bg padding4 margin-left4" @click="() => next()">
        <i class="iconfont my-next size20" :title="$t('i.next')" />
      </el-button> -->
    </div>
    <div v-if="!showInfo && !publishid" style="text-align: center;z-index: 96;" class="mid-bottom" :style="{'position': isDesktop ? 'absolute' : 'fixed', height: '34px', 'width': '156px', left: 'calc((100% - 156px) / 2)'}">
      <el-button class="opacity-bg" style="padding: 8px 16px;margin-bottom: 0;" @click="openBrickyViewer">
        <i class="iconfont my-cart margin-right8" :class="{'green': opts.workTypeid !== 2, 'blue': opts.workTypeid === 2}" /> <span :class="{'green': opts.workTypeid !== 2, 'blue': opts.workTypeid === 2}">{{ $t('i.createPuzzle') }}</span>
      </el-button>
    </div>
    <div v-if="publishid" class="right-top" :style="{'right': isDesktop ? '8px' : ''}">
      <el-button class="tap-btn opacity-bg margin-bottom8" @click="openPopPage({'type': 'publishInfo', 'publishid': publishid })">
        <i class="iconfont my-info" />
      </el-button>
      <el-button class="tap-btn opacity-bg margin-bottom8" @click="openPopPage({'type': 'publishPower', 'publishid': publishid })">
        <i class="iconfont my-crown" />
      </el-button>
      <!-- <el-button class="tap-btn opacity-bg margin-bottom8" @click="openPopPage({'type': 'publishCert', 'publishid': publishid })">
        <i class="iconfont my-blockchain" />
      </el-button> -->
      <el-button class="tap-btn opacity-bg margin-bottom8" @click="openPopPage({'type': 'nftnotice', 'publishid': publishid})">
        <i class="iconfont my-question" />
      </el-button>
    </div>
    <div v-if="!config.loading && work.workid > 0" ref="workInfo" :style="{'bottom': isDesktop ? '70px' : '', 'left': isDesktop ? '15px' : ''}" class="left-bottom" :class="{'large opacity-bg round4 padding-top4': showInfo, 'short': publishid}">
      <span v-if="!publishid" @click="emit('goto', ['user', { userid: work.userid } ])">@{{ (users[work.userid]) ? users[work.userid].nickname : view.anonymity }}</span>
      <el-button v-if="!publishid" class="tap-btn opacity-bg" @click="toggleWorkInfo">
        <i v-if="showInfo" class="iconfont my-open-down" />
        <i v-if="!showInfo" class="iconfont my-close-up" />
      </el-button>
      <span v-if="!publishid" @click="showInfo = !showInfo">
        <br>
        <!-- <span v-if="work.original" class="tag opacity-bg">{{ $t('i.origin') }}</span> <span v-if="work.best" class="tag opacity-bg">{{ $t('i.recomman') }}</span> -->
        {{ work.name || '' }} {{ getWorkTags() }}
        <br>{{ work.info || '' }}
        <br>{{ formateTime(work.updateTime) }} <i v-if="workCount.view_count" class="iconfont my-eye tiny" /> <span class="num">{{ workCount.view_count ? formateNums(workCount.view_count) : '' }}</span>
      </span>
      <span v-if="publishid && !config.loading">
        {{ publish.name || work.name }}
        <span v-if="publish.amount" class="right yellow size14">
          ¥{{ formateMoney(publish.amount) }}
        </span>
        <br>
        <span v-if="publish.seriesid && series[publish.seriesid] && series[publish.seriesid].name" class="tag opacity-bg white">{{ series[publish.seriesid].name }}</span>
        <span v-if="publish.total" class="tag bg-blue white margin-left8">{{ $t('i.numLimit', { total: publish.total }) }}</span>
      </span>
    </div>
    <div v-if="!config.loading" class="right-bottom-btns">
      <el-button v-if="publishid" class="opacity-bg" @click="openBrickyViewer">
        <i class="iconfont my-cart" :class="{'green': opts.workTypeid !== 2, 'blue': opts.workTypeid === 2}" /> <span :class="{'green': opts.workTypeid !== 2, 'blue': opts.workTypeid === 2}">{{ $t('i.createPuzzle') }}</span>
      </el-button>
      <el-button v-if="publishid && publish.paradrop" class="opacity-bg" @click="createOrder(publish.publishid, 5, publish.paradrop_state === 1 && !orderState[5], publish.paradrop_state)">
        <i class="iconfont my-airplane" :class="{'green': publish.paradrop_state === 1 && !orderState[5], 'color-66': publish.paradrop_state === -1 || orderState[5]}" />
        <span v-if="publish.paradrop_state === 1 && orderState[5]" class="color-66">{{ $t('i.received') }}</span>
        <span v-if="publish.paradrop_state === 1 && !orderState[5]" class="green">{{ $t('i.claimAirdrop') }}</span>
        <span v-if="publish.paradrop_state === -1" class="color-66">{{ publish.paradrop_tag[0] }}</span>
        <span v-if="publish.paradrop_state === 0">
          <count-down v-if="publish.paradrop_tag[2]" :current-time="publish.now" :start-time="publish.paradrop_tag[2]" :end-time="publish.paradrop_tag[2]" @start_callback="countDownStartCb()" @end_callback="() => {}" />
          <span v-if="!publish.paradrop_tag[2] && publish.paradrop_tag[0]">{{ publish.paradrop_tag[0] }}</span>
        </span>
      </el-button>
      <el-button v-if="publishid && publish.compound" class="opacity-bg" @click="createOrder(publish.publishid, 4, publish.compound_state === 1 && !orderState[4], publish.compound_state)">
        <i class="iconfont my-compound" :class="{'green': publish.compound_state === 1 && !orderState[4], 'color-66': publish.compound_state === -1 || orderState[4]}" />
        <span v-if="publish.compound_state === 1 && orderState[4]" class="color-66">{{ $t('i.alreadyCast') }}</span>
        <span v-if="publish.compound_state === 1 && !orderState[4]" class="green">{{ $t('i.wantCast') }}</span>
        <span v-if="publish.compound_state === -1" class="color-66">{{ publish.compound_tag[0] }}</span>
        <span v-if="publish.compound_state === 0">
          <count-down v-if="publish.compound_tag[2]" :current-time="publish.now" :start-time="publish.compound_tag[2]" :end-time="publish.compound_tag[2]" @start_callback="countDownStartCb()" @end_callback="() => {}" />
          <span v-if="!publish.compound_tag[2] && publish.compound_tag[0]">{{ publish.compound_tag[0] }}</span>
        </span>
      </el-button>
      <el-button v-if="publishid && publish.sell_tag" class="opacity-bg" @click="createOrder(publish.publishid, publish.order_type, publish.btnState, publish.sell_state)">
        <i v-if="!publish.countDown" class="iconfont my-pay" :class="{'green': publish.btnState, 'color-66': publish.sell_state == -1 && !publish.btnState}" />
        <span v-if="!publish.countDown" :class="{'green': publish.btnState, 'color-66': publish.sell_state == -1 && !publish.btnState}">{{ publish.btnTxt }}</span>
        <count-down v-if="publish.countDown" :pre-text="publish.sell_tag[3] || ''" :current-time="publish.now" :start-time="publish.sell_tag[2]" :end-time="publish.sell_tag[2]" @start_callback="countDownStartCb()" @end_callback="() => {}" />
      </el-button>
    </div>
    <div slot="footer" :style="{'width': (isDesktop ? width : width - 15) + 'px'}">
      <el-row class="btns-bar">
        <el-col :span="5">
          <el-badge :value="formateNums(workCount.post_count)" :hidden="!workCount.post_count">
            <el-button class="padding8 opacity-bg" @click="openComment()">
              <i class="iconfont my-comments size20" />
            </el-button>
          </el-badge>
        </el-col>
        <el-col :span="5">
          <el-badge :value="formateNums(workCount.praise_count)" :hidden="!workCount.praise_count">
            <el-button class="padding8 opacity-bg" @click="() => praiseIt(work.workid)">
              <i class="iconfont size20" :class="{ 'my-praise-fill': workCount.praise_state, 'my-praise-blank': !workCount.praise_state }" />
            </el-button>
          </el-badge>
        </el-col>
        <el-col :span="5">
          <el-badge :value="formateNums(workCount.favor_count)" :hidden="!workCount.favor_count">
            <el-button class="padding8 opacity-bg" @click="() => favorIt(work.workid)">
              <i class="iconfont size20" :class="{ 'my-star': workCount.favor_state, 'my-blank-star': !workCount.favor_state }" />
            </el-button>
          </el-badge>
        </el-col>
        <el-col :span="5">
          <el-badge :value="formateNums(workCount.idea_count)" :hidden="!workCount.idea_count">
            <el-button class="iconfont my-design size20 padding8 opacity-bg" :title="$t('i.secondCreation')" @click="toDesign" />
          </el-badge>
        </el-col>
        <el-col :span="5">
          <el-dropdown trigger="click" placement="top-start" @command="handle">
            <el-badge :value="formateNums(workCount.down_count)" :hidden="!workCount.down_count">
              <el-button class="iconfont my-out size20 padding8 opacity-bg" :disabled="playing" :title="$t('i.more')" />
            </el-badge>
            <el-dropdown-menu slot="dropdown" class="my-dropdown">
              <el-dropdown-item command="exportPixel"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />{{ $t('i.exportPixel') }}</div></el-dropdown-item>
              <el-dropdown-item command="exportGridy"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />{{ $t('i.exportGridy') }}</div></el-dropdown-item>
              <el-dropdown-item command="exportBrick"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />{{ $t('i.exportPuzzle') }}</div></el-dropdown-item>
              <el-dropdown-item command="exportAdvance"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />{{ $t('i.advancedExport') }}</div></el-dropdown-item>
              <el-dropdown-item command="publishWork" divided> <i class="iconfont my-zone" /> {{ $t('i.publish') }}...</el-dropdown-item>
              <el-dropdown-item command="share"> <i class="iconfont my-share-out" /> {{ $t('i.copyLink') }}</el-dropdown-item>
              <el-dropdown-item command="pop"> <i class="iconfont my-pop" /> {{ $t('i.createShareImage') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div v-show="false" ref="share-url" class="clipboard" :data-clipboard-text="' ' + getInfo(work) + ' ' + mainHost + '#/?mod=workViewer&workid=' + work.workid" @click="shareIt(work.workid)" />
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import service from '@/js/service'
import conf from '@/js/conf/conf'
import GRIDY from '@/js/sdk/GridySDK'
import voxelViewer from '@/components/viewer/voxelViewer'
import countDown from '@/components/countDown'
import blankImage from '@/assets/blank.png'
export default {
  components: {
    voxelViewer,
    countDown
  },
  mixins: [mixins],
  props: {},
  data() {
    let width, height
    if (this.state.platform.type === 'desktop') {
      width = 632
      height = 632
    } else {
      width = utils.width()
      height = utils.height()
    }
    const palette = conf.palette()
    const defaultOpts = {
      x: 0,
      y: 0,
      initScale: 1,
      defaultGridSize: 8,
      workTypeid: 0,
      gridSize: 8,
      fillShape: '',
      brickfy: false,
      roundTile: false,
      d3: true,
      diy: false,
      diyType: ''
    }
    return {
      mainHost: conf.hosts().mainHost,
      inited: false,
      initingWorkData: false,
      sceneIdx: 0,
      playing: false,
      work: {},
      originWork: {},
      publishid: 0,
      publish: {},
      orderState: {},
      count: 0,
      sto: null,
      imgEl: null,
      voxViewerEl: null,
      GRIDYXLS: null,
      GRIDYLXF: null,
      GRIDYPAPER: null,
      GRIDYBOM: null,
      GRIDY: null,
      touchEndLock: false,
      defaultOpts: defaultOpts,
      opts: utils.deepClone(defaultOpts),
      width: width,
      height: height,
      blankImage: blankImage,
      palette: palette,
      sceneObj: '',
      workTypes: conf.workTypes(),
      pixelOnly: true,
      showInfo: false
    }
  },
  computed: {
    config() {
      return this.view.workViewer
    },
    data() {
      return this.config.data
    },
    title() {
      return this.config.title
    },
    previewAreaStyle() {
      return { 'width': this.width + 'px', 'height': this.height + 'px', 'background': '#000000', 'overflow': 'hidden', 'text-align': 'center' }
    },
    scenes() {
      return (this.work && this.work.canvas) ? this.work.canvas.scenes : []
    },
    canPlay() {
      return this.scenes.length > 1
    },
    series() {
      return this.state.series || {}
    },
    publishes() {
      return this.state.publishes || {}
    },
    followState() {
      return this.state.followState || {}
    },
    workCount() {
      return this.state.workCount || {}
    }
  },
  mounted() {
    if (this.config.show) {
      this.loading(true)
      this.init(() => {
        // eslint-disable-next-line
        if (!this.imgEl.scaleX) Transform(this.imgEl)
        this.opts = utils.deepClone(this.defaultOpts)
        this.getWorkData(this.data.workid || this.config.workid, this.data.userid, (work) => {
          if (!work) {
            this.goto('index')
            return
          }
          service.incCount(this.loginUserId, 'work_view')
          this.opts.paletteId = work.paletteId || 'originPalette'
          // 像素画模式
          if (this.pixelOnly) {
            this.opts.fillShape = ''
            this.opts.workTypeid = 0
          } else {
            this.opts.fillShape = work.fillShape
            this.opts.workTypeid = work.type === 1 ? 2 : 0
          }
          this.work = work
          this.originWork = utils.deepClone(work)
          this.GRIDY = new GRIDY()
          this.GRIDY.setFile(work)
          this.getWorkCount(work.workid)
          this.draw()
        })
      })
    } else {
      if (this.imgEl) {
        this.imgEl.onload = null
        this.imgEl.onerror = null
      }
      this.inited = false
    }
  },
  methods: {
    init(cb) {
      if (this.inited) {
        cb && cb()
        return
      }
      this.imgEl = this.$refs.image
      this.voxViewerEl = this.$refs.voxViewer
      if (!this.imgEl || !this.voxViewerEl) {
        setTimeout(() => {
          this.init(cb)
        }, 10)
        return
      }
      const load = () => {
        this.setImageStyle()
        if (this.opts.d3) {
          const work = this.GRIDY.getFile()
          this.voxViewerEl.setWork(work)
          if (this.opts.workTypeid === 2) {
            // 拼图展示
            this.voxViewerEl.setDiyMod('brick', { frameId: 'whiteWhite' })
          } else {
            this.voxViewerEl.setDiyMod('')
          }
          this.voxViewerEl.launch(() => {
            this.voxViewerEl.draw(this.imgEl, () => {
              this.loading(false)
            })
          })
        } else {
          this.loading(false)
        }
      }
      this.imgEl.onload = load
      this.imgEl.onerror = load
      this.inited = true
      cb && cb()
    },
    showTab(id) {
      if (this.work.type === 1) {
        return id !== 0
      } else {
        return id !== 2
      }
    },
    close() {
      if (this.playing) this.stop()
      this.closePop(this.config)
    },
    loading(show, tip) {
      this.config.loading = show
      this.emit('loading', [!!show, tip || this.$t('i.rending')])
    },
    openGridyViewer(diyType) {
      diyType = this.opts.workTypeid === 2 ? 'brick' : diyType
      if (this.opts.workTypeid === 2) {
        this.work.type = 1
        this.work.paletteId = 'brickfy'
      }
      this.emit('openGridyViewer', [{ data: { work: this.work, type: 'workViewer', diyType: diyType, workTypeid: this.opts.workTypeid, fillShape: '' }}])
    },
    openBrickyViewer() {
      const work = this.GRIDY.getFile()
      if (work.workid) this.emit('workCount', [work.workid, 'diy'])
      const diyType = 'brick'
      this.emit('openBrickyViewer', [{ data: { colorfyId: '', work: work, type: 'workViewer', diyType: diyType, fillShape: '', canback: false }}])
      setTimeout(this.close, 1000)
    },
    // 二次创作
    toDesign() {
      this.emit('workCount', [this.work.workid, 'idea'])
      this.emit('traceEvent', ['toDesign'])
      this.openGridyViewer()
      setTimeout(this.close, 1000)
    },
    // 设置作品类型
    setWorkType(workTypeid) {
      this.opts.workTypeid = workTypeid
      let shapeid = ''
      for (const i in this.workTypes) {
        if (this.workTypes[i].id === this.opts.workTypeid) {
          shapeid = this.workTypes[i].shapeid
        }
      }
      this.emit('traceEvent', ['setWorkType_work', '', { shapeid: shapeid, workTypeid: workTypeid }])
      this.opts.paletteId = this.opts.workTypeid === 2 ? 'brickfy' : ''
      this.setShape(shapeid, false)
    },
    // 设置网格形状
    setShape(shapeid, trace = true) {
      this.loading(true)
      setTimeout(() => {
        this.opts.fillShape = shapeid
        this.draw()
      }, 100)
      if (trace) this.emit('traceEvent', ['setShape', '', { shapeid: shapeid }])
    },
    viewThread(threadid) {
      this.emit('goto', ['thread', { threadid: threadid }, true])
      this.emit('traceEvent', ['viewThread', '', { threadid: threadid }])
    },
    openComment() {
      this.view.comment.work = this.work
      this.goto('comment')
    },
    // 获取发行信息
    getPublish(publishid, cb) {
      service.get('publish', publishid, (ret, type) => {
        if (type === 'success') {
          if (ret.data && ret.data.items) {
            for (var i in ret.data.items) {
              const item = ret.data.items[i]
              this.state.publishes[item.publishid] = item
            }
          }
          if (ret.data && ret.data.series) {
            for (var seriesid in ret.data.series) {
              this.state.series[seriesid] = ret.data.series[seriesid]
            }
          }
        }
        cb && cb()
      }, false)
    },
    // 获取作品计数器
    getWorkCount(workid) {
      service.get('work_count', workid, (ret, type) => {
        if (type === 'success') {
          this.state.workCount = ret.data || {}
          this.work.threadid = ret.data.threadid || 0
          if (!this.work.threadid) this.work.postid = 0
        }
      }, false)
    },
    // 网络加载作品数据
    getWorkData(workid, userid, cb) {
      if (this.loginUserId !== parseInt(userid)) userid = ''
      service.get('work_data', workid, (ret, type) => {
        if (type === 'success' && ret.data && ret.data.data) {
          const data = ret.data
          const work = utils.deepClone(data.data)
          work.workid = data.workid
          work.userid = data.userid
          work.threadid = data.threadid || 0
          work.postid = data.postid || 0
          work.publish = true
          work.public = data.public || false
          work.best = data.best || false
          work.check = data.check || false
          work.flag = data.flag || false
          work.hot = data.hot || false
          work.original = data.original || false
          work.updateTime = work.updateTime || data.public_at * 1000
          work.publishid = data.publishid
          if (work.publishid) {
            this.getPublish(work.publishid, () => {
              if (this.state.publishes[work.publishid]) {
                this.publish = this.state.publishes[work.publishid] || {}
                this.getOrderState(work.publishid)
                this.publishid = work.publishid
              } else {
                work.publishid = 0
                this.publishid = 0
              }
            })
          }
          if (!this.users[data.userid]) {
            this.emit('getUser', [data.userid])
          }
          cb && cb(work)
        } else {
          cb && cb()
          this.loading(false)
        }
      }, false, { userid: userid }, false)
    },
    toggleWorkInfo() {
      this.showInfo = !this.showInfo
    },
    getAvatar(userid) {
      if (this.users[userid]) return this.getUserAvatar(this.users[userid])
    },
    getUserAvatar(user) {
      const ver = Math.round(new Date(user.avatar_at) / 1000)
      if (user.avatar) return service.getCosUrl(user.avatar, 'avatar', ver) || ''
    },
    // 更新数据
    updateItems() {
      const data = this.state.updateWork[this.work.workid]
      if (data) {
        for (const k in data) {
          this.file[k] = data[k]
        }
      }
      this.update()
    },
    getRelateWork(userid, workid, direction) {
      this.showInfo = false
      this.loading(true)
      direction = direction || 'down'
      const params = {
        'userid': userid,
        'workid': workid,
        'direction': direction,
        'limit': 1
      }
      service.actionGet('work_data', params, (res, type) => {
        if (type === 'success' && res && res.data && Array.isArray(res.data) && res.data.length) {
          service.incCount(this.loginUserId, 'work_view')
          this.emit('useRemoteWork', [res.data[0], true])
        } else {
          this.loading(false)
        }
      }, false, false)
    },
    countDownStartCb() {
      this.emit('getPublish')
    },
    getOrderState(publishid) {
      this.orderState = {}
      if (publishid) {
        service.actionGet('publish', { type: 'all_order_status', id: publishid }, (dt, type) => {
          if (type === 'success' && dt && dt.data) {
            for (var i in dt.data) {
              this.orderState[dt.data[i].type] = dt.data[i]
            }
          }
          this.calPublishOrderState()
          this.update()
        }, true)
      }
    },
    // order_type: 1=申购; 2=优先购; 3=发售; 4=铸造; 5=空投;
    calPublishOrderState() {
      const getState = (order_type) => {
        if (order_type === 3 || order_type === 2) {
          this.publish.btnTxt = (this.orderState[order_type] && this.orderState[order_type].pay_status === 1) ? this.$t('i.purchased') : (order_type === 3 ? this.$t('i.buyIt') : this.$t('i.preemptiveRights'))
          this.publish.btnState = !this.orderState[order_type] || this.orderState[order_type].pay_status !== 1
        } else if (order_type === 1) {
          this.publish.btnTxt = this.orderState[order_type].preorder_status === 1 ? (this.orderState[order_type].pay_status === 1 ? this.$t('i.purchased') : this.$t('i.payWin')) : (this.orderState[order_type].preorder_status === -1 ? this.$t('i.notWin') : this.$t('i.subscribed'))
          this.publish.btnState = this.orderState[order_type].preorder_status === 1 && this.orderState[order_type].pay_status !== 1
        } else if (order_type === 0) {
          this.publish.btnTxt = this.publish.order_type === 1 ? this.$t('i.wantSubscribed') : (this.publish.order_type === 2 ? this.$t('i.preemptiveRights') : this.$t('i.buyIt'))
          this.publish.btnState = true
        } else if (order_type === -1) {
          this.publish.btnTxt = this.$t('i.sellOut')
          this.publish.btnState = false
        }
      }
      this.publish.btnTxt = ''
      // sell_state 售卖状态： -1=售罄 0=不可购买 1=可购买
      if (this.publish.sell_state === 0) {
        if (this.publish.sell_tag[2]) {
          this.publish.countDown = true
          // 有申购订单
          if (this.orderState[1]) {
            this.publish.countDown = this.orderState[1].preorder_status !== 1
            getState(1)
          }
        } else {
          this.publish.btnTxt = this.publish.sell_tag[0]
          this.publish.btnState = false
        }
      } else if (this.publish.sell_state === 1) {
        if (this.publish.order_type === 1) {
          if (this.orderState[1]) {
            getState(1)
          } else {
            getState(0)
          }
        } else if (this.publish.order_type === 2) {
          if (this.orderState[2]) {
            getState(2)
          } else if (this.orderState[1]) {
            getState(1)
          } else {
            getState(0)
          }
        } else if (this.publish.order_type === 3) {
          if (this.orderState[3]) {
            getState(3)
          } else if (this.orderState[2]) {
            getState(2)
          } else if (this.orderState[1]) {
            if (this.orderState[1].preorder_status === 1) {
              getState(1)
            } else {
              getState(0)
            }
          } else {
            getState(3)
          }
        } else {
          getState(0)
        }
      } else if (this.publish.sell_state === -1) {
        getState(-1)
      }
    },
    // order_type: 1=申购; 2=优先购; 3=发售; 4=铸造; 5=空投;
    createOrder(publishid, order_type, state, sale_state) {
      if (state) {
        if (!this.loginStatus) {
          this.message('', 'login')
          return
        }
        const pay_cb = (status, pay_status) => {
          if (status) {
            this.getOrderState(publishid)
          } else if (typeof pay_status !== 'undefined' && pay_status < 0) {
            delete this.orderState[order_type]
            this.calPublishOrderState()
            this.update()
          }
        }
        if (this.orderState[order_type] && this.orderState[order_type].nft_orderid) {
          if (order_type === 1) {
            if (this.orderState[order_type].preorder_status === 1) {
              this.showPayment(this.orderState[order_type], pay_cb)
            } else if (this.orderState[order_type].preorder_status === 0) {
              this.alert(this.$t('i.waitingDraw'))
              return
            }
          } else if (order_type === 2 || order_type === 3) {
            this.showPayment(this.orderState[order_type], pay_cb)
          }
          return
        }
        const orderNow = () => {
          service.post('nft_order', { publishid: publishid, type: order_type }, (dt, type) => {
            if (type === 'success') {
              // 构造数据
              this.orderState[order_type] = { nft_status: 0, pay_status: 0, preorder_status: 0, type: order_type, amount: this.publish.amount, nft_orderid: (dt.data && dt.data.nft_orderid) ? dt.data.nft_orderid : 0 }
              this.calPublishOrderState()
              if (order_type === 1) {
                this.alert(this.$t('i.waitingDraw'))
              } else if (order_type === 2 || order_type === 3) {
                this.showPayment(this.orderState[order_type], pay_cb)
              } else if (order_type === 4) {
                this.view.pop.show = false
                this.alert(this.$t('i.castOk'))
              } else if (order_type === 5) {
                this.alert(this.$t('i.claimed'))
              }
              service.incCount(this.loginUserId, 'nft_order_type_' + order_type)
              this.update()
            } else {
              if (type === 'login') {
                this.message(dt, type)
              } else {
                this.alert(dt)
              }
            }
          }, true)
        }
        if (order_type === 4) {
          this.showCompound(orderNow)
        } else {
          orderNow()
        }
      } else {
        if (sale_state === 0) this.alert(this.$t('i.waiting'))
      }
    },
    showPayment(order, cb) {
      this.view.pop.title = this.$t('i.pay')
      this.view.pop.tip = ''
      this.view.pop.type = 'payment_nft'
      this.view.pop.data = order || {}
      this.view.pop.show = true
      this.view.pop.fn = cb
    },
    showCompound(cb) {
      this.view.pop.title = this.$t('i.casting')
      this.view.pop.tip = ''
      this.view.pop.type = 'compound'
      this.view.pop.data = this.publish
      this.view.pop.show = true
      this.view.pop.fn = cb
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    formateMoney: utils.formateMoney,
    getWorkTags() {
      if (!this.work.tags) return ''
      let tags = ''
      if (this.work.tags && this.work.tags.length) {
        tags = '#' + this.work.tags.join('# #') + '#'
      }
      return tags
    },
    // 关注
    follow(userid) {
      this.emit('follow', [userid, this.update])
      this.update()
    },
    // 取消关注
    unfollow(userid) {
      this.emit('unfollow', [userid, this.update])
      this.update()
    },
    // 喜欢
    praiseIt(workid) {
      if (!workid) return
      const praise = () => {
        if (this.workCount.praise_state) {
          this.workCount.praise_state = 0
          this.workCount.praise_count--
          service.decCount(this.loginUserId, 'work_praise')
        } else {
          this.workCount.praise_state = 1
          this.workCount.praise_count++
          service.incCount(this.loginUserId, 'work_praise')
        }
        this.workCount.praise_count = Math.max(0, this.workCount.praise_count)
      }
      praise()
      service.get('work_praise', workid || 0, (dt, type) => {
        if (type !== 'success') {
          // 失败回滚
          praise()
          this.message(dt, type)
        }
      }, true)
    },
    // 收藏
    favorIt(workid) {
      if (!workid) return
      let albumTip = false
      const favor = () => {
        if (this.workCount.favor_state) {
          this.workCount.favor_state = 0
          this.workCount.favor_count--
          service.decCount(this.loginUserId, 'work_favor')
        } else {
          this.workCount.favor_state = 1
          this.workCount.favor_count++
          service.incCount(this.loginUserId, 'work_favor')
          albumTip = true
        }
        this.workCount.favor_count = Math.max(0, this.workCount.favor_count)
      }
      favor()
      service.get('work_favor', workid, (dt, type) => {
        if (type !== 'success') {
          // 失败回滚
          favor()
          this.message(dt, type)
        } else if (type === 'success' && albumTip) {
          this.myNotify(true, this.$t('i.favorOk'), this.$t('i.addAlbum'), () => {
            this.myNotify(false)
            this.view.albumSelector.workid = workid
            this.view.albumSelector.show = true
          })
        }
      }, true)
    },
    // 分享
    shareIt(workid) {
      if (!workid) {
        this.alert(this.$t('i.publishAndShare'), () => {
          this.emit('publishWork', '')
        })
        return
      }
      this.workCount.share_state = 1
      this.workCount.share_count++
      // eslint-disable-next-line
      var clipboard = new ClipboardJS('.clipboard')
      clipboard.on('success', (e) => {
        e.clearSelection()
        this.message(this.$t('i.copyAndShare'), 'success')
        clipboard.destroy()
      })
      service.get('work_share', workid)
      service.incCount(this.loginUserId, 'work_share')
    },
    setImageStyle() {
      if (!this.imgEl || !this.imgEl.width || !this.imgEl.height) return
      const left = (this.width - this.imgEl.width) / 2
      const top = (this.height - this.imgEl.height) / 2
      this.imgEl.style.left = left + 'px'
      this.imgEl.style.top = top + 'px'
      this.imgEl.style.position = 'relative'
    },
    getColorfyWork(colorfyObj, work) {
      work = work || utils.deepClone(this.GRIDY.getFile())
      if (!colorfyObj) return work
      colorfyObj.name = work.name
      colorfyObj.userid = this.loginUserId
      colorfyObj.fillShape = ''
      if (work.canvas.scenes[0].objs.length === 1) {
        // 单对象改写数据
        work.canvas.scenes[0].objs[0].x = 0
        work.canvas.scenes[0].objs[0].y = 0
        work.canvas.scenes[0].objs[0].data = colorfyObj.data
        work.canvas.scenes[0].objs[0].cols = colorfyObj.cols
        work.canvas.scenes[0].objs[0].rows = colorfyObj.rows
      } else {
        // 多对象合并掉
        const newObj = this.GRIDY.createObj(colorfyObj)
        work.canvas.scenes[0].objs = [newObj]
      }
      work.canvas.cols = colorfyObj.cols
      work.canvas.rows = colorfyObj.rows
      return work
    },
    handle(act) {
      let file = utils.deepClone(this.GRIDY.getFile())
      if (act === 'exportPixel' || act === 'exportGridy' || act === 'exportBrick' || act === 'exportAdvance') {
        this.emit('showExportSheet', [file, act, this.opts, this.sceneIdx, this.voxViewerEl, true])
      } else if (act === 'workActionSheet') {
        this.emit('showActionSheet', [this.work.userid, 'work', this.work])
      } else if (act === 'publishWork') {
        if (this.opts.workTypeid === 2) {
          // 改写对象数据
          if (this.sceneObj) {
            file = this.getColorfyWork(this.sceneObj)
          }
          file.type = 1
        } else {
          file.type = 0
        }
        this.emit('publishWork', [file, false])
      } else if (act === 'share') {
        this.$refs['share-url'].click()
      } else if (act === 'pop') {
        this.emit('exportFile', [file, 'pop', 'pop', this.opts, this.sceneIdx])
      }
    },
    getInfo(file) {
      return (file.name || '') + ' ' + this.getWorkTags(file) + ' ' + (file.info || '')
    },
    ease(x) {
      return Math.sqrt(1 - Math.pow(x - 1, 2))
    },
    draw() {
      // this.loading(true)
      this.sceneObj = ''
      const options = { gridSize: this.opts.gridSize, fillShape: this.opts.fillShape, paletteId: this.opts.paletteId, workTypeid: this.opts.workTypeid, darkMatch: false }
      if (this.opts.d3) options.gridSize = 16
      if (this.opts.workTypeid === 2) {
        options.bgId = 'white'
        options.fn = (sceneObj) => {
          this.sceneObj = sceneObj
        }
      }
      this.imgEl.src = this.GRIDY.getSceneImage(this.sceneIdx, options)
    },
    submit() {
      this.stop()
      setTimeout(() => {
        const file = this.GRIDY.getFile()
        file.fillShape = this.opts.fillShape
        this.config.fn && this.config.fn(file, 'JSON')
      }, 10)
    },
    // 开始播放
    start() {
      this.playing = true
      this.count = 0
      this.play()
    },
    // 上一个
    pre() {
      const scenes = this.work.canvas.scenes
      if (this.sceneIdx === 0) {
        this.sceneIdx = scenes.length - 1
      } else if (this.sceneIdx) {
        this.sceneIdx = this.sceneIdx - 1
      }
      this.draw()
    },
    // 下一个
    next() {
      const scenes = this.work.canvas.scenes
      if (this.sceneIdx === scenes.length - 1) {
        this.sceneIdx = 0
      } else if (this.sceneIdx < scenes.length - 1) {
        this.sceneIdx = this.sceneIdx + 1
      }
      this.draw()
    },
    // 播放场景
    play() {
      const scenes = this.work.canvas.scenes
      if (scenes.length < 2 || !this.playing) {
        return
      }
      this.count++
      if (this.sceneIdx === scenes.length - 1) {
        this.sceneIdx = 0
      } else if (this.sceneIdx < scenes.length - 1) {
        this.sceneIdx = this.sceneIdx + 1
      }
      this.draw()
      this.work.rate = this.work.rate || 12
      this.work.loop = this.work.loop || 0
      if (this.work.loop && this.count / scenes.length >= this.work.loop) {
        this.count = 0
        this.playing = false
        return
      }
      this.sto = setTimeout(this.play, 1000 / this.work.rate)
    },
    // 停止播放
    stop() {
      this.playing = false
      this.count = 0
      this.sceneIdx = 0
      this.draw()
      if (this.sto) clearTimeout(this.sto)
    }
  }
}
</script>
