export default {
  'name': '黄豆豆',
  'data': [
    {
      'hex': '#FAF5CD',
      'id': 'A1',
      'order': 1
    },
    {
      'hex': '#DFF139',
      'id': 'B1',
      'order': 1
    },
    {
      'hex': '#F0FEE4',
      'id': 'C1',
      'order': 1
    },
    {
      'hex': '#ACB7EF',
      'id': 'D1',
      'order': 1
    },
    {
      'hex': '#F6D4CB',
      'id': 'E1',
      'order': 1
    },
    {
      'hex': '#FF9280',
      'id': 'F1',
      'order': 1
    },
    {
      'hex': '#FFE4D3',
      'id': 'G1',
      'order': 1
    },
    {
      'hex': '#FBFBFB',
      'id': 'H1',
      'order': 1
    },
    {
      'hex': '#BBC6B6',
      'id': 'M1',
      'order': 1
    },
    {
      'hex': '#F9F9F9',
      'id': 'P1',
      'order': 1
    },
    {
      'hex': '#D40E1F',
      'id': 'R1',
      'order': 1
    },
    {
      'hex': '#FF6FB7',
      'id': 'Y1',
      'order': 1
    },
    {
      'hex': '#DAABB3',
      'id': 'Z1',
      'order': 1
    },
    {
      'hex': '#FCFED6',
      'id': 'A2',
      'order': 2
    },
    {
      'hex': '#64F343',
      'id': 'B2',
      'order': 2
    },
    {
      'hex': '#ABF8FE',
      'id': 'C2',
      'order': 2
    },
    {
      'hex': '#868DD3',
      'id': 'D2',
      'order': 2
    },
    {
      'hex': '#FCC1DD',
      'id': 'E2',
      'order': 2
    },
    {
      'hex': '#F73D4B',
      'id': 'F2',
      'order': 2
    },
    {
      'hex': '#FCC6AC',
      'id': 'G2',
      'order': 2
    },
    {
      'hex': '#FFFFFF',
      'id': 'H2',
      'order': 2
    },
    {
      'hex': '#909994',
      'id': 'M2',
      'order': 2
    },
    {
      'hex': '#ABABAB',
      'id': 'P2',
      'order': 2
    },
    {
      'hex': '#F13484',
      'id': 'R2',
      'order': 2
    },
    {
      'hex': '#FDB583',
      'id': 'Y2',
      'order': 2
    },
    {
      'hex': '#D6AA87',
      'id': 'Z2',
      'order': 2
    },
    {
      'hex': '#FCFF92',
      'id': 'A3',
      'order': 3
    },
    {
      'hex': '#9FF685',
      'id': 'B3',
      'order': 3
    },
    {
      'hex': '#9EE0F8',
      'id': 'C3',
      'order': 3
    },
    {
      'hex': '#3653AF',
      'id': 'D3',
      'order': 3
    },
    {
      'hex': '#F6BDE8',
      'id': 'E3',
      'order': 3
    },
    {
      'hex': '#EF4D3E',
      'id': 'F3',
      'order': 3
    },
    {
      'hex': '#F1C4A5',
      'id': 'G3',
      'order': 3
    },
    {
      'hex': '#B4B4B4',
      'id': 'H3',
      'order': 3
    },
    {
      'hex': '#697E80',
      'id': 'M3',
      'order': 3
    },
    {
      'hex': '#B6DBAF',
      'id': 'P3',
      'order': 3
    },
    {
      'hex': '#FB852B',
      'id': 'R3',
      'order': 3
    },
    {
      'hex': '#D8FCA4',
      'id': 'Y3',
      'order': 3
    },
    {
      'hex': '#C1BD8D',
      'id': 'Z3',
      'order': 3
    },
    {
      'hex': '#F7EC5C',
      'id': 'A4',
      'order': 4
    },
    {
      'hex': '#5FDF34',
      'id': 'B4',
      'order': 4
    },
    {
      'hex': '#44CDFB',
      'id': 'C4',
      'order': 4
    },
    {
      'hex': '#162C7E',
      'id': 'D4',
      'order': 4
    },
    {
      'hex': '#E9639E',
      'id': 'E4',
      'order': 4
    },
    {
      'hex': '#F92B40',
      'id': 'F4',
      'order': 4
    },
    {
      'hex': '#DCB387',
      'id': 'G4',
      'order': 4
    },
    {
      'hex': '#878787',
      'id': 'H4',
      'order': 4
    },
    {
      'hex': '#E0D4BC',
      'id': 'M4',
      'order': 4
    },
    {
      'hex': '#FEA2A3',
      'id': 'P4',
      'order': 4
    },
    {
      'hex': '#F8ED33',
      'id': 'R4',
      'order': 4
    },
    {
      'hex': '#91DAFB',
      'id': 'Y4',
      'order': 4
    },
    {
      'hex': '#96B69F',
      'id': 'Z4',
      'order': 4
    },
    {
      'hex': '#F0D83A',
      'id': 'A5',
      'order': 5
    },
    {
      'hex': '#39E158',
      'id': 'B5',
      'order': 5
    },
    {
      'hex': '#06ABE3',
      'id': 'C5',
      'order': 5
    },
    {
      'hex': '#B34EC6',
      'id': 'D5',
      'order': 5
    },
    {
      'hex': '#F1559F',
      'id': 'E5',
      'order': 5
    },
    {
      'hex': '#E30328',
      'id': 'F5',
      'order': 5
    },
    {
      'hex': '#E7B34E',
      'id': 'G5',
      'order': 5
    },
    {
      'hex': '#464648',
      'id': 'H5',
      'order': 5
    },
    {
      'hex': '#D0CBAE',
      'id': 'M5',
      'order': 5
    },
    {
      'hex': '#EB903F',
      'id': 'P5',
      'order': 5
    },
    {
      'hex': '#32C958',
      'id': 'R5',
      'order': 5
    },
    {
      'hex': '#E987EA',
      'id': 'Y5',
      'order': 5
    },
    {
      'hex': '#849DC6',
      'id': 'Z5',
      'order': 5
    },
    {
      'hex': '#FDA951',
      'id': 'A6',
      'order': 6
    },
    {
      'hex': '#64E0A4',
      'id': 'B6',
      'order': 6
    },
    {
      'hex': '#54A7E9',
      'id': 'C6',
      'order': 6
    },
    {
      'hex': '#B37BDC',
      'id': 'D6',
      'order': 6
    },
    {
      'hex': '#EC4072',
      'id': 'E6',
      'order': 6
    },
    {
      'hex': '#913635',
      'id': 'F6',
      'order': 6
    },
    {
      'hex': '#E3A014',
      'id': 'G6',
      'order': 6
    },
    {
      'hex': '#2C2C2C',
      'id': 'H6',
      'order': 6
    },
    {
      'hex': '#B0AA86',
      'id': 'M6',
      'order': 6
    },
    {
      'hex': '#63CEA2',
      'id': 'P6',
      'order': 6
    },
    {
      'hex': '#1EBA93',
      'id': 'R6',
      'order': 6
    },
    {
      'hex': '#F7D4E8',
      'id': 'Y6',
      'order': 6
    },
    {
      'hex': '#94BFE2',
      'id': 'Z6',
      'order': 6
    },
    {
      'hex': '#FA8C4F',
      'id': 'A7',
      'order': 7
    },
    {
      'hex': '#3EAE7C',
      'id': 'B7',
      'order': 7
    },
    {
      'hex': '#3977CC',
      'id': 'C7',
      'order': 7
    },
    {
      'hex': '#8758A9',
      'id': 'D7',
      'order': 7
    },
    {
      'hex': '#C63674',
      'id': 'E7',
      'order': 7
    },
    {
      'hex': '#911932',
      'id': 'F7',
      'order': 7
    },
    {
      'hex': '#985C3A',
      'id': 'G7',
      'order': 7
    },
    {
      'hex': '#010101',
      'id': 'H7',
      'order': 7
    },
    {
      'hex': '#B0A796',
      'id': 'M7',
      'order': 7
    },
    {
      'hex': '#E79273',
      'id': 'P7',
      'order': 7
    },
    {
      'hex': '#1D779C',
      'id': 'R7',
      'order': 7
    },
    {
      'hex': '#F1FA7D',
      'id': 'Y7',
      'order': 7
    },
    {
      'hex': '#E2A9D2',
      'id': 'Z7',
      'order': 7
    },
    {
      'hex': '#FDD94D',
      'id': 'A8',
      'order': 8
    },
    {
      'hex': '#1D9B54',
      'id': 'B8',
      'order': 8
    },
    {
      'hex': '#0F52BD',
      'id': 'C8',
      'order': 8
    },
    {
      'hex': '#E3D2FE',
      'id': 'D8',
      'order': 8
    },
    {
      'hex': '#FDDBE9',
      'id': 'E8',
      'order': 8
    },
    {
      'hex': '#BB0126',
      'id': 'F8',
      'order': 8
    },
    {
      'hex': '#713D2F',
      'id': 'G8',
      'order': 8
    },
    {
      'hex': '#E7D6DC',
      'id': 'H8',
      'order': 8
    },
    {
      'hex': '#AE8082',
      'id': 'M8',
      'order': 8
    },
    {
      'hex': '#ECDB59',
      'id': 'P8',
      'order': 8
    },
    {
      'hex': '#1960C8',
      'id': 'R8',
      'order': 8
    },
    {
      'hex': '#5EE88C',
      'id': 'Y8',
      'order': 8
    },
    {
      'hex': '#AB91C0',
      'id': 'Z8',
      'order': 8
    },
    {
      'hex': '#F99C5F',
      'id': 'A9',
      'order': 9
    },
    {
      'hex': '#2A5037',
      'id': 'B9',
      'order': 9
    },
    {
      'hex': '#3349C3',
      'id': 'C9',
      'order': 9
    },
    {
      'hex': '#D6BAF5',
      'id': 'D9',
      'order': 9
    },
    {
      'hex': '#E575C7',
      'id': 'E9',
      'order': 9
    },
    {
      'hex': '#E0677A',
      'id': 'F9',
      'order': 9
    },
    {
      'hex': '#E4B685',
      'id': 'G9',
      'order': 9
    },
    {
      'hex': '#EFEDEE',
      'id': 'H9',
      'order': 9
    },
    {
      'hex': '#A88764',
      'id': 'M9',
      'order': 9
    },
    {
      'hex': '#DBD9DA',
      'id': 'P9',
      'order': 9
    },
    {
      'hex': '#945AB1',
      'id': 'R9',
      'order': 9
    },
    {
      'hex': '#F8F5FE',
      'id': 'Y9',
      'order': 9
    },
    {
      'hex': '#F47E36',
      'id': 'A10',
      'order': 10
    },
    {
      'hex': '#9AD1BA',
      'id': 'B10',
      'order': 10
    },
    {
      'hex': '#3DBBE3',
      'id': 'C10',
      'order': 10
    },
    {
      'hex': '#301A49',
      'id': 'D10',
      'order': 10
    },
    {
      'hex': '#D33997',
      'id': 'E10',
      'order': 10
    },
    {
      'hex': '#874628',
      'id': 'F10',
      'order': 10
    },
    {
      'hex': '#DA8C42',
      'id': 'G10',
      'order': 10
    },
    {
      'hex': '#ECEAEB',
      'id': 'H10',
      'order': 10
    },
    {
      'hex': '#C6B2BB',
      'id': 'M10',
      'order': 10
    },
    {
      'hex': '#DBC7EA',
      'id': 'P10',
      'order': 10
    },
    {
      'hex': '#F8DA54',
      'id': 'R10',
      'order': 10
    },
    {
      'hex': '#FEDB99',
      'id': 'A11',
      'order': 11
    },
    {
      'hex': '#627032',
      'id': 'B11',
      'order': 11
    },
    {
      'hex': '#2ADED3',
      'id': 'C11',
      'order': 11
    },
    {
      'hex': '#BCBAE2',
      'id': 'D11',
      'order': 11
    },
    {
      'hex': '#F7DAD4',
      'id': 'E11',
      'order': 11
    },
    {
      'hex': '#592323',
      'id': 'F11',
      'order': 11
    },
    {
      'hex': '#DAC898',
      'id': 'G11',
      'order': 11
    },
    {
      'hex': '#CDCDCD',
      'id': 'H11',
      'order': 11
    },
    {
      'hex': '#9D7693',
      'id': 'M11',
      'order': 11
    },
    {
      'hex': '#F1E9D4',
      'id': 'P11',
      'order': 11
    },
    {
      'hex': '#FCECF7',
      'id': 'R11',
      'order': 11
    },
    {
      'hex': '#FDA276',
      'id': 'A12',
      'order': 12
    },
    {
      'hex': '#1A6E3D',
      'id': 'B12',
      'order': 12
    },
    {
      'hex': '#1E334E',
      'id': 'C12',
      'order': 12
    },
    {
      'hex': '#DC99CE',
      'id': 'D12',
      'order': 12
    },
    {
      'hex': '#F893BF',
      'id': 'E12',
      'order': 12
    },
    {
      'hex': '#F8516D',
      'id': 'F12',
      'order': 12
    },
    {
      'hex': '#FEC993',
      'id': 'G12',
      'order': 12
    },
    {
      'hex': '#FDF6EE',
      'id': 'H12',
      'order': 12
    },
    {
      'hex': '#644B51',
      'id': 'M12',
      'order': 12
    },
    {
      'hex': '#E9EDEE',
      'id': 'P12',
      'order': 12
    },
    {
      'hex': '#D8D4D3',
      'id': 'R12',
      'order': 12
    },
    {
      'hex': '#FEC667',
      'id': 'A13',
      'order': 13
    },
    {
      'hex': '#C8E87D',
      'id': 'B13',
      'order': 13
    },
    {
      'hex': '#CDE7FE',
      'id': 'C13',
      'order': 13
    },
    {
      'hex': '#B5038F',
      'id': 'D13',
      'order': 13
    },
    {
      'hex': '#B5026A',
      'id': 'E13',
      'order': 13
    },
    {
      'hex': '#F45C45',
      'id': 'F13',
      'order': 13
    },
    {
      'hex': '#B2714B',
      'id': 'G13',
      'order': 13
    },
    {
      'hex': '#F4EFD1',
      'id': 'H13',
      'order': 13
    },
    {
      'hex': '#C79266',
      'id': 'M13',
      'order': 13
    },
    {
      'hex': '#ADCBF1',
      'id': 'P13',
      'order': 13
    },
    {
      'hex': '#56534E',
      'id': 'R13',
      'order': 13
    },
    {
      'hex': '#F85842',
      'id': 'A14',
      'order': 14
    },
    {
      'hex': '#ACE84C',
      'id': 'B14',
      'order': 14
    },
    {
      'hex': '#D6FDFC',
      'id': 'C14',
      'order': 14
    },
    {
      'hex': '#882893',
      'id': 'D14',
      'order': 14
    },
    {
      'hex': '#FAD4BF',
      'id': 'E14',
      'order': 14
    },
    {
      'hex': '#FCADB2',
      'id': 'F14',
      'order': 14
    },
    {
      'hex': '#8B684C',
      'id': 'G14',
      'order': 14
    },
    {
      'hex': '#CED7D4',
      'id': 'H14',
      'order': 14
    },
    {
      'hex': '#C37463',
      'id': 'M14',
      'order': 14
    },
    {
      'hex': '#337BAD',
      'id': 'P14',
      'order': 14
    },
    {
      'hex': '#A3E7DC',
      'id': 'R14',
      'order': 14
    },
    {
      'hex': '#FBF65E',
      'id': 'A15',
      'order': 15
    },
    {
      'hex': '#305335',
      'id': 'B15',
      'order': 15
    },
    {
      'hex': '#21C5C4',
      'id': 'C15',
      'order': 15
    },
    {
      'hex': '#2F1E8E',
      'id': 'D15',
      'order': 15
    },
    {
      'hex': '#F5C9CA',
      'id': 'E15',
      'order': 15
    },
    {
      'hex': '#D50527',
      'id': 'F15',
      'order': 15
    },
    {
      'hex': '#F6F8E3',
      'id': 'G15',
      'order': 15
    },
    {
      'hex': '#98A6A6',
      'id': 'H15',
      'order': 15
    },
    {
      'hex': '#747D7A',
      'id': 'M15',
      'order': 15
    },
    {
      'hex': '#668575',
      'id': 'P15',
      'order': 15
    },
    {
      'hex': '#78CEE7',
      'id': 'R15',
      'order': 15
    },
    {
      'hex': '#FEFF97',
      'id': 'A16',
      'order': 16
    },
    {
      'hex': '#C0ED9C',
      'id': 'B16',
      'order': 16
    },
    {
      'hex': '#1858A2',
      'id': 'C16',
      'order': 16
    },
    {
      'hex': '#E2E4F0',
      'id': 'D16',
      'order': 16
    },
    {
      'hex': '#FBF4EC',
      'id': 'E16',
      'order': 16
    },
    {
      'hex': '#F8C0A9',
      'id': 'F16',
      'order': 16
    },
    {
      'hex': '#F2D8C1',
      'id': 'G16',
      'order': 16
    },
    {
      'hex': '#1B1213',
      'id': 'H16',
      'order': 16
    },
    {
      'hex': '#FDC24E',
      'id': 'P16',
      'order': 16
    },
    {
      'hex': '#3FCDCE',
      'id': 'R16',
      'order': 16
    },
    {
      'hex': '#FCFDFF',
      'id': 'T1',
      'order': 16
    },
    {
      'hex': '#FDE173',
      'id': 'A17',
      'order': 17
    },
    {
      'hex': '#9EB33E',
      'id': 'B17',
      'order': 17
    },
    {
      'hex': '#02D1F3',
      'id': 'C17',
      'order': 17
    },
    {
      'hex': '#C7D3F9',
      'id': 'D17',
      'order': 17
    },
    {
      'hex': '#F7E3EC',
      'id': 'E17',
      'order': 17
    },
    {
      'hex': '#E89B7D',
      'id': 'F17',
      'order': 17
    },
    {
      'hex': '#79544E',
      'id': 'G17',
      'order': 17
    },
    {
      'hex': '#F0EEEF',
      'id': 'H17',
      'order': 17
    },
    {
      'hex': '#FDA42E',
      'id': 'P17',
      'order': 17
    },
    {
      'hex': '#4E8379',
      'id': 'R17',
      'order': 17
    },
    {
      'hex': '#FCBF80',
      'id': 'A18',
      'order': 18
    },
    {
      'hex': '#E6ED4F',
      'id': 'B18',
      'order': 18
    },
    {
      'hex': '#213244',
      'id': 'C18',
      'order': 18
    },
    {
      'hex': '#9A64B8',
      'id': 'D18',
      'order': 18
    },
    {
      'hex': '#FBC8DB',
      'id': 'E18',
      'order': 18
    },
    {
      'hex': '#D07E4A',
      'id': 'F18',
      'order': 18
    },
    {
      'hex': '#FFE4D6',
      'id': 'G18',
      'order': 18
    },
    {
      'hex': '#FCFFF8',
      'id': 'H18',
      'order': 18
    },
    {
      'hex': '#FEBDA7',
      'id': 'P18',
      'order': 18
    },
    {
      'hex': '#7DCA9C',
      'id': 'R18',
      'order': 18
    },
    {
      'hex': '#FD7E77',
      'id': 'A19',
      'order': 19
    },
    {
      'hex': '#26B78E',
      'id': 'B19',
      'order': 19
    },
    {
      'hex': '#18869D',
      'id': 'C19',
      'order': 19
    },
    {
      'hex': '#D8C2D9',
      'id': 'D19',
      'order': 19
    },
    {
      'hex': '#F6BBD1',
      'id': 'E19',
      'order': 19
    },
    {
      'hex': '#BE454A',
      'id': 'F19',
      'order': 19
    },
    {
      'hex': '#DD7D41',
      'id': 'G19',
      'order': 19
    },
    {
      'hex': '#F2EEE5',
      'id': 'H19',
      'order': 19
    },
    {
      'hex': '#FFDEE9',
      'id': 'P19',
      'order': 19
    },
    {
      'hex': '#C8E664',
      'id': 'R19',
      'order': 19
    },
    {
      'hex': '#F9D66E',
      'id': 'A20',
      'order': 20
    },
    {
      'hex': '#CAEDCF',
      'id': 'B20',
      'order': 20
    },
    {
      'hex': '#1A70A9',
      'id': 'C20',
      'order': 20
    },
    {
      'hex': '#9C34AD',
      'id': 'D20',
      'order': 20
    },
    {
      'hex': '#D7C6CE',
      'id': 'E20',
      'order': 20
    },
    {
      'hex': '#C69495',
      'id': 'F20',
      'order': 20
    },
    {
      'hex': '#A5452F',
      'id': 'G20',
      'order': 20
    },
    {
      'hex': '#96A09F',
      'id': 'H20',
      'order': 20
    },
    {
      'hex': '#FCBFD1',
      'id': 'P20',
      'order': 20
    },
    {
      'hex': '#E3CCBA',
      'id': 'R20',
      'order': 20
    },
    {
      'hex': '#FAE393',
      'id': 'A21',
      'order': 21
    },
    {
      'hex': '#176268',
      'id': 'B21',
      'order': 21
    },
    {
      'hex': '#BEDDFC',
      'id': 'C21',
      'order': 21
    },
    {
      'hex': '#940595',
      'id': 'D21',
      'order': 21
    },
    {
      'hex': '#C09DA4',
      'id': 'E21',
      'order': 21
    },
    {
      'hex': '#F2B8C6',
      'id': 'F21',
      'order': 21
    },
    {
      'hex': '#B38561',
      'id': 'G21',
      'order': 21
    },
    {
      'hex': '#F8FBE6',
      'id': 'H21',
      'order': 21
    },
    {
      'hex': '#E8BEC2',
      'id': 'P21',
      'order': 21
    },
    {
      'hex': '#A17140',
      'id': 'R21',
      'order': 21
    },
    {
      'hex': '#EDF878',
      'id': 'A22',
      'order': 22
    },
    {
      'hex': '#0A4241',
      'id': 'B22',
      'order': 22
    },
    {
      'hex': '#6BB1BB',
      'id': 'C22',
      'order': 22
    },
    {
      'hex': '#383995',
      'id': 'D22',
      'order': 22
    },
    {
      'hex': '#B58B9F',
      'id': 'E22',
      'order': 22
    },
    {
      'hex': '#F7C3D0',
      'id': 'F22',
      'order': 22
    },
    {
      'hex': '#CACAD2',
      'id': 'H22',
      'order': 22
    },
    {
      'hex': '#DFAAA4',
      'id': 'P22',
      'order': 22
    },
    {
      'hex': '#6B372C',
      'id': 'R22',
      'order': 22
    },
    {
      'hex': '#E1C9BD',
      'id': 'A23',
      'order': 23
    },
    {
      'hex': '#343B1A',
      'id': 'B23',
      'order': 23
    },
    {
      'hex': '#C8E2F9',
      'id': 'C23',
      'order': 23
    },
    {
      'hex': '#EADBF8',
      'id': 'D23',
      'order': 23
    },
    {
      'hex': '#937D8A',
      'id': 'E23',
      'order': 23
    },
    {
      'hex': '#EC806D',
      'id': 'F23',
      'order': 23
    },
    {
      'hex': '#9B9C94',
      'id': 'H23',
      'order': 23
    },
    {
      'hex': '#A3656A',
      'id': 'P23',
      'order': 23
    },
    {
      'hex': '#F6BB6F',
      'id': 'R23',
      'order': 23
    },
    {
      'hex': '#F3F6A9',
      'id': 'A24',
      'order': 24
    },
    {
      'hex': '#E8FAA6',
      'id': 'B24',
      'order': 24
    },
    {
      'hex': '#7EC5F9',
      'id': 'C24',
      'order': 24
    },
    {
      'hex': '#768AE1',
      'id': 'D24',
      'order': 24
    },
    {
      'hex': '#DEBEE5',
      'id': 'E24',
      'order': 24
    },
    {
      'hex': '#E09DAF',
      'id': 'F24',
      'order': 24
    },
    {
      'hex': '#F3C6C0',
      'id': 'R24',
      'order': 24
    },
    {
      'hex': '#FFD785',
      'id': 'A25',
      'order': 25
    },
    {
      'hex': '#4E846D',
      'id': 'B25',
      'order': 25
    },
    {
      'hex': '#A9E8E0',
      'id': 'C25',
      'order': 25
    },
    {
      'hex': '#4950C2',
      'id': 'D25',
      'order': 25
    },
    {
      'hex': '#E84854',
      'id': 'F25',
      'order': 25
    },
    {
      'hex': '#C76A62',
      'id': 'R25',
      'order': 25
    },
    {
      'hex': '#FEC832',
      'id': 'A26',
      'order': 26
    },
    {
      'hex': '#907C35',
      'id': 'B26',
      'order': 26
    },
    {
      'hex': '#42ADD1',
      'id': 'C26',
      'order': 26
    },
    {
      'hex': '#D6C6EB',
      'id': 'D26',
      'order': 26
    },
    {
      'hex': '#D093BC',
      'id': 'R26',
      'order': 26
    },
    {
      'hex': '#D0E0AF',
      'id': 'B27',
      'order': 27
    },
    {
      'hex': '#D0DEF9',
      'id': 'C27',
      'order': 27
    },
    {
      'hex': '#E58EAE',
      'id': 'R27',
      'order': 27
    },
    {
      'hex': '#9EE5BB',
      'id': 'B28',
      'order': 28
    },
    {
      'hex': '#BDCEE8',
      'id': 'C28',
      'order': 28
    },
    {
      'hex': '#9F85CF',
      'id': 'R28',
      'order': 28
    },
    {
      'hex': '#C6DF5F',
      'id': 'B29',
      'order': 29
    },
    {
      'hex': '#364A89',
      'id': 'C29',
      'order': 29
    },
    {
      'hex': '#E3FBB1',
      'id': 'B30',
      'order': 30
    },
    {
      'hex': '#B2E694',
      'id': 'B31',
      'order': 31
    },
    {
      'hex': '#92AD60',
      'id': 'B32',
      'order': 32
    }
  ]
}
