export default {
  'name': '漫漫',
  'data': [
    {
      'hex': '#FF9280',
      'id': 'A1',
      'order': 1
    },
    {
      'hex': '#DFF139',
      'id': 'C1',
      'order': 1
    },
    {
      'hex': '#F0FEE4',
      'id': 'C8',
      'order': 1
    },
    {
      'hex': '#ACB7EF',
      'id': 'D5',
      'order': 1
    },
    {
      'hex': '#F6D4CB',
      'id': 'E1',
      'order': 1
    },
    {
      'hex': '#FAF5CD',
      'id': 'E2',
      'order': 1
    },
    {
      'hex': '#FFE4D3',
      'id': 'E3',
      'order': 1
    },
    {
      'hex': '#FBFBFB',
      'id': 'F1',
      'order': 1
    },
    {
      'hex': '#F9F9F9',
      'id': 'P1',
      'order': 1
    },
    {
      'hex': '#D40E1F',
      'id': 'T1',
      'order': 1
    },
    {
      'hex': '#FF6FB7',
      'id': 'Y1',
      'order': 1
    },
    {
      'hex': '#BBC6B6',
      'id': 'YX11',
      'order': 1
    },
    {
      'hex': '#F73D4B',
      'id': 'A2',
      'order': 2
    },
    {
      'hex': '#FCC1DD',
      'id': 'A7',
      'order': 2
    },
    {
      'hex': '#FCFED6',
      'id': 'B1',
      'order': 2
    },
    {
      'hex': '#64F343',
      'id': 'C2',
      'order': 2
    },
    {
      'hex': '#ABF8FE',
      'id': 'D1',
      'order': 2
    },
    {
      'hex': '#868DD3',
      'id': 'D6',
      'order': 2
    },
    {
      'hex': '#FCC6AC',
      'id': 'E4',
      'order': 2
    },
    {
      'hex': '#000000',
      'id': 'F2',
      'order': 2
    },
    {
      'hex': '#F13484',
      'id': 'N1',
      'order': 2
    },
    {
      'hex': '#ABABAB',
      'id': 'P2',
      'order': 2
    },
    {
      'hex': '#FDB583',
      'id': 'Y2',
      'order': 2
    },
    {
      'hex': '#909994',
      'id': 'YX12',
      'order': 2
    },
    {
      'hex': '#EF4D3E',
      'id': 'A3',
      'order': 3
    },
    {
      'hex': '#F6BDE8',
      'id': 'A8',
      'order': 3
    },
    {
      'hex': '#FCFF92',
      'id': 'B2',
      'order': 3
    },
    {
      'hex': '#9FF685',
      'id': 'C7',
      'order': 3
    },
    {
      'hex': '#3653AF',
      'id': 'D10',
      'order': 3
    },
    {
      'hex': '#9EE0F8',
      'id': 'D2',
      'order': 3
    },
    {
      'hex': '#F1C4A5',
      'id': 'E5',
      'order': 3
    },
    {
      'hex': '#B4B4B4',
      'id': 'F3',
      'order': 3
    },
    {
      'hex': '#FB852B',
      'id': 'N2',
      'order': 3
    },
    {
      'hex': '#B6DBAF',
      'id': 'P4',
      'order': 3
    },
    {
      'hex': '#D8FCA4',
      'id': 'Y3',
      'order': 3
    },
    {
      'hex': '#697E80',
      'id': 'YX2',
      'order': 3
    },
    {
      'hex': '#F92B40',
      'id': 'A4',
      'order': 4
    },
    {
      'hex': '#E9639E',
      'id': 'A9',
      'order': 4
    },
    {
      'hex': '#F7EC5C',
      'id': 'B3',
      'order': 4
    },
    {
      'hex': '#5FDF34',
      'id': 'C3',
      'order': 4
    },
    {
      'hex': '#162C7E',
      'id': 'D11',
      'order': 4
    },
    {
      'hex': '#44CDFB',
      'id': 'D3',
      'order': 4
    },
    {
      'hex': '#DCB387',
      'id': 'E6',
      'order': 4
    },
    {
      'hex': '#878787',
      'id': 'F4',
      'order': 4
    },
    {
      'hex': '#F8ED33',
      'id': 'N3',
      'order': 4
    },
    {
      'hex': '#FEA2A3',
      'id': 'P5',
      'order': 4
    },
    {
      'hex': '#91DAFB',
      'id': 'Y4',
      'order': 4
    },
    {
      'hex': '#E0D4BC',
      'id': 'YX15',
      'order': 4
    },
    {
      'hex': '#F1559F',
      'id': 'A10',
      'order': 5
    },
    {
      'hex': '#E30328',
      'id': 'A5',
      'order': 5
    },
    {
      'hex': '#F0D83A',
      'id': 'B4',
      'order': 5
    },
    {
      'hex': '#E7B34E',
      'id': 'B7',
      'order': 5
    },
    {
      'hex': '#39E158',
      'id': 'C4',
      'order': 5
    },
    {
      'hex': '#B34EC6',
      'id': 'D13',
      'order': 5
    },
    {
      'hex': '#06ABE3',
      'id': 'D7',
      'order': 5
    },
    {
      'hex': '#464648',
      'id': 'F5',
      'order': 5
    },
    {
      'hex': '#32C958',
      'id': 'N4',
      'order': 5
    },
    {
      'hex': '#EB903F',
      'id': 'P3',
      'order': 5
    },
    {
      'hex': '#E987EA',
      'id': 'Y5',
      'order': 5
    },
    {
      'hex': '#D0CBAE',
      'id': 'YX6',
      'order': 5
    },
    {
      'hex': '#EC4072',
      'id': 'A11',
      'order': 6
    },
    {
      'hex': '#FDA951',
      'id': 'B5',
      'order': 6
    },
    {
      'hex': '#E3A014',
      'id': 'B8',
      'order': 6
    },
    {
      'hex': '#64E0A4',
      'id': 'C9',
      'order': 6
    },
    {
      'hex': '#B37BDC',
      'id': 'D14',
      'order': 6
    },
    {
      'hex': '#54A7E9',
      'id': 'D4',
      'order': 6
    },
    {
      'hex': '#913635',
      'id': 'E9',
      'order': 6
    },
    {
      'hex': '#2C2C2C',
      'id': 'F6',
      'order': 6
    },
    {
      'hex': '#63CEA2',
      'id': 'P8',
      'order': 6
    },
    {
      'hex': '#1EBA93',
      'id': 'T4',
      'order': 6
    },
    {
      'hex': '#B0AA86',
      'id': 'YX1',
      'order': 6
    },
    {
      'hex': '#C63674',
      'id': 'A12',
      'order': 7
    },
    {
      'hex': '#911932',
      'id': 'A6',
      'order': 7
    },
    {
      'hex': '#FA8C4F',
      'id': 'B6',
      'order': 7
    },
    {
      'hex': '#3EAE7C',
      'id': 'C10',
      'order': 7
    },
    {
      'hex': '#8758A9',
      'id': 'D12',
      'order': 7
    },
    {
      'hex': '#3977CC',
      'id': 'D8',
      'order': 7
    },
    {
      'hex': '#985C3A',
      'id': 'E7',
      'order': 7
    },
    {
      'hex': '#010101',
      'id': 'F7',
      'order': 7
    },
    {
      'hex': '#E79273',
      'id': 'P6',
      'order': 7
    },
    {
      'hex': '#1D779C',
      'id': 'T5',
      'order': 7
    },
    {
      'hex': '#B0A796',
      'id': 'YX13',
      'order': 7
    },
    {
      'hex': '#FDDBE9',
      'id': 'A13',
      'order': 8
    },
    {
      'hex': '#BB0126',
      'id': 'A17',
      'order': 8
    },
    {
      'hex': '#FDD94D',
      'id': 'B10',
      'order': 8
    },
    {
      'hex': '#1D9B54',
      'id': 'C5',
      'order': 8
    },
    {
      'hex': '#E3D2FE',
      'id': 'D16',
      'order': 8
    },
    {
      'hex': '#0F52BD',
      'id': 'D9',
      'order': 8
    },
    {
      'hex': '#713D2F',
      'id': 'E8',
      'order': 8
    },
    {
      'hex': '#E7D6DC',
      'id': 'F8',
      'order': 8
    },
    {
      'hex': '#ECDB59',
      'id': 'P7',
      'order': 8
    },
    {
      'hex': '#1960C8',
      'id': 'T3',
      'order': 8
    },
    {
      'hex': '#AE8082',
      'id': 'YX14',
      'order': 8
    },
    {
      'hex': '#E575C7',
      'id': 'A14',
      'order': 9
    },
    {
      'hex': '#E0677A',
      'id': 'A15',
      'order': 9
    },
    {
      'hex': '#F99C5F',
      'id': 'B11',
      'order': 9
    },
    {
      'hex': '#2A5037',
      'id': 'C6',
      'order': 9
    },
    {
      'hex': '#D6BAF5',
      'id': 'D17',
      'order': 9
    },
    {
      'hex': '#E4B685',
      'id': 'E10',
      'order': 9
    },
    {
      'hex': '#EFEDEE',
      'id': 'F10',
      'order': 9
    },
    {
      'hex': '#3349C3',
      'id': 'N5',
      'order': 9
    },
    {
      'hex': '#DBD9DA',
      'id': 'P13',
      'order': 9
    },
    {
      'hex': '#945AB1',
      'id': 'T2',
      'order': 9
    },
    {
      'hex': '#A88764',
      'id': 'YX10',
      'order': 9
    },
    {
      'hex': '#D33997',
      'id': 'A16',
      'order': 10
    },
    {
      'hex': '#F47E36',
      'id': 'B12',
      'order': 10
    },
    {
      'hex': '#DA8C42',
      'id': 'B9',
      'order': 10
    },
    {
      'hex': '#9AD1BA',
      'id': 'C11',
      'order': 10
    },
    {
      'hex': '#301A49',
      'id': 'D15',
      'order': 10
    },
    {
      'hex': '#3DBBE3',
      'id': 'D25',
      'order': 10
    },
    {
      'hex': '#874628',
      'id': 'E15',
      'order': 10
    },
    {
      'hex': '#ECEAEB',
      'id': 'F9',
      'order': 10
    },
    {
      'hex': '#F8DA54',
      'id': 'L2',
      'order': 10
    },
    {
      'hex': '#DBC7EA',
      'id': 'P18',
      'order': 10
    },
    {
      'hex': '#C6B2BB',
      'id': 'YX9',
      'order': 10
    },
    {
      'hex': '#F7DAD4',
      'id': 'A19',
      'order': 11
    },
    {
      'hex': '#627032',
      'id': 'C12',
      'order': 11
    },
    {
      'hex': '#BCBAE2',
      'id': 'D19',
      'order': 11
    },
    {
      'hex': '#2ADED3',
      'id': 'D28',
      'order': 11
    },
    {
      'hex': '#FEDB99',
      'id': 'E11',
      'order': 11
    },
    {
      'hex': '#DAC898',
      'id': 'E12',
      'order': 11
    },
    {
      'hex': '#592323',
      'id': 'E16',
      'order': 11
    },
    {
      'hex': '#CDCDCD',
      'id': 'F11',
      'order': 11
    },
    {
      'hex': '#F1E9D4',
      'id': 'P9',
      'order': 11
    },
    {
      'hex': '#FCECF7',
      'id': 'T6',
      'order': 11
    },
    {
      'hex': '#9D7693',
      'id': 'YX4',
      'order': 11
    },
    {
      'hex': '#FDA276',
      'id': 'A18',
      'order': 12
    },
    {
      'hex': '#F893BF',
      'id': 'A20',
      'order': 12
    },
    {
      'hex': '#F8516D',
      'id': 'A22',
      'order': 12
    },
    {
      'hex': '#1A6E3D',
      'id': 'C13',
      'order': 12
    },
    {
      'hex': '#DC99CE',
      'id': 'D20',
      'order': 12
    },
    {
      'hex': '#1E334E',
      'id': 'D26',
      'order': 12
    },
    {
      'hex': '#FEC993',
      'id': 'E13',
      'order': 12
    },
    {
      'hex': '#FDF6EE',
      'id': 'E18',
      'order': 12
    },
    {
      'hex': '#E9EDEE',
      'id': 'P12',
      'order': 12
    },
    {
      'hex': '#D8D4D3',
      'id': 'T7',
      'order': 12
    },
    {
      'hex': '#644B51',
      'id': 'YX5',
      'order': 12
    },
    {
      'hex': '#B5026A',
      'id': 'A21',
      'order': 13
    },
    {
      'hex': '#F45C45',
      'id': 'A24',
      'order': 13
    },
    {
      'hex': '#FEC667',
      'id': 'B13',
      'order': 13
    },
    {
      'hex': '#C8E87D',
      'id': 'C14',
      'order': 13
    },
    {
      'hex': '#B5038F',
      'id': 'D21',
      'order': 13
    },
    {
      'hex': '#CDE7FE',
      'id': 'D30',
      'order': 13
    },
    {
      'hex': '#B2714B',
      'id': 'E17',
      'order': 13
    },
    {
      'hex': '#F4EFD1',
      'id': 'E23',
      'order': 13
    },
    {
      'hex': '#56534E',
      'id': 'L7',
      'order': 13
    },
    {
      'hex': '#ADCBF1',
      'id': 'P17',
      'order': 13
    },
    {
      'hex': '#C79266',
      'id': 'YX8',
      'order': 13
    },
    {
      'hex': '#FCADB2',
      'id': 'A25',
      'order': 14
    },
    {
      'hex': '#F85842',
      'id': 'B14',
      'order': 14
    },
    {
      'hex': '#ACE84C',
      'id': 'C15',
      'order': 14
    },
    {
      'hex': '#882893',
      'id': 'D22',
      'order': 14
    },
    {
      'hex': '#D6FDFC',
      'id': 'D29',
      'order': 14
    },
    {
      'hex': '#8B684C',
      'id': 'E14',
      'order': 14
    },
    {
      'hex': '#FAD4BF',
      'id': 'E21',
      'order': 14
    },
    {
      'hex': '#CED7D4',
      'id': 'F12',
      'order': 14
    },
    {
      'hex': '#337BAD',
      'id': 'P22',
      'order': 14
    },
    {
      'hex': '#A3E7DC',
      'id': 'S1',
      'order': 14
    },
    {
      'hex': '#C37463',
      'id': 'YX3',
      'order': 14
    },
    {
      'hex': '#F5C9CA',
      'id': 'A23',
      'order': 15
    },
    {
      'hex': '#FBF65E',
      'id': 'B15',
      'order': 15
    },
    {
      'hex': '#305335',
      'id': 'C16',
      'order': 15
    },
    {
      'hex': '#2F1E8E',
      'id': 'D18',
      'order': 15
    },
    {
      'hex': '#21C5C4',
      'id': 'D31',
      'order': 15
    },
    {
      'hex': '#98A6A6',
      'id': 'DH4',
      'order': 15
    },
    {
      'hex': '#D50527',
      'id': 'DH8',
      'order': 15
    },
    {
      'hex': '#F6F8E3',
      'id': 'E19',
      'order': 15
    },
    {
      'hex': '#668575',
      'id': 'P23',
      'order': 15
    },
    {
      'hex': '#78CEE7',
      'id': 'S2',
      'order': 15
    },
    {
      'hex': '#747D7A',
      'id': 'YX7',
      'order': 15
    },
    {
      'hex': '#C0ED9C',
      'id': 'C17',
      'order': 16
    },
    {
      'hex': '#E2E4F0',
      'id': 'D23',
      'order': 16
    },
    {
      'hex': '#1858A2',
      'id': 'D32',
      'order': 16
    },
    {
      'hex': '#1B1213',
      'id': 'DH11',
      'order': 16
    },
    {
      'hex': '#F2D8C1',
      'id': 'E20',
      'order': 16
    },
    {
      'hex': '#FEFF97',
      'id': 'IC04',
      'order': 16
    },
    {
      'hex': '#F8C0A9',
      'id': 'IC10',
      'order': 16
    },
    {
      'hex': '#FBF4EC',
      'id': 'IC2',
      'order': 16
    },
    {
      'hex': '#FCFDFF',
      'id': 'L6',
      'order': 16
    },
    {
      'hex': '#FDC24E',
      'id': 'P14',
      'order': 16
    },
    {
      'hex': '#3FCDCE',
      'id': 'S3',
      'order': 16
    },
    {
      'hex': '#9EB33E',
      'id': 'C18',
      'order': 17
    },
    {
      'hex': '#C7D3F9',
      'id': 'D24',
      'order': 17
    },
    {
      'hex': '#02D1F3',
      'id': 'D36',
      'order': 17
    },
    {
      'hex': '#79544E',
      'id': 'E22',
      'order': 17
    },
    {
      'hex': '#F0EEEF',
      'id': 'IC6',
      'order': 17
    },
    {
      'hex': '#F7E3EC',
      'id': 'IC7',
      'order': 17
    },
    {
      'hex': '#FDE173',
      'id': 'IC9',
      'order': 17
    },
    {
      'hex': '#FDA42E',
      'id': 'P19',
      'order': 17
    },
    {
      'hex': '#E89B7D',
      'id': 'Q9',
      'order': 17
    },
    {
      'hex': '#4E8379',
      'id': 'S5',
      'order': 17
    },
    {
      'hex': '#E6ED4F',
      'id': 'C19',
      'order': 18
    },
    {
      'hex': '#9A64B8',
      'id': 'D27',
      'order': 18
    },
    {
      'hex': '#FFE4D6',
      'id': 'DH5',
      'order': 18
    },
    {
      'hex': '#213244',
      'id': 'DH6',
      'order': 18
    },
    {
      'hex': '#FCFFF8',
      'id': 'IC1',
      'order': 18
    },
    {
      'hex': '#FBC8DB',
      'id': 'IC13',
      'order': 18
    },
    {
      'hex': '#FCBF80',
      'id': 'IC14',
      'order': 18
    },
    {
      'hex': '#FEBDA7',
      'id': 'P11',
      'order': 18
    },
    {
      'hex': '#D07E4A',
      'id': 'Q10',
      'order': 18
    },
    {
      'hex': '#7DCA9C',
      'id': 'S4',
      'order': 18
    },
    {
      'hex': '#D8C2D9',
      'id': 'D33',
      'order': 19
    },
    {
      'hex': '#26B78E',
      'id': 'DH15',
      'order': 19
    },
    {
      'hex': '#DD7D41',
      'id': 'DH3',
      'order': 19
    },
    {
      'hex': '#18869D',
      'id': 'DH9',
      'order': 19
    },
    {
      'hex': '#F2EEE5',
      'id': 'IC11',
      'order': 19
    },
    {
      'hex': '#F6BBD1',
      'id': 'IC12',
      'order': 19
    },
    {
      'hex': '#FD7E77',
      'id': 'IC15',
      'order': 19
    },
    {
      'hex': '#FFDEE9',
      'id': 'P10',
      'order': 19
    },
    {
      'hex': '#BE454A',
      'id': 'Q05',
      'order': 19
    },
    {
      'hex': '#C8E664',
      'id': 'S11',
      'order': 19
    },
    {
      'hex': '#9C34AD',
      'id': 'D34',
      'order': 20
    },
    {
      'hex': '#CAEDCF',
      'id': 'DH10',
      'order': 20
    },
    {
      'hex': '#A5452F',
      'id': 'DH13',
      'order': 20
    },
    {
      'hex': '#1A70A9',
      'id': 'DH14',
      'order': 20
    },
    {
      'hex': '#FCBFD1',
      'id': 'P15',
      'order': 20
    },
    {
      'hex': '#96A09F',
      'id': 'Q12',
      'order': 20
    },
    {
      'hex': '#D7C6CE',
      'id': 'Q13',
      'order': 20
    },
    {
      'hex': '#F9D66E',
      'id': 'Q6',
      'order': 20
    },
    {
      'hex': '#C69495',
      'id': 'R04',
      'order': 20
    },
    {
      'hex': '#E3CCBA',
      'id': 'S6',
      'order': 20
    },
    {
      'hex': '#940595',
      'id': 'D35',
      'order': 21
    },
    {
      'hex': '#176268',
      'id': 'DH2',
      'order': 21
    },
    {
      'hex': '#F8FBE6',
      'id': 'G12',
      'order': 21
    },
    {
      'hex': '#BEDDFC',
      'id': 'IC3',
      'order': 21
    },
    {
      'hex': '#E8BEC2',
      'id': 'P20',
      'order': 21
    },
    {
      'hex': '#C09DA4',
      'id': 'Q2',
      'order': 21
    },
    {
      'hex': '#B38561',
      'id': 'Q8',
      'order': 21
    },
    {
      'hex': '#F2B8C6',
      'id': 'R03',
      'order': 21
    },
    {
      'hex': '#FAE393',
      'id': 'R07',
      'order': 21
    },
    {
      'hex': '#A17140',
      'id': 'S13',
      'order': 21
    },
    {
      'hex': '#383995',
      'id': 'DH1',
      'order': 22
    },
    {
      'hex': '#0A4241',
      'id': 'DH7',
      'order': 22
    },
    {
      'hex': '#CACAD2',
      'id': 'G2',
      'order': 22
    },
    {
      'hex': '#DFAAA4',
      'id': 'P16',
      'order': 22
    },
    {
      'hex': '#6BB1BB',
      'id': 'Q11',
      'order': 22
    },
    {
      'hex': '#B58B9F',
      'id': 'Q4',
      'order': 22
    },
    {
      'hex': '#F7C3D0',
      'id': 'R02',
      'order': 22
    },
    {
      'hex': '#EDF878',
      'id': 'R06',
      'order': 22
    },
    {
      'hex': '#6B372C',
      'id': 'S15',
      'order': 22
    },
    {
      'hex': '#343B1A',
      'id': 'DH12',
      'order': 23
    },
    {
      'hex': '#9B9C94',
      'id': 'G11',
      'order': 23
    },
    {
      'hex': '#EADBF8',
      'id': 'IC8',
      'order': 23
    },
    {
      'hex': '#A3656A',
      'id': 'P21',
      'order': 23
    },
    {
      'hex': '#937D8A',
      'id': 'Q3',
      'order': 23
    },
    {
      'hex': '#EC806D',
      'id': 'R05',
      'order': 23
    },
    {
      'hex': '#E1C9BD',
      'id': 'R08',
      'order': 23
    },
    {
      'hex': '#C8E2F9',
      'id': 'R13',
      'order': 23
    },
    {
      'hex': '#F6BB6F',
      'id': 'S12',
      'order': 23
    },
    {
      'hex': '#F3F6A9',
      'id': 'G3',
      'order': 24
    },
    {
      'hex': '#DEBEE5',
      'id': 'G8',
      'order': 24
    },
    {
      'hex': '#E09DAF',
      'id': 'G9',
      'order': 24
    },
    {
      'hex': '#E8FAA6',
      'id': 'IC5',
      'order': 24
    },
    {
      'hex': '#768AE1',
      'id': 'Q14',
      'order': 24
    },
    {
      'hex': '#7EC5F9',
      'id': 'R14',
      'order': 24
    },
    {
      'hex': '#F3C6C0',
      'id': 'S7',
      'order': 24
    },
    {
      'hex': '#E84854',
      'id': 'G10',
      'order': 25
    },
    {
      'hex': '#FFD785',
      'id': 'G4',
      'order': 25
    },
    {
      'hex': '#4950C2',
      'id': 'Q15',
      'order': 25
    },
    {
      'hex': '#A9E8E0',
      'id': 'R12',
      'order': 25
    },
    {
      'hex': '#C76A62',
      'id': 'S14',
      'order': 25
    },
    {
      'hex': '#FEC832',
      'id': 'G5',
      'order': 26
    },
    {
      'hex': '#907C35',
      'id': 'Q7',
      'order': 26
    },
    {
      'hex': '#D6C6EB',
      'id': 'R01',
      'order': 26
    },
    {
      'hex': '#42ADD1',
      'id': 'R15',
      'order': 26
    },
    {
      'hex': '#D093BC',
      'id': 'S9',
      'order': 26
    },
    {
      'hex': '#D0DEF9',
      'id': 'G13',
      'order': 27
    },
    {
      'hex': '#D0E0AF',
      'id': 'R10',
      'order': 27
    },
    {
      'hex': '#E58EAE',
      'id': 'S8',
      'order': 27
    },
    {
      'hex': '#BDCEE8',
      'id': 'G14',
      'order': 28
    },
    {
      'hex': '#9EE5BB',
      'id': 'R11',
      'order': 28
    },
    {
      'hex': '#9F85CF',
      'id': 'S10',
      'order': 28
    },
    {
      'hex': '#364A89',
      'id': 'G15',
      'order': 29
    },
    {
      'hex': '#C6DF5F',
      'id': 'R09',
      'order': 29
    },
    {
      'hex': '#E3FBB1',
      'id': 'G6',
      'order': 30
    },
    {
      'hex': '#B2E694',
      'id': 'G7',
      'order': 31
    }
  ]
}
