<template>
  <el-dialog
    ref="accountDialog"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page noscroll accountDialog"
  >
    <div slot="title" class="header">
      <div class="left">
        <el-button v-if="!isDesktop" class="tap-btn iconfont my-back size20" @click="closePop(config)" />
        &nbsp;
      </div>
      <div class="middle">
        {{ $t('i.myAccount') }}
      </div>
      <div class="float-right align-right">
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="closePop(config)" />
        &nbsp;
      </div>
    </div>
    <div v-show="config.show" class="my-container">
      <div class="my-body">
        <div class="my-block">
          <div class="my-row round-top4" @click="setUsername()">
            <el-row>
              <el-col :span="8">{{ $t('i.username') }}</el-col>
              <el-col :span="16" class="right">
                {{ user.username }}
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div>
          <div v-if="state.supportMobile" class="my-row round-bottom4" @click="bindPhone()">
            <el-row>
              <el-col :span="8">{{ $t('i.mobile') }}</el-col>
              <el-col :span="16" class="right">
                {{ user.mobile || $t('i.nobind') }}
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div>
          <div class="my-row round-bottom4" @click="bindEmail()">
            <el-row>
              <el-col :span="8">{{ $t('i.email') }}</el-col>
              <el-col :span="16" class="right">
                {{ user.email || $t('i.nobind') }}
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div>
          <!-- <div class="my-row" @click="goto('profile')">
            <el-row>
              <el-col :span="8">{{ $t('i.email') }}</el-col>
              <el-col :span="16" class="right">
                389193@qq.com
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div> -->
          <!-- <div class="my-row" @click="bindWechat()">
            <el-row>
              <el-col :span="8">{{ $t('i.wechat') }}</el-col>
              <el-col :span="16" class="right">
                {{ token.nickname }}
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div> -->
        </div>
        <div class="my-block">
          <div class="my-row round-top4" @click="openPage('setPassword')">
            <el-row>
              <el-col :span="8">{{ $t('i.passwd') }}</el-col>
              <el-col :span="16" class="right">
                {{ !user.password ? $t('i.setup') : $t('i.modify') }}
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div>
          <div class="my-row round-bottom4" @click="openPage('setPayPassword')">
            <el-row>
              <el-col :span="8">{{ $t('i.tradePWD') }}</el-col>
              <el-col :span="16" class="right">
                {{ !user.pay_password ? $t('i.setup') : $t('i.modify') }}
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-if="state.supportMobile" class="my-block">
          <div class="my-row round4" @click="openPage('realVerify')">
            <el-row>
              <el-col :span="8">{{ $t('i.realVerify') }}</el-col>
              <el-col :span="16" class="right">
                <span v-if="user.verify_status === 0">{{ $t('i.verify') }}</span>
                <span v-if="user.verify_status === 1" class="yellow">{{ $t('i.verifying') }}</span>
                <span v-if="user.verify_status === 2">{{ $t('i.verified') }}</span>
                <span v-if="user.verify_status === -1" class="red">{{ $t('i.veriverifyFailfy') }}</span>
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="my-block">
          <div class="my-row round4" @click="viewChainAccout()">
            <el-row>
              <el-col :span="8">{{ $t('i.blockChain') }}</el-col>
              <el-col :span="16" class="right">
                <span v-if="user.chain_account && user.chain_account.status === 1" class="yellow">{{ $t('i.creating') }}</span>
                <span v-if="user.chain_account && user.chain_account.status === 2">{{ $t('i.browse') }}</span>
                <span v-if="user.chain_account && user.chain_account.status === -1" class="red">{{ $t('i.reject') }}</span>
                <span v-if="user.chain_account && user.chain_account.status === -2" class="red">{{ $t('i.freeze') }}</span>
                <span v-if="!user.chain_account || (user.chain_account && user.chain_account.status === 0)">{{ $t('i.wantCreate') }}</span>
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="my-block">
          <div class="my-row round-top4" @click="emit('openResource', ['bankcard'])">
            <el-row>
              <el-col :span="8">{{ $t('i.myBankcard') }}</el-col>
              <el-col :span="16" class="right">
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div>
          <div class="my-row round-bottom4" @click="emit('openResource', ['address'])">
            <el-row>
              <el-col :span="8">{{ $t('i.myAddress') }}</el-col>
              <el-col :span="16" class="right">
                <i class="iconfont my-right" />
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import service from '@/js/service'
import utils from '@/js/utils'
export default {
  components: {},
  mixins: [mixins],
  data() {
    const data = {}
    data.user = {}
    return data
  },
  computed: {
    config() {
      return this.view.account
    }
  },
  watch: {
    // 'view.account.show': {
    //   handler() {
    //     if (this.view.account.show) this.loadData()
    //   }
    // },
    'view.account.renew': {
      handler() {
        this.loadData()
      }
    }
  },
  mounted() {
    if (!this.loginUserId) {
      this.emit('login')
      return
    }
    this.loadData()
  },
  methods: {
    handle(act, data) {
      this.emit(act, data)
    },
    bindWechat() {
      this.view.pop.title = this.$t('i.bindWechat')
      this.view.pop.tip = ''
      this.view.pop.placeholder = ''
      this.view.pop.rows = 1
      this.view.pop.type = ''
      this.view.pop.data = {}
      this.view.pop.show = true
    },
    setUsername() {
      this.view.pop.title = this.$t('i.modifyUname')
      this.view.pop.tip = ''
      this.view.pop.placeholder = ''
      this.view.pop.content = this.user.username || ''
      this.view.pop.classname = ''
      this.view.pop.maxlength = 18
      this.view.pop.rows = 1
      this.view.pop.type = ''
      this.view.pop.data = {}
      this.view.pop.show = true
      this.view.pop.loading = false
      this.view.pop.fn = () => {
        if (!utils.checkNow('username', this.view.pop.content, true)) {
          this.view.pop.loading = false
          return this.message(this.$t('i.usernameErr'), 'error')
        }
        if (this.view.pop.content !== this.user.username) {
          service.updateUser({ 'username': this.view.pop.content }, (dt, type) => {
            if (type === 'success') {
              this.view.pop.fn = null
              this.view.pop.show = false
              this.user.username = this.view.pop.content
              this.setCount()
              this.message(this.$t('i.modifyOk'), type)
            } else {
              this.message(dt, type)
            }
            this.view.pop.loading = false
          })
        } else {
          this.view.pop.fn = null
          this.view.pop.show = false
        }
      }
    },
    loadData() {
      if (!this.loginStatus) return this.message('', 'login')
      this.getUserProfile(this.loginUserId)
    },
    bindPhone() {
      this.openPopPage({ 'type': this.user.mobile ? 'unbindPhone' : 'bindPhone', 'data': this.user })
    },
    bindEmail() {
      this.openPopPage({ 'type': this.user.email ? 'unbindEmail' : 'bindEmail', 'data': this.user })
    },
    openPage(type) {
      if (this.state.supportMobile) {
        if (!this.user.mobile) {
          this.alert(this.$t('i.bindMobileFirst'))
          return
        }
      } else {
        if (!this.user.email) {
          this.alert(this.$t('i.bindEmailFirst'))
          return
        }
      }
      if (type === 'setPassword') {
        this.openPopPage({ 'type': type, 'title': !this.user.password ? this.$t('i.setPWD') : '', 'data': this.user })
      } else if (type === 'setPayPassword') {
        this.openPopPage({ 'type': type, 'title': !this.user.pay_password ? this.$t('i.setTradePWD') : '', 'data': this.user })
      } else if (type === 'realVerify') {
        this.openPopPage({ 'type': type + (this.user.verify_status > 0 ? 'Info' : ''), 'data': this.user })
      }
    },
    viewChainAccout() {
      if (this.state.supportMobile) {
        if (!this.user.mobile) {
          this.alert(this.$t('i.bindMobileFirst'))
          return
        }
      } else {
        if (!this.user.email) {
          this.alert(this.$t('i.bindEmailFirst'))
          return
        }
      }
      const user = this.user
      if (!user.chain_account || (user.chain_account && user.chain_account.status === 0)) {
        if (this.state.supportMobile && user.verify_status !== 2) {
          this.alert(this.$t('i.blockChainTips'), (action) => {
            if (action === 'confirm') this.openPopPage({ 'type': 'realVerify' + (user.verify_status > 0 ? 'Info' : ''), 'data': user })
          })
          return
        }
        // 创建区块链账户
        service.post('chain_account', {}, (dt, type) => {
          if (type === 'success') {
            this.message(this.$t('i.createSent'), type)
            this.getUserProfile(user.userid)
            service.incCount(this.loginUserId, 'chain_account')
          } else {
            this.message(dt, type)
          }
        }, true)
      } else if (user.chain_account && user.chain_account.status === 2) {
        this.openPopPage({ 'type': 'chainAccount', 'data': user.chain_account })
      }
    },
    getUserProfile(userid) {
      service.get('user_profile', userid, (ret, type) => {
        if (type === 'success' && ret.data) {
          this.user = ret.data
          this.setCount()
        } else {
          this.message(ret, type)
        }
      }, true)
    },
    // 设置计数器(新手任务)
    setCount() {
      const user = this.user
      if (user.avatar) service.setCount(user.userid, 'avatar', 1)
      if (user.background) service.setCount(user.userid, 'background', 1)
      if (user.signature) service.setCount(user.userid, 'signature', 1)
      if (user.sex) service.setCount(user.userid, 'sex', 1)
      if (user.birth_month && user.birth_day) service.setCount(user.userid, 'birth', 1)
      if (user.city) service.setCount(user.userid, 'city', 1)
      if (user.username) service.setCount(user.userid, 'username', 1)
      if (user.verify_status === 2) service.setCount(user.userid, 'verify', 1)
      if (user.chain_account.status === 2) service.setCount(user.userid, 'chain_account', 1)
      if (user.password) service.setCount(user.userid, 'password', 1)
      if (user.pay_password) service.setCount(user.userid, 'pay_password', 1)
    }
  }
}
</script>
