<template>
  <div class="my-container add-new">
    <el-drawer :visible.sync="view.addNew.show" direction="btt" size="12%" :show-close="false" custom-class="add-new">
      <el-col :span="5">
        <div @click="openEditer">
          <i class="iconfont my-gridsfy" />
          <div>{{ $t('i.editer') }}</div>
        </div>
      </el-col>
      <el-col :span="7">
        <div @click="openPopPage('toBrickfy')">
          <i class="iconfont my-avatar" />
          <div>{{ $t('i.aiPixelPuzzle') }}</div>
        </div>
      </el-col>
      <el-col :span="7">
        <div @click="openPopPage('toBeanPixel')">
          <i class="iconfont my-bean" />
          <div>{{ $t('i.aiBeanPixel') }}</div>
        </div>
      </el-col>
      <el-col :span="5">
        <div @click="openPost">
          <i class="iconfont my-post-add" />
          <div>{{ $t('i.addPost') }}</div>
        </div>
      </el-col>
      <!-- <el-col :span="4">
        <div @click="openPopPage('toCartoon')">
          <i class="iconfont my-cartoon" />
          <div>{{ $t('i.aiCartoon') }}</div>
        </div>
      </el-col>
      <el-col :span="4">
        <div @click="openAiPaint">
          <i class="iconfont my-ai-paint" />
          <div>{{ $t('i.aiart') }}</div>
        </div>
      </el-col> -->
      <!-- <el-col :span="6">
        <span @click="message('敬请期待', 'info')">
          <i class="iconfont my-voxel gray" />
          <div class="gray">体素模型</div>
        </span>
      </el-col> -->
    </el-drawer>
  </div>
</template>
<script>
import { mixins } from '@/mixins/common'
export default {
  components: {},
  mixins: [mixins],
  methods: {
    handle(act, data) {
      this.emit(act, data)
    },
    openPopPage(type) {
      this.view.addNew.show = false
      this.emit('openPopPage', [{ 'type': type }])
    },
    openPost() {
      this.view.addNew.show = false
      this.emit('openPost')
    },
    openEditer() {
      this.view.addNew.show = false
      // this.view.createNewfile = true
      this.goto('editer', [])
    },
    openAiPaint() {
      this.view.addNew.show = false
      this.emit('openAiPaint', [])
    }
  }
}
</script>
