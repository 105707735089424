<template>
  <div>
    <el-dialog
      :title="$t('i.newWork')"
      :visible.sync="view.createNewfile"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      class="my-dialog"
      :width="isDesktop ? '480px' : '320px'"
      :top="marginTop"
    >
      <div slot="title">
        <span class="el-dialog__title">{{ $t('i.newWork') }}</span>
        <div class="float-right align-right">
          <el-button class="tap-btn iconfont my-close size20" @click="close" />
          &nbsp;
        </div>
      </div>
      <el-card shadow="never" class="padding8 margin-bottom8">
        <div v-for="ratio in canvasRatio" :key="ratio.id" :label="ratio.label">
          <el-row class="border-28 padding8 margin-bottom4 bg-28 round4 hover-bg-33">
            <div class="float-left" style="width:calc(100% - 100px)" @click="createNewFile(ratio.id)"><i class="iconfont size24 margin-right12 margin-left4" :class="ratio.icon" /><span class="size16">{{ ratio.label }}</span></div>
            <div class="float-right align-right size12 padding-right8" style="width:100px;">
              <el-dropdown trigger="click" @command="(e) => createNewFile(ratio.id, e)">
                <div class="el-dropdown-link color-ee" style="line-height: 28px;">
                  {{ ratio.tip }}<i class="el-icon-arrow-down el-icon--right" />
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(size, i) in sizeOpts[ratio.id]" :key="i" :command="size">{{ size.cols + 'x' + size.rows }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-row>
        </div>
        <div>
          <el-row class="align-center margin-top4 padding-top12 margin-bottom4 newBtns">
            <el-col :span="span"><el-button class="iconfont iconfont my-image-add size24" @click="emit('openLocalImages', ['open'])" /><div class="size10 padding-top8">{{ $t('i.localImg') }}</div></el-col>
            <el-col :span="span"><el-button class="iconfont iconfont my-link-add size24" @click="emit('openWebImage', ['open'])" /><div class="size10 padding-top8">{{ $t('i.webImg') }}</div></el-col>
            <el-col :span="span"><el-button class="iconfont iconfont my-file-gridy size24" @click="emit('openLocalFile')" /><div class="size10 padding-top8">{{ $t('i.gridyFile') }}</div></el-col>
            <el-col v-if="isDesktop" :span="span"><el-button class="iconfont iconfont my-pixel size24" @click="emit('openResource', ['work'])" /><div class="size10 padding-top8">{{ $t('i.myWorks') }}</div></el-col>
            <el-col v-if="isDesktop" :span="span"><el-button class="iconfont iconfont my-material size24" @click="emit('openDraft', ['draft'])" /><div class="size10 padding-top8">{{ $t('i.drafts') }}</div></el-col>
            <el-col v-if="isDesktop" :span="span"><el-button class="iconfont iconfont my-random size24" @click="emit('createGeoFile')" /><div class="size10 padding-top8">{{ $t('i.randomPattern') }}</div></el-col>
            <el-col :span="span">
              <el-popover placement="top-start" offset="4" popper-class="padding4" width="280" trigger="click">
                <div class="padding4" :style="{ width: '100%', height: '146px', 'overflow-x': 'hidden', 'overflow-y': 'auto'}">
                  <div v-for="item in latestOpen" :key="item.id" class="flex hover-bg-33" @click="item.click()">
                    <div class="float-left ellipsis padding-left4" style="width: 150px;">
                      {{ item.text }}
                    </div>
                    <div class="float-right align-right color-99" style="width: 120px;">{{ formateTime(item.openTime) }}</div>
                  </div>
                </div>
                <div slot="reference"><el-button class="iconfont iconfont my-history size24" /><div class="size10 padding-top8">{{ $t('i.recentlyOpened') }}</div></div>
              </el-popover>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-dialog>
  </div>
</template>
<script>
import utils from '@/js/utils'
export default {
  props: {
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    view: {
      type: Object,
      default() {
        return {}
      }
    },
    newfileOpts: {
      type: Object,
      default() {
        return {}
      }
    },
    latestOpen: {
      type: Array,
      default: () => []
    },
    canvasRatio: {
      type: Array,
      default: () => []
    },
    sizeOpts: {
      type: Object,
      default() {
        return {}
      }
    },
    loginStatus: {
      type: Boolean,
      default: false
    },
    loginUserId: {
      type: Number,
      default: 0
    }
  },
  data() {
    const data = {}
    return data
  },
  computed: {
    marginTop() {
      const height = utils.height()
      return Math.max(Math.floor(((height - 300) / height) * 40), 0) + 'vh'
    },
    isDesktop() {
      return this.state.platform.type === 'desktop'
    },
    span() {
      return this.isDesktop ? 3 : 6
    }
  },
  methods: {
    close() {
      this.emit('traceEvent', ['createNewfile_close'])
      this.view.createNewfile = false
      if (!(!!this.state.openFiles.length || this.view.mod !== 'editer')) {
        this.emit('goto', ['index'])
      }
    },
    // 调用父组件方法
    emit(fn, params) {
      if (!fn) return
      this.$emit('receive', fn, params || [])
    },
    // 创建新文件
    createNewFile(ratio, size = null) {
      if (ratio) this.newfileOpts.ratio = ratio
      this.newfileOpts.size = size || null
      this.emit('createNewFile')
    },
    formateTime: utils.formateTime
  }
}
</script>
