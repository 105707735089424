/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2022-11-10
 * @copyright Gridy.Art
 */
import utils from '@/js/utils'
import GRIDY from '@/js/sdk/GridySDK'
import conf from '@/js/conf/conf'
import JSZip from 'jszip'
import download from 'downloadjs'
import i18n from '../../lang'
// eslint-disable-next-line
const $t = (key, value) => i18n.t(key, value)
var main = function(file = {}) {
  this.GRIDY = new GRIDY()
  this.GRIDY.setFile(file)
  this.file = file
  this.lxfConf = {
    'baseMaterialid': 26,
    'brickMaterialid': 26,
    'designIDs': {
      '16': '6098',
      '32': '3811',
      '48': '4186'
    },
    // 方形：3070  圆形：98138
    'brickDesignID': 98138
  }
}
main.prototype = {
  setFile: function(file) {
    this.GRIDY.setFile(file)
    this.file = file
  },
  exportFile: function(options = {}, cb) {
    const num = this.file.canvas.scenes.length
    const filename = (this.file.name || 'Untitle') + '-' + $t('i.virtualPuzzle') + '_' + utils.date('str') + '.lxf'
    const files = []
    const exportIt = (idx) => {
      if (idx <= 0) return cb && cb(files, 'lxf')
      this.exportScenes(idx - 1, options, (lxfml) => {
        if (!lxfml) return cb && cb($t('i.exportFail'), 'error')
        const zip = new JSZip()
        zip.file('IMAGE100.LXFML', lxfml)
        zip.generateAsync({ type: 'blob' }).then((blob) => {
          let name = filename
          if (num > 1) {
            name = filename.replace('.lxf', '-' + idx + '.lxf')
          }
          const params = [blob, name, 'application/zip']
          if (options.download) download(...params)
          files.push(params)
        }, (e) => {
          cb && cb($t('i.exportFail'), 'error')
        })
        if (typeof options.sceneIdx !== 'undefined') {
          cb && cb(files, 'lxf')
        } else {
          exportIt(idx - 1)
        }
      })
    }
    if (typeof options.sceneIdx !== 'undefined') {
      exportIt(options.sceneIdx + 1)
    } else {
      exportIt(num)
    }
  },
  // 导出xfs文件
  exportScenes: function(sceneIdx, options = {}, cb) {
    sceneIdx = sceneIdx || 0
    if (!this.file.canvas || !Array.isArray(this.file.canvas.scenes) || !this.file.canvas.scenes.length) {
      return cb && cb()
    }
    const partSize = utils.matchBaseSize(this.file.canvas.cols, this.file.canvas.rows)
    const partCols = Math.ceil(this.file.canvas.cols / partSize)
    const partRows = Math.ceil(this.file.canvas.rows / partSize)
    const workCols = partCols * partSize
    const workRows = partRows * partSize
    const offsetCols = Math.max(Math.ceil((workCols - this.file.canvas.cols) / 2), 0)
    const offsetRows = Math.max(Math.ceil((workRows - this.file.canvas.rows) / 2), 0)
    options.applyPalette = true
    options.paletteId = 'brickfy'
    const objData = this.GRIDY.getSceneData(sceneIdx, options)
    // TODO: 支持更多颜色底板
    const workBgColor = '#FFFFFF'
    // 整幅
    const workData = new Array(workCols * workRows).fill('')
    for (const i in objData.imageData) {
      if (objData.imageData[i]) {
        let objY = Math.floor(i / objData.cols)
        let objX = Math.floor(i - objY * objData.cols)
        objX = objX + offsetCols
        objY = objY + offsetRows
        // 匹配拼图块
        workData[objY * workCols + objX] = objData.imageData[i]
      }
    }
    const lxfConf = this.lxfConf
    // 生成LXFML
    const makeLXFML = () => {
      const headerStr = '<?xml version="1.0" encoding="UTF-8" standalone="no" ?><LXFML versionMajor="5" versionMinor="0" name="' + this.file.name + '"><Meta><Application name="LEGO Digital Designer" versionMajor="4" versionMinor="3"/><Brand name="LDD"/><BrickSet version="777"/></Meta>'
      const cameraStr = '\n<Cameras><Camera refID="0" fieldOfView="80" distance="80" transformation="0,0,-1,0,1,0,1,0,0,' + Math.max(partCols, partRows) * partSize * 10 + ',0,0"/></Cameras>'
      const footerStr = '\n</LXFML>'
      // 获取底板数据
      const getBase = (idx, c, r, materialid) => {
        // 不放底板
        // return ''
        materialid = materialid || lxfConf.baseMaterialid
        const step = 12.8 * partSize / 16
        let baseTemplate = '\n<Brick refID="{$idx}" designID="' + lxfConf.designIDs[partSize] + '" itemNos="{$idx}"><Part refID="{$idx}" designID="' + lxfConf.designIDs[partSize] + '" materials="{$materialid}"><Bone refID="{$idx}" transformation="0,-1,0,1,0,0,0,0,1,0,{$y},{$x}"></Bone></Part></Brick>'
        baseTemplate = baseTemplate.replace(/\{\$idx\}/ig, idx)
        baseTemplate = baseTemplate.replace(/\{\$materialid\}/ig, materialid)
        baseTemplate = baseTemplate.replace(/\{\$y\}/ig, step * r)
        baseTemplate = baseTemplate.replace(/\{\$x\}/ig, step * c * -1)
        return baseTemplate
      }
      let xml = headerStr + cameraStr + '<Bricks cameraRef="0">'
      let idx = 1
      for (let r = 0; r < partRows; r++) {
        for (let c = 0; c < partCols; c++) {
          xml = xml + getBase(idx, c, r, conf.brickDt[workBgColor])
          idx++
        }
      }
      // 获取拼图块数据
      const getBrick = (idx, c, r, materialid) => {
        materialid = materialid || lxfConf.brickMaterialid
        const posY = 12.8 * (partSize / 16) * (partRows - 1)
        const posX = 0
        const step = 0.8
        let brickTemplate = '\n<Brick refID="{$idx}" designID="' + lxfConf.brickDesignID + '" itemNos="{$idx}"><Part refID="{$idx}" designID="' + lxfConf.brickDesignID + '" materials="{$materialid},0" decoration="0"><Bone refID="{$idx}" transformation="0,-1,0,1,0,0,0,0,1,0,{$y},{$x}"></Bone></Part></Brick>'
        brickTemplate = brickTemplate.replace(/\{\$idx\}/ig, idx)
        brickTemplate = brickTemplate.replace(/\{\$materialid\}/ig, materialid)
        brickTemplate = brickTemplate.replace(/\{\$y\}/ig, posY - step * r)
        brickTemplate = brickTemplate.replace(/\{\$x\}/ig, posX - step * c)
        return brickTemplate
      }
      let i = 0
      for (let r = 0; r < workRows; r++) {
        for (let c = 0; c < workCols; c++) {
          if (workData[i] && conf.brickDt[workData[i].toUpperCase()]) {
            xml = xml + getBrick(idx + i + 1, c, r, conf.brickDt[workData[i].toUpperCase()])
          }
          i++
        }
      }
      xml = xml + '\n</Bricks>' + footerStr
      cb && cb(xml)
    }
    makeLXFML()
  }
}
export default main
