/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2023-3-5
 * @copyright Gridy.Art
 */
import utils from '@/js/utils'
import GRIDY from '@/js/sdk/GridySDK'
import download from 'downloadjs'
import Excel from 'exceljs'
import i18n from '../../lang'
// eslint-disable-next-line
const $t = (key, value) => i18n.t(key, value)
var main = function(file = {}) {
  this.GRIDY = new GRIDY()
  this.GRIDY.setFile(file)
  this.file = file
  this.workbook = null
  this.worksheet = null
  this.creator = $t('domain')
  this.copyright = $t('i.powerBy')
  this.tips = $t('i.moreGridy')
}
main.prototype = {
  setFile: function(file) {
    this.GRIDY.setFile(file)
    this.file = file
  },
  // 导出xls文件
  exportFile: function(options = {}, cb) {
    this.workbook = new Excel.Workbook()
    this.workbook.creator = this.creator
    const createTime = this.file.createTime ? new Date(this.file.createTime) : new Date()
    const updateTime = this.file.updateTime ? new Date(this.file.updateTime) : createTime
    const publishTime = this.file.publishTime ? new Date(this.file.publishTime) : createTime
    this.workbook.created = createTime
    this.workbook.modified = updateTime
    this.workbook.lastPrinted = publishTime
    if (typeof options.sceneIdx !== 'undefined') {
      this.drawScenes(options.sceneIdx, options)
    } else {
      for (var idx = 0; idx < this.file.canvas.scenes.length; idx++) {
        this.drawScenes(idx, options)
      }
    }
    this.workbook.xlsx.writeBuffer().then((buff) => {
      var blob = new Blob([buff], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const params = [blob, (this.file.name || 'Untitle') + '-' + $t('i.excelDrawing') + '_' + utils.date('str') + '.xls', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      if (options.download) download(...params)
      cb && cb([params], 'xls')
    })
  },
  // 绘制场景
  drawScenes: function(sceneIdx, options = {}) {
    this.worksheet = this.workbook.addWorksheet((sceneIdx + 1) + '-' + (this.file.canvas.scenes[sceneIdx].name || this.file.name))
    this.worksheet.properties.defaultRowHeight = 6.75 * 1.5
    this.worksheet.properties.defaultColWidth = 1.2 * 1.3
    const objData = this.GRIDY.getSceneData(sceneIdx, options)
    const mod = options.mod || ''
    this.initSheet(this.worksheet, this.file.canvas.rows, this.file.canvas.cols, objData.bgColor, mod)
    let x, y
    let i = 0
    const len = objData.imageData.length
    for (i = 0; i < len; i++) {
      y = Math.floor(i / objData.cols)
      x = Math.floor(i - y * objData.cols)
      if (objData.imageData[i]) {
        this.drawGrid(this.worksheet, x, y, objData.imageData[i], this.file.canvas.rows, this.file.canvas.cols)
      }
    }
    this.addCopyright(this.worksheet, this.file.canvas.rows)
  },
  // 写版权
  addCopyright: function(worksheet, rows) {
    worksheet.mergeCells(`B${rows + 3}:AB${rows + 3}`)
    worksheet.getCell(`B${rows + 3}`).value = {
      'richText': [
        { 'font': { 'size': 9, 'color': { 'argb': 'FFFF6600' }}, 'text': this.tips }
      ]
    }
    worksheet.mergeCells(`B${rows + 4}:AB${rows + 4}`)
    worksheet.getCell(`B${rows + 4}`).value = {
      'richText': [
        { 'font': { 'size': 9, 'color': { 'argb': 'FFFF6600' }}, 'text': this.copyright }
      ]
    }
  },
  // 初始化表格边框、背景色
  initSheet: function(worksheet, rows, cols, bgColor, mod) {
    bgColor = bgColor || '#FFFFFF'
    bgColor = bgColor.replace('#', 'FF')
    for (let r = 0; r < rows; r++) {
      for (let c = 0; c < cols; c++) {
        const col = utils.convert26(c + 2)
        const row = r + 2
        worksheet.getCell(`${col}${row}`).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: bgColor }
        }
        if (mod !== 'pixel') {
          worksheet.getCell(`${col}${row}`).border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' }
          }
        }
      }
    }
  },
  // 绘制一个格子
  drawGrid: function(worksheet, x, y, color, limitRows, limitCols) {
    if (y >= limitRows || x >= limitCols || x < 0 || y < 0) {
      return
    }
    color = color || '#FFFFFF'
    color = color.replace('#', 'FF')
    const col = utils.convert26(x + 2)
    const row = y + 2
    worksheet.getCell(`${col}${row}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color }
    }
  }
}
export default main
