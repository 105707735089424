<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'items-' + mod + '-container'" :style="listStyle" class="my-list" :top-load-method="(loaded) => loadData(loaded, showUp ? 'up' : 'renew')" :bottom-load-method="(loaded) => loadData(loaded, 'down')" @infinite-scroll="getData('down')">
      <div class="flow-container">
        <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
          <div v-for="(item, i) in column.items" :key="i" class="item">
            <div v-if="item.quantity > 0" class="card" :style="{ 'width': cardWidth + 'px', 'height': (item._height - 5) + 'px' }">
              <div>
                <img :src="getThumb(data.item_types[item.item_typeid])" :class="{'round-top': cardWidth - 16 < item._raw_width}" :style="{ width: item._raw_width + 'px', height: item._raw_height + 'px' }" @click="viewItem(item)">
                <!-- <div class="align-center size48 gray" :style="{ width: item._raw_width + 'px', height: item._raw_height + 'px', 'line-height': item._raw_height + 'px' }" @click="viewItem(item)">{{ getThumbName(item) }}</div> -->
                <div class="foot">
                  <div class="center limit padding-left4" :class="getClass(item.level)" @click="viewItem(item)">
                    {{ getName(item) }}
                  </div>
                  <div class="right">
                    <el-button class="tap-btn" @click="showActionSheet(item)">
                      <i class="iconfont my-dot-more-v" />
                    </el-button>
                  </div>
                </div>
                <div class="foot count">
                  <span v-if="item.quantity > 1" class="tag bg-22">{{ item.quantity }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div v-if="flowHeight > height && data.noDownPage" class="tip">{{ $t('i.showAll') }}</div>
      <el-empty v-if="(!itemNums && !loading[mod]) && data.active" :image="emptyImage" :description="$t('i.none')" />
      <el-empty v-if="(!itemNums && !loading[mod]) && !data.active" :image="lockImage" :description="typeDescription()" />
    </pull-to>
    <div v-if="data.scrollTop > 200" class="go-top">
      <el-button class="tap-btn" @click="backTop()">
        <i class="iconfont my-top" />
      </el-button>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/items'
export default {
  mixins: [mixins]
}
</script>
