<template>
  <div>
    <div :id="'thread-' + mod + '-container'" class="flow-container my-list">
      <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
        <div v-for="(item, i) in column.items" :key="i" class="item">
          <div class="card" :class="{'full': item._full}" :style="{ 'width': cardWidth + 'px', 'height': ((item._more ? item._height + 30 : item._height) - 5) + 'px' }">
            <div>
              <div class="head" style="height: 45px;">
                <div class="left" @click="emit('goto', ['user', { userid: item.user_id } ])">
                  <span v-if="!(users[item.user_id] && users[item.user_id].avatar)" class="el-avatar el-avatar--circle noavatar large">
                    <i class="iconfont my-man" />
                  </span>
                  <el-avatar v-if="(users[item.user_id] && users[item.user_id].avatar)" :size="42" :src="getAvatar(item)" />
                </div>
                <div class="center">
                  <span @click="emit('goto', ['user', { userid: item.user_id } ])">{{ (users[item.user_id]) ? users[item.user_id].nickname : view.anonymity }}</span>
                  <br><span>{{ getCreateTime(item) }}</span> <i v-if="(threadCount[item.id] && threadCount[item.id].view_count)" class="iconfont my-eye tiny" /><span> {{ threadCount[item.id] && threadCount[item.id].view_count ? formateNums(threadCount[item.id].view_count) : '' }}</span>
                </div>
                <div class="right">
                  <el-button class="tap-btn" @click="showActionSheet(item.user_id, item.id, item.is_sticky, item.is_essence)">
                    <i class="iconfont my-dot-more-v" />
                  </el-button>
                </div>
                <div v-if="(item.user_id !== loginUserId)" class="float-right">
                  <el-button v-if="(followState[item.user_id] === 0)" class="tap-btn on" @click="unfollow(item.user_id)">{{ $t('i.hadFollow') }}</el-button>
                  <el-button v-if="(followState[item.user_id] === 1)" class="tap-btn on" @click="unfollow(item.user_id)">{{ $t('i.followEach') }}</el-button>
                  <el-button v-if="(followState[item.user_id] !== 0 && followState[item.user_id] !== 1)" class="tap-btn" @click="follow(item.user_id)">{{ $t('i.follow') }}</el-button>
                </div>
              </div>
              <div class="txt" :class="{'full': item._full}" :style="{ 'overflow-x': 'hidden', 'overflow-y': 'hidden', 'width': cardWidth + 'px', 'height': item._raw_height + 'px' }" @click="viewThread(item.id)" v-html="getContent(item)" />
              <div v-if="item._more" class="more">
                <el-button class="tap-btn" @click="showFull(item)">
                  <i v-if="item._full" class="iconfont my-close-up" />
                  <i v-if="!item._full" class="iconfont my-open-down" />
                </el-button>
              </div>
              <div v-if="item._images && item._images.length" :style="{'height': item._imagesHeight + 'px', 'padding': '0 10px'}">
                <el-col v-for="(img, x) in item._images" :key="x" :span="(item._images.length === 3 && x === 0 ? 24 : (item._images.length === 5 || item._images.length === 7 || item._images.length === 8) && x === 0 ? 16 : 8)" :style="{'min-width': item._imageSize + 'px', 'height': (item._images.length === 2 ? 2 * item._imageSize : item._imageSize) + 'px', 'padding': '1px'}">
                  <el-image :style="{'width': ((((item._images.length === 3 || item._images.length === 5 || item._images.length === 7 || item._images.length === 8) && x === 0) || (item._images.length === 7 && x === 6)) ? (2 * item._imageSize) : item._imageSize) + 'px', 'height': (item._images.length === 2 ? 2 * item._imageSize : item._imageSize) + 'px'}" fit="cover" :src="img" lazy @click="viewImages(item._images, x, item)">
                    <div slot="error" class="image-slot align-center size24 gray" :style="{'line-height': (item._images.length === 2 ? 2 * item._imageSize : item._imageSize) + 'px'}">
                      <i class="el-icon-picture-outline" />
                    </div>
                  </el-image>
                </el-col>
              </div>
              <div v-if="item._docs && item._docs.length">
                <el-row v-for="(attachment, y) in item._docs" :key="y" style="padding: 5px 10px;">
                  <div class="round4 bg-1b padding4" :style="{'width': cardWidth - 20 + 'px', 'height': '50px', 'line-height': '22px'}">
                    <div class="ellipsis float-left padding-left8" :style="{'width': cardWidth - 90 + 'px'}">
                      <span>{{ $t('i.attachment') }} {{ y + 1 }}.</span>
                      {{ attachment.file_name }} <br><span class="color-88">{{ formateBytes(attachment.file_size) }}</span>
                    </div>
                    <div class="padding-top8 float-right align-right">
                      <el-button class="tap-btn" @click="downDoc(attachment)">
                        {{ $t('i.download') }}
                      </el-button>
                    </div>
                  </div>
                </el-row>
              </div>
              <div v-if="(item.refid && item._thumb_height)" class="work-thumb">
                <img class="round4" :style="{'height': item._thumb_height - 15 + 'px'}" :src="worksHost + 'work/action/download/gridy/size/8/shape/none/workid/' + item.refid + '/' + item.refid + '.png?ver=' + item.id" @click="openViewer(0, item.refid)">
              </div>
              <div class="foot">
                <el-row>
                  <el-col :span="6">
                    <el-button class="tap-btn share clipboard-thread" :data-clipboard-text="getContent(item, '；') + ' ' + mainHost + '#/?mod=thread&threadid=' + item.id" @click="shareIt(item.id)">
                      <i class="iconfont my-share" /> {{ (threadCount[item.id] && threadCount[item.id].share_count) ? formateNums(threadCount[item.id].share_count) : $t('i.share') }}
                    </el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button class="tap-btn share" @click="viewThread(item.id)">
                      <i class="iconfont my-comments" /> {{ (threadCount[item.id] && threadCount[item.id].post_count > 1) ? formateNums(threadCount[item.id].post_count - 1) : $t('i.comment') }}
                    </el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button class="tap-btn share" @click="praisePost(posts[item.id].id || 0, item.id)">
                      <i class="iconfont" :class="{ 'my-praise-fill': threadCount[item.id] ? threadCount[item.id]['praise_state'] : false, 'my-praise-blank': !(threadCount[item.id] ? threadCount[item.id]['praise_state'] : false) }" /> {{ (threadCount[item.id] && threadCount[item.id].praise_count) ? formateNums(threadCount[item.id].praise_count) : $t('i.praise') }}
                    </el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button class="tap-btn share" @click="collectIt(item.id)">
                      <i class="iconfont" :class="{ 'my-star' : threadCount[item.id] ? threadCount[item.id]['collect_state'] : false, 'my-blank-star' : !(threadCount[item.id] ? threadCount[item.id]['collect_state'] : false) }" /> {{ (threadCount[item.id] && threadCount[item.id].collect_count) ? formateNums(threadCount[item.id].collect_count) : $t('i.favor') }}
                    </el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="mod !=='thread' && itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
    <div v-if="mod !=='thread' && flowHeight > height && data.noDownPage" class="tip">{{ $t('i.showAll') }}</div>
    <div v-if="mod ==='index' && isDesktop" class="size12 color-88 align-center" style="height:60px;">
      <div class="float-left">
        {{ $t('copyRight') }} | <span @click="emit('goto', ['popPage', { type: 'about', 'title': $t('i.aboutUs') }])">{{ $t('i.aboutUs') }}</span> | <span @click="emit('goto', ['popPage', { type: 'contact', 'title': $t('i.contactUs') }])">{{ $t('i.contactUs') }}</span> | <span @click="emit('goto', ['popPage', { type: 'privacy', 'title': $t('i.privacy') }])">{{ $t('i.privacy') }}</span> | <span @click="emit('goto', ['popPage', { type: 'agreement', 'title': $t('i.agreement') }])">{{ $t('i.agreement') }}</span>
      </div>
      <div class="float-right">
        <a href="https://dxzhgl.miit.gov.cn/" target="_blank">闽B2-20221563</a> | <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2022000576号</a> | <a href="https://bcbeian.ifcert.cn/" target="_blank">闽网信备35012122961900430015号</a> | <a href="https://www.beian.gov.cn/" target="_blank">公安备案号35012102500631</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/thread'
export default {
  mixins: [mixins]
}
</script>
