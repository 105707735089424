<template>
  <el-dialog
    ref="paperViewerDialog"
    :title="title"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page large headless gridyviewer paperviewer"
  >
    <div class="header" style="background:none;" :class="{'padding-top12 padding-left4 padding-right8': isDesktop, 'padding-top8': !isDesktop}">
      <div class="left">
        &nbsp;
      </div>
      <div v-if="!isDesktop" class="middle">
        {{ $t('i.paper') }}
      </div>
      <div class="right">
        <el-button class="tap-btn" @click="close()">
          <i class="iconfont my-close size24" />
        </el-button>
      </div>
    </div>
    <div
      v-loading="false"
      :element-loading-text="$t('i.rending')"
      shadow="never"
      element-loading-background="rgba(0, 0, 0, 0.6)"
      class="previewArea"
      :style="previewAreaStyle"
    >
      <vue-draggable-resizable
        v-show="!opts.d3"
        :x="opts.x"
        :y="opts.y"
        :z="0"
        :w="1"
        :h="1"
        :parent="false"
        :draggable="isDesktop"
        :resizable="false"
        :disable-user-select="true"
      >
        <img
          id="image"
          ref="image"
          :src="blankImage"
        >
      </vue-draggable-resizable>
      <voxel-viewer
        v-if="opts.d3 && config.show"
        ref="voxelViewer"
        :state="state"
        :width="width"
        :height="height"
        :fixpos="0"
        @receive="(event) => emit('handle', event)"
      />
    </div>
    <div v-show="initPreviewSize" style="position: absolute;z-index: 99;" :style="{'top': isDesktop ? '20px' : '54px', height: previewAreaHeight + 'px', 'left': isDesktop ? '45px' : '14px', width: ' calc(100% - ' + (isDesktop ? '90px' : '30px') + ')'}">
      <div :style="{'width': previewAreaWidth + 'px', height: previewAreaHeight + 'px', 'margin': '0 auto'}">
        <img id="previewImage" ref="previewImage" :src="blankImage" :height="previewAreaHeight" class="round4" style="position: absolute;">
        <div style="position: absolute;z-index: 1;">
          <div v-for="r in partRows" :key="r" :style="{'width': previewAreaWidth + 'px', height: previewPartWidth + 'px'}">
            <div v-for="c in partCols" :key="c" style="float:left;width:32px;height:32px;" :style="partColStyle(r, c)" @click="selectPart(r, c)" />
          </div>
        </div>
      </div>
    </div>
    <div id="paperviewer-footer" slot="footer" class="hide-overflow" :style="{'width': (isDesktop ? width - 15 : width - 13) + 'px', 'overflow-x': 'auto', 'overflow-y': 'hidden', 'margin': isDesktop ? '0 2px' : ''}">
      <div v-if="paperDt && paperDt.bricks" class="opacity-bg round8" style="margin: 0 auto;height:32px;padding: 4px 4px 4px 8px" :style="brickStyle()">
        <div v-for="(item, k) in paperDt.bricks" v-show="data.hideColors.indexOf(item.color) < 0" :key="k">
          <div class="float-left align-center box24 size12 margin-right4" :style="{'background': item.color, 'border-radius': roundTile? '100%' : '0', 'padding-top': '6px', color: whiteBrickIds.indexOf(k) >= 0 ? '#FFFFFF' : '#000000'}">
            {{ k }}
          </div>
        </div>
      </div>
    </div>
    <div class="foot_paper" :style="{'width': (isDesktop ? width - 15 : width) + 'px', 'margin': isDesktop ? '0 2px' : '', 'position': isDesktop ? 'absolute' : 'fixed'}" style="z-index:99;bottom:5px;text-align:center;display: flex;">
      <div style="float:left;padding: 8px 0px 0px 4px;" :style="{width: (isDesktop ? 7 : 0) + 36 + 'px'}">
        <el-tooltip v-model="tooltipState.savePaper" :manual="true" :disabled="!tooltipState.savePaper" class="item" effect="dark" placement="top">
          <div slot="content"><div @click="closeToolTip('savePaper')">{{ $t('i.savePaperTips') }}</div></div>
          <el-button v-if="!config.hideSaveBtn" class="iconfont my-save-as size20 padding8 opacity-bg" :title="$t('i.savePaper')" @click="handle('savePaper')" />
        </el-tooltip>
      </div>
      <div style="width:280px;margin: 0 auto;margin-top:6px;">
        <el-button v-if="latestPaperNum > 0" type="" style="width:45%;max-width: 126px;" class="btn-ok bricky-shadow" @click="prePage">{{ $t('i.prePage') }}</el-button>
        <el-button v-if="latestPaperNum <= (partCols * partRows - 2)" type="light" style="width:45%;max-width: 126px;" class="btn-ok bricky-shadow" @click="nextPage">{{ $t('i.nextPage') }}</el-button>
      </div>
      <div style="width:36px;float:right;padding: 8px 8px 0px 0px;margin-right: 4px;">
        <el-tooltip v-model="tooltipState.downPaper" :manual="true" :disabled="!tooltipState.downPaper" class="item" effect="dark" placement="top">
          <div slot="content"><div @click="closeToolTip('downPaper')">{{ $t('i.downPaperTips') }}</div></div>
          <el-button class="iconfont my-download size20 padding8 opacity-bg" :title="$t('i.downPaper')" @click="handle('downPaper')" />
        </el-tooltip>
        <!-- 导出有bug，且没有必要开放
        <el-dropdown trigger="click" placement="top-start" @command="handle">
          <el-button class="iconfont my-out size20 padding8 opacity-bg" :title="$t('i.more')" />
          <el-dropdown-menu slot="dropdown" class="my-dropdown">
            <el-dropdown-item command="exportPixel"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />{{ $t('i.exportPixel') }}</div></el-dropdown-item>
            <el-dropdown-item command="exportGridy"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />{{ $t('i.exportGridy') }}</div></el-dropdown-item>
            <el-dropdown-item command="exportBrick"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />{{ $t('i.exportPuzzle') }}</div></el-dropdown-item>
            <el-dropdown-item command="exportAdvance"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />{{ $t('i.advancedExport') }}</div></el-dropdown-item>
            <el-dropdown-item command="publishWork" divided> <i class="iconfont my-zone" /> {{ $t('i.publish') }}...</el-dropdown-item>
            <el-dropdown-item command="share"> <i class="iconfont my-share-out" /> {{ $t('i.copyLink') }}</el-dropdown-item>
            <el-dropdown-item command="pop"> <i class="iconfont my-pop" /> {{ $t('i.createShareImage') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import service from '@/js/service'
import conf from '@/js/conf/conf'
import GRIDY from '@/js/sdk/GridySDK'
import voxelViewer from '@/components/viewer/voxelViewer'
import blankImage from '@/assets/blank.png'
export default {
  components: {
    voxelViewer
  },
  mixins: [mixins],
  props: {},
  data() {
    let width, height
    if (this.state.platform.type === 'desktop') {
      width = 632
      height = 632
    } else {
      width = utils.width()
      height = utils.height()
    }
    const palette = conf.palette()
    const defaultOpts = {
      x: 0,
      y: 0,
      gridSize: 8,
      paletteId: 'brickfyGray',
      preFillShape: '',
      fillShape: 'square',
      brickfy: false,
      d3: true,
      diy: false
    }
    return {
      mainHost: conf.hosts().mainHost,
      inited: false,
      sceneIdx: 0,
      checkPaperCount: 0,
      count: 0,
      sto: null,
      initPreviewSize: false,
      previewAreaWidth: 128,
      previewAreaHeight: 128,
      previewPartWidth: 32,
      previewImgEl: null,
      imgEl: null,
      voxelViewerEl: null,
      GRIDYXLS: null,
      GRIDYLXF: null,
      GRIDYPAPER: null,
      GRIDYBOM: null,
      GRIDY: null,
      defaultOpts: defaultOpts,
      opts: utils.deepClone(defaultOpts),
      width: width,
      height: height,
      blankImage: blankImage,
      palette: palette,
      whiteBrickIds: conf.whiteBrickIds,
      curPaperNum: 0,
      latestPaperNum: 0,
      dataSN: {},
      paperState: true,
      paperDt: '',
      tooltipState: {
        savePaper: false,
        downPaper: false
      }
    }
  },
  computed: {
    config() {
      return this.view.paperViewer
    },
    data() {
      return this.config.data
    },
    roundTile() {
      return this.config.data.roundTile
    },
    title() {
      let tit
      if (this.data.preview) {
        tit = this.$t('i.exportPreview')
      } else {
        if (this.opts.diy || this.opts.paletteId === 'brickfy') {
          tit = this.$t('i.createPuzzle')
        }
      }
      return tit
    },
    previewAreaStyle() {
      return { 'width': this.width + 'px', 'height': this.height + 'px', 'background': '#000000', 'overflow': 'hidden', 'text-align': 'center' }
    },
    scenes() {
      return (this.data.work && this.data.work.canvas) ? this.data.work.canvas.scenes : []
    },
    curObj() {
      return (this.scenes[0] && this.scenes[0].objs && this.scenes[0].objs[0]) ? this.scenes[0].objs[0] : {}
    },
    cols() {
      return this.curObj.cols || 0
    },
    rows() {
      return this.curObj.rows || 0
    },
    partCols() {
      return Math.ceil(this.cols / 16)
    },
    partRows() {
      return Math.ceil(this.rows / 16)
    },
    partWidth() {
      return 16 * 32
    }
  },
  mounted() {
    setTimeout(() => {
      this.view.myPublish.fn = null
      this.checkPaperCount = 0
      if (this.config.show) {
        this.loading(true)
        setTimeout(() => {
          this.init(() => {
            // setTimeout(() => {
            //   this.emit('checkLoginStatus', [])
            // }, 50)
            this.initToolTips()
            // eslint-disable-next-line
            if (!this.imgEl.scaleX) Transform(this.imgEl)
            this.data.work.type = 1
            this.GRIDY = new GRIDY()
            this.GRIDY.setFile(this.data.work)
            this.checkPaperMath()
            this.previewAreaWidth = Math.floor(this.cols * this.previewAreaHeight / this.rows)
            this.previewPartWidth = Math.floor(this.previewAreaWidth / this.partCols)
            this.previewAreaWidth = this.previewPartWidth * this.partCols
            this.previewAreaHeight = this.previewPartWidth * this.partRows
            this.initPreviewSize = true
            if (this.data.imageUrl) {
              this.previewImgEl.src = this.getOrderImage({ image: this.data.imageUrl })
            }
            this.draw(() => {}, true)
            if (!this.opts.d3) this.loading(false)
          })
        }, 50)
      } else {
        if (this.imgEl) {
          this.imgEl.onload = null
          this.imgEl.onerror = null
        }
        this.inited = false
      }
    }, 50)
  },
  methods: {
    async initToolTips() {
      for (const k in this.tooltipState) {
        const ret = await service.getCount(this.loginUserId, 'tooltip_' + k)
        this.tooltipState[k] = utils.empty(ret)
      }
    },
    closeToolTip(k) {
      service.incCount(this.loginUserId, 'tooltip_' + k)
      this.tooltipState[k] = false
    },
    getOrderImage(item) {
      if (!item || !item.image) return ''
      if (item.image.substr(0, 6) === 'cos://') {
        item.image = item.image.replace('public/order/', '')
        return service.getCosUrl(item.image, 'order', '') || ''
      } else {
        return item.image
      }
    },
    brickStyle() {
      if (this.paperDt && this.paperDt.bricks) {
        let n = 0
        for (const k in this.paperDt.bricks) {
          if (this.data.hideColors.indexOf(this.paperDt.bricks[k].color) < 0) n++
        }
        return { 'width': n * 28 + 12 + 'px' }
      }
    },
    partColStyle(r, c) {
      let opacity = 0.7
      let border
      if (this.latestPaperNum > (r - 1) * this.partCols + c - 1) {
        opacity = 0
      } else if (this.latestPaperNum === (r - 1) * this.partCols + c - 1) {
        opacity = 0
        border = '1px solid var(--color-yellow)'
      }
      const style = {
        background: 'rgba(0, 0, 0, ' + opacity + ')',
        border: border,
        width: this.previewPartWidth + 'px',
        height: this.previewPartWidth + 'px'
      }
      return style
    },
    selectPart(r, c) {
      this.curPaperNum = (r - 1) * this.partCols + c - 1
      this.showPaper()
    },
    prePage() {
      this.curPaperNum = this.latestPaperNum - 1
      this.showPaper()
    },
    nextPage() {
      this.curPaperNum = this.latestPaperNum + 1
      this.showPaper()
    },
    checkPaperMath() {
      this.checkPaperCount++
      // this.dataSN = service.getSN(0)
      // const colorfyDt = conf.colorfyDt()
      // if (this.dataSN.colorfy && this.dataSN.cols && this.dataSN.rows && (this.data.colorfyId !== this.dataSN.colorfy || this.cols !== this.dataSN.cols || this.rows !== this.dataSN.rows)) {
      //   if (this.checkPaperCount <= 3 & this.checkPaperCount % 2 === 1) this.alert('当前是' + this.cols + 'x' + this.rows + '的' + (colorfyDt[this.dataSN.colorfy] ? colorfyDt[this.dataSN.colorfy].name + '款' : '') + '图纸，请确认是否跟产品相匹配。')
      // }
    },
    showPaper() {
      const show = (status, dataSN) => {
        if (status) {
          this.latestPaperNum = this.curPaperNum
          this.voxelViewerEl.setDiyMod('paper', { paperNum: this.curPaperNum, partCols: this.partCols, partRows: this.partRows, partWidth: this.partWidth })
          this.voxelViewerEl.draw(this.imgEl, () => {})
          // TOOD 无法获取拼豆豆的颜色编号？
          this.paperDt = this.GRIDY.getObjPartPaper(this.sceneIdx, 0, -1, this.curPaperNum)
          if (dataSN) this.dataSN = dataSN
          this.checkPaperMath()
        } else {
          this.curPaperNum = this.latestPaperNum
        }
      }
      /* 在线图纸部分开放 */
      const freePaperNum = Math.floor(this.partCols * this.partRows * 1 / 4)
      if (this.curPaperNum >= freePaperNum && this.data.from !== 'savePaper') {
        // 保存的图纸或者VIP可直接查看完整图纸，不是VIP要验证SN
        this.emit('checkVip', [(isVip) => {
          if (isVip) {
            show(true)
          } else {
            this.emit('reqSN', [0, '', '', show])
          }
        }, true])
      } else {
        show(true)
      }
      // show(true)
    },
    init(cb) {
      if (this.inited) {
        cb && cb()
        return
      }
      this.previewImgEl = this.$refs.previewImage
      this.imgEl = this.$refs.image
      this.voxelViewerEl = this.$refs.voxelViewer
      if (!this.imgEl || (!this.voxelViewerEl && this.opts.d3)) {
        setTimeout(() => {
          this.init(cb)
        }, 50)
        return
      }
      const load = () => {
        this.setImageStyle()
        if (this.opts.d3) {
          this.voxelViewerEl.setWork(this.GRIDY.getFile())
          // TOOD 无法获取拼豆豆的颜色编号？
          this.paperDt = this.GRIDY.getObjPartPaper(this.sceneIdx, 0, -1, this.curPaperNum)
          this.voxelViewerEl.setDiyMod('paper', { paperNum: this.curPaperNum, partCols: this.partCols, partRows: this.partRows, partWidth: this.partWidth })
          this.voxelViewerEl.launch(() => {
            this.voxelViewerEl.draw(this.imgEl, () => {
              this.loading(false)
            })
          })
        } else {
          this.loading(false)
        }
      }
      this.imgEl.onload = load
      this.imgEl.onerror = load
      this.inited = true
      cb && cb()
    },
    close() {
      this.closePop(this.config)
    },
    loading(show, tip) {
      this.config.loading = show
      this.emit('loading', [!!show, tip || this.$t('i.rending')])
    },
    setImageStyle() {
      if (!this.imgEl || !this.imgEl.width || !this.imgEl.height) return
      const left = (this.width - this.imgEl.width) / 2
      const top = (this.height - this.imgEl.height) / 2
      this.imgEl.style.left = left + 'px'
      this.imgEl.style.top = top + 'px'
      this.imgEl.style.position = 'relative'
    },
    handle(act) {
      const file = this.GRIDY.getFile()
      if (act === 'exportPixel' || act === 'exportGridy' || act === 'exportBrick' || act === 'exportAdvance') {
        this.emit('showExportSheet', [file, act, this.opts, this.sceneIdx, this.voxelViewerEl])
      } else if (act === 'publishWork') {
        file.type = 1
        this.emit('publishWork', [file, false, (dt) => {
          file.catalogid = dt.catalogid
          file.name = dt.name
          file.info = dt.info
          file.tags = dt.tags
          file.public = dt.public
          file.public_at = dt.public_at
          file.share = dt.share
          file.threadid = dt.threadid
          file.postid = dt.postid
          file.workid = dt.workid
          this.GRIDY.setFile(file)
        }])
      } else if (act === 'pop') {
        this.emit('exportFile', [file, 'pop', 'pop', this.opts, this.sceneIdx])
      } else if (act === 'downPaper' || act === 'savePaper') {
        if (!this.loginUserId) return this.message('', 'login')
        this.closeToolTip(act)
        this.opts.frameId = this.data.frameId
        this.opts.hideColors = this.data.hideColors
        if (act === 'downPaper') {
          this.opts.isBrick = true
          this.opts.roundTile = this.roundTile
          this.opts.CLBrick = !this.roundTile
          this.opts.hideGridBorder = !this.roundTile
          // circle square
          this.opts.fillShape = this.roundTile ? 'circle' : 'square'
          this.emit('exportFile', [file, 'paper', 'brick', this.opts, this.sceneIdx, true])
        } else if (act === 'savePaper') {
          this.savePaper(act, file)
        }
      }
    },
    // 保存图纸 TODO： 避免重复保存图纸
    savePaper(act, file, cb) {
      const run = () => {
        this.emit('loading', [true])
        this.dataSN = service.getSN(0)
        const frameid = ''
        const bgid = ''
        const paperDt = {
          type: file.type || 0,
          snid: this.dataSN.snid || 0,
          sn: this.dataSN.sn || '100000000000000',
          name: file.name,
          info: file.name,
          cols: this.cols,
          rows: this.rows,
          colorfyid: this.data.colorfyId || '',
          frameid: frameid || '',
          bgid: bgid || '',
          workid: file.workid || 0,
          catalogid: file.catalogid || 0,
          publishid: file.publishid || 0
        }
        // 不保存图片，通过paperid可获取缩略图
        // paperDt.image = this.data.imageUrl
        paperDt.data = utils.deepClone(file)
        service.post('paper', paperDt, (r, t) => {
          let paperid = 0
          if (t === 'success' && r && r.data && r.data.id) {
            paperid = r.data.id
            this.alert(this.$t('i.savePaperOk'), t)
          } else {
            this.message(r || this.$t('i.savePaperFail'), t)
          }
          this.emit('loading', [false])
          cb && cb(paperid)
        }, true)
      }
      // VIP可直接保存图纸，不是VIP要验证SN
      this.emit('checkVip', [(isVip) => {
        if (isVip) {
          run()
        } else {
          this.emit('reqSN', [0, '', '', (status, dataSN) => {
            if (status) {
              if (dataSN.save_limit && dataSN.save_count >= dataSN.save_limit) {
                service.removeSN()
                this.alert(this.$t('i.savePaperLimit', { save_limit: dataSN.save_limit }))
              } else {
                run()
              }
            } else {
              cb && cb(0)
            }
          }, 1, '', this.$t('i.savePaper'), 0, 1])
        }
      }, true])
    },
    draw(cb, showImg = true, options) {
      options = options || {}
      options.isBrick = true
      options.roundTile = this.roundTile
      options.CLBrick = !this.roundTile
      options.hideGridBorder = !this.roundTile
      // circle square
      options.fillShape = this.roundTile ? 'circle' : 'square'
      options.gridSize = 32
      options.paperMod = true
      options.frameId = this.data.frameId
      options.hideColors = this.data.hideColors
      const imageUrl = this.GRIDY.getObjImage(this.sceneIdx, 0, -1, options)
      if (showImg) this.imgEl.src = imageUrl
      cb && cb(imageUrl, options)
    }
  }
}
</script>
