<template>
  <div>
    <el-input
      v-if="visible && editable"
      ref="saveInput"
      v-model="inputVal"
      size="mini"
      class="my-edit-text"
      @keyup.enter.native="handleInput"
      @blur="handleInput"
    />
    <div v-if="!visible && editable" :class="icon || 'el-icon-edit'" style="float: left;margin-top: 3px;margin-right: 5px;" :title="$t('i.edit')" @click="showInput" />
    <div v-if="!visible && editable" class="my-input-text" style="float: left" @click="showInput">{{ val }} {{ suffix }}</div>
    <div v-if="!editable" class="my-input-text" style="float: left">{{ val }} {{ suffix }}</div>
  </div>
</template>
<style>
.my-edit-text .el-input__inner{
  width: 124px;
  height: 20px;
  line-height: 16px;
  padding: 2px 5px!important;
}
.my-input-text {
  width: 104px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
</style>
<script>
export default {
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    val: {
      type: String,
      required: true,
      default: ''
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    suffix: {
      type: String,
      required: false,
      default: ''
    },
    min: {
      type: String,
      required: false,
      default: ''
    },
    max: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      inputVal: this.val,
      visible: false
    }
  },
  methods: {
    showInput() {
      this.inputVal = this.val
      this.visible = true
      this.$nextTick(_ => {
        this.$refs.saveInput.$refs.input.focus()
      })
    },
    handleInput() {
      if (!this.visible) {
        return
      }
      this.visible = false
      let v = this.inputVal ? this.inputVal.trim() : ''
      if (this.min) {
        if (v === '') {
          v = 0
        }
        v = Math.max(parseInt(v), this.min)
      }
      if (this.max) {
        if (v === '') {
          v = 0
        }
        v = Math.min(parseInt(v), this.max)
      }
      this.inputVal = v
      this.$emit('receive', v)
    }
  }
}
</script>
