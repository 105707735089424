/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2022-1-29
 * @copyright Gridy.Art
 */
import paletteConf from '@/js/conf/palettes'
import skusConf from '@/js/conf/skus'
import viewConf from '@/js/conf/viewConf'
import stateConf from '@/js/conf/stateConf'
import schemaConf from '@/js/conf/schemaConf'
import i18n from '../../lang'
// eslint-disable-next-line
const $t = (key, value) => i18n.t(key, value)
const brickDt = {}
for (const id in paletteConf.brickfy) {
  brickDt[paletteConf.brickfy[id].toUpperCase()] = id
}
const darkenedBrickDt = {}
for (const id in paletteConf.darkenedBrickfy) {
  darkenedBrickDt[paletteConf.darkenedBrickfy[id].toUpperCase()] = id
}
// 积木颜色ID映射表
const brickyColorIds = {}
Object.keys(brickDt).map((color) => {
  brickyColorIds[color] = brickDt[color]
})
Object.keys(darkenedBrickDt).map((color) => {
  brickyColorIds[color] = darkenedBrickDt[color]
})
Object.keys(paletteConf.lostBricky).map((color) => {
  brickyColorIds[color] = paletteConf.lostBricky[color]
})
const hosts = function(e) {
  // eslint-disable-next-line
  if(e) env = e;
  var conf = {
    mainHost: 'https://gridy.art/',
    cdnHost: 'https://cdn.gridy.art/',
    apiHost: 'https://api.gridy.art/',
    zoneApiHost: 'https://q.gridy.art/api/v3/',
    cosCdnHost: 'https://coscdn.gridy.art/',
    worksHost: 'https://cdn.gridyart.com/',
    downloadUrl: 'https://gridyart.com/',
    storeHost: 'https://store.gridy.art/'
    // storeHost: 'http://store.gridyart.cn/'
  }
  // eslint-disable-next-line
  if (env === 'dev') {
    conf = {
      mainHost: 'https://gridy.art/',
      cdnHost: 'https://cdn.gridy.art/',
      apiHost: 'https://api.gridy.art/',
      zoneApiHost: 'https://q.gridy.art/api/v3/',
      cosCdnHost: 'https://coscdn.gridy.art/',
      worksHost: 'https://cdn.gridyart.com/',
      downloadUrl: 'https://gridyart.com/',
      storeHost: 'https://store.gridy.art/'
      // storeHost: 'http://store.gridyart.cn/'
    }
  // eslint-disable-next-line
  } else if (env === 'test') {
    conf = {
      mainHost: 'https://gridyart.com/',
      cdnHost: 'https://cdn.gridyart.com/',
      apiHost: 'https://api.gridyart.com/',
      zoneApiHost: 'https://q.gridyart.com/api/v3/',
      cosCdnHost: 'https://coscdn.gridyart.com/',
      worksHost: 'https://cdn.gridyart.com/',
      downloadUrl: 'https://gridyart.com/',
      storeHost: 'https://store.gridy.art/'
      // storeHost: 'http://store.gridyart.cn/'
    }
  }
  return conf
}
// 腾讯COS桶
const cosBucket = {
  cdnHost: hosts().cosCdnHost || '',
  bucket: 'gridyart-1313190797',
  region: 'ap-guangzhou'
}
export default {
  ver: '1.0.2',
  pubAt: '1645692748',
  hosts: hosts,
  cosBucket: cosBucket,
  tmp: {
    latestOpen: [],
    webimage: '',
    uploadAssets: [],
    dt: {},
    cache: '',
    objs: [],
    partObj: '',
    selectedColor: '',
    selectObjs: [],
    lastPos: [],
    clipboard: '',
    file: {},
    catalogid: 0,
    tags: [],
    show: true,
    share: false,
    lock: {},
    count: 0
  },
  algorithms: {
    'differenceRGB': 'RGB',
    'differenceSquared': 'Squared',
    'differenceEuclideanRGB': 'EuclideanRGB',
    'differenceEuclideanLab': 'EuclideanLab',
    'differenceCie94': 'CIE94',
    'differenceCiede2000': 'CIEDE2000',
    'differenceDin99o': 'DIN99o'
  },
  // 作品分类
  catalogs: () => {
    return [
      { id: 0, label: $t('i.pleaseSelect') },
      { id: 1, label: $t('i.festivals') },
      { id: 2, label: $t('i.education') },
      { id: 4, label: $t('i.sports') },
      { id: 5, label: $t('i.healthcare') },
      { id: 3, label: $t('i.clothing') },
      { id: 6, label: $t('i.household') },
      { id: 17, label: $t('i.tools') },
      { id: 13, label: $t('i.toys') },
      { id: 7, label: $t('i.foods') },
      { id: 11, label: $t('i.fruit') },
      { id: 10, label: $t('i.animals') },
      { id: 8, label: $t('i.business') },
      { id: 15, label: $t('i.traffic') },
      { id: 14, label: $t('i.building') },
      { id: 12, label: $t('i.science') },
      { id: 9, label: $t('i.persons') },
      { id: 16, label: $t('i.icon') },
      { id: 18, label: $t('i.other') }
    ]
  },
  // 圈子分类
  categories: () => {
    return [
      { id: 0, label: $t('i.pleaseSelect') },
      { id: 1, label: $t('i.squares') },
      { id: 2, label: $t('i.gridyZone') },
      { id: 3, label: $t('i.puzzleZone') },
      { id: 4, label: $t('i.NFTZone') },
      { id: 5, label: $t('i.gameZone') },
      { id: 6, label: $t('i.headlineZone') },
      { id: 7, label: $t('i.feedbackZone') }
    ]
  },
  // 1x1拼图块
  brickImg: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAAA3NCSVQICAjb4U/gAAAAG1BMVEX///+jo6OMjIyEhIR7e3tzc3NmZmZXV1c/Pz/lBLefAAAACXRSTlMAIiIiIiIiIiIiUPyZAAAACXBIWXMAAAsSAAALEgHS3X78AAAAGHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3NPsx9OAAAAFnRFWHRDcmVhdGlvbiBUaW1lADA1LzE3LzIzR23q9gAAANVJREFUGJVd0U0WgiAQB3CvgJ0gyPYB2l7R9mW2r0wvkHqBdI4dM6D2mg3vB3++3gSlMfl1qSqQQnHOla9dkNKojXGjN2fhr5mtcPWWCZ8gS0ZZO+m8FeTDxjv0l7EMzZXwPmi0SGfLGL1bHWXWcTo/VkWXP5c6yJLVydO6OP/5vvhEfiy+4f74vbgn9/P9sXMzH3Ac0br5+Oe1nUFXcCHvoUarYpjwC7KdnPMGxlLlLXQlWVcDQA8wvQ0ZA1h22ZkCYHejJfYqb/rumlG/bAj7+KpdQ78CGm5nOUVi7gAAAABJRU5ErkJggg==',
  CLBrickImg: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAABuBAMAAAA0dTvIAAAAA3NCSVQICAjb4U/gAAAAJ1BMVEX///////9wcHBpaWlmZmZbW1tSUlJISEgzMzMaGhoJCQkAAABwcHCmWS8yAAAADXRSTlMAESIiIiIiIiIiIiIzeC7RzwAAAAlwSFlzAAALEgAACxIB0t1+/AAAABh0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzT7MfTgAAABZ0RVh0Q3JlYXRpb24gVGltZQAwMS8xMy8yNdUj/IcAAAKQSURBVFiF7dgrU8QwEADgDo/hIJjwGJ4GBRIUfwA855AwGCQIZpDwD5DMYA51tj4GFH+Lu7bJPrKb9NpzsOqm3e/aZtN2m6Lw4XyUBQojb0ZRRj8qF/3QHE3o7bTTDDtoQl+nnqbPZwl9nXqafg9LSDqD6lrClioIR1vYuRnxbPHJxMl9XH505uqUcuE5MVfXYnLPw/m683I3Qe55w/brM1CIuAL/rp+TH7d55/DcQzveny4uLp/faHoo9+R4RnqwDIa2io1r4koHrmggduMj6+OYuMKBayByY4vih7gGGpji4AaWxAlxNWzG0xE3pM5eEVcl+zo45JYYs1vUTZND/Ry4b+7sF3WTZKh7qON6xOwhc4UT5kt8ODhgSHbR9IquDl8hSuaODyYZUt0NRGZ3c25BdnaUcY+Ke8g4aTSnsZ92KwqzO2mnXZ7d4Mn0qf2qOfsSJ6On01B1V3Ey+nmkuoOUW1WZ3Us5ZbZMYzfl1DL4QihuWXfbKSfeRHVs/WXXdTy71q/rfOk6P43uTlNu1vsv9POZ+z0k875uUWObBQnev2SeZ6pb09xX2mWe17r7bHV5sUu/j3RX3IruJuuS71vkHHPt3u8GWrU2/URoOgy0aq36F988GmjV2vVLDTTQqsG7jQ/pDR67Gop93eBMq13VPJbgSF83md34EndG1FVQ6uumEI54jpj/2C6lvq4+1buG3eOt4Su21L8D6n7+g24M5Yv7umTg+dLJzfiZ8u+yLjy1c+t1JLnZif8rt+7G30ctlhiSjn139nTxUgtz2XW32VwZbWGO1bOnUxbNoNwGbcmu1yX/H5+FPinl0enqsquZyvpZVyfPJSFhPi6e3Cykm6yH094FIRybJnwzuF/TxHVUlJbyDAAAAABJRU5ErkJggg==',
  // 1x1拼图块
  diamondImg: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAAA3NCSVQICAjb4U/gAAAAD1BMVEX///8AAAAAAAAAAAAAAABRO2rwAAAABXRSTlMAESIzRJTdRHwAAAAJcEhZcwAACvAAAArwAUKsNJgAAAAYdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3Jrc0+zH04AAAAWdEVYdENyZWF0aW9uIFRpbWUAMDUvMTcvMjNHber2AAAAoUlEQVQYlV2R2xUDIQhEdSsIpIF1bMBHGkjSf08BcV3M/F0ZODKEMERE4VZkAOmxEEnqwDmZFYk4myMaiqOamy4Nw5EWo2j3QmII3+XREDc+PXPisrUnrp7lpQVs3DcGNuY/Jiy/rQi0OR8Y/lxlHWg6plyUb+l/HeIlAWRX/mgejjWP2Bb3Eehzdb8t3zmhf+dFji4guu4RWKkld8CWYeYfZnYk6sh1dVQAAAAASUVORK5CYII=',
  // 作品类型
  workTypes: () => {
    return [
      { id: 0, label: $t('i.pixel'), shapeid: 'default', icon: 'my-square-full' },
      { id: 1, label: $t('i.gridy'), shapeid: 'square', icon: 'my-square-shape' },
      { id: 2, label: $t('i.puzzle'), shapeid: 'circle', icon: 'my-circle-shape' }
    ]
  },
  puzzleSkusArr: skusConf.puzzleSkusArr,
  // 默认拼图背景用色
  // [白色, 黄色, 蓝色，红色，草绿，深紫]
  defaultBgColor: ['#FEFBF5', '#FFCC00', '#005FAD', '#D92817', '#00A73C', '#584C9D'],
  canvasRatio: [
    { id: '1-1', label: '1:1', tip: '64x64', icon: 'my-ratio-1-1', cols: 64, rows: 64 },
    { id: '3-4', label: '3:4', tip: '48x64', icon: 'my-ratio-3-4', cols: 48, rows: 64 },
    { id: '4-3', label: '4:3', tip: '64x48', icon: 'my-ratio-4-3', cols: 64, rows: 48 },
    { id: '2-3', label: '2:3', tip: '64x96', icon: 'my-ratio-9-16', cols: 64, rows: 96 },
    { id: '3-2', label: '3:2', tip: '96x64', icon: 'my-ratio-16-9', cols: 96, rows: 64 }
    // { id: '4-5', label: '4:5', tip: '64x80', icon: 'my-ratio-9-16', cols: 64, rows: 80 },
    // { id: '5-4', label: '5:4', tip: '80x64', icon: 'my-ratio-16-9', cols: 80, rows: 64 }
  ],
  squareCanvasRatios: {
    '10001': [
      { id: '1-1-48', label: '1:1', tip: '48x48', icon: 'my-ratio-1-1 size12', cols: 48, rows: 48 },
      { id: '1-1-64', label: '1:1', tip: '64x64', icon: 'my-ratio-1-1 size14', cols: 64, rows: 64 },
      { id: '1-1-80', label: '1:1', tip: '80x80', icon: 'my-ratio-1-1 size16', cols: 80, rows: 80 }
    ],
    '10002': [
      { id: '1-1-48', label: '1:1', tip: '48x48', icon: 'my-ratio-1-1 size12', cols: 48, rows: 48 },
      { id: '1-1-64', label: '1:1', tip: '64x64', icon: 'my-ratio-1-1 size14', cols: 64, rows: 64 },
      { id: '1-1-96', label: '1:1', tip: '96x96', icon: 'my-ratio-1-1 size16', cols: 96, rows: 96 }
    ]
  },
  // ??
  canvasRatios: {
    '10001': [
      { id: '1-1', label: '1:1', tip: '64x64', icon: 'my-ratio-1-1', cols: 64, rows: 64 },
      { id: '3-4', label: '3:4', tip: '48x64', icon: 'my-ratio-3-4', cols: 48, rows: 64 },
      { id: '4-3', label: '4:3', tip: '64x48', icon: 'my-ratio-4-3', cols: 64, rows: 48 }
    ],
    '10002': [
      { id: '1-1', label: '1:1', tip: '64x64', icon: 'my-ratio-1-1', cols: 64, rows: 64 },
      { id: '3-4', label: '3:4', tip: '48x64', icon: 'my-ratio-3-4', cols: 48, rows: 64 },
      { id: '4-3', label: '4:3', tip: '64x48', icon: 'my-ratio-4-3', cols: 64, rows: 48 },
      { id: '2-3', label: '2:3', tip: '64x96', icon: 'my-ratio-9-16', cols: 64, rows: 96 },
      { id: '3-2', label: '3:2', tip: '96x64', icon: 'my-ratio-16-9', cols: 96, rows: 64 }
    ]
  },
  sizeOpts: {
    '1-1': [
      { cols: 32, rows: 32 },
      { cols: 48, rows: 48 },
      { cols: 64, rows: 64 },
      { cols: 80, rows: 80 },
      { cols: 96, rows: 96 },
      { cols: 128, rows: 128 },
      { cols: 160, rows: 160 },
      { cols: 192, rows: 192 }
    ],
    '2-3': [
      { cols: 64, rows: 96 },
      { cols: 96, rows: 144 },
      { cols: 128, rows: 192 }
    ],
    '3-2': [
      { cols: 96, rows: 64 },
      { cols: 144, rows: 96 },
      { cols: 192, rows: 128 }
    ],
    '3-4': [
      { cols: 48, rows: 64 },
      { cols: 96, rows: 128 }
    ],
    '4-3': [
      { cols: 64, rows: 48 },
      { cols: 128, rows: 96 }
    ],
    '4-5': [
      { cols: 64, rows: 80 },
      { cols: 128, rows: 160 }
    ],
    '5-4': [
      { cols: 80, rows: 64 },
      { cols: 160, rows: 128 }
    ]
  },
  orderTypes: () => {
    return ['', $t('i.subscribe'), $t('i.priPurchase'), $t('i.purchase'), $t('i.casting'), $t('i.airDrop')]
  },
  gridSizeOpts: [4, 8, 12, 16, 32],
  newfileOpts: {
    name: 'Untitle',
    ratio: '9-16'
  },
  feedback: () => {
    return {
      typeOpts: [
        { id: '1', label: $t('i.suggestion') },
        { id: '2', label: $t('i.bugFeed') },
        { id: '3', label: $t('i.cooperation') },
        { id: '4', label: $t('i.other') }
      ],
      platform: '1',
      ver: '0.1.1',
      type: '',
      title: '',
      content: '',
      name: '',
      contact: ''
    }
  },
  // Ai绘画晶钻消费
  paintConsume: {
    1: 40,
    2: 40,
    8: 40,
    91: 80,
    96: 70,
    100: 60,
    300: 50,
    999: 40
  },
  tools: () => {
    return {
      'k_86': ['V', 'default', 'my-cursor', $t('i.cursor')],
      'k_65': ['A', 'select', 'my-select-cursor', $t('i.partSelect')],
      'k_67': ['C', 'freeSelect', 'my-select', $t('i.select')],
      'k_81': ['Q', 'resize', 'my-resize', $t('i.resize')],
      'k_80': ['P', 'fill', 'my-pen', $t('i.fill')],
      'k_70': ['F', 'batchFill', 'my-fill', $t('i.batchFill')],
      'k_69': ['E', 'erase', 'my-eraser', $t('i.erase')],
      'k_77': ['M', 'batchErase', 'my-eraser-batch', $t('i.batchErase')],
      'k_66': ['B', 'brush', 'my-brush', $t('i.brush')],
      'k_83': ['S', 'spray', 'my-spray', $t('i.spray')],
      'k_84': ['T', 'txt', 'my-txt', $t('i.txt')],
      'k_68': ['D', 'pick', 'my-pick', $t('i.pick')],
      // 'k_76': ['L', 'line', 'my-line', $t('i.line')],
      'k_49': ['1', 'ellipse', 'my-blank-circle', $t('i.ellipse')],
      'k_50': ['2', 'rectangle', 'my-blank-rectangle', $t('i.rectangle')],
      'k_51': ['3', 'triangle', 'my-blank-triangle', $t('i.triangle')],
      'k_52': ['4', 'star', 'my-blank-star', $t('i.star')],
      'k_72': ['H', 'hand', 'my-hand', $t('i.hand')]
    }
  },
  palette: () => {
    const paletteOpts = []
    const paletteData = {
      brickfyColors: Object.values(paletteConf.brickfyColors),
      brickfyGray: Object.values(paletteConf.brickfyGray),
      brickfyArr: paletteConf.brickfyArr
    }
    const names = paletteConf.names()
    for (const k in names) {
      paletteOpts.push({ value: k, label: names[k] })
      paletteData[k] = (Array.isArray(paletteConf[k])) ? paletteConf[k] : Object.values(paletteConf[k])
    }
    return {
      paletteId: '',
      opts: paletteOpts,
      data: paletteData
    }
  },
  getColorIds: (paletteId) => {
    let darkMatch = true
    let colorIds = brickDt
    if (paletteId && paletteConf.extendPalettes && paletteConf.extendPalettes[paletteId]) {
      darkMatch = false
      colorIds = paletteConf.extendPalettes[paletteId].ids || {}
    }
    Object.keys(brickyColorIds).map((color) => {
      colorIds[color] = brickyColorIds[color]
    })
    return { paletteId: paletteId, colorIds: colorIds, brickyColorIds: brickyColorIds, darkMatch: darkMatch }
  },
  extendPalettes: paletteConf.extendPalettes,
  brickfy: paletteConf.brickfy,
  brickfyGrayDt: paletteConf.brickfyGray,
  colorfyDt: paletteConf.colorfy,
  bgDt: skusConf.bgs,
  frameDt: skusConf.frames,
  brickSkus: skusConf.brickSkus,
  whiteBrickIds: paletteConf.whiteBrickIds,
  limitColorsConf: paletteConf.limitColorsConf,
  brickDt: brickDt,
  darkenedBrickDt: darkenedBrickDt,
  brickyColorIds: brickyColorIds,
  view: viewConf.view,
  state: stateConf.state,
  schema: schemaConf.schema
}
