/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2022-1-29
 * @copyright Gridy.Art
 */
import { deepClone, param2Obj } from '@/utils'
import device from 'current-device'
import i18n from '../lang'
// eslint-disable-next-line
const $t = (key, value) => i18n.t(key, value)
const platform = function() {
  const devices = ['Unknown', 'Win', 'Android', 'iPhone', 'iPad', 'Mac']
  let i = 0
  if (device.windows()) {
    i = 1
  } else if (device.android()) {
    i = 2
  } else if (device.iphone()) {
    i = 3
  } else if (device.ipad()) {
    i = 4
  } else if (device.macos()) {
    i = 5
  }
  let type = ''
  if (device.mobile()) {
    type = 'mobile'
  } else if (device.tablet()) {
    type = 'tablet'
  } else if (device.desktop()) {
    type = 'desktop'
  }
  let name
  if (process.env.IS_ELECTRON || device.cordova()) {
    name = 'APP ' + devices[i]
  } else {
    name = 'Web ' + devices[i]
  }
  const dt = {}
  let val = 0
  for (const k in devices) {
    dt['Web ' + devices[k]] = val
    val++
  }
  val = 10
  for (const k in devices) {
    dt['APP ' + devices[k]] = val
    val++
  }
  return { device: devices[i], type: type, name: name, value: dt[name] }
}
const getDefaultBg = function(userid) {
  const bgs = [
    '/sky0/ft.jpg',
    '/sky0/bk.jpg',
    '/sky0/up.jpg',
    '/sky0/dn.jpg',
    '/sky0/rt.jpg',
    '/sky0/lf.jpg'
  ]
  return bgs[userid % 6]
}
const open = function(url, params) {
  if (!url) return
  if (params) {
    url = url + '?'
    Object.keys(params).map((k) => {
      if (!params[k]) params[k] = 0
      url = url + '&' + k + '=' + params[k]
    })
  }
  window.location.href = url
}
const calcImageSize = function(w, h, maxWidth, maxHeight) {
  if (w < maxWidth && h < maxHeight) return { width: w, height: h }
  let width, height
  if (w > h) {
    width = maxWidth
    height = Math.round(width * h / w)
  } else {
    height = maxHeight
    width = Math.round(height * w / h)
  }
  return { width: width, height: height }
}
const isEmpty = function(val) {
  if (val === '' || val === null || val === undefined) {
    return true
  }
  if (typeof val === 'object' && Object.keys(val).length === 0) return true
  return false
}
const getInt = function(val) {
  val = parseInt(val)
  return isNaN(val) ? 0 : val
}
const isString = function(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}
const isArray = function(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}
const isObject = function(val) {
  return typeof val === 'object'
}
const isImage = function(i) {
  return i instanceof HTMLImageElement
}
const isCanvas = function(i) {
  return i instanceof HTMLCanvasElement
}
const isFile = function(i) {
  return i instanceof File
}
const isUrl = function(val) {
  if (!val || !isString(val)) {
    return false
  }
  const URL_REGULAR_EXPRESSION = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/
  const urlExp = new RegExp(URL_REGULAR_EXPRESSION)
  return urlExp.test(val.toLowerCase())
}
const isBlobUrl = function(val) {
  if (!val || !isString(val)) {
    return false
  }
  return val.indexOf('blob:http') !== -1
}
const isBase64 = function(val) {
  if (!val || !isString(val)) {
    return false
  }
  return val.indexOf('data:image/') !== -1 && val.indexOf('base64') !== -1
}
const isImageUrl = function(val) {
  if (!isUrl(val)) {
    return false
  }
  const PICTURE_EXPRESSION = /\.(png|jpe?g|gif|svg|bmp|webp|tif|tiff)(\?.*)?$/
  const picReg = new RegExp(PICTURE_EXPRESSION)
  return picReg.test(val.toLowerCase())
}
const isImageMime = function(mime) {
  if (!mime) {
    return false
  }
  mime = mime.toLowerCase()
  return (mime === 'image/png' || mime === 'image/jpeg' || mime === 'image/gif' || mime === 'image/svg' || mime === 'image/svg+xml' ||
   mime === 'image/tiff' || mime === 'image/webp' || mime === 'image/bmp' || mime === 'image/x-ms-bmp' || mime === 'image/x-icon')
}
const getFileName = function(name) {
  if (!name || name.toString().indexOf('.') <= 0) {
    return name
  }
  return name.substring(0, name.toString().indexOf('.'))
}
const blobToFile = function(blob, filename, contentType) {
  return new File([blob], filename, { type: contentType, lastModified: Date.now() })
}
const base64ToBlob = function(dataURI) {
  var byteString = atob(dataURI.split(',')[1])
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  var ab = new ArrayBuffer(byteString.length)
  var ia = new Uint8Array(ab)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}
const fileToBase64 = function(file, cb) {
  var reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function(e) {
    cb(e.target.result)
  }
}
// 颜色距离算法
const differenceSquared = function(c1, c2) {
  if (isEmpty(c1) || isEmpty(c2)) return 0
  const pixel1 = isString(c1) ? hex2rgbArr(c1) : c1
  const pixel2 = isString(c2) ? hex2rgbArr(c2) : c2
  let sum = 0
  for (let i = 0; i < 3; i++) {
    sum += Math.abs(pixel1[i] - pixel2[i])
  }
  return sum
}
// 颜色距离算法
const differenceRGB = function(c1, c2) {
  if (isEmpty(c1) || isEmpty(c2)) return 0
  if (isString(c1)) c1 = hex2rgbArr(c1)
  if (isString(c2)) c2 = hex2rgbArr(c2)
  return Math.sqrt(Math.pow(c1[0] - c2[0], 2) + Math.pow(c1[1] - c2[1], 2) + Math.pow(c1[2] - c2[2], 2))
}
// 支持以下算法(依赖：d3-color d3-color-difference 需要在 public/index.html 里引用)
// differenceRGB
// differenceSquared
// differenceEuclideanRGB
// differenceEuclideanLab
// differenceCie94
// differenceCiede2000
// differenceDin99o
const d3ColorDistance = function(algorithm) {
  // eslint-disable-next-line
  return (c1, c2) => d3[algorithm](d3.color(c1), d3.color(c2))
}
const colorDistance = function(c1, c2, algorithm = 'differenceCiede2000') {
  if (isEmpty(c1) || isEmpty(c2)) return 0
  if (algorithm === 'differenceRGB') {
    return differenceRGB(c1, c2)
  } else if (algorithm === 'differenceSquared') {
    return differenceSquared(c1, c2)
  } else {
    // eslint-disable-next-line
    if (typeof d3 === 'undefined' || !d3 || !d3[algorithm]) {
      return differenceRGB(c1, c2)
    } else {
      return d3ColorDistance(algorithm)(c1, c2)
    }
  }
}
const bestMatch = function(palColors, color, skipColor = '', algorithm = 'differenceCiede2000') {
  if (isEmpty(palColors) || isEmpty(color)) {
    return ''
  }
  const paletteArr = palColors
  if (Array.isArray(color)) {
    color = rgb2hex(color)
  }
  let best = [Infinity, '#000000']
  let compColor
  let i
  const len = paletteArr.length
  for (i = 0; i < len; i++) {
    compColor = paletteArr[i]
    if (compColor) {
      if (Array.isArray(compColor)) {
        compColor = rgb2hex(compColor)
      }
      compColor = compColor.toUpperCase()
      if (compColor !== skipColor) {
        const difference = colorDistance(compColor, color, algorithm)
        if (difference < best[0]) {
          best = [difference, compColor]
        }
      }
    }
  }
  return best[1]
}
// 获取深化颜色
const getDarkenedColor = function(color) {
  const arr = hex2rgbArr(color)
  const rgb = arr.map((color) => Math.round((color * Math.PI) / 4))
  return rgb2hex(rgb)
}
// 获取深化后调色板
const getDarkenedColorsObj = function(colorsObj) {
  // eslint-disable-next-line
  const tmpObj = getDarkenedStudMap(colorsObj)
  return Object.keys(tmpObj).map((color) => color.toUpperCase())
}
// 获取深化后调色板
const getDarkenedColors = function(colors) {
  // eslint-disable-next-line
  const tmpObj = getDarkenedStudArr(colors)
  return Object.keys(tmpObj).map((color) => color.toUpperCase())
}
// 对象转数组
const obj2Arr = function(obj) {
  if (!isObject(obj)) return []
  const arr = []
  Object.keys(obj).map((k) => {
    arr.push([k, obj[k]])
  })
  return arr
}
const sortObj = function(obj) {
  if (!obj) {
    return obj
  }
  let arr = []
  Object.keys(obj).map((k) => {
    arr.push([k, obj[k]])
  })
  arr = sortArr(arr, 1)
  const ret = {}
  Object.keys(arr).map((i) => {
    ret[arr[i][0]] = obj[arr[i][0]]
  })
  return ret
}
const sortColors = function(obj) {
  if (!obj) {
    return obj
  }
  let colors = []
  Object.keys(obj).map((k) => {
    colors.push([k, colorDistance('#FFFFFF', k)])
  })
  colors = sortArr(colors, 1)
  const ret = {}
  Object.keys(colors).map((i) => {
    ret[colors[i][0]] = obj[colors[i][0]]
  })
  return ret
}
const sortArr = function(arr, idx) {
  if (arr.length > 1) {
    idx = idx || 0
    arr = arr.sort(function(a, b) {
      return b[idx] - a[idx]
    })
  }
  return arr
}
const removeValue = function(arr, val) {
  if (!isArray(arr)) return arr
  const idx = arr.indexOf(val)
  if (idx > -1) {
    arr.splice(idx, 1)
  }
  return arr
}
const getAntiHex = function(color) {
  if (isEmpty(color)) {
    return ''
  }
  color = hex2rgbArr(color)
  color[0] = 255 - color[0]
  color[1] = 255 - color[1]
  color[2] = 255 - color[2]
  return rgb2hex(color)
}
const hex2rgba = function(hex) {
  return 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',1)'
}
const hex2rgbArr = function(hex) {
  // eslint-disable-next-line
  return hexToRgb(hex)
  // return [parseInt('0x' + hex.slice(1, 3)), parseInt('0x' + hex.slice(3, 5)), parseInt('0x' + hex.slice(5, 7))]
}
const hex2rgb = function(hex) {
  // eslint-disable-next-line
  const arr = hexToRgb(hex)
  return { r: arr[0], g: arr[0], b: arr[0], opacity: 1 }
}
const rgb2hex = function(rgb) {
  if (isEmpty(rgb)) {
    return ''
  }
  const r = rgb[0]
  const g = rgb[1]
  const b = rgb[2]
  // eslint-disable-next-line
  return rgbToHex(r, g, b).toUpperCase()
  // let rHex = r.toString(16)
  // r < 16 && (rHex = '0' + rHex)
  // let gHex = g.toString(16)
  // g < 16 && (gHex = '0' + gHex)
  // let bHex = b.toString(16)
  // b < 16 && (bHex = '0' + bHex)
  // return '#' + rHex + gHex + bHex
}
const isMacLike = function() {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
}
const isAlipayClient = function() {
  return navigator.userAgent.toLowerCase().indexOf('alipayclient') >= 0
}
const isWeixinClient = function() {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') >= 0
}
const getSegmentStr = function(name, suffix) {
  const arr = name.split('-')
  if (arr.length > 1) {
    arr.pop()
  }
  arr.push(suffix)
  return arr.join('-')
}
const getRndInt = function(min, max) {
  min = min || 1
  max = max || 10000
  return Math.floor(Math.random() * (max - min + 1)) + min
}
const date = function(format, offset, time) {
  time = time || new Date()
  offset = parseInt(offset)
  if (offset) {
    time.setDate(time.getDate() + offset)
  }
  const y = time.getFullYear()
  let m = time.getMonth() + 1
  if (m < 10) {
    m = '0' + m
  }
  let d = time.getDate()
  if (d < 10) {
    d = '0' + d
  }
  let h = time.getHours()
  if (h < 10) {
    h = '0' + h
  }
  let i = time.getMinutes()
  if (i < 10) {
    i = '0' + i
  }
  let s = time.getSeconds()
  if (s < 10) {
    s = '0' + s
  }
  const ms = time.getMilliseconds()

  if (format === 'cndate') {
    return m + '月' + d + '日 ' + h + ':' + i
  } else if (format === 'date') {
    // 返回当天日期
    return y + '-' + m + '-' + d
  } else if (format === 'datetime') {
    // 返回当前时间
    return y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s
  } else if (format === 'str') {
    // 返回当前时间
    return y + '_' + m + '_' + d + '_' + h + '_' + i
  } else {
    // 返回当前时间（包含微秒）
    return y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s + '.' + ms
  }
}
const time = function(format, offset) {
  var now = new Date()
  offset = parseInt(offset)
  if (offset) {
    now.setDate(now.getDate() + offset)
  }
  if (format === 'year') {
    now.setMonth(0)
    now.setDate(1)
    now.setHours(0)
    now.setMinutes(0)
    now.setSeconds(0)
    // 返回当天 00:00:00 的时间戳
    return parseInt(now.getTime() / 1000)
  } else if (format === 'month') {
    now.setDate(1)
    now.setHours(0)
    now.setMinutes(0)
    now.setSeconds(0)
    // 返回当天 00:00:00 的时间戳
    return parseInt(now.getTime() / 1000)
  } else if (format === 'date') {
    now.setHours(0)
    now.setMinutes(0)
    now.setSeconds(0)
    // 返回当天 00:00:00 的时间戳
    return parseInt(now.getTime() / 1000)
  } else if (format === 'time') {
    // 返回当前 时间戳（秒）
    return parseInt(now.getTime() / 1000)
  } else {
    // 返回当前 时间戳（毫秒）
    return parseInt(now.getTime())
  }
}
// 格式化bytes
const formateBytes = function(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}
// 格式化时间
const formateTime = function(time) {
  if (!time) return ''
  if (typeof time === 'number' && time.toString().length === 10) time = time * 1000
  const datetime = new Date(time)
  const diff = (new Date() - datetime) / 1000
  const dayDiff = Math.floor(diff / 86400)
  let str = ''
  if (diff < 60) {
    str = $t('i.aMomentAgo')
  } else if (diff < 120) {
    str = $t('i.minuteAgo')
  } else if (diff < 3600) {
    str = Math.floor(diff / 60) + ' ' + $t('i.minutesAgo')
  } else if (diff < 7200) {
    str = $t('i.hourAgo')
  } else if (diff < 86400) {
    str = Math.floor(diff / 3600) + ' ' + $t('i.hoursAgo')
  } else if (dayDiff === 1) {
    str = $t('i.yesterday')
  } else if (dayDiff === 2) {
    str = $t('i.beforeYesterday')
  } else if (dayDiff < 7) {
    str = dayDiff + ' ' + $t('i.daysAgo')
  } else if (dayDiff < 31) {
    str = Math.ceil(dayDiff / 7) + ' ' + $t('i.weekAgo')
  } else {
    str = date('date', 0, datetime)
  }
  return str
}
const getCountDown = function(times, countDown = false) {
  if (times < 0) return ''
  let str = ''
  let d = parseInt(times / 60 / 60 / 24)
  if (d) d = d < 10 ? '0' + d : d
  let h = parseInt(times / 60 / 60 % 24)
  if (h) h = h < 10 ? '0' + h : h
  let m = parseInt(times / 60 % 60)
  if (m) m = m < 10 ? '0' + m : m
  let s = parseInt(times % 60)
  if (s) s = s < 10 ? '0' + s : s
  if (countDown) {
    if (parseInt(d) > 0) {
      str = str + d + $t('i.days')
    }
    if (parseInt(d) > 0 || parseInt(h) > 0) {
      str = str + h + $t('i.hour')
    }
    if (parseInt(d) > 0 || parseInt(h) > 0 || parseInt(m) > 0) {
      str = str + m + $t('i.minutes')
    }
    if (parseInt(d) > 0 || parseInt(h) > 0 || parseInt(m) > 0 || parseInt(s) > 0) {
      str = str + s + $t('i.seconds')
    }
  } else {
    if (parseInt(d) > 0) {
      str = str + d + $t('i.days')
    } else if (parseInt(h) > 0) {
      str = str + h + $t('i.hours')
    } else if (parseInt(m) > 0) {
      str = str + m + $t('i.minute')
    } else {
      str = str + s + $t('i.seconds')
    }
  }
  return str
}
const formateNums = function(num) {
  if (num > 1000) {
    return Math.floor(num / 1000) + 'K+'
  } else {
    return num
  }
}
// 补齐位数
const padding = function(num, length) {
  for (var len = (num + '').length; len < length; len = num.length) {
    num = '0' + num
  }
  return num
}
// 去掉空格
const trim = function(str, all = false) {
  str = str.replace(/^\s+|\s+$/g, '')
  if (all) str = str.replace(/\s+/g, '')
  return str
}
// 银行卡号脱敏
const hintBankAccount = function(bankAccount) {
  if (!bankAccount) return ''
  const reg = /^(.{4})(?:\d+)(.{4})$/
  return bankAccount.replace(reg, '$1 **** **** $2')
}
// 手机号脱敏
const hintMobile = function(mobile) {
  return mobile ? (mobile.slice(0, 3) + '****' + mobile.slice(-4)) : ''
}
// 身份证脱敏
const hintIdcard = function(idcard) {
  return idcard ? (idcard.slice(0, 4) + '***************' + idcard.slice(-3)) : ''
}
// 用户名脱敏
const hintUsername = function(username) {
  if (!username) return ''
  const len = username.length
  if (len === 1) return username + '*'
  const frontCount = Math.floor((len - 1) / 2)
  const replaceCount = len - frontCount - 1
  const front = username.substring(0, frontCount)
  const last = username.slice(-1)
  return front + '*'.repeat(replaceCount) + last
}
// 实名脱敏
const hintRealname = function(realname) {
  if (realname) {
    var starNums = realname.length
    if (starNums > 2) {
      starNums = starNums - 2
    } else {
      starNums = starNums - 1
    }
    var star = ''
    for (var i = 0; i < starNums; i++) {
      star = star + '*'
    }
    if (realname.length > 2) {
      realname = realname.slice(0, 1) + star + realname.slice(-1)
    } else {
      realname = star + realname.slice(-1)
    }
  }
  return realname
}
// amount 金额（单位分）
// n 保留几位小数点
const formateMoney = function(amount, n = 2) {
  if (typeof amount !== 'number') amount = 0
  let pre = ''
  if (amount < 0) {
    pre = '-'
    amount = amount * -1
  }
  n = n > 0 && n <= 20 ? n : 2
  amount = amount / 100
  amount = parseFloat((amount + '').replace(/[^\d\.-]/g, '')).toFixed(n) + ''
  const l = amount.split('.')[0].split('').reverse()
  const r = amount.split('.')[1]
  let t = ''
  for (var index = 0; index < l.length; index++) {
    t += l[index] + ((index + 1) % 3 === 0 && (index + 1) !== l.length ? ',' : '')
  }
  return pre + t.split('').reverse().join('') + '.' + r
}
// 去除html标签
const noHtml = function(content) {
  if (!content) return ''
  return content.replace(/<[^>].*?>/g, '')
}
const removeEmoji = function(content) {
  return content.replace(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, '')
}
// 格式化内容
const fomateContent = function(content, split, removeHtml) {
  content = content.replace(/&lt;/ig, '<')
  content = content.replace(/&gt;/ig, '>')
  content = content.replace(/<ol><\/ol>|<ul><\/ul>|<li><\/li>|<t><\/t>|<p><\/p>|<p> 无<\/p>|<ol>|<ul>|<li>|<t>|<p>/ig, '')
  content = content.replace(/<br>|<\/br>|<br\/>|<br \/>|<\/ol>|<\/ul>|<\/li>|<\/t>|<\/p>/ig, '\n')
  content = content.replace(/\n+/ig, '<br>')
  if (content.slice(0, 4) === '<br>') content = content.slice(4)
  if (content.slice(-4) === '<br>') content = content.slice(0, -4)
  if (split) {
    if (split === 'p') {
      content = '<p>' + content.replace(/<br>/ig, '</p><p>') + '</p>'
    } else {
      content = content.replace(/<br>/ig, split)
    }
  }
  if (removeHtml) content = noHtml(content)
  // 加连接
  const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g
  content = content.replace(reg, '<a href=\'$1$2\' target=\'_blank\'>$1$2</a>')
  return content
}
const getComputedSize = function($el) {
  const style = window.getComputedStyle($el)
  return [
    parseFloat(style.getPropertyValue('width'), 10),
    parseFloat(style.getPropertyValue('height'), 10)
  ]
}
const addEvent = function(el, event, handler) {
  if (!el) {
    return
  }
  if (el.attachEvent) {
    el.attachEvent('on' + event, handler)
  } else if (el.addEventListener) {
    el.addEventListener(event, handler, true)
  } else {
    el['on' + event] = handler
  }
}

const removeEvent = function(el, event, handler) {
  if (!el) {
    return
  }
  if (el.detachEvent) {
    el.detachEvent('on' + event, handler)
  } else if (el.removeEventListener) {
    el.removeEventListener(event, handler, true)
  } else {
    el['on' + event] = null
  }
}

// 随机字符串
const randomString = function(size) {
  size = size || 6
  var code_string = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789'
  var max_num = code_string.length + 1
  var string = ''
  while (size > 0) {
    string += code_string.charAt(Math.floor(Math.random() * max_num))
    size--
  }
  return string
}

const uuid = function(len, radix) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const uuid = []
  let i
  radix = radix || chars.length

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix]
  } else {
    // rfc4122, version 4 form
    var r

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16
        uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  return uuid.join('')
}

const debounce = function(fn, delay, timerName) {
  delay = delay || 10
  timerName = timerName || 'debouncTimer'
  let ctx
  let args
  const that = this
  const later = function() {
    fn.apply(ctx, args)
    that[timerName] = null
  }
  return function() {
    ctx = this
    args = arguments
    if (that[timerName]) {
      clearTimeout(that[timerName])
      that[timerName] = null
    }
    that[timerName] = setTimeout(later, delay)
  }
}

const throttle = function(fn, delay) {
  let ctx
  let args
  let previous = Date.now()
  const later = function() {
    fn.apply(ctx, args)
  }
  return function() {
    ctx = this
    args = arguments
    const now = Date.now()
    const diff = now - previous - delay
    if (diff >= 0) {
      previous = now
      setTimeout(later, delay)
    }
  }
}
// 计算字符串长度：1个中文按2个字符算
const strLength = function(str) {
  let len = 0
  let i
  for (i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127) {
      // utf8格式下中文占3位，gb2312请修改位2位
      len += 2
    } else {
      len++
    }
  }
  return len
}
//  截取字符串：1个中文按2个字符算
const getString = function(str, length) {
  let ret = ''
  let len = 0
  let i
  for (i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127) {
      // utf8格式下中文占3位，gb2312请修改位2位
      len += 2
    } else {
      len++
    }
    if (len > length * 2) {
      return ret
    } else {
      ret += str.substr(i, 1)
    }
  }
  return str
}
// 转换成JSON数据
const jsonParse = function(str) {
  try {
    return JSON.parse(str)
  } catch (err) {
    return []
  }
}
// 匹配底板尺寸
const matchBaseSize = function(cols, rows, size = 16) {
  const min = Math.min(cols, rows)
  const arr = []
  if (size === 48 || (size === 'auto' && Math.round(min / 48))) arr.push([48, min / 48, Math.ceil(min / 48)])
  if (size === 32 || (size === 'auto' && Math.round(min / 32))) arr.push([32, min / 32, Math.ceil(min / 32)])
  if (size === 16 || (size === 'auto' && Math.round(min / 16))) arr.push([16, min / 16, Math.ceil(min / 16)])
  const obj = {}
  for (const k in arr) {
    if (obj[arr[k][2]]) {
      if (obj[arr[k][2]][1] < arr[k][1]) {
        obj[arr[k][2]] = arr[k]
      }
    } else {
      obj[arr[k][2]] = arr[k]
    }
  }
  let key
  for (const k in obj) {
    if (key) {
      if (key > k) {
        key = k
      }
    } else {
      key = k
    }
  }
  return obj[key][0]
}
// 将10进制转26进制
const convert26 = function(num) {
  var str = ''
  while (num > 0) {
    var m = num % 26
    if (m === 0) {
      m = 26
    }
    str = String.fromCharCode(m + 64) + str
    num = (num - m) / 26
  }
  return str
}
// 取反色
const colorReverse = function(color) {
  var tmp = '0x' + color.replace(/#/g, '')
  var str = '000000' + (0xFFFFFF - tmp).toString(16)
  return '#' + str.substring(str.length - 6, str.length)
}
const getHashParams = function() {
  const params = param2Obj(window.location.hash)
  params.brickfy = typeof params.brickfy === 'undefined' ? 0 : parseInt(params.brickfy)
  params.denoise = typeof params.denoise === 'undefined' ? 0 : parseInt(params.denoise)
  if (params.userid) params.userid = parseInt(params.userid)
  if (params.workid) params.workid = parseInt(params.workid)
  if (params.threadid) params.threadid = parseInt(params.threadid)
  return params
}
const snakeCase = function(str) {
  if (!str) return ''
  return str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join('_')
}
const titleCase = function(str) {
  if (!str) return str
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase()
}
const width = function() {
  return window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
}
const height = function() {
  return window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height
}
const getLen = function(str) {
  var len = 0
  str = str.toString()
  for (var i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127) {
      // utf8格式下中文占3位，gb2312请修改位2位
      len += 2
    } else {
      len++
    }
  }
  return len
}
const validLowerCase = function(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}
const validUpperCase = function(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}
const validAlphabets = function(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}
const validURL = function(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}
const validEmail = function(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}
const isUndefined = function(arg) {
  return typeof arg === 'undefined'
}
const dealUint = function(i, min, max) {
  if (!i) return 0
  i = parseInt(i)
  if (isNaN(i)) return 0
  if (i < 0 || (min > 0 && i < min) || (max > 0 && i > max)) return 0
  return i
}
const dealInt = function(i, min, max) {
  if (!i) return 0
  i = parseInt(i)
  if (isNaN(i)) return 0
  if (i < min || i > max) return 0
  return i
}
/**
 * 统一的验证
 * @param string str    验证类型，如：must、uuid、str-[min]-[max]、str2-[min]-[max]、int-[min]-[max]、alpha-[min]-[max]、alnum-[min]-[max]、
 *                      username、password、strongpassword、date、datetime、ip、email、url、zip、qq、idcard、phone、mobile、domain
 * @param string val    验证值
 * @param bool   force  是否强制校验
 */
const checkNow = function(str, val, force) {
  if (!str || !val) return !force
  const arr = str.split('-')
  let min, max, len, f, m, d, y, h, mi, s, regex, v, vLen
  switch (arr[0]) {
    case 'must':
      return !isEmpty(val)
    case 'uuid':
      return getLen(val) === 36
    case 'str':
      min = !isUndefined(arr[1]) ? dealUint(arr[1]) - 1 : -1
      max = !isUndefined(arr[2]) ? dealUint(arr[2]) + 1 : 50000
      len = val.length
      return (len > min && len < max)
    case 'str2':
      min = !isUndefined(arr[1]) ? dealUint(arr[1]) - 1 : -1
      max = !isUndefined(arr[2]) ? dealUint(arr[2]) + 1 : 50000
      len = getLen(val)
      return (len > min && len < max)
    case 'int':
      min = !isUndefined(arr[1]) ? dealUint(arr[1]) - 1 : -1
      max = !isUndefined(arr[2]) ? dealUint(arr[2]) + 1 : 9999999
      v = dealInt(val)
      return (v > min && v < max)
    case 'alpha':
      return val.match(new RegExp('^[A-Za-z]{' + arr[1] + ',' + arr[2] + '}$'))
    case 'alnum':
      return val.match(new RegExp('^[A-Za-z0-9]{' + arr[1] + ',' + arr[2] + '}$'))
    case 'username':
      vLen = val.length
      if (vLen < 6 || vLen > 18) return false
      return /^[a-zA-Z0-9_]+$/.test(val)
    case 'filename':
      len = getLen(val)
      if (len < 2 || len > 36) return false
      return /^[a-zA-Z0-9.\-_\u4e00-\u9fa5]+$/.test(val)
    case 'nickname':
      len = getLen(val)
      if (len < 6 || len > 18) return false
      return /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/.test(val)
    case 'realname':
      return /^(?:[\u4e00-\u9fa5·]{2,16})$/.test(val)
    case 'enrealname':
      return /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/.test(val)
    case 'password':
      return /^[a-zA-Z0-9_]{5,18}$/.test(val)
    case 'strongpassword':
      // if (!/[a-zA-Z]/.test(val)) return false
      if (!/[0-9]/.test(val)) return false
      if (!/[`~!@#$%^&*()\-_+={}\[\]|\\:;"'<>?,.\/]/.test(val)) return false
      if (!/^[a-zA-Z0-9`~!@#$%^&*()\-_+={}\[\]|\\:;"'<>?,.\/]{8,20}$/.test(val)) return false
      return true
    case 'paypassword':
      return /^[0-9]{6}$/.test(val)
    case 'date':
      f = typeof arr[1] === 'undefined' ? 'yyyy-MM-dd' : arr[1]
      m = 'MM'
      d = 'dd'
      y = 'yyyy'
      regex = '^' + f.replace(y, '\\d{4}').replace(m, '\\d{2}').replace(d, '\\d{2}') + '$'
      if (!new RegExp(regex).test(val)) return false
      s = val.substr(f.indexOf(y), 4) + '/' + val.substr(f.indexOf(m), 2) + '/' + val.substr(f.indexOf(d), 2)
      return !isNaN(new Date(s))
    case 'datetime':
      f = typeof arr[1] === 'undefined' ? 'yyyy-MM-dd HH:mm:ss' : arr[1]
      m = 'MM'
      d = 'dd'
      y = 'yyyy'
      h = 'HH'
      mi = 'mm'
      s = 'ss'
      regex = '^' + f.replace(y, '\\d{4}').replace(m, '\\d{2}').replace(d, '\\d{2}').replace(h, '\\d{2}').replace(mi, '\\d{2}').replace(s, '\\d{2}') + '$'
      if (!new RegExp(regex).test(val)) return false
      return !isNaN(new Date(val.substr(f.indexOf(y), 4), val.substr(f.indexOf(m), 2), val.substr(f.indexOf(d), 2), val.substr(f.indexOf(h), 2), val.substr(f.indexOf(mi), 2), val.substr(f.indexOf(s), 2)))
    case 'ip':
      return /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(val)
    case 'email':
      return /\w{1,}[@][\w\-]{1,}([.]([\w\-]{1,})){1,3}$/.test(val)
    case 'url':
      return /^([a-zA-Z0-9_-]*[.]){1,3}[a-zA-Z0-9_-]{3}$/.test(val)
    case 'zip':
      return /^[0-9]{6}$/.test(val)
    case 'qq':
      return /^[0-9]{5,15}$/.test(val)
    case 'idcard':
      return /^([a-zA-Z0-9]{15}|[a-zA-Z0-9]{18})$/.test(val)
    case 'bankcard':
      return /^[1-9]\d{9,29}$/.test(val)
    case 'phone':
      return /^((0[1-9]{3})?(0[12][0-9])?[-])?\d{7,8}$/.test(val)
    case 'mobile':
      return /^(13|14|15|16|17|18|19)+[0-9]{9}$/.test(val)
    case 'domain':
      val = val.toLowerCase()
      val = val.replace('https://', '')
      val = val.replace('http://', '')
      val = val.replace('/', '')
      return /^([a-zA-Z0-9_-]*[.]){1,3}[a-zA-Z0-9_-]{2,3}$/.test(val)
    default:
      return false
  }
}
// 邮箱脱敏
const hintEmail = function(email) {
  const parts = email.split('@')
  // 无效格式直接返回
  if (parts.length !== 2) return email
  const [local, domain] = parts
  // 无本地部分直接返回
  if (local.length === 0) return email
  let maskedLocal
  if (local.length === 1) {
    // 单字符特殊处理
    maskedLocal = local + '*'
  } else {
    const first = local[0]
    const last = local.slice(-1)
    // 至少1个星号
    const starCount = Math.max(local.length - 2, 1)
    maskedLocal = first + '*'.repeat(starCount) + last
  }
  return maskedLocal + '@' + domain
}
// 获取用户保存目录
const caclUserPath = function(userid) {
  const str = (Array(9).join('0') + userid).slice(-9)
  return str.slice(0, 3) + '/' + str.slice(3, 5) + '/' + str.slice(5, 7) + '/' + str.slice(7, 9) + ''
}
// 通过商品标题计算拼图的cols rows，标题如： （48x64）AI像素拼图（跳动款）
const calFrameSizeZh = function(str) {
  const regex = new RegExp('（(.*?)x(.*?)）', 's')
  const match = str.match(regex)
  let colorfy = 'dance'
  if (str.indexOf('跳动') >= 0) colorfy = 'dance'
  if (str.indexOf('经典') >= 0) colorfy = 'classic'
  if (str.indexOf('金黄') >= 0) colorfy = 'golden'
  return { str: (match && match[0]) ? match[0] : '', colorfy: colorfy, cols: (match && match[1]) ? match[1] : '', rows: (match && match[2]) ? match[2] : '' }
}
// 通过商品标题计算拼图的cols rows，标题如： （48x64)AI Pixel Puzzle(dance)
const calFrameSizeEn = function(str) {
  const regex = new RegExp('((.*?)x(.*?))', 's')
  const match = str.match(regex)
  let colorfy = 'dance'
  if (str.indexOf('dance') >= 0) colorfy = 'dance'
  if (str.indexOf('classic') >= 0) colorfy = 'classic'
  if (str.indexOf('golden') >= 0) colorfy = 'golden'
  return { str: (match && match[0]) ? match[0] : '', colorfy: colorfy, cols: (match && match[1]) ? match[1] : '', rows: (match && match[2]) ? match[2] : '' }
}
const calFrameSize = function(str) {
  const hasParenthesis = str.includes('(') || str.includes(')')
  if (hasParenthesis) return calFrameSizeEn(str)
  return calFrameSizeZh(str)
}
const utils = {
  device: device,
  getDefaultBg: getDefaultBg,
  open: open,
  calcImageSize: calcImageSize,
  deepClone: deepClone,
  param2Obj: param2Obj,
  hintEmail: hintEmail,
  caclUserPath: caclUserPath,
  calFrameSize: calFrameSize,
  getLen: getLen,
  width: width,
  height: height,
  platform: platform,
  debounce: debounce,
  throttle: throttle,
  getInt: getInt,
  isUndefined: isUndefined,
  dealUint: dealUint,
  dealInt: dealInt,
  checkNow: checkNow,
  empty: isEmpty,
  isEmpty: isEmpty,
  isString: isString,
  isArray: isArray,
  isObject: isObject,
  isImage: isImage,
  isCanvas: isCanvas,
  isFile: isFile,
  validLowerCase: validLowerCase,
  validUpperCase: validUpperCase,
  validAlphabets: validAlphabets,
  validEmail: validEmail,
  validURL: validURL,
  isUrl: isUrl,
  isBlobUrl: isBlobUrl,
  isBase64: isBase64,
  isImageUrl: isImageUrl,
  isImageMime: isImageMime,
  getFileName: getFileName,
  blobToFile: blobToFile,
  base64ToBlob: base64ToBlob,
  fileToBase64: fileToBase64,
  colorDistance: colorDistance,
  sortColors: sortColors,
  removeValue: removeValue,
  obj2Arr: obj2Arr,
  sortArr: sortArr,
  sortObj: sortObj,
  getDarkenedColor: getDarkenedColor,
  getDarkenedColorsObj: getDarkenedColorsObj,
  getDarkenedColors: getDarkenedColors,
  bestMatch: bestMatch,
  getAntiHex: getAntiHex,
  hex2rgba: hex2rgba,
  hex2rgb: hex2rgb,
  hex2rgbArr: hex2rgbArr,
  rgb2hex: rgb2hex,
  isMacLike: isMacLike,
  isAlipayClient: isAlipayClient,
  isWeixinClient: isWeixinClient,
  getSegmentStr: getSegmentStr,
  date: date,
  time: time,
  padding: padding,
  trim: trim,
  hintBankAccount: hintBankAccount,
  hintMobile: hintMobile,
  hintIdcard: hintIdcard,
  hintUsername: hintUsername,
  hintRealname: hintRealname,
  formateBytes: formateBytes,
  formateTime: formateTime,
  formateNums: formateNums,
  formateMoney: formateMoney,
  getCountDown: getCountDown,
  fomateContent: fomateContent,
  removeEmoji: removeEmoji,
  noHtml: noHtml,
  getRndInt: getRndInt,
  getComputedSize: getComputedSize,
  addEvent: addEvent,
  removeEvent: removeEvent,
  randomString: randomString,
  uuid: uuid,
  strLength: strLength,
  getString: getString,
  jsonParse: jsonParse,
  matchBaseSize: matchBaseSize,
  convert26: convert26,
  colorReverse: colorReverse,
  getHashParams: getHashParams,
  snakeCase: snakeCase,
  titleCase: titleCase
}
export default utils
