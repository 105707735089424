/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2023-6-8
 * @copyright Gridy.Art
 */
import i18n from '../../lang'
// eslint-disable-next-line
const $t = (key, value) => i18n.t(key, value)
// 边框/背景
const frames = () => {
  return {
    // whiteEasy: { skuStart: 'BP', skuEnd: '02', name: '白底简易', base: '#FFFFFF', border: '', baseId: '1', borderId: '', shadow: '', bg: '#EFEFEF', grid: '#E8E8E8' },
    // blackEasy: { skuStart: 'BP', skuEnd: '01', name: '黑底简易', base: '#212121', border: '', baseId: '26', borderId: '', shadow: '', bg: '#1B1B1B', grid: '#282828' },
    // whiteBase: { skuStart: 'BF', skuEnd: '02-N', name: '白底无框', base: '#FFFFFF', border: '', baseId: '1', borderId: '', shadow: '', bg: '#EFEFEF', grid: '#E8E8E8' },
    // blackBase: { skuStart: 'BF', skuEnd: '01-N', name: '黑底无框', base: '#212121', border: '', baseId: '26', borderId: '', shadow: '', bg: '#1B1B1B', grid: '#282828' },
    whiteWhite: { skuStart: 'BF', skuEnd: '02', name: $t('i.whiteFrame'), base: '#FFFFFF', border: '#EEEEEE', baseId: '1', borderId: '1', shadow: '#EFEFEF', bg: '#E8E8E8', grid: '#E8E8E8' },
    blackBlack: { skuStart: 'BF', skuEnd: '01', name: $t('i.blackFrame'), base: '#151515', border: '#151515', baseId: '26', borderId: '26', shadow: '#151515', bg: '#000000', grid: '#000000' }
  }
}
const puzzleSkusArr = ['8000', '9000', '10001', '10002']
const bgs = () => {
  return {
    // 'origin': { 'id': 0, 'name': $t('i.originalBg') },
    'bg-1': { 'id': 1, 'name': $t('i.bg') + ' 1' },
    'bg-2': { 'id': 2, 'name': $t('i.bg') + ' 2' },
    'bg-3': { 'id': 3, 'name': $t('i.bg') + ' 3' },
    'bg-4': { 'id': 4, 'name': $t('i.bg') + ' 4' },
    'bg-5': { 'id': 5, 'name': $t('i.bg') + ' 5' },
    'bg-6': { 'id': 6, 'name': $t('i.bg') + ' 6' }
  }
}
const brickSkus = () => {
  return {
    'BA-48-48-1': { id: 30001, name: $t('i.createPuzzle'), info: '48x48 ' + $t('style.classic'), price: 3990, image: 'images/suite_classic.jpg' },
    'BA-48-64-1': { id: 30002, name: $t('i.createPuzzle'), info: '48x64 ' + $t('style.classic'), price: 5990, image: 'images/suite_classic.jpg' },
    'BA-64-64-1': { id: 30003, name: $t('i.createPuzzle'), info: '64x64 ' + $t('style.classic'), price: 7990, image: 'images/suite_classic.jpg' },
    'BA-64-80-1': { id: 30004, name: $t('i.createPuzzle'), info: '64x80 ' + $t('style.classic'), price: 9990, image: 'images/suite_classic.jpg' },
    'BA-80-80-1': { id: 30005, name: $t('i.createPuzzle'), info: '80x80 ' + $t('style.classic'), price: 12900, image: 'images/suite_classic.jpg' },
    'BA-64-96-1': { id: 30006, name: $t('i.createPuzzle'), info: '64x96 ' + $t('style.classic'), price: 12900, image: 'images/suite_classic.jpg' },
    'BA-48-48-2': { id: 30101, name: $t('i.createPuzzle'), info: '48x48 ' + $t('style.golden'), price: 3990, image: 'images/suite_golden.jpg' },
    'BA-48-64-2': { id: 30102, name: $t('i.createPuzzle'), info: '48x64 ' + $t('style.golden'), price: 5990, image: 'images/suite_golden.jpg' },
    'BA-64-64-2': { id: 30103, name: $t('i.createPuzzle'), info: '64x64 ' + $t('style.golden'), price: 7990, image: 'images/suite_golden.jpg' },
    'BA-64-80-2': { id: 30104, name: $t('i.createPuzzle'), info: '64x80 ' + $t('style.golden'), price: 9990, image: 'images/suite_golden.jpg' },
    'BA-80-80-2': { id: 30105, name: $t('i.createPuzzle'), info: '80x80 ' + $t('style.golden'), price: 12900, image: 'images/suite_golden.jpg' },
    'BA-64-96-2': { id: 30106, name: $t('i.createPuzzle'), info: '64x96 ' + $t('style.golden'), price: 12900, image: 'images/suite_golden.jpg' },
    'BA-48-48-3': { id: 30201, name: $t('i.createPuzzle'), info: '48x48 ' + $t('style.dance'), price: 3990, image: 'images/suite_dance.jpg' },
    'BA-48-64-3': { id: 30202, name: $t('i.createPuzzle'), info: '48x64 ' + $t('style.dance'), price: 5990, image: 'images/suite_dance.jpg' },
    'BA-64-64-3': { id: 30203, name: $t('i.createPuzzle'), info: '64x64 ' + $t('style.dance'), price: 7990, image: 'images/suite_dance.jpg' },
    'BA-64-80-3': { id: 30204, name: $t('i.createPuzzle'), info: '64x80 ' + $t('style.dance'), price: 9990, image: 'images/suite_dance.jpg' },
    'BA-80-80-3': { id: 30205, name: $t('i.createPuzzle'), info: '80x80 ' + $t('style.dance'), price: 12900, image: 'images/suite_dance.jpg' },
    'BA-64-96-3': { id: 30206, name: $t('i.createPuzzle'), info: '64x96 ' + $t('style.dance'), price: 12900, image: 'images/suite_dance.jpg' },
    'BP-50-50-01': { id: 10001, name: '(' + $t('i.black') + ')50x50' + $t('i.base'), price: '3600' },
    'BP-50-50-02': { id: 10002, name: '(' + $t('i.white') + ')50x50' + $t('i.base'), price: '3600' },
    'BP-50-50-03': { id: 10003, name: '(' + $t('i.lightGray') + ')50x50' + $t('i.base'), price: '3600' },
    'BP-50-50-04': { id: 10004, name: '(' + $t('i.darkGray') + ')50x50' + $t('i.base'), price: '3600' },
    'BP-50-50-05': { id: 10005, name: '(' + $t('i.lightGreen') + ')50x50' + $t('i.base'), price: '3600' },
    'BP-50-50-06': { id: 10006, name: '(' + $t('i.green') + ')50x50' + $t('i.base'), price: '3600' },
    'BP-50-50-07': { id: 10007, name: '(' + $t('i.blue') + ')50x50' + $t('i.base'), price: '3600' },
    'BP-50-50-08': { id: 10008, name: '(' + $t('i.red') + ')50x50' + $t('i.base'), price: '3600' },
    'BP-50-50-09': { id: 10009, name: '(' + $t('i.yellow') + ')50x50' + $t('i.base'), price: '3600' },
    'BP-50-50-10': { id: 10010, name: '(' + $t('i.apricot') + ')50x50' + $t('i.base'), price: '3600' },
    'BP-50-50-11': { id: 10011, name: '(' + $t('i.pink') + ')50x50' + $t('i.base'), price: '3600' },
    'BP-32-32-01': { id: 10101, name: '(' + $t('i.black') + ')32x32' + $t('i.base'), price: '2800' },
    'BP-32-32-02': { id: 10102, name: '(' + $t('i.white') + ')32x32' + $t('i.base'), price: '2800' },
    'BP-32-32-03': { id: 10103, name: '(' + $t('i.lightGray') + ')32x32' + $t('i.base'), price: '2800' },
    'BP-32-32-04': { id: 10104, name: '(' + $t('i.darkGray') + ')32x32' + $t('i.base'), price: '2800' },
    'BP-32-32-05': { id: 10105, name: '(' + $t('i.lightGreen') + ')32x32' + $t('i.base'), price: '2800' },
    'BP-32-32-07': { id: 10106, name: '(' + $t('i.green') + ')32x32' + $t('i.base'), price: '2800' },
    'BP-32-32-08': { id: 10107, name: '(' + $t('i.blue') + ')32x32' + $t('i.base'), price: '2800' },
    'BP-32-32-09': { id: 10108, name: '(' + $t('i.red') + ')32x32' + $t('i.base'), price: '2800' },
    'BP-32-32-10': { id: 10109, name: '(' + $t('i.yellow') + ')32x32' + $t('i.base'), price: '2800' },
    'BP-32-32-11': { id: 10110, name: '(' + $t('i.apricot') + ')32x32' + $t('i.base'), price: '2800' },
    'BP-32-32-12': { id: 10111, name: '(' + $t('i.pink') + ')32x32' + $t('i.base'), price: '2800' },
    'BF-48-48-01': { id: 11001, name: '(' + $t('i.black') + ')48x48' + $t('i.frame'), price: '5400' },
    'BF-48-48-02': { id: 11002, name: '(' + $t('i.white') + ')48x48' + $t('i.frame'), price: '5400' },
    'BF-64-64-01': { id: 11003, name: '(' + $t('i.black') + ')64x64' + $t('i.frame'), price: '7200' },
    'BF-64-64-02': { id: 11004, name: '(' + $t('i.white') + ')64x64' + $t('i.frame'), price: '7200' },
    'BF-80-80-01': { id: 11005, name: '(' + $t('i.black') + ')80x80' + $t('i.frame'), price: '15000' },
    'BF-80-80-02': { id: 11006, name: '(' + $t('i.white') + ')80x80' + $t('i.frame'), price: '15000' },
    'BF-96-96-01': { id: 11007, name: '(' + $t('i.black') + ')96x96' + $t('i.frame'), price: '21600' },
    'BF-96-96-02': { id: 11008, name: '(' + $t('i.white') + ')96x96' + $t('i.frame'), price: '21600' },
    'BF-128-128-01': { id: 11009, name: '(' + $t('i.black') + ')128x128' + $t('i.frame'), price: '38400' },
    'BF-128-128-02': { id: 11010, name: '(' + $t('i.white') + ')128x128' + $t('i.frame'), price: '38400' },
    'BF-32-32-01': { id: 11031, name: '(' + $t('i.black') + ')32x32' + $t('i.frame'), price: '2400' },
    'BF-32-32-02': { id: 11032, name: '(' + $t('i.white') + ')32x32' + $t('i.frame'), price: '2400' },
    'BF-64-96-01': { id: 11101, name: '(' + $t('i.black') + ')64x96' + $t('i.frame'), price: '14400' },
    'BF-64-96-02': { id: 11102, name: '(' + $t('i.white') + ')64x96' + $t('i.frame'), price: '14400' },
    'BF-96-144-01': { id: 11103, name: '(' + $t('i.black') + ')96x144' + $t('i.frame'), price: '32400' },
    'BF-96-144-02': { id: 11104, name: '(' + $t('i.white') + ')96x144' + $t('i.frame'), price: '32400' },
    'BF-128-192-01': { id: 11105, name: '(' + $t('i.black') + ')128x192' + $t('i.frame'), price: '57600' },
    'BF-128-192-02': { id: 11106, name: '(' + $t('i.white') + ')128x192' + $t('i.frame'), price: '57600' },
    'BF-48-64-01': { id: 11201, name: '(' + $t('i.black') + ')48x64' + $t('i.frame'), price: '7200' },
    'BF-48-64-02': { id: 11202, name: '(' + $t('i.white') + ')48x64' + $t('i.frame'), price: '7200' },
    'BF-96-128-01': { id: 11203, name: '(' + $t('i.black') + ')96x128' + $t('i.frame'), price: '28800' },
    'BF-96-128-02': { id: 11204, name: '(' + $t('i.white') + ')96x128' + $t('i.frame'), price: '28800' },
    'BF-64-80-01': { id: 11301, name: '(' + $t('i.black') + ')64x80' + $t('i.frame'), price: '12000' },
    'BF-64-80-02': { id: 11302, name: '(' + $t('i.white') + ')64x80' + $t('i.frame'), price: '12000' },
    'BF-128-160-01': { id: 11303, name: '(' + $t('i.black') + ')128x160' + $t('i.frame'), price: '48000' },
    'BF-128-160-02': { id: 11304, name: '(' + $t('i.white') + ')128x160' + $t('i.frame'), price: '48000' },
    'BD-P100-1': { id: 22001, name: '(#1)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-2': { id: 22002, name: '(#2)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-3': { id: 22003, name: '(#3)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-4': { id: 22004, name: '(#4)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-5': { id: 22005, name: '(#5)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-6': { id: 22006, name: '(#6)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-7': { id: 22007, name: '(#7)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-8': { id: 22008, name: '(#8)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-9': { id: 22009, name: '(#9)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-10': { id: 22010, name: '(#10)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-11': { id: 22011, name: '(#11)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-12': { id: 22012, name: '(#12)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-13': { id: 22013, name: '(#13)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-14': { id: 22014, name: '(#14)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-15': { id: 22015, name: '(#15)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-16': { id: 22016, name: '(#16)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-17': { id: 22017, name: '(#17)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-18': { id: 22018, name: '(#18)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-19': { id: 22019, name: '(#19)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-20': { id: 22020, name: '(#20)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-21': { id: 22021, name: '(#21)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-22': { id: 22022, name: '(#22)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-23': { id: 22023, name: '(#23)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-24': { id: 22024, name: '(#24)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-25': { id: 22025, name: '(#25)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-26': { id: 22026, name: '(#26)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-27': { id: 22027, name: '(#27)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-28': { id: 22028, name: '(#28)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-29': { id: 22029, name: '(#29)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-30': { id: 22030, name: '(#30)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-31': { id: 22031, name: '(#31)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-32': { id: 22032, name: '(#32)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-33': { id: 22033, name: '(#33)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-34': { id: 22034, name: '(#34)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-35': { id: 22035, name: '(#35)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-36': { id: 22036, name: '(#36)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-37': { id: 22037, name: '(#37)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-38': { id: 22038, name: '(#38)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-39': { id: 22039, name: '(#39)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-40': { id: 22040, name: '(#40)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-41': { id: 22041, name: '(#41)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-42': { id: 22042, name: '(#42)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-43': { id: 22043, name: '(#43)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-44': { id: 22044, name: '(#44)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-45': { id: 22045, name: '(#45)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-46': { id: 22046, name: '(#46)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-47': { id: 22047, name: '(#47)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-48': { id: 22048, name: '(#48)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-49': { id: 22049, name: '(#49)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-50': { id: 22050, name: '(#50)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-51': { id: 22051, name: '(#51)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-52': { id: 22052, name: '(#52)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-80': { id: 22080, name: '(#80)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-81': { id: 22081, name: '(#81)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-82': { id: 22082, name: '(#82)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-83': { id: 22083, name: '(#83)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-84': { id: 22084, name: '(#84)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-85': { id: 22085, name: '(#85)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-86': { id: 22086, name: '(#86)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-87': { id: 22087, name: '(#87)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-88': { id: 22088, name: '(#88)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P100-89': { id: 22089, name: '(#89)' + $t('i.particle') + 'x100', price: '200' },
    'BD-P300-1': { id: 22301, name: '(#1)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-2': { id: 22302, name: '(#2)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-3': { id: 22303, name: '(#3)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-4': { id: 22304, name: '(#4)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-5': { id: 22305, name: '(#5)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-6': { id: 22306, name: '(#6)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-7': { id: 22307, name: '(#7)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-8': { id: 22308, name: '(#8)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-9': { id: 22309, name: '(#9)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-10': { id: 22310, name: '(#10)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-11': { id: 22311, name: '(#11)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-12': { id: 22312, name: '(#12)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-13': { id: 22313, name: '(#13)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-14': { id: 22314, name: '(#14)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-15': { id: 22315, name: '(#15)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-16': { id: 22316, name: '(#16)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-17': { id: 22317, name: '(#17)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-18': { id: 22318, name: '(#18)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-19': { id: 22319, name: '(#19)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-20': { id: 22320, name: '(#20)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-21': { id: 22321, name: '(#21)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-22': { id: 22322, name: '(#22)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-23': { id: 22323, name: '(#23)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-24': { id: 22324, name: '(#24)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-25': { id: 22325, name: '(#25)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-26': { id: 22326, name: '(#26)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-27': { id: 22327, name: '(#27)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-28': { id: 22328, name: '(#28)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-29': { id: 22329, name: '(#29)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-30': { id: 22330, name: '(#30)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-31': { id: 22331, name: '(#31)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-32': { id: 22332, name: '(#32)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-33': { id: 22333, name: '(#33)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-34': { id: 22334, name: '(#34)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-35': { id: 22335, name: '(#35)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-36': { id: 22336, name: '(#36)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-37': { id: 22337, name: '(#37)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-38': { id: 22338, name: '(#38)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-39': { id: 22339, name: '(#39)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-40': { id: 22340, name: '(#40)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-41': { id: 22341, name: '(#41)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-42': { id: 22342, name: '(#42)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-43': { id: 22343, name: '(#43)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-44': { id: 22344, name: '(#44)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-45': { id: 22345, name: '(#45)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-46': { id: 22346, name: '(#46)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-47': { id: 22347, name: '(#47)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-48': { id: 22348, name: '(#48)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-49': { id: 22349, name: '(#49)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-50': { id: 22350, name: '(#50)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-51': { id: 22351, name: '(#51)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-52': { id: 22352, name: '(#52)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-80': { id: 22380, name: '(#80)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-81': { id: 22381, name: '(#81)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-82': { id: 22382, name: '(#82)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-83': { id: 22383, name: '(#83)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-84': { id: 22384, name: '(#84)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-85': { id: 22385, name: '(#85)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-86': { id: 22386, name: '(#86)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-87': { id: 22387, name: '(#87)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-88': { id: 22388, name: '(#88)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P300-89': { id: 22389, name: '(#89)' + $t('i.particle') + 'x300', price: '400' },
    'BD-P500-1': { id: 22501, name: '(#1)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-2': { id: 22502, name: '(#2)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-3': { id: 22503, name: '(#3)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-4': { id: 22504, name: '(#4)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-5': { id: 22505, name: '(#5)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-6': { id: 22506, name: '(#6)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-7': { id: 22507, name: '(#7)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-8': { id: 22508, name: '(#8)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-9': { id: 22509, name: '(#9)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-10': { id: 22510, name: '(#10)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-11': { id: 22511, name: '(#11)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-12': { id: 22512, name: '(#12)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-13': { id: 22513, name: '(#13)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-14': { id: 22514, name: '(#14)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-15': { id: 22515, name: '(#15)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-16': { id: 22516, name: '(#16)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-17': { id: 22517, name: '(#17)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-18': { id: 22518, name: '(#18)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-19': { id: 22519, name: '(#19)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-20': { id: 22520, name: '(#20)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-21': { id: 22521, name: '(#21)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-22': { id: 22522, name: '(#22)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-23': { id: 22523, name: '(#23)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-24': { id: 22524, name: '(#24)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-25': { id: 22525, name: '(#25)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-26': { id: 22526, name: '(#26)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-27': { id: 22527, name: '(#27)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-28': { id: 22528, name: '(#28)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-29': { id: 22529, name: '(#29)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-30': { id: 22530, name: '(#30)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-31': { id: 22531, name: '(#31)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-32': { id: 22532, name: '(#32)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-33': { id: 22533, name: '(#33)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-34': { id: 22534, name: '(#34)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-35': { id: 22535, name: '(#35)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-36': { id: 22536, name: '(#36)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-37': { id: 22537, name: '(#37)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-38': { id: 22538, name: '(#38)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-39': { id: 22539, name: '(#39)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-40': { id: 22540, name: '(#40)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-41': { id: 22541, name: '(#41)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-42': { id: 22542, name: '(#42)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-43': { id: 22543, name: '(#43)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-44': { id: 22544, name: '(#44)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-45': { id: 22545, name: '(#45)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-46': { id: 22546, name: '(#46)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-47': { id: 22547, name: '(#47)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-48': { id: 22548, name: '(#48)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-49': { id: 22549, name: '(#49)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-50': { id: 22550, name: '(#50)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-51': { id: 22551, name: '(#51)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-52': { id: 22552, name: '(#52)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-80': { id: 22580, name: '(#80)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-81': { id: 22581, name: '(#81)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-82': { id: 22582, name: '(#82)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-83': { id: 22583, name: '(#83)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-84': { id: 22584, name: '(#84)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-85': { id: 22585, name: '(#85)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-86': { id: 22586, name: '(#86)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-87': { id: 22587, name: '(#87)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-88': { id: 22588, name: '(#88)' + $t('i.particle') + 'x500', price: '600' },
    'BD-P500-89': { id: 22589, name: '(#89)' + $t('i.particle') + 'x500', price: '600' }
  }
}
export default {
  frames: frames,
  puzzleSkusArr: puzzleSkusArr,
  bgs: bgs,
  brickSkus: brickSkus
}
