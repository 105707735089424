<template>
  <el-dialog :title="title" :visible.sync="config.show" :fullscreen="platform.type !== 'desktop'" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :top="marginTop" custom-class="pop_page popPageDialog">
    <div slot="title" class="header" :style="platform.type !== 'desktop' ? containerStyle : ''">
      <div class="left">
        <!-- <el-button v-if="!isDesktop" v-show="!simpleMode || (simpleMode && config.type === 'toBrickfy' && step) || (simpleMode && config.type !== 'toBrickfy')" class="tap-btn iconfont my-back size20" @click="close()" /> -->
        <el-button v-if="!isDesktop" class="tap-btn iconfont size20" :class="{'my-home': simpleMode && config.type === 'toBrickfy', 'my-back': !(simpleMode && config.type === 'toBrickfy') || (simpleMode && config.type === 'toBrickfy' && step)}" @click="close()" />
        &nbsp;
      </div>
      <div class="middle">
        {{ title }}
      </div>
      <div class="float-right align-right">
        <el-button v-if="config.type === 'paint'" class="tap-btn iconfont my-ai-paint size20" :title="$t('i.aiWorks')" @click="emit('openResource', 'paint')" />
        <el-button v-if="config.type === 'toCartoon'" class="tap-btn iconfont my-share size20 clipboard" :data-clipboard-text="' ' + $t('i.aiCartoonTips') + ' ' + mainHost + '#/?mod=popPage&type=toCartoon&inviteid=' + loginUserId" @click="share" />
        <el-button v-if="config.type === 'toBrickArt'" class="tap-btn iconfont my-share size20 clipboard" :data-clipboard-text="' ' + $t('i.aiImgPuzzleTips') + ' ' + mainHost + '#/?mod=popPage&type=toBrickArt&inviteid=' + loginUserId" @click="share" />
        <el-button v-if="config.type === 'toPixel' || config.type === 'toBeanPixel'" class="tap-btn iconfont my-share size20 clipboard" :data-clipboard-text="' ' + $t('i.aiBeanPixelTips') + ' ' + mainHost + '#/?mod=popPage&type=toBeanPixel&inviteid=' + loginUserId" @click="share" />
        <el-button v-if="config.type === 'toBrickfy'" class="tap-btn iconfont my-share size20 clipboard" :data-clipboard-text="' ' + $t('i.aiPuzzleTips') + ' ' + mainHost + '#/?mod=popPage&type=toBrickfy&inviteid=' + loginUserId" @click="share" />
        <el-button v-if="isDesktop" class="tap-btn iconfont my-close size20" @click="close()" />
        &nbsp;
      </div>
    </div>
    <div :class="{'my-container': platform.type !== 'desktop'}" :style="containerStyle">
      <div v-if="loginStatus && (config.type === 'bindEmail' || config.type === 'rebindEmail' || config.type === 'unbindEmail' || config.type === 'bindPhone' || config.type === 'rebindPhone' || config.type === 'unbindPhone' || config.type === 'setPassword' || config.type === 'setPayPassword' || config.type === 'realVerify' || config.type === 'realVerifyInfo') " class="my-block">
        <el-form ref="formWidget" v-loading="config.loading" class="my-form" label-width="90px" :inline="false" :model="form" :rules="rules" element-loading-background="rgba(0, 0, 0, 0.6)">
          <el-form-item v-if="state.supportMobile && config.type !== 'bindPhone' && config.type !== 'rebindPhone' && config.type !== 'unbindPhone' && config.type !== 'bindEmail' && config.type !== 'rebindEmail' && config.type !== 'unbindEmail' && config.type !== 'realVerifyInfo'" :label="$t('i.mobile')" prop="phone">
            <el-input v-model.trim="form.phone" type="input" maxlength="11" :placeholder="$t('i.reqPhone') + ' ' + (config.data.mobile || '') " clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="form.phone = form.phone.replace(/[^\d.]/g,'')" />
          </el-form-item>
          <el-form-item v-if="state.supportMobile && config.type !== 'bindPhone' && config.type !== 'rebindPhone' && config.type !== 'unbindPhone' && config.type !== 'bindEmail' && config.type !== 'rebindEmail' && config.type !== 'unbindEmail' && config.type !== 'realVerifyInfo'" :label="$t('i.verifyCode')" prop="code" class="sms-item">
            <el-input v-model.trim="form.code" type="input" maxlength="6" :placeholder="$t('i.reqMcode')" onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="form.code = form.code.replace(/[^\d.]/g,'')" />
            <div class="sms-send" :class="{'is-disabled': !form.phone || state.countDownNum}" @click="sendSMS()">
              {{ state.countDownNum ? state.countDownNum : $t('i.getMcode') }}
            </div>
          </el-form-item>
          <el-form-item v-if="!state.supportMobile && config.type !== 'bindEmail' && config.type !== 'rebindEmail' && config.type !== 'unbindEmail' && config.type !== 'realVerifyInfo'" :label="$t('i.email')" prop="email">
            <el-input v-model.trim="form.email" type="input" maxlength="50" :placeholder="$t('i.reqEmail') + ' ' + (config.data.email || '') " clearable />
          </el-form-item>
          <el-form-item v-if="!state.supportMobile && config.type !== 'bindEmail' && config.type !== 'rebindEmail' && config.type !== 'unbindEmail' && config.type !== 'realVerifyInfo'" :label="$t('i.verifyCode')" prop="code" class="sms-item">
            <el-input v-model.trim="form.code" type="input" maxlength="6" :placeholder="$t('i.reqMcode')" onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="form.code = form.code.replace(/[^\d.]/g,'')" />
            <div class="sms-send" :class="{'is-disabled': !form.email || state.countDownNum}" @click="sendEmail()">
              {{ state.countDownNum ? state.countDownNum : $t('i.getMcode') }}
            </div>
          </el-form-item>
          <el-form-item v-if="config.type === 'bindPhone' || config.type === 'rebindPhone' || config.type === 'unbindPhone'" :label="config.type === 'rebindPhone'? $t('i.newMobile') : $t('i.mobile')" prop="phone">
            <el-input v-model.trim="form.phone" type="input" maxlength="11" :placeholder="(config.type === 'rebindPhone'? $t('i.reqNewMobile') : $t('i.reqPhone')) + ' ' + (config.type === 'unbindPhone' ? (config.data.mobile || '') : '')" clearable onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="form.phone = form.phone.replace(/[^\d.]/g,'')" />
          </el-form-item>
          <el-form-item v-if="config.type === 'bindPhone' || config.type === 'rebindPhone' || config.type === 'unbindPhone'" :label="$t('i.verifyCode')" prop="code" class="sms-item">
            <el-input v-model.trim="form.code" type="input" maxlength="6" :placeholder="$t('i.reqMcode')" onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="form.code = form.code.replace(/[^\d.]/g,'')" />
            <div class="sms-send" :class="{'is-disabled': !form.phone || state.countDownNum}" @click="sendSMS()">
              {{ state.countDownNum ? state.countDownNum : $t('i.getMcode') }}
            </div>
          </el-form-item>
          <el-form-item v-if="config.type === 'bindEmail' || config.type === 'rebindEmail' || config.type === 'unbindEmail'" :label="config.type === 'rebindEmail' ? $t('i.newEmail') : $t('i.email')" prop="email">
            <el-input v-model.trim="form.email" type="input" maxlength="50" :placeholder="(config.type === 'rebindEmail' ? $t('i.reqNewEmail') : $t('i.reqEmail')) + ' ' + (config.type === 'unbindEmail' ? (config.data.email || '') : '')" clearable />
          </el-form-item>
          <el-form-item v-if="config.type === 'bindEmail' || config.type === 'rebindEmail' || config.type === 'unbindEmail'" :label="$t('i.verifyCode')" prop="code" class="sms-item">
            <el-input v-model.trim="form.code" type="input" maxlength="6" :placeholder="$t('i.reqMcode')" onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" @blur="form.code = form.code.replace(/[^\d.]/g,'')" />
            <div class="sms-send" :class="{'is-disabled': !form.email || state.countDownNum}" @click="sendEmail('bind')">
              {{ state.countDownNum ? state.countDownNum : $t('i.getMcode') }}
            </div>
          </el-form-item>
          <el-form-item v-if="config.type === 'setUsername'" :label="$t('i.username')" prop="username">
            <el-input v-model.trim="form.username" type="input" maxlength="18" clearable />
          </el-form-item>
          <el-form-item v-if="config.type === 'setPassword' || config.type === 'setPayPassword'" :label="config.type === 'setPayPassword' ? $t('i.payPWD'): $t('i.passwd')" prop="password">
            <el-input v-model.trim="form.password" type="password" :maxlength="config.type === 'setPayPassword' ? 6 : 20" clearable />
          </el-form-item>
          <el-form-item v-if="config.type === 'setPassword' || config.type === 'setPayPassword'" :label="$t('i.checkPWD')" prop="repassword">
            <el-input v-model.trim="form.repassword" type="password" :maxlength="config.type === 'setPayPassword' ? 6 : 20" clearable />
          </el-form-item>
          <el-form-item v-if="config.type === 'realVerify'" :label="$t('i.realname')" prop="realname">
            <el-input v-model.trim="form.realname" type="input" maxlength="18" clearable />
          </el-form-item>
          <el-form-item v-if="config.type === 'realVerify'" :label="$t('i.idcard')" prop="idcard">
            <el-input v-model.trim="form.idcard" type="input" maxlength="18" clearable />
          </el-form-item>
          <el-form-item v-if="config.type === 'realVerifyInfo'" :label="$t('i.realname')">
            {{ config.data.realname }}
          </el-form-item>
          <el-form-item v-if="config.type === 'realVerifyInfo'" :label="$t('i.idcard')">
            {{ config.data.identity }}
          </el-form-item>
          <el-form-item v-if="config.type === 'realVerifyInfo'" :label="$t('i.state')">
            <span v-if="config.data.verify_status === 0">{{ $t('i.noVerify') }}</span>
            <span v-if="config.data.verify_status === 1" class="yellow">{{ $t('i.verifying') }}</span>
            <span v-if="config.data.verify_status === 2" class="green">{{ $t('i.verified') }}</span>
            <span v-if="config.data.verify_status === -1" class="red">{{ $t('i.verifyFail') }}</span>
          </el-form-item>
          <el-form-item label="">
            <el-button v-if="config.type !== 'realVerifyInfo'" class="submit" @click="submitForm('formWidget')">
              {{ (config.type === 'bindPhone' || config.type === 'rebindPhone' || config.type === 'bindEmail' || config.type === 'rebindEmail') ? $t('i.bind') : $t('i.commit') }}
            </el-button>
          </el-form-item>
          <div v-if="config.type === 'realVerify'" class="article" v-html="$t('i.realTips')" />
        </el-form>
      </div>
      <div v-if="config.type === 'chainAccount'">
        <div class="padding15">
          <b>{{ $t('i.chainAccount') }}</b>
        </div>
        <div class="block-round clipboard" :data-clipboard-text="config.data.account || ''" @click="clipboardCopy('chain_account')">
          {{ config.data.account || '' }}  <i class="iconfont my-copy size16 color-bb" />
        </div>
        <div class="padding15">
          <b>{{ $t('i.chainBrowser') }}</b>
        </div>
        <div class="block-round clipboard" :data-clipboard-text="'https://tianzhou.wenchang.bianjie.ai/'" @click="clipboardCopy('chain_browser')">
          https://tianzhou.wenchang.bianjie.ai/ <i class="iconfont my-copy size16 color-bb" />
        </div>
      </div>
      <div v-if="config.type === 'feedback'">
        <el-form v-loading="config.loading" class="my-form" label-width="90px" element-loading-background="rgba(0, 0, 0, 0.6)">
          <el-form-item :label="$t('i.classify')" class="my-el-form-item">
            <el-select v-model.trim="feedback.type" :placeholder="$t('i.pleaseSelect')">
              <el-option
                v-for="item in feedback.typeOpts"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('i.topic')">
            <el-input v-model.trim="feedback.title" class="my-from-item" maxlength="255" :placeholder="$t('i.reqTopic')" clearable />
          </el-form-item>
          <el-form-item :label="$t('i.content')">
            <el-input v-model.trim="feedback.content" class="my-from-item" maxlength="2048" type="textarea" :placeholder="$t('i.reqContent')" rows="10" resize="false" />
          </el-form-item>
          <el-form-item :label="$t('i.linkman')">
            <el-input v-model.trim="feedback.name" class="my-from-item" maxlength="20" :placeholder="$t('i.reqName')" clearable />
          </el-form-item>
          <el-form-item :label="$t('i.contact')">
            <el-input v-model.trim="feedback.contact" class="my-from-item" maxlength="100" :placeholder="$t('i.reqContact')" clearable />
          </el-form-item>
          <el-form-item label="">
            <el-button class="submit" @click="sendFeedback()">{{ $t('i.commitNow') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="config.type === 'aboutUs'" class="aboutus">
        <div class="icon">
          <img src="@/assets/icon.png" :title="$t('siteName')" width="64">
        </div>
        <div class="txt">
          {{ $t('siteName') }} <br><br>
          <span v-html="$t('siteTips')" /><br><br>
          <el-link :href="mainHost" target="_blank" class="blue">{{ $t('i.viewSite') }}</el-link><br><br><br>
        </div>
        <div class="txt gray copyright">
          {{ $t('copyRight') }}
        </div>
      </div>
      <div v-if="config.type === 'about'" class="aboutus">
        <div class="icon">
          <img src="@/assets/icon.png" :title="$t('siteName')" width="64"><br><br>
          {{ $t('siteName') }} <br><br>
        </div>
        <div class="txt align-center" :style="{'padding': isDesktop ? '10px 80px' : '10px' }">
          &nbsp; &nbsp; &nbsp; <span v-html="$t('siteIntro')" /><br><br>
        </div>
        <div class="txt gray copyright">
          {{ $t('copyRight') }}
        </div>
      </div>
      <div v-if="config.type === 'contactUs'" class="aboutus">
        <div class="icon">
          <img src="@/assets/icon.png" :title="$t('siteName')" width="64"><br><br>
          {{ $t('siteName') }} <br><br>
        </div>
        <div class="txt align-left" :style="{'padding': isDesktop ? '10px 80px 10px 150px' : '' }">
          <div style="width: 340px;margin: 0 auto;" v-html="$t('contactInfo')" />
        </div>
        <div class="txt gray copyright">
          {{ $t('copyRight') }}
        </div>
      </div>
      <div v-if="config.type === 'checkUpdate'" class="aboutus">
        <div class="icon">
          <img src="@/assets/icon.png" :title="$t('siteName')" width="64">
        </div>
        <div class="txt new-version">
          Ver {{ ver }} <br>
          <span v-if="platform.value > 10">
            {{ state.newVersion ? $t('i.newVer') + state.newVersion.ver : '' }}
            <br><el-link v-if="state.newVersion" :href="downloadUrl" target="_blank" class="yellow">{{ $t('i.downloadNow') }}</el-link>
            <el-button v-if="!state.newVersion" size="mini" @click="checkUpdate">{{ $t('i.checkUpdate') }}</el-button> <br>
          </span>
          <span v-if="platform.value <= 10"> {{ $t('i.lastVer') }}</span>
          <br><br><br>
        </div>
        <div class="txt gray copyright">
          {{ $t('copyRight') }}
        </div>
      </div>
      <div v-if="config.type === 'agreement' || config.type === 'privacy'" class="article text" v-html="settings[config.type] || ''" />
      <div v-if="config.type === 'nftnotice'" class="article padding4">
        <div class="block-round">
          <b>{{ $t('i.issuInfo') }}</b>
        </div>
        <div class="padding15">
          <el-row>
            <el-col :span="19"><b>{{ $t('i.issuNums') }}</b></el-col>
            <el-col :span="5">{{ publish.total }} {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="19"><span class="float-left" style="width:100px">· {{ $t('i.pubSale') }}</span> <span class="float-left">({{ getDate(publish.sell_at) }})</span></el-col>
            <el-col :span="5">{{ publish.sell_nums }} {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row v-if="publish.advance_sell">
            <el-col :span="19"><span class="float-left" style="width:100px">&nbsp;&nbsp; · {{ $t('i.priPurchase') }}</span> <span class="float-left">({{ getDate(publish.sell_at - 3600) }})</span></el-col>
          </el-row>
          <el-row v-if="publish.preorder">
            <el-col :span="19"><span class="float-left" style="width:100px">&nbsp;&nbsp; · {{ $t('i.openSub') }}</span> <span class="float-left">({{ getDate(publish.sell_at - 7 * 86400) }})</span></el-col>
          </el-row>
          <el-row v-if="publish.compound">
            <el-col :span="19"><span class="float-left" style="width:100px">· {{ $t('i.openCast') }}</span> <span class="float-left">({{ getDate(publish.compound_at) }})</span></el-col>
            <el-col :span="5">{{ publish.compound_nums }} {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row v-if="publish.paradrop">
            <el-col :span="19"><span class="float-left" style="width:100px">· {{ $t('i.freeDrop') }}</span> <span class="float-left">({{ getDate(publish.paradrop_at) }})</span></el-col>
            <el-col :span="5">{{ publish.paradrop_nums }} {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row v-if="publish.blind">
            <el-col :span="19">· {{ $t('i.blindReser') }}</el-col>
            <el-col :span="5">{{ publish.blind_nums }} {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· {{ $t('i.activityReser') }}</el-col>
            <el-col :span="5">8 {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· {{ $t('i.creatorReser') }}</el-col>
            <el-col :span="5">1 {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· {{ $t('i.protectReser') }}</el-col>
            <el-col :span="5">1 {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· {{ $t('i.commercialReser') }}</el-col>
            <el-col :span="5">1 {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· {{ $t('i.exhibitionReser') }}</el-col>
            <el-col :span="5">1 {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· {{ $t('i.secondaryReser') }}</el-col>
            <el-col :span="5">1 {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· {{ $t('i.gameReser') }}</el-col>
            <el-col :span="5">1 {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· {{ $t('i.puzzleReser') }}</el-col>
            <el-col :span="5">1 {{ $t('i.copyy') }}</el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="19">· {{ $t('i.puzzleReser') }}</el-col>
            <el-col :span="5">1 {{ $t('i.copyy') }}</el-col>
          </el-row> -->
          <el-row>
            <el-col :span="19">· {{ $t('i.printingReser') }}</el-col>
            <el-col :span="5">1 {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· {{ $t('i.commerceReser') }}</el-col>
            <el-col :span="5">1 {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· {{ $t('i.publishingReser') }}</el-col>
            <el-col :span="5">1 {{ $t('i.copyy') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="19">· {{ $t('i.auctionReser') }}</el-col>
            <el-col :span="5">1 {{ $t('i.copyy') }}</el-col>
          </el-row>
        </div>
        <div class="block-round">
          <b>{{ $t('i.purchaseNotes') }}</b>
        </div>
        <div class="text padding12" v-html="settings[config.type] || ''" />
      </div>
      <div v-if="config.type === 'publishInfo'" class="article padding4">
        <div class="block-round">
          <b>{{ $t('i.workIntro') }}</b>
        </div>
        <div v-if="publish.story" class="text padding12">
          {{ publish.story }}
        </div>
        <div v-if="publish.images && publish.images.length" class="block-round">
          <el-row>
            <el-col :span="24"><b>{{ $t('i.workStory') }}</b></el-col>
          </el-row>
        </div>
        <div class="padding12">
          <div v-for="(img, i) in publish.images" :key="i" class="images">
            <img :src="img">
          </div>
          <br>
        </div>
      </div>
      <div v-if="config.type === 'publishPower'" class="article padding4">
        <div class="block-round">
          <b>{{ $t('i.benefits') }}</b>
        </div>
        <div class="padding15">
          <ul>
            <li v-for="(powerid, idx) in publish.powers" v-show="powers[powerid]" :key="idx" class="line-height-18">
              · {{ powers[powerid] ? (powers[powerid].name || '') : '' }}
            </li>
          </ul>
        </div>
        <div v-if="settings['powernotice']" class="block-round">
          <b>{{ $t('i.benefitsInfo') }}</b>
        </div>
        <div class="padding15" v-html="settings['powernotice']" />
      </div>
      <div v-if="config.type === 'publishCert'" class="article text">
        <span>{{ publish.chain_data }}</span>
      </div>
      <div v-if="config.type === 'download'" class="article padding4 align-center">
        <div>
          <div v-if="config.data.res.length" class="block-round margin-top12 margin-bottom12">
            {{ $t('i.longPress') }}
          </div>
          <div v-for="(re, i) in config.data.res" :key="i" class="padding12">
            <img :src="re[0]" :style="{'max-width': maxImageWidth + 'px'}">
          </div>
          <!-- <div class="margin-top4">
            如果无法下载，请用PC电脑访问gridy.art下载该文件！！！
          </div> -->
        </div>
      </div>
      <div v-if="config.type === 'paint'" v-loading="config.loading" element-loading-background="rgba(0, 0, 0, 0.6)">
        <div style="overflow: hidden;" :style="{'height': isDesktop ? '85px' : '105px'}">
          <div class="post-row">
            <el-input v-model="paint.content" type="textarea" class="post-input" maxlength="120" :rows="isDesktop ? 3 : 4" resize="false" :placeholder="paint.placeholder" />
          </div>
          <div style="width: 100%;text-align:right;position: relative;padding-right:10px;top:-30px;" class="color-99 size12">
            <div class="float-right round4 bg-22" style="padding: 1px 8px;line-height:22px;">{{ txtCount }}</div>
          </div>
        </div>
        <el-row class="post-row">
          <div style="width:100%;">
            <div class="float-left margin-top8" style="width: 150px;">热门效果关键词</div>
            <div class="float-right">
              <el-button v-if="!isDesktop" class="tap-btn bg-22" style="width: 30px;" @click="paint.largeMod = !paint.largeMod">
                <i v-if="!paint.largeMod" class="iconfont my-open-down color-ee size16" />
                <i v-if="paint.largeMod" class="iconfont my-close-up color-ee size16" />
              </el-button>
            </div>
          </div>
        </el-row>
        <div class="post-row">
          <el-row style="overflow-x: hidden;overflow-y: auto;" :style="{'height': isDesktop ? '' : paint.largeMod ? '' : '85px'}">
            <div v-for="tag in paint.tags" :key="tag" class="tag bg-gray white margin-top4 margin-bottom4 margin-right8 float-left size14" @click="addPaintContent(tag)">{{ tag }}</div>
          </el-row>
        </div>
        <el-row class="post-row padding-top12">
          风格 {{ styles[paint.styleid] ? '(' + styles[paint.styleid][1] + ')' : '' }}
        </el-row>
        <div style="overflow-y: hidden;overflow-x: auto;" :style="{'height': paint.thumbFactor * 160 + 20 + 'px'}">
          <div class="padding-left4" :style="{'width': styles.length * paint.thumbFactor * 120 + styles.length * 9 + 'px'}">
            <el-col v-for="(item, i) in styles" :key="i" class="padding4 margin-right8" :style="{'width': paint.thumbFactor * 120 + 'px', 'height': paint.thumbFactor * 160 + 'px'}">
              <div class="round4" :style="{'width': paint.thumbFactor * 120 + 'px', 'height': paint.thumbFactor * 160 + 'px', 'background-color': 'var(--color-1b)'}" @click="paint.styleid = i">
                <el-image class="round4" :style="{'width': paint.thumbFactor * 120 + 'px', 'height': paint.thumbFactor * 160 + 'px', 'opacity': paint.styleid === i ? 1: 0.6}" fit="cover" :src="item[2]" />
              </div>
              <div class="opacity-bg align-center size12" :class="{'white': paint.styleid === i, 'color-99': paint.styleid !== i }" :style="{'width': paint.thumbFactor * 120 + 'px', 'height':'24px', 'line-height':'24px', 'position': 'relative', 'top':'-24px'}" @click="paint.styleid = i">{{ item[1] }}</div>
            </el-col>
          </div>
        </div>
        <div class="post-row padding-top8">
          比例 {{ resolutions[paint.resolutionid] ? '(' + resolutions[paint.resolutionid][1] + ')' : '' }}
        </div>
        <div class="post-row">
          <el-button v-for="(resolution, i) in resolutions" :key="i" :command="i" :type="i === paint.resolutionid ? 'light' : ''" style="padding: 10px 24px 6px 24px;" @click="paint.resolutionid = i">
            <i class="size20 iconfont" :class="resolution[2]" />
            <span class="padding-left8" style="font-size: 14px;line-height:28px;">{{ resolution[1] }}</span>
          </el-button>
        </div>
      </div>
      <div v-if="config.type === 'paint'" class="footer padding-top8" :style="{'position' : isDesktop ? 'absolute' : ''}">
        <el-button v-if="loginUserId" :disabled="!canPaint" :type="canPaint ? 'light' : ''" class="btn-ok" style="max-width:200px;margin-left: 32px;" @click="requestAiPaint()">
          立即生成
          <span class="size12">（{{ canPaint ? paintPrice + $t('i.diamond') : $t('i.diamondLess') }}）</span>
        </el-button>
        <el-button v-if="!loginUserId" type="light" class="btn-ok" style="max-width:200px;margin-left: 32px;" @click="emit('login')">
          立即生成
        </el-button>
      </div>
      <div v-if="config.type === 'nftorder'">
        <div class="margin-bottom12 align-center paddign12 line-height-12" style="height:160px;">
          <br><br>
          <span v-if="(order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status === 1" class="green"><i class="iconfont my-ok size64 green" /><br>{{ $t('i.tradeSuccess') }}</span>
          <span v-if="(order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status < 0" class="color-99"><i class="iconfont my-fail size64 color-99" /><br>{{ order.pay_status === -1 ? $t('i.payFail') : (order.pay_status === -2 ? $t('i.hadCancel') : (order.pay_status === -3 ? $t('i.hadExpires') : '')) }}</span>
          <span v-if="(order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status === 0" class="yellow"><i class="iconfont my-pay size64 yellow" /><br>{{ $t('i.waitPay') }}</span>
          <span v-if="order.type === 1 && order.preorder_status === 0" class="yellow"><i class="iconfont my-remain size64 yellow" /><br>{{ $t('i.waitingDrawLots') }}</span>
          <span v-if="order.type === 1 && order.preorder_status === -1" class="red"><i class="iconfont my-del size64 red" /><br>{{ $t('i.notWin') }}</span>
        </div>
        <div class="block-round margin-bottom12" style="height:140px;">
          <div class="float-left" style="height:120px;width: 120px;">
            <img v-if="order.workid" :src="worksHost + 'work/action/download/gridy/size/8/shape/none/workid/' + order.workid + '/' + order.workid + '.png?ver=' + order.update_at" class="round4" style="max-width: 120px;max-height: 120px;" :style="imgStyle(order.work)">
          </div>
          <div class="float-left margin-left12" style="margin-top: 30px;">
            <el-row class="padding-bottom4">
              {{ order.publish ? order.publish.name : '' }}
            </el-row>
            <el-row class="padding-bottom4">
              <div v-if="order.publish" class="color-99">{{ order.user.nickname || '' }}</div>
            </el-row>
          </div>
        </div>
        <div class="block-round line-height-20 margin-bottom12">
          <el-row v-if="orderTypes[order.type]">
            <el-col :span="8" class="color-99">{{ $t('i.orderType') }}</el-col>
            <el-col :span="16" class="align-right">
              {{ orderTypes[order.type] }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">{{ $t('i.orderAmount') }}</el-col>
            <el-col :span="16" class="align-right">
              ¥{{ formateMoney(order.amount) }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">{{ $t('i.purchaseAuantity') }}</el-col>
            <el-col :span="16" class="align-right">
              1
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">{{ $t('i.createTime') }}</el-col>
            <el-col :span="16" class="align-right">
              {{ getDate(order.add_at) }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">{{ $t('i.orderNumber') }}</el-col>
            <el-col :span="16" class="align-right">
              <div class="clipboard" :data-clipboard-text="order.order_sn" @click="clipboardCopy('order_sn')">
                {{ order.order_sn }} <i class="iconfont my-copy size16 color-bb" />
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">{{ $t('i.paymentMethod') }}</el-col>
            <el-col :span="16" class="align-right">
              {{ order.pay_type === 0 ? $t('i.moneyPay') : (order.pay_type === 1 ? $t('i.moneyPay') : (order.pay_type === 2 ? $t('i.wechatPay') : (order.pay_type === 12 ? $t('i.aliPayment') : $t('i.other')))) }}
            </el-col>
          </el-row>
          <el-row v-if="order.pay_status">
            <el-col :span="8" class="color-99">{{ $t('i.paymentTime') }}</el-col>
            <el-col :span="16" class="align-right">
              {{ order.pay_at ? getDate(order.pay_at) : '-' }}
            </el-col>
          </el-row>
          <!-- <el-row v-if="order.pay_type !== -1">
            <el-col :span="8" class="color-99">{{ $t('i.state') }}</el-col>
            <el-col :span="16" class="align-right">
              <span v-if="(order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status === 1">{{ $t('i.payed') }}</span>
              <span v-if="(order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status < 0" class="color-99">{{ order.pay_status === -1 ? $t('i.payFail') : (order.pay_status === -2 ? $t('i.hadCancel') : (order.pay_status === -3 ? $t('i.hadExpires') : '')) }}</span>
              <span v-if="(order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status === 0" class="blue" @click="payIt(order)">{{ $t('i.payNow') }}</span>
              <span v-if="order.type === 1 && order.preorder_status === 0" class="yellow">{{ $t('i.waitingDrawLots') }}</span>
              <span v-if="order.type === 1 && order.preorder_status === -1" class="red">{{ $t('i.notWin') }}</span>
            </el-col>
          </el-row> -->
        </div>
        <div v-if="order.pay_status === 1" class="block-round line-height-20">
          <el-row>
            <el-col :span="8" class="color-99">{{ $t('i.nftStatus') }}</el-col>
            <el-col :span="16" class="align-right" :class="{'red': order.nft_status === -1,'yellow': order.nft_status === 0,'green': order.nft_status === 1}">
              {{ order.nft_status === -1 ? $t('i.distributionFailed') : (order.nft_status === 0 ? $t('i.waitingDistribution') : (order.nft_status === 1 ? $t('i.distributionSuccess') : '-')) }}
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="config.type === 'nftorder' && (order.type !== 1 || (order.type === 1 && order.preorder_status === 1)) && order.pay_status === 0" class="footer padding-top8" :style="{'position': isDesktop ? 'absolute' : ''}">
        <el-button type="primary" class="btn-cancel" style="max-width:200px;" @click="cancelIt(order)">
          {{ $t('i.cancelOrder') }}
        </el-button>
        <el-button type="light" class="btn-ok" style="max-width:200px;" @click="payIt(order)">
          {{ $t('i.payNow') }}
        </el-button>
      </div>
      <div v-if="config.type === 'brickorder'">
        <div class="margin-bottom12 align-center paddign12 line-height-12" style="height:140px;">
          <br><br>
          <span v-if="order.pay_status > 0 && !order.delivery_status" class="white"><i class="iconfont my-delivery size64" /><br>{{ $t('i.waitSend') }}</span>
          <span v-if="order.pay_status > 0 && order.delivery_status && !order.receipt_status" class="white"><i class="iconfont my-receipt size64" /><br>{{ $t('i.waitReceive') }}</span>
          <!-- <span v-if="order.pay_status > 0 && order.delivery_status && order.receipt_status && !order.is_comment" class="white"><i class="iconfont my-reply size64" /><br>{{ $t('i.waitReply') }}</span>
          <span v-if="order.pay_status > 0 && order.delivery_status && order.receipt_status && order.is_comment" class="green"><i class="iconfont my-ok size64 green" /><br>{{ $t('i.hadFinish') }}</span> -->
          <span v-if="order.pay_status > 0 && order.delivery_status && order.receipt_status" class="green"><i class="iconfont my-ok size64 green" /><br>{{ $t('i.hadFinish') }}</span>
          <span v-if="order.pay_status < 0" class="color-99"><i class="iconfont my-fail size64 color-99" /><br>{{ order.pay_status === -1 ? $t('i.payFail') : (order.pay_status === -2 ? $t('i.hadCancel') : (order.pay_status === -3 ? $t('i.hadExpires') : '')) }}</span>
          <span v-if="order.pay_status === 0" class="yellow"><i class="iconfont my-pay size64 yellow" /><br>{{ $t('i.waitPay') }}</span>
        </div>
        <div v-if="order.brick_orderid && order.diy && !order.isGoods" class="block-round margin-bottom8" style="height:120px;">
          <div class="float-left" style="height:100px;width: 100px;">
            <el-image v-if="order.image" fit="scale-down" :src="getOrderImage(order)" class="round4" style="height:100px;width: 100px;" />
            <el-image v-if="!order.image && order.workid" fit="scale-down" :src="worksHost + 'work/action/download/gridy/size/8/shape/none/workid/' + order.workid + '/' + order.workid + '.png?ver=' + order.update_at" class="round4" style="height:120px;width: 120px;" />
          </div>
          <div class="float-left padding-top12 margin-left12 line-height-20" style="width: calc(100% - 140px)">
            <el-row class="ellipsis white ellipsis">
              {{ order.name }}
            </el-row>
            <el-row>
              <span class="color-99">{{ order.info }}</span><br>
              {{ 'x' + order.num }}
            </el-row>
          </div>
        </div>
        <div v-if="order.brick_orderid && order.suites">
          <div v-for="(ord, index) in order.suites" :key="index" class="block-round margin-bottom8" style="height:120px;">
            <div class="float-left" style="height:100px;width: 100px;">
              <el-image v-if="ord.image" fit="scale-down" :src="cdnHost + ord.image + '?ver=1.08'" class="round4" style="height:100px;width: 100px;" />
              <el-image v-if="!ord.image && ord.goods_image" fit="scale-down" :src="ord.goods_image" class="round4" style="height:100px;width: 100px;" />
            </div>
            <div class="float-left padding-top12 margin-left12 line-height-20" style="width: calc(100% - 120px)">
              <el-row class="ellipsis white ellipsis">
                {{ ord.name }}
              </el-row>
              <el-row>
                <span class="color-99">{{ ord.info }}</span><br>
                {{ 'x' + ord.num }}
              </el-row>
            </div>
          </div>
        </div>
        <div class="block-round line-height-20" :style="{'margin-bottom': isDesktop ? '55px' : '90px'}">
          <el-row>
            <el-col :span="8" class="color-99">{{ $t('i.orderAmount') }}</el-col>
            <el-col :span="16" class="align-right">
              ¥{{ formateMoney(order.amount) }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">{{ $t('i.purchaseAuantity') }}</el-col>
            <el-col :span="16" class="align-right">
              {{ getTradeNums(order) }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">{{ $t('i.createTime') }}</el-col>
            <el-col :span="16" class="align-right">
              {{ getDate(order.add_at) }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">{{ $t('i.orderNumber') }}</el-col>
            <el-col :span="16" class="align-right">
              <div class="clipboard" :data-clipboard-text="'B' + order.order_sn" @click="clipboardCopy('order_sn')">
                B{{ order.order_sn }} <i class="iconfont my-copy size16 color-bb" />
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="color-99">{{ $t('i.paymentMethod') }}</el-col>
            <el-col :span="16" class="align-right">
              {{ order.pay_type === 0 ? $t('i.moneyPay') : (order.pay_type === 1 ? $t('i.moneyPay') : (order.pay_type === 2 ? $t('i.wechatPay') : (order.pay_type === 12 ? $t('i.aliPayment') : $t('i.other')))) }}
            </el-col>
          </el-row>
          <el-row v-if="order.pay_status">
            <el-col :span="8" class="color-99">{{ $t('i.paymentTime') }}</el-col>
            <el-col :span="16" class="align-right">
              {{ order.pay_at ? getDate(order.pay_at) : '-' }}
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="config.type === 'brickorder'" class="footer padding-top8" :style="{'position': isDesktop ? 'absolute' : ''}">
        <el-button v-if="order.pay_status === 0" type="primary" class="btn-cancel" style="max-width:200px;" @click="cancelIt(order)">
          {{ $t('i.cancelOrder') }}
        </el-button>
        <el-button v-if="order.pay_status === 0" type="light" class="btn-ok" style="max-width:200px;" @click="payIt(order)">
          {{ $t('i.payNow') }}
        </el-button>
        <el-button v-if="order.pay_status > 0 && order.delivery_status && !order.receipt_status" type="light" class="btn-ok" style="max-width:200px;" @click="receiptIt(order)">
          {{ $t('i.confirmReceipt') }}
        </el-button>
        <el-button v-if="order.pay_status > 0" type="light" class="btn-ok" style="max-width:200px;" @click="openPaperViewer()">
          {{ $t('i.obtainDrawings') }}
        </el-button>
      </div>
      <div v-if="config.type === 'toBrickfy' && step !== 1" class="align-center padding-top24 padding-bottom24">
        <el-row class="padding4">
          <div class="padding8">
            <div v-if="!simpleMode" class="padding-bottom12">
              <el-radio-group v-model="colorfyId" @input="() => emit('traceEvent', ['changeColorfyId', '', { colorfyId: colorfyId }])">
                <el-radio-button v-for="(item, k) in colorfyDt" :key="k" :label="k" class="opacity-bg">{{ item.name }}</el-radio-button>
              </el-radio-group>
            </div>
            <div :style="carouselStyle">
              <div class="twenty-images">
                <TwentyTwenty :offset="offset" :before="cdnHost + 'images/' + guideDir + '/origin.jpg?ver=1.08'" :after="'images/' + guideDir + '/3_' + (colorfyId || 'dance') + '.jpg?ver=1.08'" />
              </div>
            </div>
          </div>
        </el-row>
        <el-row class="padding12 margin-bottom16">
          <div class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">{{ $t('i.uploadTips') }}</div>
            <el-button type="light" style="width: 175px;" @click="startNow">{{ $t('i.startNow') }}</el-button>
          </div>
          <div class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">{{ $t('i.paperTips') }}</div>
            <el-button type="green" style="width: 85px;padding: 10px;" @click="onOpenSelecter({ 'title': $t('i.paper'), 'table': 'paper', 'keyfield': 'paperid', 'maxSelectCount': 20, 'simple': false, 'showActions': false, 'batchDelete': true }, true)">{{ $t('i.paper') }}</el-button>
            <el-button type="green" style="width: 85px;padding: 10px;" @click="onOpenSelecter({ 'title': $t('i.sn'), 'table': 'sn', 'keyfield': 'snid', 'maxSelectCount': 1, 'simple': true, 'leftBack': true }, true)">{{ $t('i.sn') }}</el-button>
          </div>
          <!-- <div class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">这里可以免费制作自己的像素画、像素头像。</div>
            <el-button type="yellow" style="width: 175px;" @click="gotoEditer">在线创作</el-button>
          </div>
          <div class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">已经购买了吗？请输入系列号获取图纸吧。</div>
            <el-button type="green" style="width: 175px;" @click="reqSN">获取图纸</el-button>
          </div> -->
          <div v-if="!simpleMode" class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">{{ $t('i.haveTips') }}</div>
            <el-button type="yellow" style="width: 175px;" @click="gotoStore(colorfyId)">{{ $t('i.buyNow') }}</el-button>
          </div>
          <!-- <div class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">大额优惠券等着你哦，快来领取把。</div>
            <el-button type="green" style="width: 175px;" @click="openSelecter({ 'title': $t('i.getCoupons'), 'table': 'coupon', 'keyfield': 'coupon_id', 'maxSelectCount': 1, 'simple': true, 'leftBack': true }, true)">领取优惠券</el-button>
          </div> -->
        </el-row>
      </div>
      <div v-if="config.type === 'toBrickfy' && step === 1" class="footer padding-top8" :style="{'position': isDesktop ? 'absolute' : ''}">
        <el-button type="primary" class="btn-cancel" style="max-width:200px;" @click="step = 0">
          {{ $t('i.back') }}
        </el-button>
        <el-button type="light" class="btn-ok" style="max-width:200px;" @click="openLocalImages('brick', colorfyId, cols, rows)">
          {{ $t('i.uploadPhoto') }}
        </el-button>
      </div>
      <div v-if="config.type === 'toBrickfy' && step === 1" class="align-center padding-top12 padding-bottom24">
        <el-row v-if="!isDesktop" style="line-height: 60px;">
          {{ $t('i.uploadTips') }}
        </el-row>
        <el-row style="margin:0 auto;" :style="{'width': isDesktop ? '80%' : 'min(90vh,90vw)'}">
          <el-col :span="12" class="padding12">
            <img :src="cdnHost + 'images/' + guideDir + '/0.jpg?ver=1.08'" width="100%" class="round8">
          </el-col>
          <el-col :span="12" class="padding12">
            <img :src="cdnHost + 'images/' + guideDir + '/1_' + colorfyId + '.jpg?ver=1.08'" width="100%" class="round8">
          </el-col>
        </el-row>
        <el-row style="margin:0 auto;" :style="{'width': isDesktop ? '80%' : 'min(90vh,90vw)'}">
          <el-col :span="12" class="padding12">
            <img :src="cdnHost + 'images/' + guideDir + '/2.jpg?ver=1.08'" width="100%" class="round8">
          </el-col>
          <el-col :span="12" class="padding12">
            <img :src="cdnHost + 'images/' + guideDir + '/3_' + colorfyId + '.jpg?ver=1.08'" width="100%" class="round8">
          </el-col>
        </el-row>
        <el-row style="height: 45px;">
          {{ $t('i.cropTips') }}
        </el-row>
      </div>
      <div v-if="config.type === 'toPixel' || config.type === 'toBeanPixel'" class="align-center padding-top24 padding-bottom12">
        <el-row>
          <div class="padding8">
            <div :style="carouselStyle">
              <el-carousel trigger="click" indicator-position="none" :height="carouselHeight + 'px'">
                <el-carousel-item v-for="img in pixelImages" :key="img">
                  <img :src="img" :style="{'width': carouselHeight + 'px', 'height': carouselHeight + 'px'}" class="round8">
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </el-row>
        <el-row class="padding12 margin-bottom16">
          <div class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">{{ $t('i.aiBeanPixelTips') }}</div>
            <el-button type="light" style="width: 175px;" @click="openLocalImages">{{ $t('i.startNow') }}</el-button>
          </div>
          <div class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">{{ $t('i.beanPaperTips') }}</div>
            <el-button type="green" style="width: 175px;" @click="emit('openResource', ['work'])">{{ $t('i.myWorks') }}</el-button>
          </div>
          <div class="align-left" style="width: 330px;margin: 0 auto;">
            <div class="line-height-25">{{ $t('i.editerTips') }}</div>
            <el-button type="yellow" style="width: 175px;" @click="gotoEditer()">{{ $t('i.editer') }}</el-button>
          </div>
        </el-row>
      </div>
      <div v-if="config.type === 'toCartoon'" class="align-center padding-top24 padding-bottom12">
        <el-row class="padding-top24">
          <img src="@/assets/logo-s.png" :title="$t('siteName')">
        </el-row>
        <el-row class="padding12">
          <span class="opacity-bg color-cc padding8 round4 size12">{{ $t('i.makeCarton') }}</span>
        </el-row>
        <el-row>
          <div class="padding8">
            <div :style="carouselStyle">
              <el-carousel trigger="click" indicator-position="none" :height="carouselHeight + 'px'">
                <el-carousel-item v-for="img in cartoonImages" :key="img">
                  <img :src="img" :style="{'width': carouselHeight + 'px', 'height': carouselHeight + 'px'}" class="round8">
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </el-row>
        <el-row class="padding12" style="margin-bottom: 56px;">
          <el-dropdown split-button type="light" trigger="click" class="dropdown-button" @click="createCartoon" @command="(e) => paint.cartoonStyleid = e">
            {{ $t('i.uploadPhoto') }}
            <el-dropdown-menu slot="dropdown" class="my-dropdown">
              <el-dropdown-item v-for="(item, i) in cartoonStyles" :key="i" :command="i">
                {{ item[1] }}
                <span v-if="paint.cartoonStyleid - i === 0" class="float-right align-right iconfont my-check" />
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button type="yellow" style="width: 155px;margin-left: 8px;" @click="gotoEditer()">{{ $t('i.editer') }}</el-button>
        </el-row>
      </div>
      <div v-if="config.type === 'toBrickArt'" class="align-center padding-top24 padding-bottom12">
        <el-row class="padding-top24">
          &nbsp;
        </el-row>
        <el-row>
          <div class="padding8">
            <div :style="carouselStyle">
              <el-carousel trigger="click" indicator-position="none" :height="carouselHeight + 'px'">
                <el-carousel-item v-for="img in brickartImages" :key="img">
                  <img :src="img" :style="{'width': carouselHeight + 'px', 'height': carouselHeight + 'px'}" class="round8">
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </el-row>
        <el-row class="padding12" style="margin-bottom: 56px;">
          <div class="align-left" style="width: 300px;margin: 0 auto;">
            <div class="line-height-25">{{ $t('i.reqSNForPaper') }}</div>
            <el-button type="green" style="width: 155px;" @click="reqSN">{{ $t('i.obtainDrawings') }}</el-button>
          </div>
          <div class="align-left" style="width: 300px;margin: 0 auto;">
            <div class="line-height-25">{{ $t('i.haveMagicPuzzle') }}</div>
            <el-button type="yellow" style="width: 155px;" @click="gotoStore()">{{ $t('i.buyNow') }}</el-button>
          </div>
        </el-row>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import 'vue-twentytwenty/dist/vue-twentytwenty.css'
import TwentyTwenty from 'vue-twentytwenty'
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import GRIDY from '@/js/sdk/GridySDK'
import conf from '@/js/conf/conf'
import service from '@/js/service'
export default {
  components: {
    TwentyTwenty
  },
  mixins: [mixins],
  props: {
    feedback: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    const form = {
      phone: '',
      code: '',
      password: '',
      repassword: '',
      username: '',
      nickname: '',
      sex: 1,
      checked: 0,
      realname: '',
      idcard: ''
    }
    const resolutions = [['768:768', '方图', 'my-ratio-1-1'], ['768:1024', '竖图', 'my-ratio-3-4'], ['1024:768', '横图', 'my-ratio-4-3']]
    const stylesConf = [
      ['000', '不限定风格', 'cos://000/01/00/14/10208.png'],
      ['201', '动漫风格', 'cos://000/01/00/14/10200.png'],
      ['301', '游戏卡通', 'cos://000/01/00/14/10199.png'],
      ['114', '科幻风格', 'cos://000/01/00/14/10188.png'],
      ['113', '赛博朋克', 'cos://000/01/00/14/10138.png'],
      ['102', '概念艺术', 'cos://000/01/00/14/10180.png'],
      ['115', '黑暗艺术', 'cos://000/01/00/14/10166.png'],
      ['112', '黑白素描', 'cos://000/01/00/14/10167.png'],
      ['110', '2.5D人像', 'cos://000/01/00/14/10169.png'],
      ['111', '肖像画', 'cos://000/01/00/14/10201.png'],
      ['109', '印象派', 'cos://000/01/00/14/10176.png'],
      ['107', '插图', 'cos://000/01/00/14/10207.png']
    ]
    const styles = []
    for (const i in stylesConf) {
      styles.push([stylesConf[i][0], stylesConf[i][1], service.getCosUrl(stylesConf[i][2], 'paint')])
    }
    const cartoonStylesConf = [
      ['000', '不限定风格', 'cos://000/01/00/14/10208.png'],
      ['201', '日系动漫', 'cos://000/01/00/14/10138.png'],
      ['202', '美系动漫', 'cos://000/01/00/14/10199.png'],
      ['203', '唯美古风', 'cos://000/01/00/14/10200.png'],
      ['106', '水彩画', 'cos://000/01/00/14/10188.png']
    ]
    const cartoonStyles = []
    for (const i in cartoonStylesConf) {
      cartoonStyles.push([cartoonStylesConf[i][0], cartoonStylesConf[i][1], service.getCosUrl(cartoonStylesConf[i][2], 'paint')])
    }
    const paint = {
      total: 120,
      largeMod: false,
      thumbFactor: this.state.platform.type === 'desktop' ? 1 : 0.75,
      placeholder: '请输入或选择效果关键词',
      tags: ['青春', '可爱', '英俊', '少年', '长发', '城市', '乡村', '吊带', '女生', '机甲', '呆萌', '校服', '废墟', '草地', '太阳镜', '棒球帽', '大眼睛', '戴帽子', '短发', '唯美', '马尾辫', '科幻', '星际', '萝莉', '毛茸茸', '布偶', '武侠风', '细节丰富', '照片质感', '自然风光', '面容精致', '高对比度', '天空晴朗', '瀑布河流', '风景如画', '乡间小路', '电影效果'],
      content: '',
      file: null,
      largeInput: false,
      resolutionid: 0,
      resolution: '768:768',
      styleid: 0,
      cartoonStyleid: 0
    }
    const brickfyImages = [service.getCosUrl('cos://brick_03.png', 'assets'), service.getCosUrl('cos://brick_02.png', 'assets'), service.getCosUrl('cos://brick_01.png', 'assets')]
    const pixelImages = [service.getCosUrl('cos://bean_01.png', 'assets', '1.02'), service.getCosUrl('cos://bean_02.png', 'assets', '1.02'), service.getCosUrl('cos://bean_03.png', 'assets', '1.02'), service.getCosUrl('cos://bean_04.png', 'assets', '1.02'), service.getCosUrl('cos://bean_05.png', 'assets', '1.02'), service.getCosUrl('cos://bean_06.png', 'assets', '1.02')]
    const brickartImages = [service.getCosUrl('cos://brickart_001.png', 'assets'), service.getCosUrl('cos://brickart_002.png', 'assets'), service.getCosUrl('cos://brickart_003.png', 'assets'), service.getCosUrl('cos://brickart_004.png', 'assets'), service.getCosUrl('cos://brickart_005.png', 'assets')]
    const cartoonImages = [service.getCosUrl('cos://carton_02.jpg', 'assets'), service.getCosUrl('cos://carton_03.jpg', 'assets'), service.getCosUrl('cos://carton_01.jpg', 'assets')]
    const rules = {
      email: [{ trigger: 'blur', validator: this.validEmail }],
      phone: [{ trigger: 'blur', validator: this.validPhone }],
      code: [{ trigger: 'blur', validator: this.validCode }],
      username: [{ trigger: 'blur', validator: this.validUsername }],
      password: [{ trigger: 'blur', validator: this.validPassword }],
      repassword: [{ trigger: 'blur', validator: this.validRepassword }],
      nickname: [{ trigger: 'blur', validator: this.validNickname }],
      realname: [{ trigger: 'blur', validator: this.validRealname }],
      idcard: [{ trigger: 'blur', validator: this.validIdcard }],
      checked: [{ trigger: 'blur', validator: this.validChecked }]
    }
    let container_width
    if (this.state.platform.type === 'desktop') {
      container_width = 632
    } else {
      container_width = utils.width()
    }
    const data = {}
    data.GRIDY = null
    data.brickfyImages = brickfyImages
    data.pixelImages = pixelImages
    data.brickartImages = brickartImages
    data.cartoonImages = cartoonImages
    data.resolutions = resolutions
    data.styles = styles
    data.cartoonStyles = cartoonStyles
    data.paint = paint
    data.imageSize = 120
    data.maxImageWidth = container_width * 0.8
    data.orderTypes = conf.orderTypes()
    data.worksHost = conf.hosts().worksHost
    data.platform = utils.platform()
    data.apiHost = conf.hosts().apiHost
    data.mainHost = conf.hosts().mainHost
    data.cdnHost = conf.hosts().cdnHost
    data.downloadUrl = conf.hosts().downloadUrl
    data.storeHost = conf.hosts().storeHost
    data.params = utils.getHashParams()
    data.ver = conf.ver
    data.settings = {
      agreement: '',
      privacy: ''
    }
    data.step = 0
    data.publish = {}
    data.order = {}
    data.publishInfo = ''
    data.form = form
    data.rules = rules
    data.countDownNum = 0
    data.countDownIt = null
    data.colorfyDt = conf.colorfyDt()
    data.colorfyId = 'dance'
    data.cols = 0
    data.rows = 0
    data.offset = this.state.language !== 'zh' ? 0.5 : 0.4
    // defaultSkuId 8000=乐高式积木拼图 9000=百乐酷积木拼图 10001=串联式积木拼图 10002=钻石拼图
    data.defaultSkuId = ''
    return data
  },
  computed: {
    simpleMode() {
      return this.params.referrer === 'sn'
    },
    guideDir() {
      return 'guide' + (this.state.language !== 'zh' ? '_en' : '')
    },
    reqId() {
      return this.state.reqId
    },
    config() {
      return this.view.popPage
    },
    types() {
      return this.config.types()
    },
    powers() {
      return this.state.powers || {}
    },
    series() {
      return this.state.series || {}
    },
    publishes() {
      return this.state.publishes || {}
    },
    publishid() {
      return this.view.popPage.publishid
    },
    orderid() {
      return this.view.popPage.orderid
    },
    skuId() {
      return this.view.popPage.skuId || this.defaultSkuId
    },
    title() {
      let title = this.config.title
      if (!title && this.config.type) {
        for (const k in this.types) {
          if (this.config.type === this.types[k][0]) {
            title = this.types[k][1]
            break
          }
        }
      }
      return title
    },
    txtCount() {
      return (this.paint.total * 2 - utils.strLength(this.paint.content)) + '/' + this.paint.total * 2
    },
    carouselHeight() {
      return 320
    },
    carouselStyle() {
      const style = {}
      style.margin = '0 auto'
      style.width = this.carouselHeight + 'px'
      style.height = this.carouselHeight + 'px'
      return style
    },
    containerStyle() {
      const style = {
        display: 'inline-table',
        width: '100%',
        'background-size': 'cover'
      }
      let img = ''
      let pos = ''
      if (this.config.type === 'toBrickfy') {
        pos = 'top'
        img = 'sky0/ft.jpg'
      } else if (this.config.type === 'toPixel' || this.config.type === 'toBeanPixel') {
        pos = 'center top'
        img = 'images/sky_bg.jpg'
      } else if (this.config.type === 'toCartoon' || this.config.type === 'toBrickArt') {
        pos = 'left top'
        img = 'images/sky_bg.jpg'
      }
      if (img) style['background-image'] = 'url(' + this.cdnHost + img + ')'
      if (pos) style['background-position'] = pos
      return style
    },
    source() {
      return this.state.source
    },
    paintPrice() {
      return conf.paintConsume[this.state.userGroupId] || 90
    },
    canPaint() {
      const quantity = (this.source[10007] && this.source[10007].quantity) ? this.source[10007].quantity : 0
      return quantity >= this.paintPrice
    }
  },
  watch: {
    'paint.content': {
      handler() {
        if (utils.strLength(this.paint.content) > this.paint.total) {
          this.paint.content = utils.getString(this.paint.content, this.paint.total)
        }
      }
    }
  //   'view.popPage.show': {
  //     handler() {
  //       // if (this.$refs['formWidget']) this.$refs['formWidget'].resetFields()
  //       if (this.view.popPage.show) {
  //         if (this.view.popPage.type === 'agreement' || this.view.popPage.type === 'privacy' || this.view.popPage.type === 'nftnotice') {
  //           this.getSettings(this.view.popPage.type)
  //           if (this.view.popPage.type === 'nftnotice') this.getPublish()
  //         } else if (this.view.popPage.type === 'nftorder') {
  //           this.getOrder(this.view.popPage.type)
  //         } else if (this.view.popPage.type === 'publishInfo' || this.view.popPage.type === 'publishCert') {
  //           this.getPublish()
  //         } else if (this.view.popPage.type === 'publishPower') {
  //           this.getSettings('powernotice')
  //           this.getPublishPower()
  //         }
  //         this.update()
  //       }
  //     }
  //   }
  },
  mounted() {
    this.step = 0
    if (this.view.popPage.type === 'agreement' || this.view.popPage.type === 'privacy' || this.view.popPage.type === 'nftnotice') {
      this.getSettings(this.view.popPage.type)
      if (this.view.popPage.type === 'nftnotice') this.getPublish()
    } else if (this.view.popPage.type === 'nftorder' || this.view.popPage.type === 'brickorder') {
      this.getOrder(this.view.popPage.type)
    } else if (this.view.popPage.type === 'publishInfo' || this.view.popPage.type === 'publishCert') {
      this.getPublish()
    } else if (this.view.popPage.type === 'publishPower') {
      this.getSettings('powernotice')
      this.getPublishPower()
    } else if (this.view.popPage.type === 'toBrickfy') {
      const data = this.view.popPage.data || {}
      // URL 传递重要参数示例：/#/?mod=popPage&type=toBrickfy&inviteid=1&cols=[48]&rows=[48]&skuId=[10001|10002]&referrer=[sn]&colorfy=[dance|classic|golden]&reqLogin=true&snid=1&sn=9012312321
      // referrer=sn 表示扫系列号进入该页面，cols、rows 可以指定像素画长度和高度，colorfy可指定默认配色, skuId 指定是10001积木画还是10002钻石画, reqLogin 指定是否要求登录（未实现），sn为系列号
      const params = utils.getHashParams()
      this.colorfyId = data.colorfy || params.colorfy || 'dance'
      this.cols = data.cols || params.cols || 0
      this.rows = data.rows || params.rows || 0
      /*
      if (params.limit) {
        // 系列号已被限制已使用
        this.alert(this.$t('i.snLimit'))
      // } else {
        // 自动绑定激活系列号
        // if (params.sn && this.loginUserId) {
        //   this.emit('activeSN', [params.sn])
        // }
      }
      */
      if (params.sn) {
        this.emit('SNLogin', [params.sn, true])
      }
    } else if (this.view.popPage.type === 'paint') {
      if (!this.loginUserId) this.emit('login')
      if (this.view.popPage.data) {
        setTimeout(() => {
          this.initPaint(this.view.popPage.data.paint)
        }, 100)
      }
      this.getSource()
    }
  },
  methods: {
    titleCase: utils.titleCase,
    reqSN() {
      const next = (status, dataSN) => {
        this.colorfyId = dataSN.colorfy || 'dance'
        this.cols = dataSN.cols || 0
        this.rows = dataSN.rows || 0
        this.step = 1
        if (this.config.type === 'toBrickArt') {
          if (!dataSN.albumid && !dataSN.workid) {
            // service.removeSN()
            this.emit('reqSN', [0, '', '', next])
          } else if (dataSN.workid) {
            const data = {
              userid: this.loginUserId,
              workid: dataSN.workid,
              publishid: 0
            }
            this.emit('openBrickyViewer', [{ data: data }])
          } else if (dataSN.albumid) {
            this.goto('albumWorks', { albumid: dataSN.albumid, typeid: 1 })
          }
        }
      }
      this.emit('reqSN', [0, '', '', next])
    },
    getOrderImage(item) {
      if (!item || !item.image) return ''
      if (item.image.substr(0, 6) === 'cos://') {
        item.image = item.image.replace('public/order/', '')
        return service.getCosUrl(item.image, 'order', '') || ''
      } else {
        return item.image
      }
    },
    // 获取晶钻
    getSource() {
      this.emit('getSource', [(dt, type) => {
        this.update()
      }, 'coin'])
    },
    gotoEditer() {
      this.emit('traceEvent', ['popPage_gotoEditer', '', { type: this.config.type }])
      // const search = utils.param2Obj(window.location.search)
      // if (!this.loginStatus && search.td_channelid && search.td_channelid.indexOf('baidu') >= 0) {
      //   this.message('请先登录，再开始创作像素画！', 'login')
      //   return
      // }
      // if (!this.loginStatus) {
      //   this.message('请先登录，再生成拼图！', 'login')
      //   return
      // }
      this.close()
      this.goto('editer')
    },
    gotoWorks(classid = 1) {
      this.emit('traceEvent', ['popPage_gotoWorks', '', { type: this.config.type }])
      this.close()
      this.emit('gotoWorks', [classid])
    },
    getSkuName(goodsSkuId, simple = false) {
      if (simple) {
        if (goodsSkuId === '10002') {
          return this.$t('i.diamondPuzzle')
        } else {
          return this.$t('i.blockPuzzle')
        }
      }
      return this.$t('productTypes.' + goodsSkuId) || this.$t('i.blockPuzzle')
    },
    // 下单
    orderGoods(goods) {
      this.emit('traceEvent', ['orderGoods', '', {}])
      if (!this.loginStatus) {
        this.message('', 'login')
        return
      }
      const getGoodsTmp = (goodsDt, skuId) => {
        const goodsTmp = utils.deepClone(goodsDt)
        if (goodsTmp.cart[skuId]) {
          goodsTmp.num = goodsTmp.cart[skuId]
          goodsTmp.goodsSkuId = skuId
          goodsTmp.price = goodsTmp.goods_price_max * 100
          goodsTmp.info = goodsTmp.cols + 'x' + goodsTmp.rows + this.getSkuName(skuId)
          delete goodsTmp.cart
          return goodsTmp
        }
      }
      // 构造数据
      const suites = []
      if (utils.isArray(goods)) {
        for (const i in goods) {
          goods[i].addCart = true
          goods[i].isGoods = true
          goods[i].workid = 0
          goods[i].id = goods[i].goods_id
          goods[i].goodsId = goods[i].goods_id
          delete goods[i].goods_id
          Object.values(conf.puzzleSkusArr).map((skuId) => {
            const goodsTmp = getGoodsTmp(goods[i], skuId)
            if (goodsTmp) suites.push(goodsTmp)
          })
        }
      }
      this.emit('openOrderMng', [{ act: 'create', data: suites }])
      // this.emit('openOrderMng', [{ act: 'create', data: [{ isGoods: true, goods: goods, isSuite: false, type: '', bgId: '', colorfyId: '', frameId: '', hideColors: true, work: '', imageUrl: '', name: '' }] }])
    },
    // 开始
    startNow() {
      this.step = 1
    },
    gotoStore(colorfyId = '') {
      // 多彩的不能购买
      if (colorfyId === 'colors') colorfyId = ''
      const config = { 'title': this.$t('i.goodsList'), 'table': 'goods', 'keyfield': 'goods_id', 'maxSelectCount': 10, 'tab': colorfyId || 'dance', 'tabs': [['dance', this.$t('colorfy.dance')], ['classic', this.$t('colorfy.classic')], ['golden', this.$t('colorfy.golden')]], 'simple': true, 'leftBack': true }
      config.sifts = []
      config.sift = '1'
      const arr = utils.deepClone(conf.puzzleSkusArr).reverse()
      Object.keys(arr).map((i) => {
        // 部分开放
        if (arr[i] >= 10001) config.sifts.push([arr[i], this.$t('productTypes.' + arr[i])])
        if (this.skuId + '' === arr[i]) {
          config.sift = i
        }
      })
      config.fn = this.orderGoods
      this.emit('openResourceSelecter', [config])
      // 去商城
      // window.openLink(this.storeHost)
    },
    openLocalImages(diyType = '', colorfyId = '', cols = 64, rows = 64) {
      this.emit('traceEvent', ['popPage_clickUpload', '', { type: this.config.type, diyType: diyType, colorfyId: colorfyId, skuId: this.skuId, isPixel: true }])
      this.emit('openLocalImages', ['open', false, diyType, colorfyId, cols, rows, false, true, this.skuId, true])
    },
    createCartoon() {
      if (!this.loginStatus) {
        this.message('', 'login')
        return
      }
      this.state.openLocalImages.callback = (file) => {
        this.emit('loading', [true, this.$t('i.rending')])
        this.GRIDY = new GRIDY()
        const name = 'AI_Cartoon_' + utils.getFileName(file.name)
        this.GRIDY.compressImage(name, window.URL.createObjectURL(file), (objData) => {
          let style = '000'
          if (this.cartoonStyles[this.paint.cartoonStyleid]) {
            style = this.cartoonStyles[this.paint.cartoonStyleid][0]
            this.state.paint.cartoonStyleid = this.paint.cartoonStyleid
          }
          const data = {
            name: name,
            info: name,
            // 来源；0=文生图; 1=图生图;
            origin: 1,
            resolution: '768:1024',
            input_image: objData.base64,
            style: style
          }
          service.post('paint', data, (dt, type) => {
            if (type === 'success') {
              if (dt.data.images) {
                this.emit('openResource', ['paint'])
                // this.emit('openGridyViewer', [{ data: { images: [service.getCosUrl(dt.data.images, 'paint', dt.data.id)], names: [name], idx: 0, type: 'imagesViewer' }}])
              }
              service.incCount(this.loginUserId, 'paint')
              if (dt.data.consumeNums) {
                setTimeout(() => {
                  this.alert(this.$t('i.consumeMoney', { consumeNums: dt.data.consumeNums }))
                }, 1000)
              }
            } else {
              if (type === 'login') {
                this.message(dt, type)
              } else {
                this.alert(dt)
              }
            }
            this.emit('loading', [false])
          }, true)
        }, 768, 768, 'base64')
      }
      this.emit('openLocalImages', ['paint'])
    },
    initPaint(conf = {}) {
      this.paint.content = conf.Prompt || ''
      if (conf.Styles) {
        for (const i in this.styles) {
          if (this.styles[i][0] === conf.Styles) {
            this.paint.styleid = i
            break
          }
        }
      } else if (this.state.paint.styleid) {
        this.paint.styleid = this.state.paint.styleid
      }
      this.paint.styleid = parseInt(this.paint.styleid)
      if (conf.Resolution) {
        for (const i in this.resolutions) {
          if (this.resolutions[i][0] === conf.Resolution) {
            this.paint.resolutionid = i
            break
          }
        }
      } else if (this.state.paint.resolutionid) {
        this.paint.resolutionid = this.state.paint.resolutionid
      }
      this.paint.resolutionid = parseInt(this.paint.resolutionid)
    },
    addPaintContent(tag) {
      this.paint.content = utils.trim(this.paint.content, true)
      this.paint.content = (this.paint.content ? this.paint.content + '，' : '') + tag
    },
    requestAiPaint() {
      if (!this.loginStatus) {
        this.message('', 'login')
        return
      }
      if (utils.getLen(this.paint.content) < 8) {
        this.alert('效果描述至少需要8个字')
        return
      }
      let style = '000'
      if (this.styles[this.paint.styleid]) {
        style = this.styles[this.paint.styleid][0]
        this.state.paint.styleid = this.paint.styleid
      }
      let resolution = '000'
      if (this.resolutions[this.paint.resolutionid]) {
        resolution = this.resolutions[this.paint.resolutionid][0]
        this.state.paint.resolutionid = this.paint.resolutionid
      }
      const data = {
        name: this.paint.content,
        info: this.paint.content,
        resolution: resolution,
        style: style
      }
      this.aiPaint(data, this.paint.content)
    },
    aiPaint(data, name) {
      this.emit('loading', [true, this.$t('i.rending')])
      service.post('paint', data, (dt, type) => {
        if (type === 'success') {
          if (dt.data.images) {
            this.emit('openGridyViewer', [{ data: { images: [service.getCosUrl(dt.data.images, 'paint', dt.data.id)], names: [name], idx: 0, type: 'imagesViewer' }}])
          }
          service.incCount(this.loginUserId, 'paint')
          if (dt.data.consumeNums) {
            setTimeout(() => {
              this.alert(this.$t('i.consumeMoney', { consumeNums: dt.data.consumeNums }))
            }, 1000)
          }
        } else {
          if (type === 'login') {
            this.message(dt, type)
          } else {
            this.alert(dt)
          }
        }
        this.emit('loading', [false])
      }, true)
    },
    validEmail(rule, value, cb) {
      if (!utils.checkNow('email', value, true)) {
        cb(new Error(this.$t('i.reqCorrectEmail')))
      } else {
        let checkEmail = value + ''
        checkEmail = utils.hintEmail(checkEmail)
        if (this.config.data.email && this.config.data.email !== checkEmail) {
          cb(new Error(this.$t('i.reqEmail') + ' ' + this.config.data.email))
        } else {
          cb()
        }
      }
    },
    validPhone(rule, value, cb) {
      if (!utils.checkNow('mobile', value, true)) {
        cb(new Error(this.$t('i.reqCorrectPhone')))
      } else {
        let checkMobile = value + ''
        checkMobile = checkMobile.slice(0, 3) + '****' + checkMobile.slice(-4)
        if (this.config.data.mobile && this.config.data.mobile !== checkMobile) {
          cb(new Error(this.$t('i.reqPhone') + ' ' + this.config.data.mobile))
        } else {
          cb()
        }
      }
    },
    validCode(rule, value, cb) {
      if (!utils.checkNow('str-6-6', value, true)) {
        cb(new Error(this.$t('i.reqMcode')))
      } else {
        cb()
      }
    },
    validChecked(rule, value, cb) {
      if (!value) {
        cb(new Error(this.$t('i.agreeFirst')))
      } else {
        cb()
      }
    },
    validPassword(rule, value, cb) {
      if (this.config.type === 'setPayPassword') {
        if (!utils.checkNow('paypassword', value, true)) {
          cb(new Error(this.$t('i.tradePWDTips')))
        } else {
          cb()
        }
      } else {
        if (!utils.checkNow('strongpassword', value, true)) {
          cb(new Error(this.$t('i.pwdTips')))
        } else {
          cb()
        }
      }
    },
    validRepassword(rule, value, cb) {
      if (!value) {
        cb(new Error(this.$t('i.reqCheckPWD')))
      } else if (value !== this.form.password) {
        cb(new Error(this.$t('i.pwdCheckFail')))
      } else {
        cb()
      }
    },
    validUsername(rule, value, cb) {
      if (!utils.checkNow('username', value, true)) {
        cb(new Error(this.$t('i.usernameTips')))
      } else {
        cb()
      }
    },
    validNickname(rule, value, cb) {
      if (!utils.checkNow('nickname', value, true)) {
        cb(new Error(this.$t('i.nicknameTips')))
      } else {
        cb()
      }
    },
    validRealname(rule, value, cb) {
      if (!utils.checkNow('str2-4-18', value, true)) {
        cb(new Error(this.$t('i.reqRealname')))
      } else {
        cb()
      }
    },
    validIdcard(rule, value, cb) {
      if (!utils.checkNow('idcard', value, true)) {
        cb(new Error(this.$t('i.idcardErr')))
      } else {
        cb()
      }
    },
    // 粘贴板
    clipboardCopy(type) {
      let msg = ''
      if (type === 'order_sn') msg = this.$t('i.orderCopyOk')
      // eslint-disable-next-line
      var clipboard = new ClipboardJS('.clipboard')
      clipboard.on('success', (e) => {
        e.clearSelection()
        if (msg) this.message(msg, 'success')
        clipboard.destroy()
      })
    },
    handle(act, data) {
      this.emit(act, data)
    },
    getTradeNums(order) {
      let num = 0
      if (order.diy) {
        num = order.num
      }
      if (order.suites && order.suites.length) {
        for (const i in order.suites) {
          num = num + order.suites[i].num
        }
      }
      return num
    },
    getDate(time) {
      if (!time) return ''
      return utils.date('datetime', 0, new Date(time * 1000))
    },
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    formateMoney: utils.formateMoney,
    close() {
      if (this.config.type === 'toBrickfy' && this.step === 1) {
        this.step = 0
        return
      }
      const types = []
      for (const i in this.types) {
        types.push(this.types[i][0])
      }
      if (this.config.from && this.config.from.indexOf(types) >= 0) {
        this.config.type = this.config.from
        this.config.from = ''
        return
      }
      this.closePop(this.config)
      // if (this.config.fromUrl) this.goto()
    },
    // 发送反馈
    sendFeedback() {
      if (!utils.checkNow('str2-4-225', this.feedback.title, true)) return this.message(this.$t('i.reqTopic'), 'error')
      if (!utils.checkNow('str2-4-2048', this.feedback.content, true)) return this.message(this.$t('i.reqContent'), 'error')
      if (!utils.checkNow('str2-4-20', this.feedback.name, true)) return this.message(this.$t('i.reqName'), 'error')
      if (!utils.checkNow('str2-4-100', this.feedback.contact, true)) return this.message(this.$t('i.reqContact'), 'error')
      this.feedback.platform = this.platform.value
      this.feedback.ver = conf.ver
      this.config.loading = true
      const data = utils.deepClone(this.feedback)
      delete data.typeOpts
      service.post('feedback', data, (msg, type) => {
        if (type === 'success') {
          msg = msg.msg || this.$t('i.sendSuccess')
          this.feedback.title = ''
          this.feedback.content = ''
        }
        this.message(msg, type)
        this.config.loading = false
      })
    },
    calcWorkSize(w, h) {
      if (w < this.imageSize && h < this.imageSize) return { width: w, height: h }
      let width, height
      if (w > h) {
        width = this.imageSize
        height = Math.round(width * h / w)
      } else {
        height = this.imageSize
        width = Math.round(height * w / h)
      }
      return { width: width, height: height }
    },
    imgStyle(work) {
      if (!work) return {}
      const size = this.calcWorkSize(work.width, work.height)
      const left = (this.imageSize - size.width) / 2
      const top = (this.imageSize - size.height) / 2
      return { 'padding-left': left + 'px', 'padding-top': top + 'px' }
    },
    // 领取优惠券
    receiveCoupon(coupon_id) {
      if (!coupon_id) return
      service.receiveCoupon(coupon_id, (dt, type) => {
        if (type === 'success') {
          this.alert(this.$t('i.reveiveOk'), type)
        } else {
          if (dt) this.alert(dt, type)
        }
      })
    },
    // 打开选择器
    onOpenSelecter(config, reqLogin = false) {
      if (reqLogin && !this.loginUserId) return this.message('', 'login')
      this.emit('openSelecter', [config])
    },
    // 打开选择器
    openSelecter(config, reqLogin = false) {
      if (reqLogin && !this.loginUserId) return this.message('', 'login')
      config.fn = (items) => {
        if (config.table === 'coupon') {
          if (items && items[0] && items[0].coupon_id) {
            this.receiveCoupon(items[0].coupon_id)
          }
        }
      }
      this.emit('openResourceSelecter', [config])
    },
    // 取消订单
    cancelIt(order) {
      this.confirm(this.$t('i.ifCancelOrder'), (e) => {
        if (e === 'cancel') return
        let resource = ''
        let orderid = 0
        if (this.config.type === 'nftorder') {
          resource = 'nft_order'
          orderid = order.nft_orderid
        } else if (this.config.type === 'brickorder') {
          resource = 'brick_order'
          orderid = order.brick_orderid
        }
        service.put(resource, orderid, { type: 'cancel' }, (dt, type) => {
          if (type === 'success' && dt && dt.data && dt.data.rows) {
            this.message(this.$t('i.orderCancel'), type)
            this.order.pay_status = -2
            this.update()
            this.view.nftorder.renew = utils.time('time')
            this.view.order.renew = utils.time('time')
            setTimeout(this.close, 100)
          } else {
            this.message(dt, type)
          }
        }, true)
      })
    },
    // 订单支付
    payIt(order) {
      const pay_cb = (status) => {
        if (status) {
          this.order.pay_status = 1
          this.update()
        }
      }
      if (this.config.type === 'nftorder') {
        if ((order.type === 1 && order.preorder_status === 1) || order.type === 2 || order.type === 3) {
          this.showPayment(order, pay_cb)
        }
      } else {
        this.showPayment(order, pay_cb)
      }
    },
    // 确认收货
    receiptIt(order) {
      service.receipt(order.order_id, (dt, type) => {
        if (type === 'success') {
          order.receipt_status = 1
          this.alert(this.$t('i.confirmReceive'))
          this.update()
        } else {
          this.message(dt, type)
        }
      })
    },
    openPaperViewer() {
      this.getOrder('brickorder', (order) => {
        if (order && order.data && order.data.work) {
          this.emit('openPaperViewer', [{ data: { colorfyId: order.data.colorfyId, frameId: order.data.frameId, hideColors: order.data.hideColors, work: order.data.work, imageUrl: order.image, name: order.data.work.name }}])
          setTimeout(this.close, 2000)
        }
      }, 0)
    },
    showPayment(order, cb) {
      this.view.pop.title = this.$t('i.pay')
      this.view.pop.tip = ''
      this.view.pop.type = this.config.type === 'nftorder' ? 'payment_nft' : 'payment_brick'
      this.view.pop.data = order || {}
      this.view.pop.show = true
      this.view.pop.fn = cb
    },
    getOrder(type, cb, simple = 1) {
      if (type === 'nftorder') {
        service.get('nft_order', this.orderid, (dt, type) => {
          if (type === 'success' && dt.count) {
            this.order = dt.items[0]
            if (this.order.publishid && dt.publishes) this.order.publish = dt.publishes[this.order.publishid] || {}
            if (this.order.workid && dt.works) this.order.work = dt.works[this.order.workid] || {}
            if (this.order.publish && this.order.publish.userid && dt.users) this.order.user = dt.users[this.order.publish.userid] || {}
          }
          this.update()
        }, true, '', false)
      } else if (type === 'brickorder') {
        service.get('brick_order', this.orderid, (dt, type) => {
          if (type === 'success' && dt && dt.data && dt.data.items) {
            if (cb) {
              cb(dt.data.items[0])
            } else {
              this.order = dt.data.items[0]
            }
          }
          this.update()
        }, true, { simple: simple }, false)
      }
    },
    // 获取配置信息
    getSettings(tag) {
      service.get('settings', tag, (dt, type) => {
        if (type === 'success') {
          this.settings[tag] = dt.data.value || ''
          this.update()
        }
      }, false, '', true)
    },
    // 获取藏品信息
    getPublish(cb) {
      this.publish = {}
      if (this.publishes[this.publishid]) {
        this.publish = this.publishes[this.publishid]
        this.update()
        cb && cb(this.publish)
        return
      }
      service.get('publish', this.publishid, (dt, type) => {
        if (type === 'success') {
          this.publish = dt.data
        }
        this.update()
        cb && cb(this.publish)
      }, false, '', false)
    },
    // 获取藏品权益
    getPublishPower() {
      this.getPublish((publish) => {
        if (publish && Array.isArray(publish.powers)) {
          const ids = []
          for (var idx in publish.powers) {
            if (!this.state.powers[publish.powers[idx]]) ids.push(publish.powers[idx])
          }
          if (!ids.length) return
          service.batchGet('power', ids.join(','), (dt, type) => {
            if (type === 'success') {
              if (dt.data && dt.data.items) {
                for (var i in dt.data.items) {
                  this.state.powers[dt.data.items[i].powerid] = dt.data.items[i]
                }
              }
            }
            this.update()
          }, false, '', false)
        }
      })
    },
    // 检查更新
    checkUpdate() {
      this.emit('checkUpdate')
    },
    // type 验证类别；0=注册; 1=登录; 2=实名认证; 3=二次认证;
    sendSMS() {
      this.validPhone('', this.form.phone, (err) => {
        if (err) {
          this.message(err.message, 'error')
        } else {
          if (this.config.type === 'setPassword') return this.sendDzqSMS('reset_pwd')
          if (this.config.type === 'setPayPassword') return this.sendDzqSMS('reset_pay_pwd')
          if (this.config.type === 'unbindPhone') return this.sendDzqSMS('verify')
          if (this.config.type === 'rebindPhone') return this.sendDzqSMS('rebind')
          if (this.config.type === 'bindPhone') return this.sendDzqSMS('bind')
          let type = 3
          if (this.config.type === 'realVerify') type = 2
          this.emit('sendSMS', [type, this.form.phone])
        }
      })
    },
    // 发送DZQ验证码
    sendDzqSMS(type) {
      this.validPhone('', this.form.phone, (err) => {
        if (err) {
          this.message(err.message, 'error')
        } else {
          this.emit('sendDzqSMS', [type, this.form.phone])
        }
      })
    },
    // type 验证类别；0=注册; 1=登录; 2=实名认证; 3=二次认证;
    sendEmail(type = '') {
      this.validEmail('', this.form.email, (err) => {
        if (err) {
          this.message(err.message, 'error')
        } else {
          if (this.config.type === 'setPassword') return this.sendDzqEmail('reset_pwd')
          if (this.config.type === 'setPayPassword') return this.sendDzqEmail('reset_pay_pwd')
          if (this.config.type === 'unbindEmail') return this.sendDzqEmail('verify')
          if (this.config.type === 'rebindEmail') return this.sendDzqEmail('rebind')
          if (this.config.type === 'bindEmail') return this.sendDzqEmail(type || 'bind')
        }
      })
    },
    // 发送DZQ邮件验证码
    sendDzqEmail(type) {
      this.validEmail('', this.form.email, (err) => {
        if (err) {
          this.message(err.message, 'error')
        } else {
          this.emit('sendDzqEmail', [type, this.form.email])
        }
      })
    },
    // 分享、邀请
    share() {
      // eslint-disable-next-line
      var clipboard = new ClipboardJS('.clipboard')
      clipboard.on('success', (e) => {
        e.clearSelection()
        this.message(this.$t('i.inviteLink'), 'success')
        clipboard.destroy()
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.config.type === 'setPassword') {
            const setPasswordCb = (dt, type) => {
              if (type === 'success') {
                this.alert(this.$t('i.pwdChanged'))
                this.form.code = ''
                this.form.password = ''
                this.form.repassword = ''
                this.closePop(this.config)
                this.emit('logout', [true])
                this.goto('index')
                this.emit('login')
              } else {
                this.message(dt, type)
              }
            }
            if (this.state.supportMobile) {
              service.setPassword(this.form.phone, this.form.code, this.form.password, setPasswordCb)
            } else {
              service.setEmailPassword(this.form.email, this.form.code, this.form.password, setPasswordCb)
            }
          } else if (this.config.type === 'setPayPassword') {
            const setPayPasswordCb = (dt, type) => {
              if (type === 'success') {
                this.message(this.$t('i.tradePwdChanged'), type)
                this.form.code = ''
                this.form.password = ''
                this.form.repassword = ''
                this.view.account.renew = utils.time('time')
                this.closePop(this.config)
              } else {
                this.message(dt, type)
              }
            }
            if (this.state.supportMobile) {
              service.setPayPassword(this.form.phone, this.form.code, this.form.password, this.form.repassword, setPayPasswordCb)
            } else {
              service.setEmailPayPassword(this.form.email, this.form.code, this.form.password, this.form.repassword, setPayPasswordCb)
            }
          } else if (this.config.type === 'bindPhone' || this.config.type === 'rebindPhone') {
            const bindPhoneCb = (dt, type) => {
              if (type === 'success') {
                this.emit('renewToken', ['phone'])
                this.message(this.$t('i.bindOk'), type)
                this.form.phone = ''
                this.form.code = ''
                this.view.account.renew = utils.time('time')
                this.closePop(this.config)
              } else {
                this.message(dt, type)
              }
            }
            if (this.config.type === 'bindPhone') {
              service.smsBind(this.form.phone, this.form.code, bindPhoneCb)
            } else {
              service.smsRebind(this.form.phone, this.form.code, bindPhoneCb)
            }
          } else if (this.config.type === 'unbindPhone') {
            service.smsVerify(this.form.phone, this.form.code, (dt, type) => {
              if (type === 'success') {
                this.config.type = 'rebindPhone'
                this.config.data.mobile = ''
                this.form.phone = ''
                this.form.code = ''
                this.state.countDownNum = 0
                if (this.state.countDownIt) {
                  clearInterval(this.state.countDownIt)
                  this.state.countDownIt = null
                }
              } else {
                this.message(dt, type)
              }
            })
          } else if (this.config.type === 'bindEmail' || this.config.type === 'rebindEmail') {
            const bindEmailCb = (dt, type) => {
              if (type === 'success') {
                this.emit('renewToken', ['email'])
                this.message(this.$t('i.bindOk'), type)
                this.form.email = ''
                this.form.code = ''
                this.view.account.renew = utils.time('time')
                this.closePop(this.config)
              } else {
                this.message(dt, type)
              }
            }
            if (this.config.type === 'bindEmail') {
              service.emailBind(this.form.email, this.form.code, bindEmailCb)
            } else {
              service.emailRebind(this.form.email, this.form.code, bindEmailCb)
            }
          } else if (this.config.type === 'unbindEmail') {
            service.emailVerify(this.form.email, this.form.code, (dt, type) => {
              if (type === 'success') {
                this.config.type = 'rebindEmail'
                this.config.data.email = ''
                this.form.email = ''
                this.form.code = ''
                this.state.countDownNum = 0
                if (this.state.countDownIt) {
                  clearInterval(this.state.countDownIt)
                  this.state.countDownIt = null
                }
              } else {
                this.message(dt, type)
              }
            })
          } else if (this.config.type === 'realVerify') {
            const params = {}
            params.type = 'verify'
            params.phone = this.form.phone
            params.code = this.form.code
            params.realname = this.form.realname
            params.identity = this.form.idcard
            service.actionPut('user', params, (dt, type) => {
              if (type === 'success') {
                this.message(this.$t('i.realCommited'), type)
                this.form.phone = ''
                this.form.code = ''
                this.view.account.renew = utils.time('time')
                this.closePop(this.config)
              } else {
                this.message(dt, type)
              }
            }, true)
          }
        }
      })
    }
  }
}
</script>
