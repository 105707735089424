<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'album-' + mod + '-container'" :ref="'album-' + mod + '-container'" :style="{height: 'calc(100% - 50px)'}" class="my-list" :top-load-method="(loaded) => loadData(loaded, showUp ? 'up' : 'renew')" :bottom-load-method="(loaded) => loadData(loaded, 'down')" @infinite-scroll="getData('down')">
      <div class="flow-container">
        <div class="column" :style="{'width': '100%'}">
          <div v-for="(item, i) in data.items" :key="i" class="item">
            <div class="card" :style="{ 'width': cardWidth + 'px', 'height': '35px' }">
              <div class="top" @click="setAlbum(item)">
                <div class="left">
                  {{ item.name }} <i v-if="!item.public" class="iconfont my-lock gray size14" /> <span v-if="item.original" class="tag">{{ $t('i.origin') }}</span> <span v-if="item.best" class="tag">{{ $t('i.recomman') }}</span> <span v-if="!item.check" class="tag bg-red white">{{ $t('i.lockUp') }}</span>
                </div>
                <div class="right" style="padding-right:5px;">
                  <i class="el-icon-success gray size24" :class="{'blue': albumid === item.albumid}" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div v-if="(!itemNums && !loading[mod])" class="empty" style="margin-top: 20px">
        <i class="iconfont my-empty" />
        <br><span class="gray">{{ $t('i.createAlbum') }}</span>
      </div>
    </pull-to>
    <div v-if="!(showInput || !itemNums)" class="input auto_height" :style="{'position': (isDesktop || isTablet) ? 'absolute' : ''}" @click="showInput=true">
      <div class="blue" style="padding-top: 14px;">
        {{ $t('i.addNewAlbum') }}
      </div>
    </div>
    <div v-if="showInput || !itemNums" class="input auto_height" :style="{'position': (isDesktop || isTablet) ? 'absolute' : ''}">
      <div class="left">
        <el-input v-model.trim="name" type="input" maxlength="20" :placeholder="$t('i.addAlbumTips')" clearable />
      </div>
      <div class="right">
        <el-button class="tap-btn" @click="createaAlbum">
          <i class="iconfont my-check" :class="{ 'on': name }" />
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/album'
import utils from '@/js/utils'
import conf from '@/js/conf/conf'
import service from '@/js/service'
export default {
  mixins: [mixins],
  data() {
    const data = {}
    data.apiHost = conf.hosts().apiHost
    data.albumid = 0
    data.name = ''
    data.showInput = false
    return data
  },
  methods: {
    setAlbum(item) {
      this.albumid = item.albumid
      this.emit('setAlbum', [item.albumid, item.name])
    },
    createaAlbum() {
      if (!utils.checkNow('filename', this.name, true)) return this.message(this.$t('i.titleTips'), 'error')
      service.post('album', { name: this.name, info: this.name }, (dt, type) => {
        if (type === 'success') {
          if (dt.data.id) {
            const time = utils.time('time')
            const item = {
              albumid: dt.data.id,
              userid: this.loginUserId,
              catalogid: 0,
              name: this.name,
              public: 1,
              original: 0,
              best: 0,
              check: 1,
              flag: 1,
              add_at: time,
              update_at: time
            }
            this.addItem(item)
            this.setAlbum(item)
          }
          this.name = ''
        } else {
          this.message(dt, type)
        }
      }, true)
    }
  }
}
</script>
