/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2023-2-20
 * @copyright Gridy.Art
 */
import i18n from '../../lang'
import BRICK from '../palette/BRICK.js'
import HDD from '../palette/HDD.js'
import KK from '../palette/KK.js'
import MARD from '../palette/MARD.js'
import MM from '../palette/MM.js'
import MXW from '../palette/MXW.js'
import PP from '../palette/PP.js'
import XW from '../palette/XW.js'
const extendPalettes = {
  'BRICK': BRICK,
  'MARD': MARD,
  'HDD': HDD,
  'KK': KK,
  'MM': MM,
  'MXW': MXW,
  'PP': PP,
  'XW': XW
}
Object.keys(extendPalettes).map((k) => {
  extendPalettes[k].ids = {}
  extendPalettes[k].colors = {}
  Object.values(extendPalettes[k].data).map((color) => {
    extendPalettes[k].ids[color.hex] = color.id
    extendPalettes[k].colors[color.id] = color.hex
  })
})
// eslint-disable-next-line
const $t = (key, value) => i18n.t(key, value)
const ALL_COLORS = BRICK.data
const getDarkenedColor = function(color) {
  // eslint-disable-next-line
  const arr = hexToRgb(color)
  const rgb = arr.map((color) => Math.round((color * Math.PI) / 4))
  // eslint-disable-next-line
  return rgbToHex(...rgb).toUpperCase()
}
const whiteBrickIds = [
  'H7',
  '4',
  '5',
  '6',
  '8',
  '17',
  '18',
  '20',
  '29',
  '30',
  '31',
  '34',
  '35',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52'
]
// 缺失颜色：
const lostBricky = {
  '#AC5017': '17',
  '#FFE840': '80',
  '#FFEE7D': '21',
  '#F9CD00': '22',
  '#E9E1DA': '81'
}
const brickfyGrayIds = ['1', '2', '3', '4', '5', '6']
// eslint-disable-next-line
const brickfyColorsIds = ['9', '10', '11', '13', '15', '17', '18', '6']
// const brickfyColorsIds = ['9', '10', '11', '13', '14', '15', '17', '18', '6']
// const brickfyColorsIds = ['9', '11', '13', '14', '15', '17', '18', '6']
const danceReplace = {
  '#FEFBF5': '#FEFBF5',
  '#E6E6E4': '#FFE155',
  '#ADADB0': '#FFCC00',
  '#838588': '#DC6AA0',
  '#616160': '#005FAD',
  '#212121': '#111111'
}
const classicReplace = {
  '#FEFBF5': '#DACBBE',
  '#E6E6E4': '#CAB5A2',
  '#ADADB0': '#BA9E85',
  '#838588': '#663651',
  '#616160': '#174664',
  '#212121': '#111111'
}
const goldenReplace = {
  '#FEFBF5': '#FFE155',
  '#E6E6E4': '#FFCC00',
  '#ADADB0': '#FFAA00',
  '#838588': '#B15218',
  '#616160': '#482413',
  '#212121': '#111111'
}
// eslint-disable-next-line
const colorsReplace = {
  '#FEFBF5': '#FEFBF5',
  '#D92817': '#D92817',
  '#FFCC00': '#FFCC00',
  '#00A73C': '#00A73C',
  '#0086C1': '#0086C1',
  '#111111': '#111111'
}
// eslint-disable-next-line
const pinkReplace = {
  '#FEFBF5': '#FEFBF5',
  '#E6E6E4': '#FFE155',
  '#ADADB0': '#FFCC00',
  '#838588': '#DC6AA0',
  '#616160': '#9C0A67',
  '#212121': '#111111'
}
// 修正颜色限制数量：在颜色列表中的多给300颗
const limitColorsConf = {
  fixNums: 300,
  fixColors: {
    'dance': ['#FEFBF5', '#FFCC00', '#111111'],
    'classic': ['#DACBBE', '#BA9E85', '#111111'],
    'golden': ['#FFE155', '#FFAA00', '#111111']
  }
}
const brickfyGray = {}
const brickfyColors = {}
const brickfy = {}
const brickfyArr = []
const darkenedBrickfy = {}
Object.values(ALL_COLORS).map((color) => {
  color.hex = color.hex.toUpperCase()
  brickfyArr.push(color.hex)
  brickfy[color.id] = color.hex
  darkenedBrickfy[color.id] = getDarkenedColor(color.hex)
  if (brickfyColorsIds.indexOf(color.id) > -1) brickfyColors[color.id] = color.hex
  if (brickfyGrayIds.indexOf(color.id) > -1) brickfyGray[color.id] = color.hex
  // brickfyColors[color.id] = color.hex
})
const main = {
  names: () => {
    const arr = {
      // base: $t('paletteName.base'),
      brickfy: $t('paletteName.brickfy')
      // brickfyColors: $t('paletteName.brickfyColors'),
      // skin: $t('paletteName.skin'),
      // dance: $t('colorfy.dance'),
      // classic: $t('colorfy.classic'),
      // golden: $t('colorfy.golden'),
      // colors: $t('colorfy.colors'),
      // gray: $t('paletteName.gray')
      // metals: $t('paletteName.metals')
    }
    Object.keys(extendPalettes).map((k) => {
      if (!extendPalettes[k].hide) arr[k] = $t('extendPalettes.' + k)
    })
    return arr
  },
  colorfy: () => {
    return {
      dance: { name: $t('colorfy.dance'), palette: danceReplace },
      classic: { name: $t('colorfy.classic'), palette: classicReplace },
      // pink: { name: $t('colorfy.pink'), palette: pinkReplace },
      golden: { name: $t('colorfy.golden'), palette: goldenReplace },
      colors: { name: $t('colorfy.colors'), palette: colorsReplace }
    }
  },
  frames: frames,
  base: ['#000000', '#ffffff', '#eeece1', '#e2534d', '#f9974c', '#ffc12a', '#9aba60', '#47acc5', '#1e497b', '#956FE7', '#808080', '#f2f2f2', '#ddd8c3', '#f2dbdb', '#fee9da', '#fff2cc', '#eaf1de', '#d9eef3', '#c6d9f0', '#f0edf6', '#696969', '#dbdbdb', '#b6b29f', '#d5b6b6', '#e9c9b1', '#e5d4a3', '#cad4ba', '#b3d0d7', '#a1b4cc', '#d0c3dc', '#525252', '#c4c4c4', '#8f8c7b', '#b89291', '#d5a988', '#cbb77a', '#abb796', '#8eb2bb', '#7c90a8', '#b099c3', '#3b3b3b', '#adadad', '#696657', '#9c6e6c', '#c18960', '#b29a51', '#8b9a73', '#68949f', '#576c84', '#906faa', '#242424', '#969696', '#424033', '#7f4a47', '#ad6937', '#987d28', '#6c7d4f', '#437683', '#324860', '#704591'],
  brickfy: brickfy,
  brickfyArr: brickfyArr,
  darkenedBrickfy: darkenedBrickfy,
  brickfyGray: brickfyGray,
  dance: Object.values(danceReplace),
  classic: Object.values(classicReplace),
  golden: Object.values(goldenReplace),
  colors: brickfyColors,
  brickfyColors: brickfyColors,
  skin: ['#ffe0bd', '#ffdbac', '#ffcd94', '#eac086', '#e0ac69', '#f1c27d', '#ffad60', '#c68642', '#8d5524', '#765339', '#613D24', '#4C2D17', '#391E0B', '#2D1304', '#180A01', '#090300'],
  gray: ['#F0F0F0', '#E0E0E0', '#D0D0D0', '#C0C0C0', '#B0B0B0', '#A0A0A0', '#909090', '#808080', '#707070', '#606060', '#505050', '#404040', '#303030', '#202020', '#101010', '#000000'],
  metals: ['#000000', '#996614', '#946D0A', '#A5790B', '#B8870C', '#BF9325', '#C69E3B', '#784F11', '#848A99', '#848588', '#939598', '#A4A6AA', '#ADAFB2', '#B5B7B9', '#595D66', '#6B3805', '#643A00', '#704100', '#7D4900', '#8A5B1A', '#956B31', '#4D2804', '#4D4D4D', '#6A6A68', '#767674', '#848482', '#90908E', '#9B9B99', '#363636', '#B36452', '#924F39', '#A3583F', '#B56246', '#BD7259', '#C4806A'],
  // colors: ['#D5B69F', '#E3D3C5', '#FFFBF5', '#653350', '#144463', '#151515'],
  extendPalettes: extendPalettes,
  lostBricky: lostBricky,
  whiteBrickIds: whiteBrickIds,
  limitColorsConf: limitColorsConf
}
Object.keys(extendPalettes).map((k) => {
  main[k] = Object.values(extendPalettes[k].colors)
})
export default main
