<template>
  <div class="padding-left8 padding-right8">
    <div v-for="(item, idx) in items" :key="idx">
      <el-row class="item bg-28 round8 padding-top12 padding-bottom12 margin-bottom8">
        <el-col :span="4" class="avatar align-center" style="max-width: 80px;">
          <div @click="emit('goto', ['user', { userid: item.user_id } ])">
            <span v-if="!(users[item.user_id] && users[item.user_id].avatar)" class="el-avatar el-avatar--circle noavatar medium">
              <i class="iconfont my-man" />
            </span>
            <el-avatar v-if="(users[item.user_id] && users[item.user_id].avatar)" :size="32" :src="getAvatar(item.user_id)" />
          </div>
        </el-col>
        <el-col :span="20">
          <el-row class="top">
            <el-col :span="16" class="name padding-top8">
              <div @click="emit('goto', ['user', { userid: item.user_id } ])">{{ users[item.user_id] ? users[item.user_id].nickname : view.anonymity }}
                <span v-if="mod === 'comment' && file.userid === item.user_id" class="tag bg-22 margin-left8">{{ $t('i.author') }}</span>
                <span v-if="(state.workCount.thread_userid === item.user_id || threadCount[item.thread_id] && threadCount[item.thread_id].thread_userid === item.user_id)" class="tag bg-22 margin-left8">{{ $t('i.landlord') }}</span>
                <span v-if="mod === 'reply' && data.thread && data.thread.user_id === item.user_id" class="tag bg-22 margin-left8">{{ $t('i.floorMaster') }}</span>
              </div>
            </el-col>
            <el-col :span="8" class="float-right align-right">
              <el-button class="btn" @click="() => praisePost(item.id, item.thread_id)">
                <i class="iconfont" :class="{ 'my-praise-fill': state.likeState[item.id], 'my-praise-blank': !state.likeState[item.id] }" /> {{ state.likeCount[item.id] ? formateNums(state.likeCount[item.id]) : '' }}
              </el-button>
              <el-button class="btn" @click="showActionSheet(item.user_id, item.id, item.thread_id, file.postid === item.id)">
                <i class="iconfont my-dot-more-v" />
              </el-button>
            </el-col>
          </el-row>
          <el-row class="txt" v-html="getContent(item)" />
          <el-row class="foot">
            <el-button v-if="(!(idx === 0 && mod === 'reply' && data.thread && data.thread.user_id))" class="padding-4-8 size12" @click="reply(item.id, users[item.user_id] ? users[item.user_id].nickname : view.anonymity)"> {{ state.replyCount[item.id] ? formateNums(state.replyCount[item.id]) : '' }} {{ $t('i.replies') }} <i class="iconfont my-right size12" /></el-button>
            {{ formateTime(item.created_at) }}
          </el-row>
          <el-row v-if="item.replies && item.replies.length" class="rep">
            <div @click="() => showReply(item.id, users[item.user_id] ? users[item.user_id].nickname : view.anonymity, item.reply_count)">
              <div v-for="(reply, i) in item.replies" :key="i" v-html=" '<b>' + (users[reply.user_id] ? users[reply.user_id].nickname : view.anonymity) + ': </b>' + getContent(reply)" />
              <div v-if="item.reply_count"><b>{{ $t('i.replyCount', { reply_count: formateNums(item.reply_count) }) }}</b></div>
            </div>
          </el-row>
        </el-col>
      </el-row>
      <div v-if="idx === 0 && mod === 'reply' && data.thread && data.thread.user_id && item.reply_count" class="padding12 margin-bottom8">
        {{ $t('i.allReply') }}
      </div>
    </div>
    <div v-if="items.length && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
    <div v-if="items.length > 5 && data.noDownPage" class="tip">{{ $t('i.showAll') }}</div>
    <el-empty v-if="(!items.length && !loading[mod])" :image="sofaImage" :description="$t('i.replyTips')" />
  </div>
</template>
<script>
import { mixins } from '@/mixins/comment'
export default {
  mixins: [mixins]
}
</script>
