export default {
  domain: 'Grid.Art',
  siteName: 'Grid.Art',
  copyRight: '©2025 Grid.Art',
  siteIntro: 'Founded in 2015, focuses on developing <br>a pixel art content ecosystem, creating pixel IPs, <br>and promoting pixel culture. We offer cross-platform <br>leading tools for pixel art, brick puzzles, <br>diamond puzzles, bead mosaics, and 3D voxel <br> modeling. Leveraging AI and blockchain technology, <br>we aim to build an integrated platform for content <br>creation, sharing, distribution, copyright protection, <br>commercial applications, and a blended digital-physical <br>content ecosystem.',
  siteTips: 'We provide pixel art, brick puzzles, diamond puzzles, <br>and bead mosaic creation tools, dedicated to building<br> a digital content ecosystem and Web3.0 community.',
  contactInfo: 'Company: Gridance Network Technology Co., Ltd.<br>Address: Fuzhou High-Tech Zone, Fujian, China.<br> Contact: Mr. Wu<br>Wechat: dreawu<br>Email: 389193@qq.com',
  currency: '￥',
  productTypes: {
    '8000': 'LEGO Puzzle',
    '9000': 'Bricky Puzzle',
    '10001': 'Tandem Puzzle',
    '10002': 'Diamond Puzzle',
    '11': 'Diamond UniPanda',
    '12': 'Graffiti UniPanda',
    '13': 'Fluid UniPanda',
    '14': 'UniPanda Ornament',
    '15': 'UniPanda Garage Kit',
    '0': 'Block Puzzle',
    '1': 'Diamond Puzzle',
    '99': 'Other'
  },
  tabNames: {
    'draft': 'My Draft',
    'works': 'My Workks',
    'mynft': 'My NFT',
    'sellnft': 'Selling NFT',
    'find': 'Discover',
    'creator': 'Creator',
    'myassets': 'My Assets',
    'assets': 'Public Assets',
    'setting': 'Setup',
    'msg': 'Message',
    'fans': 'Fans',
    'worth': 'Property',
    'orders': 'Order',
    'faq': 'Quick Start',
    'hotkeys': 'Hot Key List',
    'update': 'Check Update',
    'feedback': 'Feedback',
    'aboutus': 'About Us',
    'login': 'Sign In'
  },
  i: {
    aiPixelPuzzle: 'Ai Pixel Puzzle',
    zoomin: 'Zoom In',
    zoomout: 'Zoom Out',
    sizeSmall: 'Smaller Grid Size',
    sizeBig: 'Larger Grid Size',
    moveYLeft: 'Move Y-axis left',
    moveYRight: 'Move Y-axis right',
    moveXUp: 'Move X-axis upwards',
    moveXDown: 'Move X-axis downwards',
    sizeExceeds: 'The current row or column of the work exceeds {size}',
    previewFirst: 'Please preview before submitting!',
    optimizeColors: 'Denoise',
    showAllLines: 'Grids',
    previewEffect: 'Preview',
    toGridy: 'To Gridy',
    gridSize: 'Grid Size',
    offsetX: 'Offset X',
    offsetY: 'Offset Y',
    viewSite: 'Visit Grid.Art',
    realTips: 'Tips: <br>1. We will collect your name and ID number for real-name verification. <br>2. After successful verification, we will provide complete services and experiences.',
    hours: 'Hours',
    minute: 'Minutes',
    aMomentAgo: 'A Moment Ago',
    minuteAgo: '1 Minute Ago',
    minutesAgo: 'Minutes Ago',
    hourAgo: '1 Hours Ago',
    hoursAgo: 'Hours Ago',
    yesterday: 'Yesterday',
    beforeYesterday: 'Before Yesterday',
    daysAgo: 'Days Ago',
    weekAgo: 'Weekes Ago',
    beanPuzzle: 'Bean Puzzle',
    blockPuzzle: 'Block Puzzle',
    diamondPuzzle: 'Diamond Puzzle',
    my: 'My',
    your: 'Your',
    yTopics: 'Topic',
    yReplies: 'Comment',
    reqConten: 'Please enter content',
    discard: 'Discard',
    donateLock: 'Donate(after {donateLockSeconds})',
    donateLocking: 'Can be Donate after {donateLockSeconds}',
    setPayPWDFirst: 'Please set the trade password first',
    realVerifyFirst: 'Pass ID Verification first',
    loginAgain: 'Please Sign In Again',
    opend: 'Opened',
    noItems: 'You don\'t have {name} yet',
    waitCheck: 'Pending Audit',
    checkErr: 'Audit Failed',
    waitingPay: 'Pending Payment',
    payOk: 'Paid',
    payErr: 'Payment Failed',
    sendOk: 'Successfully sent',
    puzzleBOM: 'Puzzle Parts List',
    virtualPuzzle: 'Virtual Puzzle',
    powerBy: 'Power By Grid.Art All rights reserved',
    paperPowerBy: 'Power By Grid.Art All rights reserved',
    moreGridy: 'For more Gridy resources, please visit: https://www.grid.art/',
    newTxt: 'New Txt',
    clickEdit: 'Double-click to edit...',
    scanForPixel: 'Scan QR code to experience massive pixel art',
    broswerPixel: 'Visit Grid.Art to experience massive pixel art',
    POP: 'POP',
    mergerObj: 'Merge Object',
    free: 'Free',
    goodsList: 'Goods List',
    copyy: 'Copy',
    orderDetail: 'Order Details',
    reqOrderAddress: 'Please select a shipping address',
    goodsDetail: 'Goods Details',
    customized: 'Customize',
    total: 'Total',
    deliveryFee: 'Shipping Fee',
    freeShipping: 'Free Shipping',
    notRange: 'Not within the shipping range',
    coupons: 'Coupons',
    getCoupons: 'Get Coupons',
    clickGet: '(Click to Claim)',
    maxDiscount: 'Enjoyed the maximum discount,',
    couponNums: 'Coupons',
    noCoupons: 'No available coupons',
    vipDiscount: 'Member Discount',
    orderNotes: 'Order Notes',
    optional: 'Optional',
    payAmount: 'Amount',
    submitOrder: 'Submit Order',
    noOpen: 'This customized product is currently not available!',
    outRange: 'Sorry, your shipping address is not within the delivery range',
    reqGoods: 'Please select the product you want to purchase',
    orderErr: 'Unable to place an order, please return and try again!',
    simpleWhite: 'Simple White',
    simpleBlack: 'Simple Black',
    whiteFrame: 'White Frame',
    blackFrame: 'Black Frame',
    originalBg: 'Original Background',
    black: 'Black',
    white: 'White',
    lightGray: 'Light Gray',
    lightGreen: 'Light Green',
    green: 'Green',
    blue: 'Blue',
    red: 'Red',
    yellow: 'Yellow',
    apricot: 'Apricot',
    pink: 'Pink',
    darkGray: 'Dark Gray',
    base: 'Base',
    orderDetails: 'Order Details',
    NFTOrderDetails: 'NFT Order Details',
    NFTIntro: 'NFT Introduction',
    NFTBenefits: 'NFT Benefits',
    NFTCert: 'NFT Cert',
    NFTIPS: 'NFT Notes',
    bindNewMobile: 'Bind New Mobile',
    bindNewEmail: 'Bind New Email',
    verifyOldMobile: 'Verify Old Mobile',
    verifyOldEmail: 'Verify Old Email',
    changeLoginPWD: 'Change Password',
    changeTradePWD: 'Change Trade Password',
    hall: 'Hall',
    warehouse: 'Warehouse',
    backpack: 'Backpack',
    withDrawaList: 'Withdrawal Records',
    payRecords: 'Balance Records',
    visiter: 'Visitor',
    acceTask: 'Acceptable Tasks',
    myTask: 'My Tasks',
    newUser: 'New Users',
    saleCalendar: 'Release Calendar',
    sale: 'Release',
    add: 'Add',
    creat: 'Create',
    user: 'Users',
    aiPuzzle: 'AI Pixel Puzzle',
    vPuzzle: 'Super Puzzle',
    recommendTopic: 'Recommend Topics',
    newTopic: 'New Topics',
    pubTime: 'Publish time',
    updateTime: 'Update time',
    new: 'Latest',
    atMen: 'Want @',
    topics: 'Topics',
    asset: 'Assetes',
    addres: 'Address',
    subscribe: 'Subscribe',
    purchase: 'Purchase',
    airDrop: 'Airdrop',
    suggestion: 'Suggestion',
    bugFeed: 'Feedback BUG',
    cooperation: 'Cooperation',
    festivals: 'Festival',
    education: 'Education',
    sports: 'Sports',
    healthcare: 'Healthcare',
    clothing: 'Clothing',
    household: 'Household',
    tools: 'Tools',
    toys: 'Toys',
    foods: 'Foods',
    fruit: 'Fruit',
    animals: 'Animals',
    business: 'Business',
    traffic: 'Traffic',
    building: 'Building',
    science: 'Science',
    persons: 'Persons',
    icon: 'Icon',
    squares: 'Square',
    gridyZone: 'Gridy Zone',
    puzzleZone: 'Puzzle Zone',
    NFTZone: 'NFT Zone',
    gameZone: 'Game Zone',
    headlineZone: 'Headline',
    feedbackZone: 'Feedback',
    quickErr: 'Do not operate frequently',
    reqErr: 'Request Error',
    intErr: 'Internal error',
    netErr: 'Network error',
    reqPubContent: 'Please enter content',
    pubQuick: 'Publishing too fast, please try again later',
    reqReplyConetnt: 'Please enter comment content',
    replyQuick: 'Reply too fast, please try again later',
    accountBen: 'Current account has been disabled',
    checkFail: 'Current account has not been approved yet',
    checkFailed: 'Current account has not been approved for review',
    loginSucess: 'Sign in succeeded',
    loginFail: 'Sign in failed, please try again',
    taskCancel: 'Task abandoned',
    ifCancelTask: 'Are you sure to give up this task?',
    taskAccepted: 'Task accepted',
    gain: 'Gain',
    aiLineUping: 'AI painting queuing, please be patient...',
    maxSelectTips: 'Select up to {maxSelectCount} items',
    deleteComp: 'Completely delete',
    updated: 'New Update',
    delSuccess: 'Delete successfully',
    cancelTopUp: 'Cancel Top Up',
    fans: 'Fans',
    discounted: '{discount} discount',
    joinVip: 'Join VIP and enjoy more benefits',
    vipBenefits: 'Comparison of Benefits',
    benefit: 'Benefits',
    withDrawalTips: 'Withdrawal successful, please wait for payment',
    canWithDrawal: 'Withdrawable',
    withDrawalOk: 'Withdrawal successful',
    rechargeOk: 'Recharge successful',
    recharg: 'Recharge',
    origin: 'Original',
    buySucess: 'Purchase successful',
    regularFee: 'Original Price',
    discoun: 'Discount',
    fee: 'Current Price',
    scale: 'Commission',
    haveAd: 'AD',
    waterMark: 'Watermark',
    creatAlbum: 'Create Album',
    maxCooperate: 'Multiple Painting',
    exportAnimation: 'Export Gif',
    exportHigh: 'High Definition Export',
    diyDiscount: 'Product Discounts',
    nftDiscount: 'NFT Discounts',
    advanceService: 'VIP Service',
    offlineActivity: 'Offline Activities',
    signPraise: 'Check In Reward',
    visitCount: 'Visited {count} times',
    verifyed: 'Verifyed',
    pubOrg: 'Issuing Institution',
    cancelFollow: 'Unfollow',
    visit: 'Visit',
    msg: 'Message',
    draf: 'Draft',
    invit: 'Invite',
    newAlbum: 'New Album',
    mine: 'Mine',
    homepage: 'Homepage',
    watch: 'Come And Watch',
    reqReply: 'Leave your wonderful comment now',
    relays: 'Relay',
    praiseCount: 'A total of {liked_count} likes',
    allComment: 'All comments',
    views: 'Views',
    signRuleTips: '1. The continuous check in reward increases daily, and on the 7th day, you can receive the same reward as on the 7th day <br>2. If the check in is interrupted, you can make up for yesterday\'s interruption. Otherwise, it will start over from the first day <br>3. Long term adherence to check in and additional mysterious rewards',
    signCalendar: 'Calendar',
    signTips: 'Checked in continuously for {contue_days} days',
    signTody: 'Checked today',
    notSignTody: 'Not Check In today',
    dayTips: 'Day {continue_days}',
    signed: 'Checked',
    signNow: 'Check In',
    suppleSign: 'Do you want to supplement?',
    suppleSignObtain: 'Check In Prizes',
    signObtain: 'Check In Prizes',
    taskCenter: 'Task Center',
    goFinish: 'GO',
    getPraise: 'Receive',
    receiveTask: 'Accept',
    signRule: 'Rules',
    style: 'Style',
    from: 'Source',
    catalog: 'Catalog',
    sift: 'Sift',
    contactUs: 'Contact Us',
    userFeedback: 'Feedback',
    myMoney: 'My Coin',
    aiCartoon: 'AI Cartoon',
    aiPixel: 'AI Pixel',
    aiBeanPixel: 'AI Bean Drawings',
    allSerivce: 'All Serivces',
    serivce: 'Serivces',
    newCanvas: 'New Canvas',
    interact: 'Interaction',
    download: 'Download',
    favor: 'Favorite',
    praise: 'Praises',
    footprint: 'Footprint',
    yes: 'Yes',
    no: 'No',
    area: 'Region',
    depositBank: 'Bank',
    accountName: 'Name',
    IDNO: 'ID Number',
    note: 'Notes',
    reqBankName: 'Enter bank name',
    reqBankCard: 'Enter your bank Card No.',
    reqAccountName: 'Enter account name',
    reqIDNO: 'Enter ID Number',
    reqNote: 'Enter notes',
    name: 'Name',
    reqRealName: 'Enter your name',
    address: 'Addresss',
    reqAddress: 'Enter address',
    accountNameErr: 'Please enter correct account name',
    IDNOErr: 'Please enter correct ID Number',
    bankNameErr: 'Please enter correct bank name',
    bankCardErr: 'Please enter correct bank Card No.',
    reqArea: 'Please select your region',
    albumIntro: 'Please enter album introduction',
    unlimited: 'Unlimited',
    publishAndShare: 'Publish First Please',
    copyAndShare: 'Link copied successfully, please paste and share',
    usePaperTips: 'Please use this drawing with caution. If particles is not enough, please contact customer service!',
    rendingFail: 'Sorry, please try adjusting the size of the croped face or replacing it with a high-definition photo!',
    haveTips: 'Don\'t you have a universal AI pixel puzzle yet?',
    paperTips: 'Find your saved drawings and serial numbers here',
    editerTips: 'Freely design your own pixel art',
    beanPaperTips: 'Find your pixel art and bean drawings here',
    uploadTips: 'Upload photos and see what your AI pixel puzzle',
    cropTips: 'Tips: Crop large face, The effect will be better',
    startNow: 'Start Now',
    viewDrawings: 'View Drawings',
    recomman: 'Recommend',
    secondCreate: 'Second Create',
    hotValue: 'Heat Value',
    lockUp: 'Lock Up',
    upChain: 'Up Chain',
    copyrights: 'Copyright',
    published: 'Published',
    upload: 'Upload',
    discount: 'Discount',
    coupon: 'Coupon',
    discountValue: '{discount}0% Off',
    overAvailable: 'Available for orders over {min_price}',
    validDay: 'Valid within {expire_day} days',
    collect: 'Collect',
    applicableProducts: 'Applicable: ',
    SNTitle: 'SN: ',
    savePaperTitle: 'Save: ',
    useTimes: 'Usage: ',
    downPaperTitle: 'Download: ',
    useSN: ' Use ',
    increaseFrequency: 'Increase',
    localRestore: 'Local Restore',
    localBackup: 'Local Backup',
    cleanRecycle: 'Empty Recycle Bin',
    startCreate: 'Start Creating',
    settings: 'Settings',
    setAvatar: 'Set Avatar',
    setBg: 'Set Background',
    nickname: 'Nickname',
    sex: 'Gender',
    girl: 'Female',
    boy: 'Male',
    secrecy: 'Secrecy',
    birthday: 'Birthday',
    location: 'Location',
    editProfile: 'Set Introduction',
    profileTips: 'Introduction limit in 120 chars',
    editNick: 'Set Nickname',
    selectSex: 'Choose Gender',
    attachment: 'Attachment',
    selectAiart: 'Select Ai Works',
    publishing: 'Publishing, Please Wait...',
    reqTitle: 'Fill in the title to get more likes (optional)',
    sayWhat: 'Say Something...',
    selectAssets: 'Select Assets',
    uploadAttachment: 'Upload Attachment',
    selectFile: 'Select File',
    selectWorks: 'Select Works',
    selectNFT: 'Select NFT',
    addTitle: 'Add Title',
    cancelTitle: 'Cancel Title',
    publishTo: 'Publish To',
    noRightAttachment: 'Sorry, No attachment permission',
    commonEmojis: 'Common',
    faceEmo: 'Smiling',
    animalNature: 'Animals',
    travel: 'Travel',
    maxAttachment: 'Upload up to {count} attachment',
    maxImg: 'Upload up to {count} image',
    canAttachment: 'Can also upload {count} attachment',
    canImg: 'Can also upload {count} image',
    maxFilesize: 'Filesize exceeds {count}, unable to upload',
    saySome: 'Say something',
    sayMore: 'Say more, there will be more likes',
    toHot: 'Too popular, please republish later',
    pubSuccess: 'Published successfully',
    realCommited: 'Real name information submitted successfully',
    pwdChanged: 'Password has been changed, please sign in again',
    tradePwdChanged: 'Trade password has been changed',
    bindOk: 'Binding successful',
    confirmReceive: 'Confirmed receipt of goods',
    ifCancelOrder: 'Are you sure to cancel the order',
    reveiveOk: 'Received successfully',
    tradePWDTips: 'Trade password is 6-digit number',
    pwdTips: '8-20 chars requiring letters/numbers/special chars',
    reqCheckPWD: 'Enter confirmation password',
    pwdCheckFail: 'Entered passwords differ!',
    usernameTips: '6-18 chars, cannot contain special chars',
    nicknameTips: '6-18 chars, cannot contain special chars',
    reqRealname: 'Enter your real name',
    idcardErr: 'Please enter correct ID number',
    orderCopyOk: 'Order number copied successfully',
    sendSuccess: 'Sent successfully, thank you for your attention',
    snLimit: 'Current serial number has exceeded the usage limit!',
    orderType: 'Order Type',
    orderAmount: 'Order Amount',
    purchaseAuantity: 'Purchase Auantity',
    orderNumber: 'Order Number',
    paymentMethod: 'Payment Method',
    aliPayment: 'Alipay',
    paymentTime: 'Payment Time',
    nftStatus: 'NFT Status',
    distributionFailed: 'Distribution Failed',
    waitingDistribution: 'Waiting Distribution',
    distributionSuccess: 'Distribution Success',
    cancelOrder: 'Cancel Order',
    confirmReceipt: 'Confirm Receipt',
    obtainDrawings: 'Get Drawings',
    uploadImg: 'Upload Photo',
    createPixelHeader: 'Create Pixel Avatar',
    makeCarton: 'Upload photo and generate cartoon',
    reqSNForPaper: 'Enter serial number to get the drawing',
    haveMagicPuzzle: 'Don\'t you have a versatile puzzle yet?',
    issuInfo: 'Issuance Information',
    issuNums: 'Issuance quantity',
    pubSale: 'Public sale',
    priPurchase: 'Priority purchase',
    openSub: 'Open subscription',
    openCast: 'Open Casting',
    freeDrop: 'Free airdrop',
    blindReser: 'Blind box reservation',
    activityReser: 'Activity reservation',
    creatorReser: 'Creator reservation',
    protectReser: 'Copyright protection reservation',
    commercialReser: 'Commercial reservation',
    exhibitionReser: 'Exhibition reservation',
    secondaryReser: 'Secondary Creation reservation',
    gameReser: 'Game application reservation',
    puzzleReser: 'Puzzle toys reservation',
    printingReser: 'Printing reservation',
    commerceReser: 'E-commerce reservation',
    publishingReser: 'Publishing reservation',
    auctionReser: 'Auction reservation',
    purchaseNotes: 'Purchase Notes',
    workIntro: 'Work Introduction',
    workStory: 'Work Story',
    benefits: 'Benefits',
    benefitsInfo: 'Benefits Information',
    longPress: 'Please long press to save the image',
    commitNow: 'Submit',
    realname: 'RealName',
    idcard: 'ID Number',
    state: 'Status',
    noVerify: 'Not certified',
    bind: 'Bind',
    chainAccount: 'Blockchain account address',
    chainBrowser: 'Blockchain Browser',
    classify: 'Type',
    topic: 'Topics',
    reqTopic: 'Enter feedback topic',
    content: 'Content',
    reqContent: 'Enter feedback content',
    linkman: 'Name',
    reqName: 'Enter your name',
    contact: 'Contact',
    reqContact: 'Enter contact information',
    newVer: 'New version available:',
    downloadNow: 'Download Now',
    lastVer: 'Latest Version',
    aiWorks: 'AI Works',
    aiCartoonTips: 'Come and experience AI cartoon characters!',
    aiImgPuzzleTips: 'Come and take a look at the versatile puzzle!',
    aiBeanPixelTips: 'Turn pixel art into bean drawings instantly',
    aiPuzzleTips: 'Come and upload photos to experience the AI pixel puzzle! ',
    checkPWD: 'Re-enter',
    payPWD: 'Password',
    reqNewMobile: 'Enter New Mobile',
    reqNewEmail: 'Enter New Email',
    newMobile: 'New Mobile',
    newEmail: 'New Email',
    verifyCode: 'Code',
    choose: 'Choose',
    ifClean: 'Are you sure to clear the recycle bin?',
    waitPay: 'Awaiting Payment',
    waitSend: 'Processing',
    waitReceive: 'Shipped',
    hadFinish: 'Completed',
    waitReply: 'Awaiting Review',
    myOrders: 'My Orders',
    tradeSuccess: 'Successful trade',
    payFail: 'Payment failed',
    hadCancel: 'Cancelled',
    hadExpires: 'Expired',
    payNow: 'Pay Now',
    waitingDrawLots: 'Waiting for draw',
    limitCount: 'Limited to {total} serving',
    notification: 'Notification',
    interaction: 'Interaction',
    growth: 'Growth',
    diamondLess: 'Diamond Shortage',
    diamond: 'Diamonds',
    crystal: 'Crystals',
    myItems: 'My Items',
    nfts: 'NFT',
    donate: 'Donate',
    donateNums: 'Transfer Quantity',
    receiverNick: 'Receiver nickname',
    receiverAddress: 'Receiver Address',
    reqReceiverAddress: 'Enter receiver address',
    openin: 'Open',
    reqChainAdd: 'Please enter correct email or blockchain address',
    chainAddressErr: 'Blockchain address does not exist',
    receiverErr: 'Recipient does not exist or the account is abnormal',
    receiverError: 'Recipient does not meet the transaction conditions',
    donateErr: 'Cannot be gifted to oneself',
    donateSuccess: 'Transferred successfully',
    myInvite: 'My invitation',
    inviteCount: '{total} person has been invited',
    inviteLinkTips: 'Inviting you to join Grid.Art, Only you can understand the excitement here!',
    inviteLink: 'Link copied successfully, please paste to invite friends',
    creator: 'Creator',
    nftOrders: 'NFT Orders',
    inviteTips: 'Invite friends quickly',
    withDrawal: 'Withdrawal',
    recommendedTopics: 'Recommended Topics',
    activeUsers: 'Active users',
    hotTopic: 'Hot Topics',
    cropImg: 'Crop image',
    loading: 'Loading...',
    topUp: 'Top Up',
    re: 'Reply',
    sayTips: 'Hurry up, tell me something',
    author: 'Author',
    landlord: 'OP',
    floorMaster: 'Commenter',
    allReply: 'Reply All',
    replyTips: 'Hurry up, come and grab the sofa',
    replyCount: 'View all {reply_count} replies',
    replies: 'Reply',
    comment: 'Comment',
    chat: 'Chat',
    gridyAblum: 'Ablums',
    hadFollow: 'Followed',
    followEach: 'Friend',
    follow: 'Follow',
    albumDes: 'Album Details',
    selectAlbum: 'Select Album',
    selectAlbumTips: 'Please select or create an album',
    joined: 'Joined',
    titleTips: '2-24 chars, letters/numbers only',
    createAlbum: 'Please create an album',
    addNewAlbum: 'Create a new album',
    addAlbumTips: 'Please enter album name',
    noAlbum: 'Empty Album',
    none: 'Empty',
    showAll: 'All content has been displayed',
    creatingSimilarity: 'Creating Similarity',
    addOk: 'Added successfully',
    setup: 'Set Up',
    username: 'Username',
    nobind: 'Unbound',
    mobile: 'Mobile',
    email: 'Email',
    wechat: 'WeChat',
    passwd: 'Password',
    tradePWD: 'Trade password',
    realVerify: 'ID Verification',
    verify: 'Verification',
    verifying: 'Verificating',
    verified: 'Verificated',
    verifyFail: 'Verification Failed',
    blockChain: 'Chain Account',
    creating: 'Creating',
    browse: 'Details',
    reject: 'Rejected',
    freeze: 'Frozen',
    wantCreate: 'Create',
    myBankcard: 'My Bankcard',
    myAddress: 'My Address',
    bindWechat: 'Bind WeChat',
    modifyUname: 'Change username',
    modifyOk: 'Modified successfully',
    bindMobile: 'Bind Mobile',
    bindMobileTips: 'Tips: For account security,  please bind  your mobile',
    bindEmail: 'Bind Email',
    bindEmailTips: 'Tips: For account security,  please bind  your email',
    bindMobileFirst: 'Please bind your mobile first',
    bindEmailFirst: 'Please bind your email first',
    setPWD: 'Set Password',
    setTradePWD: 'Set Trade Password',
    blockChainTips: 'After ID Verification is passed, a blockchain account can be created',
    createSent: 'Create request sent successfully',
    days: 'Day',
    reqNewVer: 'To provide better service, please install the latest version',
    hadOut: 'You have been signed out',
    closeOther: 'Close Others',
    closeLeft: 'Close to the left side',
    closeRight: 'Close to the right side',
    closeAll: 'Close ALL',
    gltf: 'GLTF Voxel Model',
    glb: 'GLB Voxel Model',
    highPng: 'High Definition PNG',
    highJpg: 'High Definition JPG',
    highWebp: 'High Definition WEBP',
    highGif: 'High Definition GIF',
    effectImg: 'Rendering',
    highEffectImg: 'High Definition Rendering',
    effectImgRound: 'Rendering(circular)',
    highEffectImgRound: 'High Definition Rendering(circle)',
    downPaperErr: 'Drawing download failed, the current serial number can only be downloaded up to {down_limit} times!',
    exportInPreview: 'Please export in \'Export Preview\'',
    useClient: 'Please use the client to download the file',
    cantEdit: 'Cannot edit this work',
    copies: 'Copy',
    workSaveAs: 'Has been saved as: ',
    saveOk: 'Successfully saved',
    nameTips: 'Name: Max 18 chars, letters/numbers only',
    consumeMoney: 'Consumption {consumeNums} Coins, Check In Or completing tasks can earn Coins',
    noRightSave: 'No right to save other people\'s works',
    noRights: 'No right to publish other people\'s works',
    shareImgCreated: 'Shared image has been generated',
    downTips: 'Download Tips',
    all: 'All',
    cantOpen: 'Cannot open this drawing!',
    paperDeled: 'The selected drawing has been deleted!',
    useCountAdded: 'The usage frequency has increased!',
    userSn: 'Use serial number',
    snPass: 'Serial number verification passed',
    inputSN: 'Enter serial number',
    snTips: 'Tips: Scratch QR code on the packaging',
    recycle: 'Recycle',
    cantMeger: 'Locked objects cannot be merged',
    defaultObj: 'Default',
    newObj: 'New Object',
    copyObj: 'Copy Object',
    cutObj: 'Cut Object',
    inputTxt: 'Please enter text',
    newScene: 'New Scene',
    setCanvasSize: 'Set canvas size',
    crop: 'Cropping',
    exportFail: 'Export failed',
    backupTips: 'Please use the client for backup',
    imgCantLoad: 'This image does not support external loading',
    inputImgUrl: 'Enter image URL',
    fileErr: 'The file is damaged and cannot be opened',
    restoreOk: 'Successfully restored locally',
    opening: 'Opening file...',
    reported: 'Reported',
    reason: 'Submit reasons for reporting',
    reasonTips: 'Report reasons within 4-100 words',
    wait: 'Waiting...',
    actFail: 'Operation failed, please try again later',
    hotESC: 'When selecting a part of an object, press ESC to deselect it',
    hotDEL: 'When selecting a part of an object, press the DEL key to quickly delete it',
    hotEnter: 'When selecting a part of an object, press Enter to quickly crop it',
    hotShift: 'Press the Shift key to lock the scale when scaling',
    hotSpace: 'Press the spacebar to display the original image',
    hotShiftB: 'Press Shift+B to quickly set the canvas background',
    hotShiftG: 'Press Shift+G to quickly set grid color',
    tool: 'Tool',
    menu: 'Menu',
    other: 'Other',
    hadSendSMS: 'The code has been sent',
    hadSendEmail: 'The email code has been sent',
    reqVIP: 'Please activate VIP first',
    had: 'Had',
    setAs: 'Set As ',
    private: 'Private',
    public: 'Public',
    rejectAudit: 'Cancel Audit',
    auditPass: 'Pass Audit',
    unhot: 'Cancel Hot',
    hot: 'Hot',
    notRecommend: 'Cancel Recommend',
    recommend: 'Recommend',
    notOriginal: 'Cancel Original',
    original: 'Original',
    powerOnOff: 'Power Toggle',
    advertisingGarbage: 'Advertising Garbage',
    violationContent: 'Violation Content',
    maliciousWatering: 'Malicious Watering',
    repeatPosting: 'Repeat Posting',
    otherReasons: 'Other Reasons',
    actSuccess: 'Operation successful',
    pleaseSel: 'Please Select',
    finish: 'Finish',
    preStep: 'Previous Step',
    nextStep: 'Next Step',
    empty: 'Empty',
    hotKeysList: 'HotKeys',
    help: 'Help',
    quickStart: 'Quick Start',
    feedback: 'Feedback',
    aboutUs: 'About Us',
    checkUpdate: 'Check Update',
    resetZoom: 'Reset Zoom',
    view: 'View',
    canvasRatio: 'CanvasRatio',
    canvas: 'Canvas',
    moveDown: 'Move Down',
    moveUp: 'Move Up',
    moveRight: 'Move Right',
    moveLeft: 'Move Left',
    objPos: 'Object Coordinate',
    height: 'Height',
    width: 'Width',
    objZoom: 'Object Scaling',
    objSize: 'Object Size',
    triangl: 'Triangle',
    gridShape: 'Grid Shape',
    clearObj: 'Clear Objects',
    modify: 'Modify',
    revoke: 'Revoke',
    redo: 'Redo',
    clearHistory: 'Clear History',
    cut: 'Cut',
    paste: 'Paste',
    selectAll: 'Select All',
    deselect: 'Deselect',
    print: 'Print',
    import: 'Import',
    export: 'Export',
    file: 'File',
    draft: 'My Drafts',
    localFile: 'Local File',
    customize: 'Customize',
    quickOpen: 'Quick Open Pixel',
    pixel2Gridy: 'Import Pixel',
    pixelImport: 'Local Pixel Image',
    quickImport: 'Quick Import',
    excelDrawing: 'EXCEL Drawing',
    puzzleEffect: 'Puzzle Effect',
    puzzleDrawing: 'Puzzle Drawing',
    pdfPuzzleDrawing: 'Puzzle Drawing(PDF)',
    selectPalette: 'Select Palette',
    object: 'Object',
    ifDelete: 'Are you sure to delete?',
    editSceneName: 'Edit scene name',
    scene: 'Scene',
    zoomOut: 'Zoom Out',
    zoomIn: 'Zoom In',
    rendin: 'Rending...',
    works: 'Works',
    album: 'Albums',
    zone: 'Zone',
    nft: 'NFT',
    discover: 'Discover',
    aiart: 'AI Painting',
    editer: 'Create Pixel',
    addPost: 'Post Topic',
    message: 'Message',
    pleaseLogin: 'Sign In Please',
    task: 'Task',
    sign: 'Sign In',
    items: 'Items',
    money: 'Coins',
    order: 'Orders',
    vip: 'VIP',
    invite: 'Invite',
    localDrafts: 'Local Drafts',
    myAlbum: 'My Albums',
    myThread: 'My Topics',
    myHomepage: 'My HomePage',
    myNFT: 'My NFT',
    myFollow: 'My Follow',
    myFans: 'My Fans',
    myGuest: 'My Visitor',
    myDownload: 'My Downloads',
    myFavor: 'My Favorite',
    myPraise: 'My Thumbs up',
    myFootprint: 'My Footprint',
    myProfile: 'Profiles',
    myAccount: 'Account',
    logout: 'Sign Out',
    ifLogout: 'Are you sure to sign out?',
    firstColor: 'First Color(Left Click)',
    secondColor: 'Second Color(Right Click)',
    exchange: 'Exchange',
    retract: 'Retract',
    open: 'Open',
    home: 'Home',
    login: 'Sign In',
    reg: 'Sign Up',
    setNick: 'Set Nickname',
    wechatLogin: 'WeChat Sign In',
    unameLogin: 'Username Sign In',
    phoneLogin: 'Mobile Sign Up/Sign In',
    emailLogin: 'Email Sign Up/Sign In',
    loging: 'Signing In...',
    reqUname: 'Enter Username',
    reqPWD: 'Enter Password',
    reqPhone: 'Enter Mobile',
    reqEmail: 'Enter Email',
    reqMcode: 'Enter 6-digit Code',
    getMcode: 'Send Code',
    qrExpires: 'QR has expired, click to refresh',
    scanLogin: 'Use WeChat, Scan to sign in',
    otherLogin: 'Other ways to sign in',
    agree: 'Agree',
    agreement: 'User Agreement',
    privacy: 'Privacy Policy',
    setHeader: 'Set Avatar',
    reqNick: 'Enter nickname',
    commit: 'Submit',
    uploading: 'Uploading...',
    busy: 'Too hot, please try again later',
    setOk: 'Setting successful',
    reqCorrectPhone: 'Please enter correct Mobile',
    reqCorrectEmail: 'Please enter correct Email',
    usernameErr: 'Username 6-18 chars, cannot contain special chars',
    PWDErr: 'Password 8-20 chars, must contain letters/numbers/special chars',
    agreeFirst: 'Please read and agree to the User Agreement and Privacy Policy',
    nickErr: 'Nickname 6-18 chars, cannot contain special chars',
    nickOk: 'Nickname has been set',
    smsCodeErr: 'Please enter correct code',
    mergeDown: 'Merge Down',
    clear: 'Clear',
    layer: 'Layer',
    blankObj: 'Add Blank Object',
    openLocalImages: 'Add Local image',
    openWebImage: 'Add Web image',
    editObjName: 'Edit Name',
    grid: 'Grids',
    createTime: 'Creation Time',
    canvaSizes: 'Canvas Size',
    consumeTime: 'Consume Time ',
    fixCanvas: 'Match Canvas',
    setCanvas: 'Settings',
    leftBar: 'Left Side',
    rightBar: 'Right Side',
    newFile: 'New File...',
    openFile: 'Open...',
    save: 'Save',
    saveAs: 'Save As...',
    cloudSync: 'Sync To Cloud',
    rename: 'Rename',
    cursor: 'Cursor(V)',
    partSelect: 'Partially Selected(A)',
    fill: 'Pencil(P)',
    batchFill: 'Bucket(F)',
    erase: 'Eraser(E)',
    batchErase: 'Magic Eraser(M)',
    brush: 'Brush(B)',
    spray: 'Spray(S)',
    line: 'Line(L)',
    ellipse: 'Ellipse(1)',
    rectangle: 'Rectangle(2)',
    triangle: 'Triangle(3)',
    star: 'Star(4)',
    pick: 'Dropper(D)',
    curColor: 'Current Color',
    hand: 'Hand(H)',
    txt: 'Text(T)',
    select: 'Select(C)',
    resize: 'Scale(Q)',
    remove: 'Remove',
    pickColor: 'Color picking',
    pleaseInput: 'Please enter content...',
    newWork: 'New Pixel Canvas',
    localImg: 'Local Image',
    webImg: 'Web Image',
    gridyFile: 'Gridy File',
    myWorks: 'My Works',
    drafts: 'Drafts',
    randomPattern: 'Random',
    recentlyOpened: 'Recently',
    pleaseSelect: 'Please Select...',
    pubWork: 'Publish works',
    submitting: 'Submitting...',
    catalogue: 'Catalogue',
    title: 'Title',
    inputTitle: 'Please enter title of the work',
    introduction: 'Intro',
    inputIntro: 'Please enter introduction of the work',
    tag: 'Tags',
    showOut: 'Exhibition',
    pubShow: 'Public show',
    share: 'Share',
    shareOut: 'Share It Out',
    hotKeys: 'Hotkeys',
    palette: 'Palette',
    standardColor: 'Standard Color',
    moreColors: 'More Colors...',
    CDStart: 'Distance Star',
    CDStop: 'Distance End',
    end: 'Ended',
    hour: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    edit: 'Edit',
    flip: 'Flip',
    flipH: 'Flip Horizontal',
    flipV: 'Flip Vertical',
    sort: 'Order',
    layTop: 'Send To Top',
    layBottom: 'Send To Back',
    layUp: 'Bring Forward',
    layDown: 'Send Backward',
    align: 'Align',
    alignLeft: 'Align Left',
    alignCenter: 'Align Horizontal Center',
    alignRight: 'Align Right',
    alignTop: 'Align Top',
    alignMiddle: 'Align Vertical Center',
    alignBottom: 'Align Bottom',
    alignSpaceH: 'Horizontal Equidistant',
    alignSpaceV: 'Vertical Equidistant',
    group: 'Group',
    ungroup: 'Ungroup',
    merge: 'Merge',
    lock: 'Lock',
    unlock: 'Unlock',
    copy: 'Copy',
    objColors: 'Colors',
    setPalette: 'Color Toning',
    ifRestoreImage: 'Are you sure to restore the image?',
    restoreImage: 'Restore',
    rotate: 'Rotate',
    rotateRight: 'Rotate Clockwise',
    rotateLeft: 'Rotate Anticlockwise',
    loop: 'Loop',
    playRate: 'Frame rate: {rate} frames per second',
    playTimes: 'Play Count',
    animateSet: 'Animation Settings',
    addScene: 'Add Scene',
    copyparse: 'Copy',
    delScene: 'Are you sure to delete this scene?',
    play: 'Play',
    stop: 'Stop',
    singlePrinting: 'Front Printing',
    backPrinting: 'Back Printing',
    twoPrinting: 'Double sided Printing',
    AImages: 'AI generated images',
    exportOrigin: 'Export Original',
    create: 'Create',
    toEditor: 'Return to Editor',
    colorCount: 'Color Quantity',
    changeColor: 'Change color',
    deleteColor: 'Remove color',
    restoreAll: 'Are you sure to restore to the initial state?',
    restore: 'Restore',
    numLimit: 'Limited to {total} servings',
    preemptiveRights: 'Priority',
    payWin: 'Pay(winning)',
    notWin: 'Not winning',
    subscribed: 'Subscribed',
    wantSubscribed: 'Subscribe',
    sellOut: 'Sold Out',
    waitingDraw: 'Subscription successful, please wait for the lottery draw',
    castOk: 'Congratulations, successfully cast',
    claimed: 'Congratulations, successfully claimed',
    waiting: 'It\'s not open yet',
    pay: 'Pay',
    casting: 'Casting Now',
    favorOk: 'Successfully added to favorites',
    addAlbum: 'Join Album',
    buyIt: 'Buy Now',
    purchased: 'Purchased',
    copyLink: 'Copy Works Link',
    secondCreation: 'Second Creation',
    wantCast: 'Casting Now',
    alreadyCast: 'Casted',
    claimAirdrop: 'Claim Airdrop',
    received: 'Received',
    cantBuy: 'Can\'t purchase! Please export the drawings first!',
    toning: 'Toning',
    primaryColors: 'Primary Colors',
    simplifiedColors: 'Simplified Colors',
    shape: 'Shape',
    size: 'Size',
    pre: 'Backward',
    next: 'Forward',
    originImage: 'Original',
    savePaperLimit: 'Drawing save failed, the current serial number can only save up to {save_limit} copies!',
    savePaperFail: 'Drawing save failed, please return and try again!',
    savePaperOk: 'Drawing has been saved! You can find it in \'My Drawings\'!',
    createPuzzle: 'AI Pixel Puzzle',
    downPaperTips: 'Download Drawings',
    downPaper: 'Download Drawings',
    nextPage: 'Next Page',
    prePage: 'Previous Page',
    savePaperTips: 'Save Drawings',
    savePaper: 'Save drawings',
    rending: 'AI rendering, please be patient and wait...',
    paySuccess: 'Pay Success',
    buy: 'Buy',
    payed: 'Paid',
    orderExpires: 'Order has expired',
    orderCancel: 'Order cancelled',
    orderClosed: 'Order has been closed',
    prompt: 'Prompt',
    sure: 'OK',
    refuse: 'Cancel',
    ok: 'OK',
    cancel: 'Cancel',
    canvaWidth: 'Canvas Width',
    canvaHeight: 'Canvas Height',
    canvaSize: 'Select canvas size',
    va: 'Value',
    sa: 'Saturation',
    hu: 'HUE',
    quantFilter: 'Filter Colors',
    mergeColors: 'Merge Colors',
    autoDenoise: 'Remove Colors',
    tips: 'Tips',
    lastDigits: 'Last Digits',
    selectCard: 'Select Bankcard',
    bCard: 'Bankcard',
    bankCard: 'Card No.',
    inputAmount: 'Enter the withdrawal amount',
    withdrawalAmount: 'The withdrawal amount is',
    canWithdrawal: 'Maximum withdrawal',
    amountLess: 'Insufficient Balance',
    withDrawalFee: 'Withdraw once a day, handling fee ',
    selectPCD: 'Please select area',
    selectYMD: 'Please select date',
    year: 'Year',
    month: 'Month',
    day: 'Day',
    tradePwd: 'Enter your trade password',
    recharge: 'Please recharge now',
    tradePwdError: 'Enter the correct trade password',
    moneyLess: 'Insufficient Coins, Please recharge now',
    moneyPay: 'Coins payment',
    scanPay: 'Use WeChat to scan for payment',
    wechatPay: 'WeChat Pay',
    castingFail: 'Insufficient materials for casting',
    materials: 'Casting Requires',
    remaining: 'Remaining',
    close: 'Close',
    exportPreview: 'Export Preview',
    customizedPuzzle: 'Customized Puzzle',
    exportMainImage: 'Export Main Image',
    exportPreviewImage: 'Export Preview Image',
    mainImage: 'Main Image',
    previewImage: 'Preview Image',
    pixel: 'Pixel',
    gridy: 'Gridy',
    puzzle: 'Puzzle',
    exportPixel: 'Export Pixel',
    exportGridy: 'Export Gridy',
    exportPuzzle: 'Export Puzzle',
    advancedExport: 'Advanced Export',
    publish: 'Publish',
    createShareImage: 'Get Share Image',
    shareMoments: 'Share This Moments',
    onWall: 'Hang it on the wall',
    quitePreview: 'Quite Preview',
    preview: 'Preview',
    ifAdvancedMode: 'Are you sure to enter advanced mode?',
    advancedMode: 'Advanced Mode',
    round: 'Round',
    square: 'Square',
    brickShape: 'Brick Shape',
    manageColors: 'Manage Colors',
    change: 'Change',
    delete: 'Delete',
    hide: 'Hide',
    show: 'Show',
    moreThen: 'More Then',
    noiseReduction: 'Noise Reduction',
    positive: 'Natural',
    reverse: 'Reverse',
    color: 'colors',
    particle: 'Particles',
    colors: 'Colors',
    hue: 'HUE',
    frame: 'Frame',
    default: 'Default',
    bg: 'Background',
    picSize: 'Size',
    getDrawings: 'Drawings',
    buyNow: 'Buy Now',
    uploadPhoto: 'Upload Photo',
    lookBack: 'Back',
    ahead: 'Ahead',
    back: 'Back',
    more: 'More',
    pdfPaper: 'Drawings(PDF)',
    paper: 'Drawings',
    sn: 'SN'
  },
  paletteName: {
    base: 'Theme',
    brickfy: 'Bricky',
    brickfyColors: 'Colors',
    skin: 'Skin',
    gray: 'Gray',
    metals: 'Metals'
  },
  style: {
    default: '',
    dance: 'Dance',
    classic: 'Classic',
    pink: 'Pink',
    golden: 'Golden',
    colors: 'Colors'
  },
  colorfy: {
    dance: 'Dance',
    classic: 'Classic',
    pink: 'Pink',
    golden: 'Golden',
    colors: 'Colors'
  },
  extendPalettes: {
    BRICK: 'Bricky',
    HDD: '黄豆豆',
    KK: '卡卡',
    MARD: 'Mard',
    MM: '漫漫',
    MXW: '咪小窝',
    PP: '盼盼',
    XW: '小舞'
  },
  shapes: {
    'line': 'Line',
    'ellipse': 'Ellipse',
    'rectangle': 'Rectangle',
    'triangle': 'Triangle',
    'star': 'Star',
    'heart': 'Heart'
  },
  errMsg: {
    'BAD_REQUEST': 'Request exception, please contact customer service',
    'UNAUTHORIZED': 'Sign In Please',
    'REQUEST_DENIED': 'This request has been rejected',
    'NOT_FOUND': 'Data not found',
    'METHOD_NOT_ALLOWED': 'Unsupported request method',
    'NOT_ACCEPTABLE': 'Unacceptable request',
    'UNSUPPORTED_MEDIA_TYPE': 'Unacceptable request',
    'REQUEST_RATE_LIMITED': 'Please do not refresh frequently, please try again later',
    'INTERNAL_SERVER_ERROR': 'Request error, please contact customer service',
    'BAD_GATEWAY': 'Gateway error, please contact customer service',
    'SERVICE_UNAVAILABLE': 'Request not available, please contact customer service'
  },
  guideSteps: [
    {
      element: '.my-menu',
      popover: {
        title: 'Start',
        description: 'Select \'New...\' in \'File\'',
        position: 'bottom'
      }
    },
    {
      element: '.my-link-add',
      popover: {
        title: 'Import Web Images',
        description: 'Import web images and automatically generate gridy works',
        position: 'left'
      }
    },
    {
      element: '.my-object-add',
      popover: {
        title: 'Import Local Images',
        description: 'Import local images and automatically generate gridy works',
        position: 'left'
      }
    },
    {
      element: '.my-blank-object-add',
      popover: {
        title: 'Add empty object',
        description: 'After adding empty objects, you can freely create',
        position: 'left'
      }
    },
    {
      element: '.left-side',
      popover: {
        title: 'Select tools',
        description: 'Please choose a drawing tool for artistic creation',
        position: 'right'
      }
    },
    {
      element: '.work-area',
      popover: {
        title: 'Start artistic creation',
        description: 'Click the left or right button to start drawing',
        position: 'right'
      }
    }
  ]
}
