<template>
  <div style="width:100%;height:100%;">
    <pull-to :id="'topics-' + mod + '-container'" :style="listStyle" class="my-list" :top-load-method="(loaded) => topPull ? loadData(loaded, showUp ? 'up' : 'renew') : {}" :bottom-load-method="(loaded) => bottomPull ? loadData(loaded, 'down') : {}" @infinite-scroll="getData('down')">
      <div class="flow-container">
        <div v-for="(column, index) in columns" :key="index" class="column" :style="{'width': columnWidth + 'px' }">
          <div v-for="(item, i) in column.items" :key="i" class="item">
            <div class="card" :style="{ 'width': cardWidth + 'px', 'height': (item._height - 5) + 'px' }">
              <div class="user" @click="emit('goto', ['topicDetail', { topicid: item.id } ])">
                <div class="center white" :style="{'line-height': '22px'}">
                  <b>#{{ item.content }}#</b>
                  <span class="color-bb"><br>{{ $t('i.hotValue') }} {{ item.view_count }} {{ $t('i.topic') }} {{ item.thread_count }}</span>
                </div>
                <div class="float-right">
                  <i class="iconfont my-right color-cc" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="itemNums && !data.noDownPage" class="tip load" @click="getData('down')"><i class="iconfont my-dot-more" /></div>
      <div v-if="flowHeight > height && data.noDownPage" class="tip">{{ $t('i.showAll') }}</div>
      <el-empty v-if="(!itemNums && !loading[mod])" :image="emptyImage" :description="$t('i.none')" />
    </pull-to>
    <div v-if="data.scrollTop > 200" class="go-top">
      <el-button class="tap-btn" @click="backTop()">
        <i class="iconfont my-top" />
      </el-button>
    </div>
  </div>
</template>
<script>
import { mixins } from '@/mixins/topics'
export default {
  mixins: [mixins]
}
</script>
